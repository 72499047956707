import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';

export default function SearchItem({
    slug,
    name,
    type,
    selected,
    onHover,
    truncate,
}) {
    const className = classnames('d-block mb-2 line-height-rem-1-25', {
        'text-primary': selected,
        'text-dark': !selected,
        'text-truncate': truncate,
    });

    return type === 'MoreItems' ? (
        <div className="line-height-rem-1">
            <a href={slug} onMouseEnter={onHover} className={className}>
                {name}
                <FontAwesomeIcon icon={faLongArrowRight} className="ml-2" />
            </a>
        </div>
    ) : (
        <a href={slug} onMouseEnter={onHover} className={className}>
            {name}
        </a>
    );
}
