import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { redirectToUrl } from '../../utils/controller';
import { BLOG_CATEGORY_COLORS } from '../../models/constants';
import { Link } from 'react-router-dom';

const BlogCategoryMenu = ({ categories }) => {
    const [hover, setHover] = useState({});

    const handleClick = useCallback(
        (url) => () => {
            redirectToUrl(url);
        },
        [],
    );

    const handleMouseEnter = useCallback(
        (index) => () => {
            setHover(prevHover => ({ ...prevHover, [index]: true }));
        },
        [hover],
    );

    const handleMouseLeave = useCallback(
        (index) => () => {
            setHover(prevHover => ({ ...prevHover, [index]: false }));
        },
        [hover],
    );

    return (
        <nav>
            <ul className="blog-category-menu d-block text-uppercase">
                {categories &&
                    categories?.map(({ Slug = '', BlogCategory, BlogPostCategoryId }, index) => (
                        <li
                            key={`bgc_${index}`}
                            className={`py-2-5 mb-1 rounded bg-blog-color-${BLOG_CATEGORY_COLORS[BlogPostCategoryId] || 0} d-flex justify-content-between align-items-center cursor-pointer ${hover?.[index] ? 'arrow-move-in pl-3 pr-4': 'arrow-move-out px-3'}`}
                            onClick={handleClick(Slug)}
                            onMouseEnter={handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave(index)}
                        >
                            <Link to={`/${Slug}`} className="text-dark">
                               {BlogCategory}
                            </Link>
                            <div className="circle">
                                <FontAwesomeIcon
                                    icon={faLongArrowRight}
                                    className=""
                                />
                            </div>
                        </li>
                    ))}
            </ul>
        </nav>
    );
};

export default BlogCategoryMenu;
