import {put, takeLatest,} from 'redux-saga/effects'
import {success} from "../actions";
import {ADD_TO_CART_NOPCOMMERCE, ADD_TO_CART_AJAX} from "./index";
import {showError, showSuccess} from "../notification/actions";

function* handleAddToCartResult({result}) {
    const {success, message} = result;
    if(success) {
        yield put(showSuccess(message));
    } else {
        for(const m of message) {
            yield put(showError(m)); 
        }
    }
}

function* handleAddToCartAjaxSizeResult({result}) {
    const {Success, Message} = result;  
    if(Number(Success) === 1) {
        yield put(showSuccess(Message));
    } else {
        yield put(showError(Message));
    }
}

function* cartSaga() {
    yield takeLatest(success(ADD_TO_CART_NOPCOMMERCE), handleAddToCartResult);
    yield takeLatest(success(ADD_TO_CART_AJAX), handleAddToCartAjaxSizeResult);
}

export default cartSaga;