import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

const InlineNotify = ({message, variant, className}) => {

  const variants = {
    'success-light':  {
      className: 'p-2-5 bg-green-light rounded-5 text-green-darker d-flex',
      icon: <div className="mr-2"><FontAwesomeIcon icon={faInfoCircle} className="text-green-2" data-testid="inline-notify-icon" /></div>,
    }
  }
  
  return (
    <div className={classNames("inline-notify__container", variants[variant]?.className, className)} data-testid="inline-notify">
      {variants[variant]?.icon && variants[variant].icon}
      {message && <div className={`inline-notify__text`}>{message}</div>}
    </div>
  )
};  

export default InlineNotify;
