import React from 'react';
import Footer from "./Footer";

function Content({onSkip, primaryButton, onClick, link, children}) {
    return <>
        <div className="font-size-0-875 line-height-3 h-100 text-gray">{children}</div>
        <Footer onSkip={onSkip} onClick={onClick} link={link} button={primaryButton}/>
    </>
}

export default Content;
