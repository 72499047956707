import React from 'react';
import BlogCategoryPostList from '../../components/Blog/BlogCategoryPostList';
import BlogTitle from '../../components/Blog/BlogTitle';
import BlogHtmlMeta from '../../components/Blog/BlogHtmlMeta';
import { useSelector } from 'react-redux';
import { getShopStoreId } from '../../store/shopstore/selectors';

const BlogCategoryScreen = ({ id: categoryId }) => {
  const storeId = useSelector(getShopStoreId);
  return (
    <>
      <div className="blog">
        <BlogTitle storeId={storeId} />
        <BlogCategoryPostList storeId={storeId} categoryId={categoryId} />
      </div>
    </>
  );
};

export default BlogCategoryScreen;
