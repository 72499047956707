import React, { useEffect, useState } from 'react';
import BannerZone from '../../banners/BannerZone';
import ChecklistService from '../../services/checklist';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import NewbornEquipment from '../../components/NewbornEquipment/NewbornEquipment';

const NewbornChecklistScreen = ({
    registered,
    loginUrl,
    description,
    loginButtonText,
}) => {
    const [pageData, setPageData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getPageData = async () => {
            const data = await ChecklistService.get();
            setPageData(data);
            setLoading(false);
        };
        getPageData();
    }, []);
    return (
        <>
            <MetaHeader
                title={pageData?.Title}
                description={pageData?.MetaDescription}
            />
            <h1 className="font-size-1-125 font-weight-bold mb-4 mt-5">
                {pageData?.Title}
            </h1>
            <BannerZone bannerBlockId={15} topicId={84} />
            <div
                className="mb-3 formatted-content font-size-0-875 text-gray"
                dangerouslySetInnerHTML={{ __html: pageData?.TopDescription }}
            ></div>
            <NewbornEquipment
                registered={registered}
                description={description}
                loginButtonText={loginButtonText}
                loginUrl={loginUrl}
            />
            <div
                className="mt-3 formatted-content"
                dangerouslySetInnerHTML={{
                    __html: pageData?.BottomDescription,
                }}
            ></div>
        </>
    );
};

export default NewbornChecklistScreen;
