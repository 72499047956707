import React, { useMemo } from 'react';
import SquareCheckbox from '../../../elements/SquareCheckbox';
import CheckboxComboIconWrapper from './CheckboxComboIconWrapper';

/**
 * @typedef {Object} Item
 * @property {string} name - Item name.
 * @property {string} description - Item description.
 * @property {boolean} isEnabled - Item is enabled.
 * @property {object} icon - Item icon. (font awesome icon name)
 **/

/**
 * 
 * @param {Item} item 
 * @param {number} index
 * @param {number} selectedIndex
 * @param {boolean} disabled
 * @param {function} onClick
 * @param {object} fontAwesomeIcon  
 * 
 * @returns 
 */
const CheckboxComboListItem = ({
    item,
    index,
    selectedIndex,
    disabled,
    onClick,
    hoverClassName = 'border-hover-dark',
}) => {
    const selected = useMemo(() => selectedIndex === index || false, [selectedIndex, index]);
    return (
        <li
            className={`mb-2-5 py-2-5 border d-flex align-items-center rounded cursor-pointer ${selected ? 'border-primary border-hover-primary border-2px' : hoverClassName} ${disabled ? 'opacity-0-5 disabled-no-hover cursor-default' : ''}`}
            key={index}
            onClick={disabled ? null : onClick}
        >
            <CheckboxComboIconWrapper
                icon={item.icon}
                containerClass={`mx-2-5 ${selected ? 'text-primary' : ''}`}
            />
            <div
                className={`d-flex font-size-0-875 flex-column flex-1 align-self-center ${
                    selected ? 'text-primary' : ''
                }`}
            >
                <span className="font-weight-medium line-height-rem-1-125">
                    {item?.name}
                </span>
                <span
                    className="font-size-0-75 line-height-rem-1-125"
                    dangerouslySetInnerHTML={{
                        __html: item?.description,
                    }}
                />
            </div>
            <SquareCheckbox
                value={selected}
                className={'ml-auto mr-2-5'}
                disabled={disabled}
            />
        </li>
    );
};

export default CheckboxComboListItem;
