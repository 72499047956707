import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLongArrowRight,
    faLongArrowLeft,
} from '@fortawesome/pro-regular-svg-icons';

const ContentCarouselNav = ({
    className,
    selected,
    maxItemNumber,
    handleNext,
    handlePrev,
    navItemConfig,
}) => {
    const createTitle = useMemo(
        () => (
            <>
                {Array.isArray(navItemConfig?.middle?.contents) ? (
                    navItemConfig?.middle?.contents?.[selected]
                ) : (
                    <>
                        {selected + 1} / {maxItemNumber}
                    </>
                )}
            </>
        ),
        [navItemConfig?.middle?.contents, selected, maxItemNumber],
    );
    return (
        <>
            {navItemConfig?.middle?.titleBefore && (
                <div
                    className={
                        navItemConfig?.middle?.titleBeforeClassName || ''
                    }
                >
                    {createTitle}
                </div>
            )}
            <div
                className={`content-carousel-nav d-flex align-items-center justify-content-center mt-2 ${
                    className || ''
                }`}
            >
                <div
                    className={`cursor-pointer ${
                        navItemConfig?.left?.visible ? 'visible' : 'invisible'
                    } `}
                    onClick={handlePrev}
                >
                    <div className="circle bg-gray-light d-inline-block">
                        <FontAwesomeIcon icon={faLongArrowLeft} className="" />
                    </div>
                    {navItemConfig?.left?.children || ''}
                </div>

                <span
                    className={`font-size-0-875 mx-2 ${
                        navItemConfig?.middle?.className || ''
                    }`}
                >
                    {createTitle}
                </span>
                <div
                    className={`cursor-pointer ${
                        navItemConfig?.right?.visible ? 'visible' : 'invisible'
                    } `}
                    onClick={handleNext}
                >
                    {navItemConfig?.right?.children || ''}
                    <div className="circle bg-gray-light d-inline-block">
                        <FontAwesomeIcon icon={faLongArrowRight} className="" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentCarouselNav;
