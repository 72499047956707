import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';

const CircleLink = ({
    className,
    containerClassName,
    animation,
    disabled,
    visible = true,
    iconType = faLongArrowRight,
    onClick,
}) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = useCallback(() => {
        setHover(true);
    }, [hover]);

    const handleMouseLeave = useCallback(() => {
        setHover(false);
    }, [hover]);

    if (visible === false) {
        return null;
    }

    return (
        <div
            className={`${containerClassName || ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={disabled ? null : onClick}
        >
            <div
                className={`circle-link d-inline-block font-size-1 cursor-pointer ${
                    animation ? (hover ? 'move-in' : 'move-out') : ''
                } ${className || ''}`}
            >
                <FontAwesomeIcon
                    icon={iconType}
                />
            </div>
        </div>
    );
};

export default CircleLink;
