const axios = require('axios');

/* TODO: OLD Versions */
async function addToCart(productId, size) {
    const result = await axios.get('/BrendonAjax/Wishlist/AddToCart/', {
        params: {
            productid: productId,
            sizename: size,
        },
    });
    return result.data.message;
}

/* TODO: New Versions */
async function addProductToWishlist(productId) {
    const { data } = await axios.get(`/Wishlist/Add/${productId}`);
    return data;
}

async function getWishlist() {
    const { data } = await axios.get(`/Wishlist/GetWishlist/`);
    return data;
}

async function removeWishlistItem(productId) {
    const { data } = await axios.delete(`/Wishlist/DeleteWishlistItem/${productId}`);
    return data;
}

async function addWishlistItemToCart(productId, sizeName) {
    const result = await axios.get(`/Wishlist/AddToCart/${productId}${sizeName && sizeName!='' ? `/${sizeName}`:''}`);
    return result.data;
}

export const Wishlist = {
    addToCart,
    addProduct: addProductToWishlist,
    removeProduct: removeWishlistItem,
    getWishlist,
    removeWishlistItem,
    addWishlistItemToCart,
};

export default Wishlist;
