import { object, string, ref } from 'yup';
import { legacy_t as t} from '../../contexts/ResourceContext';
import { isSpaceFilled } from '../../utils/validation';

export default function createCustomerPersonalDataSchema() {
    return object({
        oldPassword: string()
            .transform(isSpaceFilled)
            .required(t('brendon.customer.validation.oldPasswordRequired')),
        newPassword: string()
            .transform(isSpaceFilled)
            .required(t('brendon.customer.validation.newPasswordRequired'))
            .min(
                8,
                t('brendon.customer.validation.passwordLength').replace('#0#', 8),
            )
            .test('newPassword', t('brendon.customer.validation.passwordComplexity'), (value) => {
                // check if password contains spaces
                if (value?.indexOf(' ') >= 0) {
                    return false;
                }
                // check if password contains at least one digit, one lowercase and one uppercase letter
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
                return passwordRegex.test(value);
            }),
        newPasswordConfirm: string()
            .transform(isSpaceFilled)
            .required(t('brendon.customer.validation.newPasswordConfirmRequired'))
            .oneOf([ref('newPassword'), null], t('brendon.customer.validation.newPasswordConfirmMatch')),
    });
}
