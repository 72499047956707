import React from 'react';
import BlogCategoryScreen from '../views/blog/BlogCategoryScreen';
import BlogPostScreen from '../views/blog/BlogPostScreen';
import BlogTagScreen from '../views/blog/BlogTagScreen';
import { Provider } from 'react-redux';
import getStore from '../store';
import MainLayout from '../views/layouts/MainLayout';
import TopicDetails from '../views/topic/TopicDetails';
import CategoryScreen from '../views/products/CategoryScreen';
import ProductScreen from '../views/products/ProductScreen';
import ShopsScreen from '../views/shop-finder/ShopsScreen';
import PageNotFound from '../views/screens/PageNotFound';
import PaymentCallbackScreen from '../views/checkout/PaymentCallbackScreen';
import PaymentCancelkScreen from '../views/checkout/PaymentCancelScreen';
import { ScrollRestoration } from 'react-router-dom';

const DynamicComponent = (Component) => {
  return (props) => (
    <Provider store={getStore()}>
      <MainLayout>
        <Component {...props} />
      </MainLayout>
      <ScrollRestoration />
    </Provider>
  );
};

const PureDynamicComponent = (Component) => {
  return (props) => <Component {...props} />;
};

export const DynamicRouterDescriptor = {
  BlogCategory: DynamicComponent(BlogCategoryScreen),
  BlogPost: DynamicComponent(BlogPostScreen),
  BlogTag: DynamicComponent(BlogTagScreen),
  Topic: DynamicComponent(TopicDetails),
  ProductCategory: DynamicComponent(CategoryScreen),
  Product: DynamicComponent(ProductScreen),
  ShopFinder: DynamicComponent(ShopsScreen),
  PageNotFound: PureDynamicComponent(PageNotFound),
  PaymentCallback: DynamicComponent(PaymentCallbackScreen),
  PaymentCancel: DynamicComponent(PaymentCancelkScreen),
};
