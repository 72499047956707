import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import CustomerMainContainer from '../../components/CustomerNew/CustomerMainContainer';
import CustomerTitle from '../../components/CustomerNew/CustomerTitle';
import CustomerDashboard from '../../components/CustomerNew/CustomerDashboard';
import { useResource } from '../../contexts/ResourceContext';

const CustomerDashboardScreen = () => {
    const { t } = useResource();
    return (
        <>
            <MetaHeader title={t('PageTitle.Account')} showBrendonAddon />
            <CustomerMainContainer>
                <div className="row justify-content-center mt-4">
                    <CustomerTitle
                        title={t('brendon.customer.dashboard.title')}
                    />
                </div>
                <CustomerDashboard />
            </CustomerMainContainer>
        </>
    );
};

export default CustomerDashboardScreen;
