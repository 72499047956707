import React from 'react';
import { redirectToUrl } from '../../../../utils/controller';
import MoreLink from '../../MoreLink';
import { useResource } from '../../../../contexts/ResourceContext';
import { Link } from 'react-router-dom';

const PregnancyPost = ({ title, text, image, url }) => {
  const { t } = useResource();

  return (
    <div className="pregnancy-post">
      <div
        className="pregnancy-post-img cursor-pointer"
        onClick={() => redirectToUrl(url)}
      >
        <img src={image} alt={title} className="w-100" />
      </div>
      <div className="pregnancy-post-content d-flex flex-column justify-content-end p-3 bg-orange-light">
        <h2 className="font-size-1-25 mb-3 line-height-rem-1-25">
          <Link to={url} className="text-body">
            {title}
          </Link>
        </h2>
        <Link to={url} className="text-body h-100">
          {text}
        </Link>
        <MoreLink
          classNameContainer="mt-3"
          url={url}
          title={t('brendon.blog.readmore')}
        />
      </div>
    </div>
  );
};

export default PregnancyPost;
