import React, {useEffect, useRef, useState} from "react";
import loader from "../../assets/loading.gif";
import { useIntersection } from 'react-use'
import LazyLoad from "vanilla-lazyload";

function LazyImage({alt, title, src, disabledLoad, className, ...rest}) {
    const [isLoaded, setLoaded] = useState(false);
    const [lazyInstance, setLazyInstance] = useState(null)
    const imageRef = useRef(null);
    const intersection = useIntersection(imageRef, { root: null, rootMargin: '0px', threshold: 0})

    useEffect(() => {
        if (lazyInstance || disabledLoad || isLoaded) {
            return
        }

         setLazyInstance(new LazyLoad( {
            elements_selector: ".lazy",
            cancel_on_exit: false,
            unobserve_entered: true,
            callback_loaded: () => {
                setLoaded(true)
            }
        })) 
 
        return () => lazyInstance && lazyInstance.destroy();
    }, [disabledLoad])
    
    if (!disabledLoad && !isLoaded && intersection && intersection.isIntersecting) {
        if (!lazyInstance) {
            setLazyInstance(new LazyLoad({
                elements_selector: ".lazy", 
                cancel_on_exit: false,
                unobserve_entered: true,
                callback_loaded: () => {
                    setLoaded(true)
                }
            }))
        }
    }
 
    return <img alt={alt} title={title} className={`${className} lazy ${!isLoaded ? 'loading' : ''}`} ref={imageRef} src={loader} data-src={src} {...rest}/>
}
export default LazyImage