import React, { useEffect } from 'react';
import { orderDataForGA4 as orderDataService } from '../../services/checkout';
import GA4Service from '../../services/gaGA4';
import GAUAService from '../../services/gaEnhancedEcommerce';
import BannerZone from '../../banners/BannerZone';
import { getURLPathWithParams } from '../../utils/staticPaths';
import { useParams } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

const PurchaseCompletedScreen = () => {
  const { t } = useResource();
  const { orderId } = useParams();

  useEffect(() => {
    const getOrderData = async () => {
      const result = await orderDataService.get(orderId);
      if (Object.keys(result)?.length > 0) {
        GA4Service.gtagEvent(
          'purchase',
          GA4Service.transformOrderData(orderId, result),
        );

        // Legacy UA event
        const uaProducts = result?.Products?.map((product) => ({
          id: product?.ProductId,
          name: product?.ProductName,
          brand: product?.Brand,
          category: product?.CategoryName,
          variant: product?.Variant,
          price: product?.Price,
          quantity: product?.Quantity,
        }));

        GAUAService.purchaseCompleted(
          {
            id: orderId,
            revenue: result?.Revenue,
            shipping: result?.Shipping,
          },
          uaProducts,
        );
        // end of legacy UA event
      }
    };
    getOrderData();
  }, [orderId]);

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
        <div className="mb-2">
          <div className="font-size-1 font-weight-medium text-uppercase pb-2">
            {t('Checkout.YourOrderHasBeenSuccessfullyProcessed')}
          </div>
          <div
            className="font-size-0-875 pb-2"
            dangerouslySetInnerHTML={{
              __html: t('Checkout.ConfirmationAndTracking'),
            }}
          ></div>
          <div className="font-size-0-875 pb-2">
            {t('Checkout.OrderNumber')}: {orderId}
          </div>
        </div>
        <BannerZone bannerBlockId={18} containerClass="mb-2" />
        <a
          href={getURLPathWithParams('customerOrderDetails', { orderId })}
          className="btn btn-outline-primary w-100 mb-2"
        >
          {t('Checkout.PlacedOrderDetails')}
        </a>
        <a href="/" className="btn btn-primary btn-lg w-100">
          {t('Checkout.ThankYou.Continue')}
        </a>
      </div>
    </div>
  );
};

export default PurchaseCompletedScreen;
