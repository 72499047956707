import React from "react";


const AddressBoxShippingData = ({ address }) => {
    return (
        <>
            <div className="font-weight-medium text-dark">
                {address?.ReceiverName}
            </div>
            <div>
                {`${address.ZipPostalCode} ${address.City}, ${address.Country}`}
            </div>
            <div>
                {address.Address}
            </div>
            <div>
                {address?.ShippingDesc}
            </div>
        </>
    );
}

export default AddressBoxShippingData;
