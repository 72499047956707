import React from "react";
import {BLOCK_TYPE, DESKTOP_OR_MOBILE} from "../models/constants";
import styled from 'styled-components';
import BannerLink from './BannerLink';
import LazyImage from "../elements/LazyImage";

const FormattedContent = styled.div`
    p {
        margin-bottom: 0;
    }
`;

const BannerWrapper = styled.div`
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: ${props => props.ratio * 100}%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
`;

function getColumnClasses(bannerType) {
    if (bannerType === BLOCK_TYPE.HALF_WIDTH) {
        return "col-12 col-md-6";
    } else if (bannerType === BLOCK_TYPE.THIRD_WIDTH) {
        return "col-12 ol-sm-6 col-md-4";
    } else if (bannerType === BLOCK_TYPE.QUARTER_WIDTH) {
        return "col-12 col-sm-6 col-md-3";
    } else {
        // full width
        return "col-12";
    }
}

function getDisplayClass(desktopOrMobile) {
    if (desktopOrMobile === DESKTOP_OR_MOBILE.ONLY_MOBILE) {
        return "d-lg-none";
    } else if (desktopOrMobile === DESKTOP_OR_MOBILE.ONLY_DESKTOP) {
        return "d-none d-lg-block";
    } else {
        return "";
    }
}

function getAspectRatio(banner) {
    if (banner.BannerTypeID === BLOCK_TYPE.QUARTER_WIDTH) {
        return 1;
    } else if (banner.BannerTypeID === BLOCK_TYPE.FULL_WIDTH) {
        return banner.ImageHeight/banner.ImageWidth;
    }
    
    return 9/16;
}

function Banner({banner, target, bannerId}) {
    return <div className={`mb-3 ${getColumnClasses(banner.BannerTypeID)} ${getDisplayClass(banner.DesktopOrMobil)}`} data-banner-id={bannerId}>
        <BannerLink targetUrl={banner.TargetURL} target={target}>
            <div className="row">
                {
                    banner.BannerTypeID !== BLOCK_TYPE.ONLY_TEXT &&
                    <div className={banner.BannerTypeID === BLOCK_TYPE.QUARTER_WIDTH ? 'col-5 col-sm-12' : 'col-12'}>
                        <BannerWrapper
                            ratio={getAspectRatio(banner)}
                            className={`${banner.BannerTypeID === BLOCK_TYPE.QUARTER_WIDTH ? 'mb-md-1' : 'mb-1'}`}>
                            <LazyImage fetchpriority="high" src={banner.PictureUrl} className="rounded-sm w-100 object-fit-cover" alt={banner.Title} title={banner.Title} loading="lazy"/>
                        </BannerWrapper>
                    </div>
                }
                <div
                    className={`${banner.BannerTypeID === BLOCK_TYPE.QUARTER_WIDTH ? 'col-7 col-sm-12 pl-0 pl-sm-2' : 'col-12'} line-height-3`}>
                    <div className="font-weight-medium font-size-1">{banner.Title}</div>
                    <FormattedContent className="text-gray" dangerouslySetInnerHTML={{__html: banner.Description}}/>
                </div>
            </div>
        </BannerLink>
    </div>
}

export default Banner;
