import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { redirectToUrl } from '../../utils/controller';
import { useResource } from '../../contexts/ResourceContext';
import { Link } from 'react-router-dom';

const BlogLatestItem = ({ item }) => {
  const { t } = useResource();
  return (
    <div className="d-flex flex-wrap blog-list-post py-2-5 border-top">
      <div className="col-12 p-0">
        {item?.PictureUrl && (
          <Link to={`/${item?.Slug}`} className="title">
            <img
              src={item?.PictureUrl}
              className="rounded cover mb-2"
              alt={item?.Title}
              title={item?.Title}
            />
            <h4 className="font-size-1-25 mb-2">{item.Title}</h4>
          </Link>
        )}
      </div>
      <div className="col-12 p-0">
        <div className="pb-2">{item.BodyOverview}</div>

        <div className="text-right line-height-0">
          <Link to={`/${item?.Slug}`}>{t('brendon.blog.readmore')} </Link>
          <div
            className="d-inline-block circle cursor-pointer bg-gray-light ml-2"
            onClick={() => redirectToUrl(`/${item?.Slug}`)}
          >
            <FontAwesomeIcon
              icon={faLongArrowRight}
              className="text-gray-dark"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogLatestItem;
