import React, { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { addDays, addYears } from 'date-fns';
import hu from 'date-fns/locale/hu';
import sk from 'date-fns/locale/sk';
import de from 'date-fns/locale/de';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { getStoreLanguage } from '../../utils/localization';

const defaultLanguage = getStoreLanguage();

if (defaultLanguage === 'hu') registerLocale('hu', hu);
else if (defaultLanguage === 'sk') registerLocale('sk', sk);
else registerLocale('de', de);

/**
 *
 * @param {value} Date | null | undefined - Value of the datepicker.
 * @param {sm} boolean - Small size.
 * @param {onChange} function - On change event of the datepicker.
 * @param {placeholder} string - Placeholder of the datepicker input.
 * @param {wrapperClassName} string - Wrapper class name of the datepicker.
 * @param {invalid} boolean - Is the datepicker invalid.
 * @param {children} ReactNode - Children of the datepicker.
 * @param {open} boolean - Is the datepicker open.
 * @returns
 */
const DatePicker = ({
    value,
    sm,
    onChange,
    placeholder,
    wrapperClassName,
    invalid,
    children,
    open,
    minDate,
    maxDate,
    dateFormat='yyyy-MM-dd',
    dateFormatCalendar='yyyy. MMMM',
    onClickOutside,
    disableMinDate,
    onCalendarClose,
    onInputClick,
}) => {
    const [startDate, setStartDate] = useState();

    useEffect(() => {
        setStartDate(value);
    }, [value]);
    return (
        <div className="position-relative">
            <ReactDatePicker
                calendarStartDay={1}
                popperPlacement={'bottom'}
       /*          popperModifiers={[
                    {
                      name: 'flip',
                      options: {
                        allowedAutoPlacements: ['bottom'], // by default, all the placements are allowed
                      },
                    },
                  ]} */
                selected={startDate}
                selectsStart={false}
                disabledKeyboardNavigation
                dateFormat={dateFormat}
                dateFormatCalendar={dateFormatCalendar}
                className={`form-control ${
                    invalid ? 'border-danger' : 'border-gray-light'
                } ${sm ? 'form-control-sm' : ''} w-100`}
                wrapperClassName={wrapperClassName}
                minDate={disableMinDate === true ? null : (minDate || addDays(new Date(), 1))}
                maxDate={maxDate || addYears(new Date(), 5)}
                open={open}
                onChange={(date) => {
                    onChange && onChange(date);
                    setStartDate(date);
                }}
                onCalendarClose={onCalendarClose}
                onInputClick={onInputClick}
                onClickOutside={onClickOutside}
                placeholderText={placeholder}
                locale={window.languageCode}
            />
            {invalid && (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="font-size-1 position-absolute top right text-danger mt-2 d-flex align-items-center mr-2"
                />
            )}
            {children}
        </div>
    );
};

export default DatePicker;
