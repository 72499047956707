import React from 'react';
import sendEvent from "../../tracking";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import { useResource } from '../../contexts/ResourceContext';

function Footer({onSkip, button, onClick, link}) {
    const { t } = useResource();
    return <div className="d-flex justify-content-between">
        {
            link ?
                <a href={link} className="btn btn-primary btn-sm" onClick={onClick}>{button}</a>
                :
                <div className="btn btn-primary btn-sm" onClick={onClick}>{button}</div>
        }
        {
            onSkip &&
            <div
                className="btn btn-sm font-weight-light text-gray d-flex align-items-center"    
                onClick={() => {
                sendEvent('intro', 'skip');
                onSkip()
            }}>
                {t('brendon.intro.skip')}
                <FontAwesomeIcon icon={faChevronRight} className="font-size-0-5 ml-1" />
            </div>
        }
    </div>
}

export default Footer;
