import React, { useState } from 'react';
import BlogTitle from '../../components/Blog/BlogTitle';
import BlogList from '../../components/Blog/BlogList';
import BlogTagTitle from '../../components/Blog/BlogTagTitle';
import BlogHtmlMeta from '../../components/Blog/BlogHtmlMeta';
import { useSelector } from 'react-redux';
import { getShopStoreId } from '../../store/shopstore/selectors';
import { useResource } from '../../contexts/ResourceContext';

const BlogTagScreen = ({ id: tagId }) => {
  const { t } = useResource();
  const [tag, setTag] = useState();
  const storeId = useSelector(getShopStoreId);

  return (
    <>
      <BlogHtmlMeta titleType="tag" titleAddon={tag} />
      <div className="blog">
        <BlogTitle storeId={storeId} />
        <BlogTagTitle
          type="tag"
          title={t('brendon.blog.tag.article')}
          tag={tag}
        />
        <BlogList storeId={storeId} tagId={tagId} setTag={setTag} />
      </div>
    </>
  );
};

export default BlogTagScreen;
