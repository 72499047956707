import AuthError from '../../models/errors/AuthError';
import { NetworkError } from '../../models/errors/NetworkError';

const axios = require('axios');

export const callAxios = async (method, url, data, reqHeaders) => {
  try {
    const { data: responseData } = await axios(
      {
        method,
        url,
        data,
        headers: {...reqHeaders}
      },
      {
        withCredentials: true, // maybe need: Access-Control-Allow-Credentials: true in the server
      },
    );
    
    return responseData;
  } catch (error) {
    console.log('axiosHelper error', error)
    if (!error?.response) {
      throw new NetworkError('NetworkError: Server is not available', 0);
    }
    const {
      response: { status, data: responseData },
    } = error;
    if (status === 401) {
      throw new AuthError(responseData.message, status);
    }
    throw new Error(responseData?.message);
  }
};
