import React from 'react';
import classnames from 'classnames';

const GreyDataBox = ({
    title,
    children,
    rightChildren,
    className,
    classNameRight,
    classNameTitle,
}) => {
    return (
        <div
            className={classnames(
                'd-flex bg-gray-light p-3',
                className,
            )}
        >
            <div className="flex-1">
                {title && <div
                    className={classnames(
                        'text-uppercase font-weight-light line-height-3 font-size-0-875 text-gray',
                        classNameTitle,
                    )}
                >
                    {title}
                </div>}
                {children}
            </div>
            <div className={classNameRight}>{rightChildren}</div>
        </div>
    );
};

export default GreyDataBox;
