import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import ProductService from '../../services/products';
import PageLoader from '../../components/PageLoader';
import ProductList from '../../category/ProductList';
import ArrowButton from '../../components/Elements/ArrowButton';
import { DEDICATED_URLS } from '../../models/constants';
import { getDomain } from '../../utils/localization';
import classnames from 'classnames';
import MessageSucces from '../../components/Elements/MessageSucces';
import GreyDataBox from '../../components/Elements/GreyDataBox';
import LazyImage from '../../elements/LazyImage';
import ProductBoxName from '../../category/ProductBoxName';
import { useParams } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

const UpsellScreen = () => {
    const { t } = useResource();
    const { orderId } = useParams();
    const [product, setProduct] = useState({});
    const [isInCart, setIsInCart] = useState(false);
    const [upsellProducts, setUpsellProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUpsell = async () => {
            const {
                IsBaseProductInCart = false,
                UpSellProducts = [],
                BaseProduct = {},
            } = await ProductService.getUpsell(orderId);
            setIsInCart(IsBaseProductInCart);
            setUpsellProducts(UpSellProducts);
            setProduct(BaseProduct);
            setIsLoading(false);
        };

        setIsLoading(true);
        fetchUpsell();
    }, [setProduct, setIsInCart, setUpsellProducts, orderId]);

    const handleBack = useCallback((e) => {
        e.preventDefault();
        window.history.back();
    }, []);

    const enableBackButton = useMemo(
        () => document.referrer.includes(getDomain()),
        [],
    );

    const productName = product.Model
        ? `${product.Brand} ${product.Model} ${product.Design} ${product.Function}`
        : product.Name;

    const isUpsellAvailable = useMemo(() => upsellProducts?.length > 0, [upsellProducts]);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            {isLoading ? (
                <PageLoader isLoading />
            ) : (
                <>
                    <div
                        className={classnames(
                            'd-flex',
                            enableBackButton
                                ? 'justify-content-between'
                                : 'justify-content-end',
                        )}
                    >
                        {enableBackButton && (
                            <ArrowButton onClick={handleBack} leftSide>
                                {t('brendon.upsell.back')}
                            </ArrowButton>
                        )}
                        <ArrowButton to={DEDICATED_URLS['cart']} rightSide>
                            {t('brendon.upsell.linktocart')}
                        </ArrowButton>
                    </div>
                    {isInCart && (
                        <div className="d-flex justify-content-center mb-2">
                            <MessageSucces className="col-12 col-md-8 col-lg-6">
                                <div className="text-uppercase font-weight-medium">
                                    {t('brendon.upsell.productincart')}
                                </div>
                            </MessageSucces>
                        </div>
                    )}
                    <div className="d-flex justify-content-center mb-2">
                        <GreyDataBox className="col-12 col-md-8 col-lg-6 rounded-lg">
                            <div className="d-flex">
                                <a href={`/${product?.ProductUrl}`}>
                                    <div className="d-flex justify-content-center align-items-center bg-white s-80px rounded-lg mr-3">
                                        <LazyImage
                                            alt={productName}
                                            title={productName}
                                            src={product?.Image}
                                            className="rounded-lg mh-100"
                                        />
                                    </div>
                                </a>
                                <div className="align-self-center">
                                    <ProductBoxName
                                        product={product}
                                        productUrl={`/${product.ProductUrl}`}
                                        className="mb-0"
                                    />
                                </div>
                            </div>
                        </GreyDataBox>
                    </div>
                    {isUpsellAvailable && <div className="upsell-more pb-2 pt-2 mb-3 mt-4 text-center font-weight-medium font-size-1-5">
                        {t('brendon.upsell.moreproducts')}
                    </div>}
                    {isUpsellAvailable && <ProductList
                        products={upsellProducts}
                        legacy={false}
                        enableLeftSide={false}
                        selectedOrderBy={'stock'}
                        categoryName={'Upsell'}
                        gaEEList={'Upsell list page'}
                        enableUpsell={false}
                        upsellList={true}
                    />}
                </>
            )}
        </>
    );
};

export default UpsellScreen;
