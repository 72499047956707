import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { getURLPath } from '../../utils/staticPaths';
import { useResource } from '../../contexts/ResourceContext';

const PaymentCancelkScreen = ({ id: paymentProcessor = '' }) => {
  const { t } = useResource();
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId');


  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
        <div className="mb-5">
          <div className="font-size-1 font-weight-medium text-uppercase">
            {t(
              `Plugins.Payments.${paymentProcessor}.YourPaymentHasBeenDeclined`,
            )}
          </div>
          <div className="font-size-0-875 pb-2">
            {t('Checkout.OrderNumber')}: ${orderId}
          </div>
        </div>

        <a href={getURLPath('cart')} className="btn btn-primary btn-lg w-100">
          {t('checkout.brendon.reorder')}
        </a>

        <div className="text-gray font-size-0-875 my-3">
          {t('brendon.checkout.declinedpaymentrewardpoints')}
        </div>
      </div>
    </div>
  );
};

export default PaymentCancelkScreen;
