import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGiftCard } from '@fortawesome/pro-regular-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faMoneyBillAlt } from '@fortawesome/pro-light-svg-icons';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { faGooglePay, faApplePay } from '@fortawesome/free-brands-svg-icons';

const PaymentMethodIcons = ({
    iconName,
    className,
    containerClass,
    onClick,
}) => {
    switch (iconName) {
        case 'GiftCard':
            return (
                <div
                    className={`payment-method-icons ${containerClass ?? ''}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon
                        icon={faGiftCard}
                        className={`font-size-1-5 ${className ?? ''}`}
                    />
                </div>
            );
        case 'PayPal':
            return (
                <div
                    className={`payment-method-icons ${containerClass ?? ''}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon
                        icon={faPaypal}
                        className={`font-size-1-875 ${className}`}
                    />
                </div>
            );
        case 'CashOnDelivery':
            return (
                <div
                    className={`payment-method-icons ${containerClass ?? ''}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon
                        icon={faMoneyBillAlt}
                        className={`font-size-1-25 ${className ?? ''}`}
                    />
                </div>
            );
        case 'ApplePay':
            return (
                <div
                    className={`payment-method-icons ${containerClass ?? ''}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon
                        icon={faApplePay}
                        className={`font-size-1-5 ${className ?? ''}`}
                    />
                </div>
            );
        case 'GooglePay':
            return (
                <div
                    className={`payment-method-icons ${containerClass ?? ''}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon
                        icon={faGooglePay}
                        className={`font-size-1-5 ${className ?? ''}`}
                    />
                </div>
            );
        case 'BankCard':
        default:
            return (
                <div
                    className={`payment-method-icons ${containerClass ?? ''}`}
                    onClick={onClick}
                >
                    <FontAwesomeIcon
                        icon={faCreditCard}
                        className={`font-size-1-5 ${className ?? ''}`}
                    />
                </div>
            );
    }
};

export default PaymentMethodIcons;
