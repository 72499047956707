import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useModuleScript } from '../../hooks/useScript';
import { executeCallback } from '../../utils/function';

/**
 * @typedef {Object} GLSDropoffMapProps
 * @property {string} [type='dialog'] - Type of the map. Can be 'dialog' or 'widget'.
 * @property {string} [id='gls-map'] - Id of the map.
 * @property {string} [country='hu'] - Country code of the map and language.
 * @property {Function} [onSelect] - Callback function when a GLS point is selected.
 * @property {Object} [props] - Other gls map props.
 **/

const GLSDropoffMap = forwardRef(
    (
        {
            type = 'dialog',
            id = 'gls-map',
            country = 'hu',
            onSelect,
            onLoaded,
            ...props
        },
        ref,
    ) => {
        /*     const mapRef = useRef(null); */
        const { loaded, error } = useModuleScript(
            'https://map.gls-hungary.com/widget/gls-dpm.js',
        );

        useEffect(() => {
            const handleSelect = (e) => {
                executeCallback(onSelect, e.detail);
            };

            if (ref.current) {
                ref.current.addEventListener('change', handleSelect);
            }

            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('change', handleSelect);
                }
            };
        }, [ref.current]);

        useEffect(() => {
            if (loaded) {
                executeCallback(onLoaded);
            }
        }, [loaded, error]);

        return (
            <>
                {type === 'dialog' ? (
                    <>
                        <gls-dpm-dialog
                            ref={ref}
                            country={country}
                            id={id}
                            {...props}
                        ></gls-dpm-dialog>
                    </>
                ) : (
                    <div style={{ height: '600px' }}>
                        <gls-dpm
                            ref={ref}
                            country={country}
                            id={id}
                            {...props}
                        ></gls-dpm>
                    </div>
                )}
            </>
        );
    },
);

export default GLSDropoffMap;
