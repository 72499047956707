import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckboxComboIconWrapper = ({containerClass, onClick, className, icon, customIconChildren}) => {
    return (
        <div
            className={`checkbox-combo-icons ${containerClass ?? ''}`}
            onClick={onClick}
        >
            {customIconChildren}
            {!customIconChildren && <FontAwesomeIcon
                icon={icon}
                className={`font-size-1-875 ${className}`}
            />}
        </div>
    );
};

export default CheckboxComboIconWrapper;
