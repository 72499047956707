import React from 'react';
import Loader from './Loader';

const PageLoader = ({ isLoading, children }) => {
    return (
        <>
            {isLoading && (
                <Loader
                    isLoading={isLoading}
                    style={{ maxHeight: '400px' }}
                    loaderType="new"
                    absoluteContainer={false}
                    disableBGWhite
                />
            )}
            {!isLoading && children}
        </>
    );
};

export default PageLoader;
