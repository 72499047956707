import React from 'react';
import { STORE_SETTINGS } from '../../models/constants';
import { useResource } from '../../contexts/ResourceContext';
import { Link } from 'react-router-dom';

const HeartLogoSVG = () => {
  return (
    <svg
      width="24"
      height="26"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="#ffffff"
      className="mr-1 align-self-center"
    >
      <g>
        <title>Layer 1</title>
        <path
          stroke="null"
          className="st0"
          d="m18.05705,6.45387c-0.54062,-1.50764 -1.40948,-2.80875 -2.51003,-3.86203c0,0 -0.05792,-0.06196 -0.07723,-0.06196c-1.62186,-1.52829 -3.76504,-2.45766 -6.1013,-2.45766c-0.01931,0 -0.19308,0 -0.21239,0c-5.03936,0.12392 -9.07472,4.52291 -9.07472,9.93389c0,0.02065 0,11.42087 0,11.42087c0,2.51961 1.91148,4.56422 4.26705,4.56422l10.07873,0c5.23244,0 9.49949,-4.56422 9.49949,-10.16106c0,-4.17182 -2.37487,-7.84798 -5.8696,-9.37627zm-3.74573,16.23292l-9.98219,0c-0.63716,0 -1.15847,-0.55762 -1.15847,-1.23915l0,-11.21435c0,-0.70219 0.55993,-1.28046 1.23571,-1.23915c0.61785,0.04131 1.08124,0.61958 1.08124,1.28046l0,9.50018c0,0.22718 0.17377,0.41305 0.38616,0.41305l8.5534,0c2.25903,0 4.07397,-1.96199 4.07397,-4.35769l0,0l0,0c0,-2.33374 -1.7184,-4.23378 -3.86158,-4.35769c-0.07723,0 -0.13516,0 -0.21239,0l-0.77232,0c-0.21239,0 -0.38616,-0.18587 -0.38616,-0.41305l0,-0.8261l0,0c0,-1.5696 -0.79162,-2.97397 -1.96941,-3.73812c-0.05792,-0.04131 -0.11585,-0.06196 -0.17377,-0.10326c-0.11585,-0.06196 -0.23169,-0.12392 -0.34754,-0.18587c-0.4827,-0.22718 -1.02332,-0.35109 -1.58325,-0.35109c-0.65647,0 -1.19709,-0.59892 -1.15847,-1.30111c0.03862,-0.66088 0.57924,-1.1772 1.19709,-1.1772c3.14719,0.02065 5.75376,2.47831 6.25576,5.70011c3.03134,0.55762 5.34829,3.38702 5.32898,6.7947c-0.01931,3.80007 -2.97342,6.81535 -6.50677,6.81535z"
          id="svg_1"
        />
      </g>
    </svg>
  );
};
const BlogTitle = ({ storeId }) => {
  const {
    blog: { root: blogRootUrl },
  } = STORE_SETTINGS[storeId];
  const { t } = useResource();

  return (
    <div className="blog-title rounded mb-4">
      <Link to={blogRootUrl} className="d-flex justify-content-center rounded">
        <HeartLogoSVG />
        <h1 className="my-0 align-self-center">{t('Blog')}</h1>
      </Link>
    </div>
  );
};

export default BlogTitle;
