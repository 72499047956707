const theme = {
  gridBreakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  borderRadius: '.25rem !default',
  borderRadiusSm: '.2rem !default',
  borderRadiusLg: '.3rem !default',
  gridGutterWidth: '30px !default',
  primary: '#00ACC6',
  pink: '#E24585',
  light: '#EEEEEE',
  grayLight:'#EEEEEE',
  grayMedium: '#CCCCCC',
  white: '#fff',
};

export default theme;