import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { redirectToUrl } from '../../utils/controller';
import { useResource } from '../../contexts/ResourceContext';
import { Link } from 'react-router-dom';

const BlogListItem = ({ item }) => {
  const { t } = useResource();

  return (
    <div className="d-flex flex-wrap blog-list-post pb-5">
      <div className="col-12 p-0 pb-4 col-md-6 pb-md-0">
        {item?.PictureUrl && (
          <Link to={`/${item?.Slug}`}>
            <img
              src={item?.PictureUrl}
              className="rounded post-list-image"
              alt={item?.Title}
              title={item?.Title}
            />
          </Link>
        )}
      </div>
      <div className="col-12 p-0 col-md-6 pl-md-4">
        <Link to={`/${item?.Slug}`} className="title">
          <h2>{item.Title}</h2>
        </Link>
        <div className="lead my-3">{item.BodyOverview}</div>
        <Link to={`/${item?.Slug}`}>{t('brendon.blog.readmore')} </Link>
        <div
          className="d-inline-block circle cursor-pointer bg-gray-light ml-2"
          onClick={() => redirectToUrl(`/${item?.Slug}`)}
        >
          <FontAwesomeIcon icon={faLongArrowRight} className="text-gray-dark" />
        </div>
      </div>
    </div>
  );
};

export default BlogListItem;
