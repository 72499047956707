import React from 'react';
import { useResource } from '../../contexts/ResourceContext';
import {Link} from "react-router-dom";
import { getURLPath } from '../../utils/staticPaths';

const RegisterBox = () => {
    const { t } = useResource();

    return (
        <div className="col-sm-8 p-2 p-sm-3 m-2 border border-light rounded">
            <h1 className="font-size-1-125 mb-3 text-center">
                {t("Account.Login.NewCustomer")}
            </h1>
            <div className="font-size-0-75 mb-3">
                <div className="formatted-content">
                    <ul className="gray-bullet">
                        <li dangerouslySetInnerHTML={{__html:t("Account.Login.NewCustomer.InfoList1")}}></li>
                        <li dangerouslySetInnerHTML={{__html:t("Account.Login.NewCustomer.InfoList2")}}></li>
                        <li dangerouslySetInnerHTML={{__html:t("Account.Login.NewCustomer.InfoList3")}}></li>
                    </ul>
                </div>
            </div>
            <Link to={getURLPath('register')} className="btn btn-primary w-100">
                {t("Account.Register")}
            </Link>
        </div>
    );
};

export default RegisterBox;
