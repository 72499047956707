import React, { useMemo } from 'react';
import ContentCarousel from '../../../Elements/ContentCarousel';
import ContentCarouselItem from '../../../Elements/ContentCarouselItem';
import PregnancyPost from './PragnancyPost';
import { calculateDatesForWeeks, formatDate } from '../../../../utils/date';
import { getStoreDateFormat } from '../../../../utils/localization';
import { useResource } from '../../../../contexts/ResourceContext';

const PREGNANCY_MAX_WEEKS = 40;

const PregnancyWeekByWeek = ({ posts, startDate, week }) => {
    const { t } = useResource();
    if (!Array.isArray(posts) || posts.length === 0) {
        return null;
    }

    const datesForWeeks = useMemo(
        () => calculateDatesForWeeks(startDate, PREGNANCY_MAX_WEEKS + 1),
        [startDate],
    );

    const titles = useMemo(() => {
        const slides = [];
        const format = getStoreDateFormat().replaceAll('-', '.');

        for (let x = 0; x <= PREGNANCY_MAX_WEEKS; x++) {
            slides.push(
                `${x}. ${t('brendon.calculator.pregnancy.week')} - ${formatDate(
                    format,
                    datesForWeeks[x].start,
                )}. - ${formatDate(format, datesForWeeks[x].end)}.`,
            );
        }
        return slides;
    }, [datesForWeeks, formatDate]);

    return (
        <div>
            <ContentCarousel
                enableInfinity={false}
                defaultSelected={
                    week > PREGNANCY_MAX_WEEKS || week <= 0 ? 0 : week
                }
                autoSliderSecond={0}
                scrollSnapAlign="center"
                className="mb-3"
                navPosition="top"
                classNameNav="mb-2-5 justify-content-between justify-content-md-center"
                navItemConfig={{
                    left: {
                        children: (
                            <span className="ml-2 user-select-none">
                                {t('brendon.calculator.pregnancy.prev')}
                            </span>
                        ),
                    },
                    right: {
                        children: (
                            <span className="mr-2 user-select-none">
                                {t('brendon.calculator.pregnancy.next')}
                            </span>
                        ),
                    },
                    middle: {
                        className:
                            'd-none d-md-inline flex-1 text-center font-size-1-125 line-height-rem-1-125 font-weight-bold',
                        contents: titles,
                        titleBefore: true,
                        titleBeforeClassName:
                            'd-md-none text-center font-size-1-125 line-height-rem-1-125 font-weight-bold mb-2',
                    },
                }}
            >
                {posts.map((post, index) => (
                    <ContentCarouselItem key={index} className="col-10">
                        <PregnancyPost
                            key={index}
                            title={post.Title}
                            text={post.BodyOverview}
                            url={post.Slug}
                            image={post.PictureUrl}
                        />
                    </ContentCarouselItem>
                ))}
            </ContentCarousel>
        </div>
    );
};

export default PregnancyWeekByWeek;
