import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

const QuantityInput = ({ item, changeItem }) => {
    const increase = useCallback(() => {
        changeItem(item.Quantity + 1);
    }, [item.Quantity, changeItem]);

    const decrease = useCallback(() => {
        if (item.Quantity > 1) {
            changeItem(item.Quantity - 1);
        }
    }, [item.Quantity, changeItem]);

    return (
        <div className="d-flex align-items-center justify-content-center">
            <span
                onClick={decrease}
                className="bg-gray-light text-primary font-size-0-75 d-flex justify-content-center align-items-center w-p40 h-p40 rounded-sm cursor-pointer"
            >
                <FontAwesomeIcon icon={faMinus} />
            </span>
            <input
                type="text"
                value={item.Quantity}
                className="form-control text-center w-p40 h-p40 mx-1 p-1 border-gray-light font-weight-medium font-size-1"
                disabled
            />
            <span
                onClick={increase}
                className="bg-gray-light text-primary font-size-0-75 d-flex justify-content-center align-items-center w-p40 h-p40 rounded-sm cursor-pointer"
            >
                <FontAwesomeIcon icon={faPlus} />
            </span>
        </div>
    );
};

export default QuantityInput;
