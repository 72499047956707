import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { executeCallback } from '../utils/function';
import classnames from 'classnames';
import { isNewFilterEnabled } from '../services/featureFlagService';

const FilterTag = ({
  filterId,
  filterItemId,
  onRemove,
  parentFilterCheckbox,
  className,
  filterName,
  filterItemName,
  isDiscounted,
}) => {
  const onRemoveClick = useCallback(() => {
    executeCallback(onRemove, filterId, filterItemId);
  }, [onRemove, filterId, filterItemId]);

  return (
    <div
      onClick={onRemoveClick}
      className={classnames(
        className,
        'tag-items cursor-pointer d-flex text-nowrap px-2 py-8px align-items-center bg-gray-light font-size-0-875 rounded line-height-rem-1-125',
        isDiscounted ? 'text-pink' : 'text-dark',
      )}
    >
      <span className="tag-item">
        {isNewFilterEnabled() ? (
          <>
            {!parentFilterCheckbox ? (
              `${filterItemName}`
            ) : (
              <span className="font-weight-medium">{filterName}</span>
            )}
          </>
        ) : (
          <>
            <span className="font-weight-medium">{filterName}</span>
            {!parentFilterCheckbox && `: ${filterItemName}`}
          </>
        )}
      </span>
      <FontAwesomeIcon
        icon={faXmarkCircle}
        className={classnames('ml-2', isDiscounted ? 'text-pink' : 'text-dark')}
      />
    </div>
  );
};

export default FilterTag;
