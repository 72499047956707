import { addDataAction, success } from '../actions';
import { fromJS } from 'immutable';

const initialState = fromJS({
  storeId: null,
});

export const SET_SHOP_STORE_ID = 'SET_SHOP_STORE_ID';

export const setShopStoreId = (storeId) =>
  addDataAction(SET_SHOP_STORE_ID, storeId)();

export default function shopStore(state = initialState, action) {
  switch (action.type) {
    case success(SET_SHOP_STORE_ID):
      return state.set('storeId', action.data);
    default:
      return state;
  }
}
