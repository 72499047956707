import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import CustomerMainContainer from '../../components/CustomerNew/CustomerMainContainer';
import CustomerTitle from '../../components/CustomerNew/CustomerTitle';
import CustomerOrderDetails from '../../components/CustomerNew/CustomerOrderDetails';
import CustomerBackButton from '../../components/CustomerNew/CustomerBackButton';
import { useParams } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

const CustomerOrderDetailsScreen = () => {
    const { t } = useResource();
    const { orderId } = useParams();

    return (
        <>
            <MetaHeader title={t('PageTitle.Account')} showBrendonAddon />
            <CustomerMainContainer>
                <CustomerBackButton />
                <CustomerTitle
                    title={`${t(
                        'Account.CustomerOrders.OrderNumber',
                    )}: #${orderId}`}
                />
                <CustomerOrderDetails orderId={orderId} />
            </CustomerMainContainer>
        </>
    );
};

export default CustomerOrderDetailsScreen;
