import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux-immutable';
import thunk from 'redux-thunk';
import wishlist from './wishlist';
import cart from './cart';
import filters from './filters';
import search from './search';
import notification from './notification';
import product from './product';
import blog from './blog';
import megamenu from './megamenu';
import shopstore from './shopstore';
import checkout from './checkout';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
/* import { fromJS } from 'immutable'; */

function createAppStore() {
  const reducers = combineReducers({
    wishlist,
    cart,
    filters,
    search,
    notification,
    product,
    blog,
    megamenu,
    shopstore,
    checkout,
  });

  const sagaMiddleware = createSagaMiddleware();

  /*  const initialState = window.initialState;

  initialState?.wishlist?.forEach((product) => {
    try {
      product.productData = JSON.parse(product.ShortDescription);
    } catch (e) {
      product.productData = null;
    }
  });
 */
  const store = createStore(
    reducers,
    /* fromJS(initialState), */
    composeWithDevTools(applyMiddleware(thunk, sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

let store = null;

function getStore() {
  if (!store) {
    store = createAppStore();
  }

  return store;
}

export default getStore;
