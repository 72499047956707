import React, { useCallback, useMemo } from 'react';

const filterButtonVariants = {
    default: {
        className:
            'select-none cursor-pointer border border-dark rounded py-2 px-3 font-size-0-875 font-weight-medium mr-2 line-height-rem-1-125',
        selectedClassName:
            'select-none cursor-pointer border border-primary text-primary rounded py-2 px-3 font-size-0-875 font-weight-medium mr-2 line-height-rem-1-125',
    },
    link: {
        className:
            'select-none cursor-pointer font-size-0-875 font-weight-medium mr-2 line-height-rem-1-125 text-uppercase text-underline',
        selectedClassName:
            'select-none cursor-pointer text-primary font-size-0-875 font-weight-medium mr-2 line-height-rem-1-125 text-uppercase text-underline',
    },
};
/**
 * FilterButotn
 *
 * @param {string} id - Id of the filter button
 * @param {string} variant - Variant of the filter button. Default is "default
 * @param {string} label - Label of the filter button
 * @param {boolean} selected - Whether the filter button is selected
 * @param {React.ReactNode} icon - Icon of the filter button
 * @param {function} onClick - Function to be called when the filter button is clicked
 * 
 *
 * @returns
 */
const FilterButton = ({
    id,
    variant,
    label,
    selected,
    selectedIcon,
    selectedClassName,
    icon,
    afterIcon,
    onClick,
    className,
}) => {
    const handleClick = useCallback((e) => {
        onClick(id);
    }, [id, onClick]);

    const classes = useMemo(() => {
        return (
            filterButtonVariants[variant] || {
                className,
                selectedClassName,
            }
        );
    }, [selectedClassName, className, variant]);

    return (
        <div
            className={selected ? classes?.selectedClassName : classes?.className}
            onMouseUp={handleClick}
        >
            {selected && selectedIcon} {icon} {label} {afterIcon}
        </div>
    );
};

export default FilterButton;
