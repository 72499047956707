import React, {Component} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';

const Wrapper = styled.div`
    width: ${props => props.show ? '100%' : '0%'};
    transition: all 0.2s ease-in-out;
`;

class SquareCheckbox extends Component {

    change = () => {
        const {
            value,
            onChange,
            disabled,
        } = this.props;

        if (!disabled && onChange) {
            onChange(!value);
        }
    };

    render() {
        const {
            invalid,
            value,
            children,
            name,
            light,
            className,
            disabled,
        } = this.props;

        return <div className={ `${className ? className : 'd-flex line-height-3'}` }>
            {name &&
            <input type="checkbox" className="d-none" name={name} value="true" checked={value} disabled={disabled} readOnly/>
            }
            <div
                className={`cursor-pointer h-p20 w-p20 border ${invalid ? 'border-danger' : (value ? 'border-primary' : (light ? 'border-gray-light' : ''))} rounded-sm ${disabled ? 'bg-gray-light' : 'bg-white'}`}
                onClick={this.change}>
                <Wrapper className="overflow-hidden" show={value}>
                    <div className="m-npixel h-p20 w-p20 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faCheck} className="text-primary font-size-0-875" />
                    </div>
                </Wrapper>
            </div>
            {children &&
            <div className={`ml-2 ${invalid ? 'text-children-danger' : ''}`}>
                {children}
            </div>
            }
        </div>
    }
}

export default SquareCheckbox;