import { createSelector } from 'reselect'

export const selectGetMegaMenu = state => state.get('megamenu');

export const isLoading = createSelector(selectGetMegaMenu, megamenu => megamenu.get('loading'));
export const isMegaMenuLoaded = createSelector(selectGetMegaMenu, megamenu => !megamenu?.get('loading') && (megamenu?.get('CategoryMenu')?.size > 0 || megamenu?.get('TopMenu')?.size > 0));
export const selectCategoryMenu = createSelector(selectGetMegaMenu, megamenu => megamenu?.get('CategoryMenu')?.toJS?.());
export const selectTopMenu = createSelector(selectGetMegaMenu, megamenu => megamenu?.get('TopMenu')?.toArray?.());
export const selectFooterTopicList = createSelector(selectGetMegaMenu, megamenu => megamenu?.get('FooterTopicList')?.toArray?.());
export const selectShoppingInfos = createSelector(selectGetMegaMenu, megamenu => megamenu?.get('ShoppingInfos')?.toArray?.());
