import React, { useState } from "react";
import styled from 'styled-components';
import LazyImage from "../elements/LazyImage";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons'

const Square = styled.div`
    position: relative;
    width: 100%;
    padding-top: 100%;
`;

const SquareVideo = styled.div`
    position: relative;
    width: 100%;
    padding-top: 18%;
`;

const IconLayer = styled.div`
    position: absolute;
    top: 0;
`;

const PlayButton = styled.div`
    border-radius: 50%;
    width: 60px!important;
    height: 60px;
    cursor: pointer;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 60px;
    width: 60px;
    padding-left: 6px;
`;

function ProductVideoWithThumbnail({ visible, disabledLoad, thumbnailAlt, thumbnailUrl, embedCode }) {
    const [playVideo, setPlayVideo] = useState(false)

    let autoplayEmbedCode = embedCode;

    if (!embedCode || embedCode == '') {
        return null;
    }

    if (embedCode && playVideo) {
        const regex = /<iframe[^>]+src="https:\/\/([^">]+)/g
        const url = regex.exec(embedCode)?.[1];
        if (url) { 
            const autoplayURL = new URL(`http://${url}`);
            autoplayURL.searchParams.set('autoplay',1);
            autoplayEmbedCode = embedCode.replace(url,autoplayURL?.toString().replace('http://', ''))
        }
    }
    
    return (
        visible && playVideo && autoplayEmbedCode ?
            <SquareVideo><div className="youtube-container mb-3" dangerouslySetInnerHTML={ {__html: autoplayEmbedCode }}></div></SquareVideo>
        :
            !playVideo && <Square>
                <LazyImage
                    alt={thumbnailAlt}
                    title={thumbnailAlt}
                    src={thumbnailUrl}
                    disabledLoad={disabledLoad}
                    className="w-100 h-100 position-absolute top left object-fit-contain" />
                <IconLayer className="w-100 h-100">
                        <PlayButton onClick={ () => setPlayVideo(true) } className='text-center btn-primary'>
                            <IconContainer className="w-100">
                                <FontAwesomeIcon  icon={faPlay} className="align-center m-auto font-size-1-875" />
                            </IconContainer>
                        </PlayButton>
                </IconLayer>
            </Square>
    )
                
}
 

export default ProductVideoWithThumbnail;