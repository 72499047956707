import React, { useRef } from "react";
import { Form, Formik } from "formik";
import InputGroup from "../../elements/forms/InputGroup";
import SelectGroup from "../../elements/forms/SelectGroup";
import TextareaGroup from "../../elements/forms/TextareaGroup";
import shippingAddressSchema from "../../models/validation/shippingAddressSchema";
import ErrorFocus from "../../elements/forms/ErrorFocus";
import { executeCallback } from "../../utils/function";
import { useResource } from '../../contexts/ResourceContext';

function ShippingAddressForm({
    countries,
    nextButtonTitle,
    initialValues,
    saveHandler,
}) {
    const { t } = useResource();
    const form = useRef(null);

    return <Formik
        enableReinitialize
        validationSchema={shippingAddressSchema}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
            executeCallback(saveHandler, values);
            setSubmitting(false);
        }}
    >
        {() => {
            return <div>
                <Form ref={form} autoComplete="off">
                    <ErrorFocus />
                    <InputGroup
                        variation="checkout"
                        name="ReceiverName"
                        label={t('brendon.checkout.shippingaddress.addresstitlelabel')}
                        errorType="input-after"
                        maxLength={100}
                        required
                    />
                    <SelectGroup
                        variation="checkout"
                        name="CountryId"
                        label={t('Address.Fields.Country')}
                        options={countries}
                        optionName="CountryName"
                        optionId="Id"
                        errorType="input-after"
                        required
                    />
                    <InputGroup
                        variation="checkout"
                        name="ZipPostalCode"
                        label={t('Address.Fields.ZipPostalCode')}
                        maxLength={16}
                        errorType="input-after"
                        required
                    />
                    <InputGroup
                        variation="checkout"
                        name="City"
                        label={t('Address.Fields.City')}
                        maxLength={50}
                        errorType="input-after"
                        required
                    />
                    <InputGroup
                        variation="checkout"
                        name="Address"
                        label={t('address.fields.address1')}
                        maxLength={50}
                        errorType="input-after"
                        required
                    />
                    <TextareaGroup
                        variation="checkout"
                        name="ShippingDesc"
                        placeholder={t('address.fields.address2')}
                        errorType="input-after"
                        maxLength={100}
                        disableNewLines
                    />
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-4">
                            <input type="submit" name="nextstep" value={nextButtonTitle}
                                className="btn btn-primary w-100" />
                        </div>
                    </div>
                </Form>
            </div>
        }
        }
    </Formik>
}

export default ShippingAddressForm;
