import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import packageJson from '../../package.json';

import smoothscroll from 'smoothscroll-polyfill';
import scrollRestorer from './services/scrollRestorer';
import './registerFAS';
import './services/middleware/axios';

// kick off the polyfill!
smoothscroll.polyfill();

scrollRestorer();

if (process.env.NODE_ENV === 'development' ) {
  // axios.defaults.baseURL = 'https://dev.brendon.hu';
  const { worker } = require('../mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: {
      options: {
        // Narrow the scope of the Service Worker to intercept requests
        // only from pages under this path.
        scope: '/',
      },
      url: '/mockServiceWorker.js',
    },
  });
}

/* window.addEventListener('load', (loadEvent) => {
    const recoveryForm = document.getElementById('password-recovery-form');
    let disabled = false;
    recoveryForm?.addEventListener('submit', (formEvent) => {
        if(disabled) {
            return false;
        }
        disabled = true;
        return true;
    });
    if (recoveryForm) {
        const emailField = document.querySelector("#Email")
        emailField?.addEventListener('change', () => { disabled = false })
    }
}) */

Sentry.init({
  dsn:
    'https://83c445b4c7214ef18797c7984adf627c@o1169223.ingest.sentry.io/6458402',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  /*  replaysSessionSampleRate: 0.1, */
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  /* replaysOnErrorSampleRate: 1.0, */
  ignoreErrors: [
    // Other marketing stuffs: Taboola,
    "reading 'isCookieBannerOpen'",
    "evaluating 't.isCookieBannerOpen'",
    // FB & FB pixel
    'fb_xd_fragment',
    "Can't find variable: fbq",
    'fbq is not defined',
    // Google tag manager
    '_tfa is not defined',
    'Can\'t find variable: _tfa',
    'Cannot read properties of null (reading \'parentNode\')',
    'undefined is not an object (evaluating \'a.M\')',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Google things,
    // https://googleads.g.doubleclick.net/pagead/viewthroughconversion/842540967/....
    /googleads\.g\.doubleclick\.net\/pagead\/viewthroughconversion/i,
    // https://pagead2.googlesyndication.com/pagead/buyside_topics/set/
    /pagead2\.googlesyndication\.com\/pagead\/buyside_topics\/set/i,
    // Other plugins
    /cdn\.taboola\.com/i,
  ],
  integrations: [
    new BrowserTracing() /*  new Sentry.Replay({
        networkDetailAllowUrls: [''],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
    }) */,
  ],
  tracesSampleRate: 0, // transaction sampling
  sampleRate: 0.5, // error sampling
  release: packageJson.version,
  environment: process.env.NODE_ENV,
});
