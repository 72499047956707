
import BlogService from "../../services/blog";
import {apiRequestAction, failure, request, success} from "../actions";
import {fromJS, Map, List } from "immutable";

export const ADD_BLOG_POST_LIST = 'ADD_BLOG_POST_LIST';
export const ADD_BLOG_CATEGORY_POST_LIST = 'ADD_BLOG_CATEGORY_POST_LIST';
export const ADD_BLOG_TAG_LIST = 'ADD_BLOG_TAG_LIST';

const initialState = fromJS({
    result: {},
    tagList: [],
    categoryPostList: {},
})

export const getBlogPostList = (storeId) => apiRequestAction(ADD_BLOG_POST_LIST, async () => BlogService.getPostList(storeId))();
export const getBlogTagList = () => apiRequestAction(ADD_BLOG_TAG_LIST, async () => BlogService.getTagList())();
export const getBlogCategoryPostList = (categoryId) => apiRequestAction(ADD_BLOG_CATEGORY_POST_LIST, async () => BlogService.getCategoryPostList(categoryId))();

export default function blog(state = initialState, action) {
    switch (action.type) {
        case success(ADD_BLOG_CATEGORY_POST_LIST):
            return state
                .set('categoryPostList', Map(action.result))
        case success(ADD_BLOG_TAG_LIST):
            return state
                .set('tagList', List(action.result))
        case success(ADD_BLOG_POST_LIST):
            return state
                .set('result', Map(action.result))
        default:
            return state
    }
}