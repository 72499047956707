import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLongArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { redirectToUrl } from '../utils/controller';
import classnames from 'classnames';

const MoreCategories = ({ className, text, link }) => {

    if (!text || !link) return null;
    return (
        <div
            className={ classnames(className, `d-flex bg-gray-light px-2-5 py-2 rounded cursor-pointer align-items-center mb-2 mb-sm-0 mx-2 mx-sm-0 font-size-0-875`) }
            onClick={() => redirectToUrl(link)}
        >
            <div className="flex-1">{text}</div>
            <div>
                <FontAwesomeIcon icon={faLongArrowRight} />{' '}
            </div>
        </div>
    );
};

export default MoreCategories;
