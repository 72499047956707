import staticUrls from "../models/staticUrls";


export function getURLPath(pathName) {
    return staticUrls[pathName?.toLowerCase()] || staticUrls[pathName];
}
export function getURLPathWithParams(pathName, params) {
    let url = getURLPath(pathName);
    if (params) {
        Object.keys(params).forEach((key) => {
            url = url.replace(`:${key}`, params[key]);
        });
    }
    return url;
}

export default {
    getURLPath,
    getURLPathWithParams,
};
