import React from 'react';
import {Link} from "react-router-dom";

const ProductBoxName = ({ product, productUrl }) => {

    return (
        <Link to={productUrl} className="d-block text-dark mb-2 line-height-rem-1-125">
            <h2 className="mb-0 font-size-0-875 font-weight-normal">
                <div className="font-weight-medium text-uppercase">
                    {product?.Design || product?.design}
                </div>
                <div>
                    {product?.Brand || product?.brand} {product?.Model || product?.model}
                </div>
                <div>
                    {product?.ModelFunction || product['Function'] ? product['Function'] : ''}
                </div>
            </h2>
        </Link>);
}

export default ProductBoxName;
