import React, {Component} from "react";
import styled from 'styled-components';
import PerfectScrollbar from 'perfect-scrollbar';
import theme from '../theme';

const Container = styled.div`
    overflow: hidden;
    position: relative;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
    
    &>.ps__rail-x,
    &>.ps__rail-y {
        display: none;
        position: absolute;
        background-color: ${theme.grayLight};
        border-radius: 3px;
        height: 100%;
    }
    
    &.ps--active-x > .ps__rail-x,
    &.ps--active-y > .ps__rail-y {
        display: block;
    }
    
    &.ps--active-y > .content {
        padding-right: 16px;
    }
    
    &>div>.ps__thumb-y {
        height: 12px;
        width: 6px;
        border-radius: 3px;
        background-color: ${theme.grayMedium};
        position: absolute;
        right: 0;
    }
    
    &>.ps__rail-x {
        bottom: 0;
        height: 6px;
    }
    
    &>.ps__rail-y {
        right: 10px;
        width: 6px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
`;

class ScrollableArea extends Component {

    container = null;
    ps = null;

    componentDidUpdate() {
        this.ps?.update();
    }

    setContainer = (container) => {
        this.ps?.destroy();

        if (container) {
            this.ps = new PerfectScrollbar(container, {
                minScrollbarLength: 30,
            });
        } else {
            this.ps = null;
        }

        this.container = container;
    };

    render() {
        const {children, ...rest} = this.props;
        return <Container ref={this.setContainer} {...rest}>
            <div className="content">
                {children}
            </div>
        </Container>
    }
}

export default ScrollableArea;