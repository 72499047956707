import { addDays, addMonths, differenceInDays } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { format } from 'date-fns';

function monthsNumberBetweenDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = (end.getFullYear() - start.getFullYear()) * 12;
    return months - start.getMonth() + end.getMonth();
}

function generateShiftedMonthDays(startDate, startMonth, calculatedMonths) {
    const dayOfTheFirstDay = new Date(startDate).getDay();
    const shifting = (dayOfTheFirstDay === 0 ? 7 : dayOfTheFirstDay) - 1;
    const daysOfMonth = Array.from({ length: shifting }, () => ({
        day: null,
        type: 'regular',
    }));

    for (let i = 0; i < 31; i++) {
        const nextDay = addDaysFormated(startDate, i);
        const month = format(zonedDate(nextDay), 'MM');
        if (month !== startMonth) {
            break;
        }
        daysOfMonth.push({
            day: i + 1,
            type: calculatedMonths[nextDay]
                ? calculatedMonths[nextDay]
                : 'regular',
        });
    }

    if (daysOfMonth.length < 35) {
        for (let i = daysOfMonth.length; i < 35; i++) {
            daysOfMonth.push({ day: null, type: 'regular' });
        }
    }

    if (daysOfMonth.length > 35) {
        for (let i = daysOfMonth.length; i < 42; i++) {
            daysOfMonth.push({ day: null, type: 'regular' });
        }
    }

    return daysOfMonth;
}

function convertCalculatedMonthsToObject(calculatedMonths, skips = []) {
    const result = {};
    calculatedMonths.forEach((monthData, index) => {
        Object.entries(monthData).forEach(([key, value]) => {
            if (skips.includes(key)) {
                return;
            }
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    result[item] = key;
                });
            } else {
                result[value] = key;
            }
        });
    });
    return result;
}

function calculatingMonths(lastMenstrualPeriod, cycleLength, numberOfMonths) {
    const calculatedMonths = [];
    // Calculating all date to definied months
    for (let i = 0; i < numberOfMonths; i++) {
        calculatedMonths.push(
            calculating(
                addDaysFormated(lastMenstrualPeriod, i * cycleLength),
                cycleLength,
            ),
        );
    }

    // Calculate first and last day of the month from the last/next mentrual period
    const dates = {};
    calculatedMonths.forEach((monthData, index) => {
        const startMonth = format(
            zonedDate(monthData?.lastMenstrualPeriod),
            'yyyy-MM',
        );
        const endMonth = format(
            zonedDate(monthData?.nextMenstruationDate),
            'yyyy-MM',
        );
        dates[`${startMonth}-01`] = 1;
        dates[`${endMonth}-01`] = 1;
    });

    const yyMMs = Object.keys(dates);
    const firstyyMM = yyMMs[0];
    const lastyyMM = yyMMs[yyMMs.length - 1];
    // Original calculation change to object because of type
    const calculatedMonthsObject = convertCalculatedMonthsToObject(
        calculatedMonths,
        ['lastMenstrualPeriod', 'expectedDateOfBirth'],
    );
    // Append last menstrual period to the object
    calculatedMonthsObject[
        format(zonedDate(lastMenstrualPeriod), 'yyyy-MM-dd')
    ] = 'lastMenstrualPeriod';
    const result = [];
    // calculating all months (original months number plus new months from
    for (let i = 0; i <= monthsNumberBetweenDates(firstyyMM, lastyyMM); i++) {
        const startDate = addMonthsFormated(firstyyMM, i);
        const startYear = format(zonedDate(startDate), 'yyyy');
        const startMonth = format(zonedDate(startDate), 'MM');
        const days = generateShiftedMonthDays(
            startDate,
            startMonth,
            calculatedMonthsObject,
        );
        result.push({
            date: startDate,
            year: startYear,
            month: startMonth,
            days: days,
            expectedDateOfBirth: calculatedMonths.expectedDateOfBirth,
        });
    }
    return result;
}

function calculating(lastMenstrualPeriod, cycleLength) {
    const ovulationDate = calculateOvulationDay(
        lastMenstrualPeriod,
        cycleLength,
    );
    const veryFertile = veryFertileDays(ovulationDate);
    const fertile = fertileDays(ovulationDate);
    const embedding = embeddingDays(ovulationDate);
    const nextMenstrual = nextMenstruationDate(
        lastMenstrualPeriod,
        cycleLength,
    );
    const pregnancyTest = pregnancyTestCanUse(nextMenstrual);
    const dateOfBirth = expectedDateOfBirth(lastMenstrualPeriod, cycleLength);
   
    return {
        ovulationDay: ovulationDate,
        veryFertileDays: veryFertile,
        fertileDays: fertile,
        embeddingDays: embedding,
        lastMenstrualPeriod: lastMenstrualPeriod,
        nextMenstruationDate: nextMenstrual,
        pregnancyTestCanUse: pregnancyTest,
        expectedDateOfBirth: dateOfBirth,
    };
}

const zonedDate = (date) => utcToZonedTime(new Date(date), 'Europe/Budapest');
const formatedDate = (date) => format(date, 'yyyy-MM-dd');
const addMonthsFormated = (date, months) =>
    formatedDate(addMonths(zonedDate(date), months));
const addDaysFormated = (date, days) =>
    formatedDate(addDays(zonedDate(date), days));

const calculateOvulationDay = (lastMenstrualPeriod, cycleLength) =>
    addDaysFormated(lastMenstrualPeriod, cycleLength - 14);

const veryFertileDays = (ovulationDate) => [
    addDaysFormated(ovulationDate, -1),
    addDaysFormated(ovulationDate, -2),
];

const fertileDays = (ovulationDate) => [
    addDaysFormated(ovulationDate, -3),
    addDaysFormated(ovulationDate, -4),
    addDaysFormated(ovulationDate, -5),
    addDaysFormated(ovulationDate, 1),
];

const embeddingDays = (ovulationDate) => [
    addDaysFormated(ovulationDate, 7),
    addDaysFormated(ovulationDate, 8),
    addDaysFormated(ovulationDate, 9),
];

const nextMenstruationDate = (lastMenstrualPeriod, cycleLength) =>
    addDaysFormated(lastMenstrualPeriod, cycleLength);

const pregnancyTestCanUse = (nextMenstruationDate) =>
    addDaysFormated(nextMenstruationDate, 1);

const expectedDateOfBirth = (lastMenstrualPeriod, cycleLength) =>
    addDaysFormated(lastMenstrualPeriod, cycleLength - 28 + 280);

const embryoAge = (lastMenstrualPeriod, addToday = true) => {
    const today = formatedDate(new Date());
    let days = differenceInDays(
        zonedDate(today),
        zonedDate(lastMenstrualPeriod),
    );
    if (addToday) {
        days++;
    }
    if (days < 0) {
        days = 0;
    }
    return {
        days,
        calculated: {
            weeks: Math.floor(days / 7),
            days: days % 7,
        },
    };
};

export {
    calculating,
    calculateOvulationDay,
    veryFertileDays,
    fertileDays,
    embeddingDays,
    nextMenstruationDate,
    pregnancyTestCanUse,
    expectedDateOfBirth,
    calculatingMonths,
    embryoAge,
};
