import {createActions} from 'redux-actions';

export const {
    setFiltersPagination,
    initializeFilters,
    checkSpecificationFilter,
    checkAttributeFilter,
    updateFilters,
    goToPage,
    setSortOptions,
    setOrderBy,
    setLoading,
    setProductContent,
    clearAllFilters,
} = createActions({
    SET_FILTERS_PAGINATION: pagination => pagination,
    INITIALIZE_FILTERS: (attributeFilters, specificationFilters, categoryId, categoryContextFilter) => ({
        attributeFilters,
        specificationFilters,
        categoryId,
        categoryContextFilter,
    }),
    CHECK_SPECIFICATION_FILTER: (filterId, filterItemId, isChecked) => ({
        filterId,
        filterItemId,
        isChecked,
    }),
    CHECK_ATTRIBUTE_FILTER: (filterId, filterItemId, isChecked) => ({
        filterId,
        filterItemId,
        isChecked,
    }),
    UPDATE_FILTERS: (attributeFilters, specificationFilters) => ({
        attributeFilters,
        specificationFilters,
    }),
    GO_TO_PAGE: pageNumber => pageNumber,
    SET_SORT_OPTIONS: sortOptions => sortOptions,
    SET_ORDER_BY: orderBy => orderBy,
    SET_LOADING: isLoading => isLoading,
    SET_PRODUCT_CONTENT: productContent => productContent,
    CLEAR_ALL_FILTERS: () => null,
});