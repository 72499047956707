import { object, string, ref } from 'yup';
import { legacy_t as t} from '../../contexts/ResourceContext';
import { isSpaceFilled, isValidEmail } from '../../utils/validation';
import {
  FORM_FIELD_LENGTHS,
} from '../constants';

export default function createCustomerPersonalDataSchema() {
    return object({
        email: string()
            .transform(isSpaceFilled)
            .test(
                'Email',
                t('brendon.customer.validation.emailInvalid'),
                isValidEmail,
            )
            .max(
                FORM_FIELD_LENGTHS.EMAIL,
                t(
                    'brendon.customer.validation.emailLength',
                ).replace('#0#', FORM_FIELD_LENGTHS.EMAIL),
            )
            .required(
                t('brendon.customer.validation.emailRequired'),
            ),
        emailConfirm: string()
            .transform(isSpaceFilled)
            .required(
                t('brendon.customer.validation.emailConfirmRequired'),
            )
            .oneOf(
                [ref('email'), null],
                t('brendon.customer.validation.emailConfirmMatch'),
            ),
    });
}
