import React, {Component} from 'react';

export default class OutsideClickDetector extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        const {onClickOutside} = this.props;
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) && onClickOutside) {
            onClickOutside(event);
        }
    };

    render() {
        const {onClickOutside, ...rest} = this.props;
        return <div ref={this.wrapperRef} {...rest}>{this.props.children}</div>;
    }
}
