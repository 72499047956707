import React from 'react';
import { getURLPath } from '../../utils/staticPaths';
import { useResource } from '../../contexts/ResourceContext';

const EmptyCart = () => {
    // TODO: change to text  props
    const { t } = useResource();
    return (<div className="row justify-content-center">
        <div className="col-12 col-md-4">
            <div className="my-2 text-center">{ t("ShoppingCart.CartIsEmpty") }</div>
            <a className="btn btn-primary w-100" href={getURLPath('customerOrders')}>{ t("ShoppingCart.NavigateToOrderHistory") }</a>
        </div>
    </div>)
}

export default EmptyCart;