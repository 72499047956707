import React from 'react';
import { formatPrice } from '../../utils/price';

const OrderTotalItem = ({
    itemName,
    price,
    subItems,
    subItemClassName,
    disabled = false,
    visibleWhenPrice = true,
    visibleZeroPrice = false,
    visibleWhenSubItems = false,
    visibleWhenSubItemPrice = false,
    children,
}) => {
   
    if (visibleZeroPrice) {
        if (visibleWhenPrice && (price === undefined || price === null)) {
            return null;
        }
    } else {
        if (visibleWhenPrice && (!price || price === 0 )) {
            return null;
        }
    }

    if (visibleWhenSubItems && (!subItems || subItems.length === 0)) {
        return null;
    }

    if (
        visibleWhenSubItemPrice &&
        subItems?.reduce?.(
            (sum, item) => sum + (Number(item.price) || 0),
            0,
        ) === 0
    ) {
        return null;
    }

    return (
        <>
        {(!disabled || subItems || children) && <div className="row bg-gray-light font-weight-bold rounded mb-1 mx-0 py-2-5">
            {!disabled && (
                <div
                    className="col pl-2-5"
                    data-test-id="order-total-item-name"
                >
                    {itemName}
                </div>
            )}
            {!disabled && (
                <div
                    className="col text-right pr-2-5"
                    data-test-id="order-total-item-price"
                >
                    {(visibleZeroPrice || Number(price)) > 0 &&
                        formatPrice(price)}
                </div>
            )}
            {subItems?.length > 0 && (
                <>
                    {subItems.map((subItem, index) => (
                        <div
                            key={`tsi_${index}`}
                            className="col-12 row mx-0 px-0 pt-2"
                        >
                            <div
                                className={`col font-weight-normal pl-2-5 ${
                                    subItemClassName || ''
                                }`}
                            >
                                {subItem?.item}
                            </div>
                            <div className="col text-right">
                                {formatPrice(subItem?.price)}
                            </div>
                        </div>
                    ))}
                </>
            )}
            {children && (
                <div className={`col-12 px-2-5 ${disabled ? '' : 'pt-2'} `}>
                    {children}
                </div>
            )}
        </div>}
        </>
    );
};

export default OrderTotalItem;
