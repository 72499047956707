import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Input from '../../elements/Input';
import ShopCard from './ShopCard';
import ShopFinderService from '../../services/shopfinder';
import Loader from '../Loader';
import BannerZone from '../../banners/BannerZone';
import { SEARCH_INPUT_MAX_LENGTH } from '../../models/constants';
import { useResource } from '../../contexts/ResourceContext';

const ShopFinder = () => {
    const { t } = useResource();
    const [shops, setShops] = useState([]);
    const [shopData, setShopData] = useState({
        shopTopDescription: '',
        shopBottomDescription: '',
        shopMetadescription: '',
        shopMetaTitle: '',
    });
    const [filter, setFilter] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getShop = async () => {
            const {
                ShopTopDescription,
                ShopBottomDescription,
                ShopMetaTitle,
                ShopMetadescription,
                ShopList,
            } = await ShopFinderService.get();
            setShops(ShopList);
            setShopData({
                shopTopDescription: ShopTopDescription,
                shopBottomDescription: ShopBottomDescription,
                shopMetadescription: ShopMetadescription,
                shopMetaTitle: ShopMetaTitle,
            });
            setIsLoading(false);
        };
        setIsLoading(true);
        getShop();
    }, [setShops, setIsLoading, setShopData]);

    const handleFilterChange = useCallback(
        (value) => {
            if (value.length > SEARCH_INPUT_MAX_LENGTH) return;
            setFilter(value);
        },
        [setFilter],
    );

    const filterdShops = useMemo(() => {
        return filter
            ? shops.filter((shop) =>
                  shop.StoreName.toLowerCase().includes(filter.toLowerCase()),
              )
            : shops;
    }, [shops, filter]);

    return (
        <>
            {isLoading ? (
                <Loader isLoading />
            ) : (
                <>
                    <Helmet>
                        <title>{shopData?.shopMetaTitle}</title>
                        <meta
                            name="description"
                            content={shopData?.shopMetadescription}
                        />
                    </Helmet>
                    <div className="mt-4">
                        <h1 className="font-size-1-5 text-uppercase text-center">
                            {t('brendon.shopfinder.shops.title')}
                        </h1>
                        <div className="mt-4 promotions">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: shopData?.shopTopDescription,
                                }}
                            ></div>
                            <BannerZone bannerBlockId={20} />
                            {/* <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="p-2-5 bg-gray-light rounded">
                                        <div className="d-flex">
                                            <div>
                                                <a
                                                    href="#"
                                                    className="font-size-1-125 font-weight-medium"
                                                >
                                                    Kedvezményes nagybevásárlás
                                                </a>
                                                <p className="mt-2">
                                                    Vásárolj legalább 200 000 Ft
                                                    értékben, és mi 2% helyett
                                                    vásárlásod értékének 5%-át
                                                    írjuk jóvá neked!
                                                </p>
                                            </div>
                                            <a
                                                href="#"
                                                className="align-self-center ml-2-5"
                                            >
                                                <div className="circle-link d-inline-block font-size-1 cursor-pointer bg-white text-body">
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="far"
                                                        data-icon="long-arrow-right"
                                                        className="svg-inline--fa fa-long-arrow-right fa-w-14 "
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M295.515 115.716l-19.626 19.626c-4.753 4.753-4.675 12.484.173 17.14L356.78 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h344.78l-80.717 77.518c-4.849 4.656-4.927 12.387-.173 17.14l19.626 19.626c4.686 4.686 12.284 4.686 16.971 0l131.799-131.799c4.686-4.686 4.686-12.284 0-16.971L312.485 115.716c-4.686-4.686-12.284-4.686-16.97 0z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div> 
                            </div>  */}
                        </div>
                        <div className="mt-7 row">
                            <div className="col-12 col-sm-8 offset-sm-2 text-center">
                                <h2 className="font-size-1-125 mb-2-5">
                                    {t('brendon.shopfinder.shops.searchtext')}
                                </h2>
                                <Input
                                    value={filter}
                                    onChange={handleFilterChange}
                                    inputClassName="border-gray-dark font-size-1 height-50px shadow-none"
                                    placeholder={t(
                                        'brendon.shopfinder.shops.searchplaceholder',
                                    )}
                                    maxLength={SEARCH_INPUT_MAX_LENGTH}
                                />
                            </div>
                        </div>

                        {filterdShops.length > 0 ? (
                            <div className="mt-4 grid-container size-col-1 size-lg-col-2 size-row-auto">
                                {filterdShops.map((shop, index) => (
                                    <ShopCard
                                        index={index}
                                        key={shop.ShopId}
                                        containerClassName="mb-3"
                                        shop={shop}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="mt-4 col-12 text-center">{t('brendon.shopfinder.shops.noresult')}</div>
                        )}

                        <div className="mt-4 promotions">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: shopData?.shopBottomDescription,
                                }}
                            ></div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ShopFinder;
