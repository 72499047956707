import React, {useCallback} from "react";

function TileSize({id, name, size, checked, onSizeChange, ...props}) {
    const onChange = useCallback(() => onSizeChange(size), [onSizeChange, size]);
    
    let disabledCSS = ''
    const available = size?.available || size?.Available
    if (size.hasOwnProperty('IsOrderable') && !size.IsOrderable) {
        disabledCSS = 'text-gray-medium'
    } else if (!available) {
        disabledCSS = 'text-gray-medium'
    }
    return <div {...props}>
        <input id={id}
               className="d-none"
               type="radio"
               name={name}
               value={size.id || size.name || size.Name}
               onChange={onChange}
               checked={checked}
        />
        <label htmlFor={id}
               className={`cursor-pointer border border-hover-primary text-hover-primary ${checked ? 'text-primary' : disabledCSS } ${checked ? 'border-primary' : 'border-gray-light'} w-100 h-p40 d-flex align-items-center justify-content-center text-center rounded-sm bg-white font-size-0-75 font-weight-medium`}>{size.Name || size?.name }</label>
    </div>
}

export default TileSize