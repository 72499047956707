import React, { useCallback, useState } from 'react';
import CalculatorForm from './CalculatorShared/CalculatorForm';
import CalendarMonth from './OvulationCalculator/CalendarMonth';
import {
  calculatingMonths,
  expectedDateOfBirth,
} from '../../../services/calculators/ovulation';
import {
  OVULATION_CALCULATOR_MAX_MONTHS,
  MIN_CYCLE_LENGTH,
  MAX_CYCLE_LENGTH,
} from '../../../models/constants';
import CalendarExplanation from './OvulationCalculator/CalendarExplanation';
import { getStoreDateFormatVisual } from '../../../utils/localization';
import { useResource } from '../../../contexts/ResourceContext';

const OvulationCalculator = () => {
  const [menstrualPeriod, setMenstrualPeriod] = useState(new Date(Date.now()));
  const [cycleLength, setCycleLength] = useState(28);
  const [calculatedMonths, setCalculatedMonths] = useState([]);
  const { t } = useResource();

  const handleCycleLengthChange = useCallback(
    (value) => {
      setCycleLength(value);
    },
    [setCycleLength],
  );

  const handleBlurCycleLength = useCallback(
    (value) => {
      if (cycleLength < MIN_CYCLE_LENGTH) {
        setCycleLength(MIN_CYCLE_LENGTH);
        return;
      }
      if (cycleLength > MAX_CYCLE_LENGTH) {
        setCycleLength(MAX_CYCLE_LENGTH);
        return;
      }
    },
    [cycleLength, setCycleLength],
  );

    const handleSubmit = useCallback(() => {
        setCalculatedMonths(
            calculatingMonths(
                menstrualPeriod.toISOString(),
                cycleLength,
                OVULATION_CALCULATOR_MAX_MONTHS,
            ),
        );
    }, [menstrualPeriod, cycleLength, setCalculatedMonths]);
// TODO: move to utils (calculator has the same function, but needs to update)
    const formatDate = useCallback((date) => {
        if (!date) return;
        const format = getStoreDateFormatVisual();
        const dateParts = date.split('-');
        return format
            .replace('yyyy', dateParts[0])
            .replace('MMMM', t(`brendon.months.${Number(dateParts[1])}`))
            .replace('dd', Number(dateParts[2]));
    }, []);

  return (
    <div className="ovulation-calculator">
      <CalculatorForm
        menstrualPeriod={menstrualPeriod}
        setMenstrualPeriod={setMenstrualPeriod}
        cycleLength={cycleLength}
        setCycleLength={handleCycleLengthChange}
        blurCycleLength={handleBlurCycleLength}
        onSubmit={handleSubmit}
      />
      {calculatedMonths.length > 0 &&
        calculatedMonths.map((dateData, index) => {
          let baseOfBirth = dateData.days.find(
            (day) => day.type === 'nextMenstruationDate',
          );
          if (baseOfBirth) {
            baseOfBirth = `${dateData.year}-${dateData.month}-${
              String(baseOfBirth?.day)?.length === 1
                ? `0${baseOfBirth.day}`
                : baseOfBirth.day
            }`;
          } else {
            baseOfBirth = menstrualPeriod?.toISOString();
          }
          return (
            <React.Fragment key={index}>
              <CalendarMonth
                key={index}
                year={Number(dateData.year)}
                month={Number(dateData.month)}
                data={dateData}
              />
              <div className="mt-4 mb-7">
                <CalendarExplanation
                  className="d-md-none mb-2 mb-md-0"
                  accepted={dateData?.days?.reduce((acc, day) => {
                    if (!acc[day.type]) {
                      acc[day.type] = true;
                    }
                    return acc;
                  }, {})}
                />
                {t('brendon.calculator.ovulation.expectedDateOfBirth')}{' '}
                <div className="font-weight-medium d-inline-block">
                  {baseOfBirth &&
                    formatDate(expectedDateOfBirth(baseOfBirth, cycleLength))}
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default OvulationCalculator;
