import { callAxios } from './helper/axiosHelper';

export const CustomerRewardPointsService = {
    get: async () =>
        callAxios('get', '/CustomerAccount/GetCustomerPointsFamilyCardcode'),
};

export const CustomerOrdersService = {
    list: async () => callAxios('get', '/CustomerAccount/GetCustomerOrderList'),
    get: async ({orderId}) => callAxios('get', `/CustomerAccount/GetCustomerOrder/${orderId}`),
};

export const CustomerBaseDataService = {
    get: async ({ signal }) => callAxios('get', '/CustomerAccount/GetBaseData', null, { signal }),
    post: async (data) => callAxios('post', '/CustomerAccount/SaveBaseData', data),
};

export const CustomerPasswordChangeService = {
    post: async (data) => callAxios('post', '/CustomerAccount/ChangePassword', data),
};

export const CustomerEmailChangeService = {
    post: async (data) => callAxios('post', '/CustomerAccount/ChangeEmailRequest', data),
};

export const CustomerReorderService = {
    get: async (orderId) => callAxios('get', `/CustomerAccount/Reorder/${orderId}`),
}

export default {
    CustomerRewardPointsService,
    CustomerOrdersService,
    CustomerBaseDataService,
    CustomerPasswordChangeService,
    CustomerReorderService,
};
