import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLongArrowLeft,
    faLongArrowRight,
} from '@fortawesome/pro-regular-svg-icons';

const ArrowButton = ({ to, onClick, children, leftSide = false, rightSide = false, title }) => {
    return (
        <a
            href={to}
            className="btn btn-gray-light font-size-0-875 line-height-rem-1-25 py-1 font-weight-normal rounded-15 text-capitalize mt-4 mb-4 border-0"
            onClick={onClick}
            title={title}
        >
            {leftSide && (
                <FontAwesomeIcon icon={faLongArrowLeft} className="mr-2" />
            )}
            {children}
            {rightSide && (
                <FontAwesomeIcon icon={faLongArrowRight} className="ml-2" />
            )}
        </a>
    );
};

export default ArrowButton;
