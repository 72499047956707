import React from "react";


const MoreButtonV2 = ({ label, icon='', onClick, className='', btnClassName='' }) => {
  return (
    <div className={ `${className} more-post text-center mb-3`}>
      <button
        type="button"
        className={ `${btnClassName || ''} btn rounded-pill bg-gray-dark text-white font-size-0-625` } 
        onClick={ onClick }
      >
        {icon}{label}
      </button>
    </div>
  )
}

export default MoreButtonV2;
