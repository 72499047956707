import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import withStore from "../hoc/withStore";
import {addProduct, removeProduct} from "../store/wishlist";
import {createStructuredSelector} from "reselect";
import {makeIsProductAddedToWishlistSelector} from "../store/wishlist/selectors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart} from '@fortawesome/pro-solid-svg-icons'
import {faHeart as farHeart} from '@fortawesome/pro-regular-svg-icons';

const Icon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.pink};
    font-size: 1.2rem;
    line-height: inherit;
    display: block;
    transition: transform 0.2s ease-in-out;
    transform: scale(${props => props.shown ? 1 : 0});
    position: ${props => props.filling ? 'absolute' : 'relative'};
`;

const IconWrapper = styled.span`
    position: relative;
`;

const makeMapStateToProps = () => createStructuredSelector({
    isAdded: makeIsProductAddedToWishlistSelector()
});

const mapDispatchToProps = dispatch => ({
    addProduct: (productId) => dispatch(addProduct(productId)),
    removeProduct: (productId) => dispatch(removeProduct(productId)),
});

class AddToWishlistButton extends Component {
    onClick = () => {
        const {productId} = this.props;
        if (this.props.isAdded) {
            this.props.removeProduct(productId);
        } else {
            this.props.addProduct(productId);
        }
    };

    render() {
        const {title, isAdded} = this.props;
        return <div onClick={this.onClick} className="btn btn-lg btn-outline-pink font-size-0-875 d-flex justify-content-center align-items-center">
            <span className="mr-2">{title}</span>
            <IconWrapper className="text-pink">
                <Icon icon={faHeart} shown={isAdded?1:0} filling={1}/>
                <Icon icon={farHeart} shown={1} filling={0}/>
            </IconWrapper>
        </div>
    }
}

export default withStore(connect(makeMapStateToProps, mapDispatchToProps)(AddToWishlistButton));
