import axios from 'axios';

async function getProductPage(productId) {
    const {data} = await axios.get(`/Catalog/GetProductPage/${productId}`);
    return data;
}

async function getUpsell(productId) {
    const {data} = await axios.get(`/Catalog/GetUpsellProducts/${productId}`);
    return data;
}

export const ProductService = {
    getProductPage,
    getUpsell,
};

export default ProductService;
