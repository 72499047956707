import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import CustomerMainContainer from '../../components/CustomerNew/CustomerMainContainer';
import CustomerTitle from '../../components/CustomerNew/CustomerTitle';
import CustomerChangePassword from '../../components/CustomerNew/CustomerChangePassword';
import CustomerBackButton from '../../components/CustomerNew/CustomerBackButton';
import { useResource } from '../../contexts/ResourceContext';

const CustomerChangePasswordScreen = () => {
    const { t } = useResource();
    return (
        <>
            <MetaHeader title={t('PageTitle.Account')} showBrendonAddon />
            <CustomerMainContainer>
                <CustomerBackButton />
                <CustomerTitle title={t('brendon.customer.passwordchange.title')} />
                <CustomerChangePassword />
            </CustomerMainContainer>
        </>
    );
};

export default CustomerChangePasswordScreen;
