import React from 'react';
import Icon from '../Icon';
import { getDomainStoreId } from '../../utils/localization';
import SafePayIcon from './SafePayIcon';

const SVG_ICONS = [
    { icon: 'google-pay', title: 'Google Pay' },
    { icon: 'apple-pay', title: 'Apple Pay' },
    { icon: 'mastercard', title: 'Mastercard' },
    { icon: 'visa', title: 'Visa' },
    { icon: 'maestro', title: 'Maestro' },
    getDomainStoreId() === 1 ? { icon: 'kh', title: 'K&H' } : null,
    {
        link: 'https://www.paymentgateway.hu/',
        icon: 'big-fish',
        title: 'BIG FISH Payment Gateway',
    },
    getDomainStoreId() === 2 ? { icon: 'borgun', title: 'Borgun' } : null,
/*     getDomainStoreId() === 2 ? { icon: 'brendon-storepickup-sk', title: 'Brendon' } : { icon: 'brendon-storepickup', title: 'Brendon' }, */
    getDomainStoreId() === 2 ? null : { icon: 'brendon-storepickup', title: 'Brendon' },
    getDomainStoreId() === 2 ? { icon: 'gls-pickup-sk', title: 'GLS' } : { icon: 'gls-csomagpont', title: 'GLS' },
    getDomainStoreId() === 2 ? { icon: 'gls-locker-sk', title: 'GLS' } : { icon: 'gls-locker', title: 'GLS' },
    { icon: 'gls-delivery', title: 'GLS' },
];

const SafePayBox = ({ title }) => {
    return (
        <div className="bg-light rounded p-3 p-md-4 h-100">
            <div className="d-flex align-items-center mb-3 mb-md-4">
                <Icon
                    iconType="fal"
                    iconName="shield-check"
                    className="text-primary font-size-1-875 font-size-lg-2-5 mr-3"
                />
                <span className="font-weight-medium font-size-0-875 font-size-md-1 text-uppercase">
                    {title}
                </span>
            </div>
            <div className="row mx-n1">
                {SVG_ICONS.map(
                    (icon, idx) =>
                        icon && (
                            <SafePayIcon key={`safepay_${idx}`} {...icon} />
                        ),
                )}
            </div>
        </div>
    );
};

export default SafePayBox;
