import React from "react";


const MoreButton = ({ label, icon='', onClick, className='', btnClassName='' }) => {
  return (
    <div className={ `${className} more-post text-center mb-5`}>
      <button
        type="button"
        className={ `${btnClassName} btn btn-outline-primary` } 
        onClick={ onClick }
      >
        {icon}{label}
      </button>
    </div>
  )
}

export default MoreButton;
