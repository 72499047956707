import React from "react";
import RegistrationForm from './RegistrationForm';
import { useResource } from '../contexts/ResourceContext';

function Registration(props) {
    // TODO: change to title props
    const { t } = useResource();
    return <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 mt-5">
                    <h1 className="font-size-1-125 mb-4 text-center">
                        { t("Account.Register") }
                    </h1>
                    <div className="font-size-0-75">
                     <RegistrationForm 
                        {...props} />
                    </div>

                </div>
            </div>
        </div>
}

export default Registration;