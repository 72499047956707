import { callAxios } from './helper/axiosHelper'

const billingAddress = {
    get: async () => callAxios('get', '/Checkout/GetBillingAddressList'),
    select: async (id) => callAxios('get', `/Checkout/PickBillingAddress/${id}`),
    post: async (data) => callAxios('post', `/Checkout/SetNewBillingAddress`, data),
    delete: async (id) => callAxios('delete', `/Checkout/DeleteBillingAddress/${id}`),
    update: async (data) => callAxios('post', `/Checkout/ChangeBillingAddress`, data),
}

const shippingAddress = {
    get: async () => callAxios('get', '/Checkout/GetShippingAddressList'),
    select: async (id) => callAxios('get', `/Checkout/PickShippingAddress/${id}`),
    post: async (data) => callAxios('post', `/Checkout/SetNewShippingAddress`, data),
    delete: async (id) => callAxios('delete', `/Checkout/DeleteShippingAddress/${id}`),
    update: async (data) => callAxios('post', `/Checkout/ChangeShippingAddress`, data),
}

const paymentMethod = {
    get: async () => callAxios('get', '/Checkout/GetPaymentModeList'),
    select: async (id) => callAxios('get', `/Checkout/PickPaymentMode/${id}`),
}

const giftCard = {
    post: async (data) => callAxios('post', `/Checkout/SetGiftCard`, data),
    delete: async (id) => callAxios('delete', `/Checkout/DeleteGiftCard/${id}`), 
}

const orderConfirm = {
    get: async () => callAxios('get', '/Checkout/OrderConfirm'),
}

const deliveryMode = {
    get: async () => callAxios('get', '/Checkout/GetDeliveryModes'),
    select: async (id) => callAxios('get', `/Checkout/PickDeliveryMode/${id}`),
}

const shopList = {
    get: async () => callAxios('get', '/Checkout/GetShopList'),
    select: async (id) => callAxios('get', `/Checkout/PickShop/${id}`),
}

const orderDataForGA4 = {
    get: async (id) => callAxios('get', `/Checkout/OrderDataForGA4/${id}`),
}

const glsDropoff = {
    get: async () => callAxios('get', '/Checkout/GLSDropoffLoad'),
    post: async (data) => callAxios('post', '/Checkout/GLSDropoffPick', data),
}

export {
    billingAddress,
    shippingAddress,
    paymentMethod,
    giftCard,
    orderConfirm,
    deliveryMode,
    shopList,
    orderDataForGA4,
    glsDropoff
}