import React, {Component} from 'react';
import styled from 'styled-components';
import Carousel from "../components/Carousel";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import LazyImage from "../elements/LazyImage";

const Square = styled.div`
    position: relative;
    width: 100%;
    padding-top: 100%;
`;

class RelatedCategories extends Component {
    render() {
        const {title, categories} = this.props;

        return <>
            <h3 className="font-size-1 text-uppercase font-weight-normal border-bottom line-height-3 pb-2 mb-0 mb-md-3">
                {title}
            </h3>
            <div className="d-none d-md-block">
                <Carousel snap={true} wrapperClass="row flex-nowrap" elementWrapperClass="col-8 col-md-4 col-lg-3">
                    {categories.map((category, index) =>
                        <div className="col-8 col-md-4 col-lg-3" key={index}>
                            <a href={`/${category?.CategorySlug || category?.Url || category?.Slug}`} className="d-block rounded-sm border p-2 h-100 text-dark">
                                <Square>
                                    <LazyImage
                                        alt={category?.PictureSeoFilename || category.Alt}
                                        title={category?.PictureSeoFilename || category.Alt}
                                        src={category?.CategoryPictureUrl || category.Image || category?.PictureUrl}
                                        className="lazy w-100 h-100 position-absolute top left object-fit-contain"/>
                                </Square>
                                <h2 className="font-size-0-875 font-weight-medium line-height-rem-1-125 text-center mt-md-2 mb-0">
                                    {category?.CategoryName || category.Name}
                                </h2>
                            </a>
                        </div>
                    )}
                </Carousel>
            </div>
            <div className="d-flex flex-column d-md-none">
                {categories.map((category, index) =>
                    <a href={`/${category?.CategorySlug || category?.Url || category?.Slug}`} key={index} className="d-flex border-bottom p-1 h-100 text-dark align-items-center">
                            <LazyImage
                                alt={category?.PictureSeoFilename || category.Alt}
                                title={category?.PictureSeoFilename || category.Alt}
                                src={category?.CategoryPictureUrl || category.Image || category?.PictureUrl}
                                className="lazy w-p50 h-p50 object-fit-contain mr-2"
                            />
                        <h2 className="font-size-0-75 font-weight-medium line-height-rem-1 flex-grow-1 mb-0">
                            {category?.CategoryName || category.Name}
                        </h2>
                        <div className="font-size-0-625 px-2">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </a>
                )}
            </div>
        </>
    }
}

export default RelatedCategories;
