import Cart from "../../services/cart";
import {apiRequestAction, failure, request, success} from "../actions";
import {ADD_TO_CART} from "../wishlist";
import {fromJS, Map} from "immutable";
import {updateCartCount, updateWishlistCount} from "./actions";

export const ADD_TO_CART_NOPCOMMERCE = 'ADD_TO_CART_NOPCOMMERCE';
export const ADD_TO_CART_AJAX = 'ADD_TO_CART_AJAX';
const ADD_TO_WISHLIST_NOPCOMMERCE = 'ADD_TO_WISHLIST_NOPCOMMERCE';

const initialState = fromJS({
    cartCount: 0,
    wishlistCount: 0,
    cartSuccess: false,
    wishlistSuccess: false,
    loading: false,
    result: {},
});

// Legacy, deprecated, need to remove and remove all references
export const addProductToCart = (productId, data) => apiRequestAction(ADD_TO_CART_NOPCOMMERCE, async () => Cart.addToCart(productId, data))();
// Use this
export const addProductToCartWithSize = (productId, size, qty) => apiRequestAction(ADD_TO_CART_AJAX, async () => Cart.addToCartWithSizeName(productId, size, qty))();
// Deprecated
export const addProductToWishlist = (productId, data) => apiRequestAction(ADD_TO_WISHLIST_NOPCOMMERCE, async () => Cart.addToWishlist(productId, data))();

export default function cart(state = initialState, action) {
    switch (action.type) {
        case updateWishlistCount.toString():
            return state.set('wishlistCount', action.payload);
        case updateCartCount.toString():
            return state.set('cartCount', action.payload);
        case success(ADD_TO_CART):
            return state.set('cartCount', action.result.CartCount);
        case request(ADD_TO_CART_AJAX):    
        case request(ADD_TO_WISHLIST_NOPCOMMERCE):
        case request(ADD_TO_CART_NOPCOMMERCE):
            return state.set('loading', true); 
        case success(ADD_TO_CART_AJAX):    
        case success(ADD_TO_WISHLIST_NOPCOMMERCE):
        case success(ADD_TO_CART_NOPCOMMERCE):
            return state
                .set('result', Map({
                    message: action.result.message,
                    success: action.result.success || action.result.Success,
                }))
                .set('cartSuccess', (action.type === success(ADD_TO_CART_NOPCOMMERCE) || action.type === success(ADD_TO_CART_AJAX)) ? action.result.success || !!action.result.Success : state.get('cartSuccess'))
                .set('wishlistSuccess', (action.type === success(ADD_TO_WISHLIST_NOPCOMMERCE)) ? action.result.success : state.get('wishlistSuccess'))
                .set('cartCount', action.result.success || action.result.Success ? (action.result.updatetopcartsectionhtml || action.result.CartCount || state.get('cartCount') ) : state.get('cartCount'))
                .set('wishlistCount', action.result.success ? (action.result.updatetopwishlistsectionhtml || state.get('wishlistCount')) : state.get('wishlistCount'))
                .set('loading', false);
        case failure(ADD_TO_CART_AJAX):        
        case failure(ADD_TO_WISHLIST_NOPCOMMERCE):
        case failure(ADD_TO_CART_NOPCOMMERCE):
            return state.set('loading', false);
        default:
            return state
    }
}