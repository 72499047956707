import React, { useLayoutEffect, useMemo } from 'react';
import classnames from 'classnames';
import { on } from 'react-use/lib/util';

const FullscreenModalItem = ({
    leftChildren,
    rightChildren,
    isSelected,
    isDisabled = false,
    selectedClassName,
    notSelectedClassName,
    leftSelectedClassName,
    leftNotSelectedClassName,
    className,
    children,
    scrollHookId,
    scrollTo,
    onSelect    
}) => {
    useLayoutEffect(() => {
        if (scrollTo) {
            const scrollHook = document.getElementById(scrollHookId);
            scrollHook.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [scrollTo]);

    return (
        <>
            {scrollHookId && <div id={scrollHookId} className="scroll-hook" />}
            <div
                className={classnames(
                    'font-size-0-875 d-flex flex-column',
                    className,
                    isSelected ? selectedClassName : notSelectedClassName,
                    isDisabled ? 'opacity-50' : '',
                    onSelect ? 'cursor-pointer' : ''
                )}
            >
                <div className="d-flex flex-1" onClick={onSelect}>
                    <div className={classnames(isSelected ? leftSelectedClassName : leftNotSelectedClassName,)}>{leftChildren}</div>
                    <div className="ml-auto">{rightChildren}</div>
                </div>
                {children}
            </div>
        </>
    );
};

export default FullscreenModalItem;
