import axios from 'axios';

async function getPostList(
    StoreId,
    {
        tagId: BlogPostTagId,
        categoryId: BlogCategoryId,
        searchString: SearchString,
    },
) {
    const { data } = await axios.post('/BrendonBlog/GetPostList', {
        StoreId,
        BlogPostTagId,
        BlogCategoryId,
        SearchString,
    });

    return {
        postList: data?.BlogPostList,
        tagList: data?.BlogPostTagList,
        tagName: data?.BlogPostTagName || null,
    };
}

async function getPost(blogPostId) {
    const { data } = await axios.get(`/BrendonBlog/GetPost/${blogPostId}`);

    return {
        post: data?.BaseData,
        tagList: data?.Tags,
        categories: data?.Categories,
        postListByTag: data?.BlogPostsByTag,
        latestList: data?.Last10BlogPosts,
    };
}

async function getTagList() {
    const { data } = await axios.get(`/BrendonBlog/GetBlogPosTags/`);
    return data;
}

async function getMain() {
    const { data } = await axios.get(`/BrendonBlog/GetBlogMainPageData/`);
    return data;
}

async function getCategoryPostList(categoryId) {
    const {
        data: { Tags, BlogPosts, ...category },
    } = await axios.get(`/BrendonBlog/GetBlogCategoryPageData/${categoryId}`);

    return {
        category: category,
        posts: BlogPosts,
        tagList: Tags,
    };
}

export const BlogService = {
    getPostList,
    getPost,
    getTagList,
    getMain,
    getCategoryPostList,
};

export default BlogService;
