import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useResource } from '../../contexts/ResourceContext';

const BlogHtmlMeta = ({
  titleType = 'list',
  titleAddon = '',
  metaDescription,
}) => {
  const [title, setTitle] = useState();
  const { t } = useResource()
  
  useLayoutEffect(() => {
    if (metaDescription != null) {
      document
        .querySelector('meta[name="description"]:not([data-react-helmet])')
        ?.remove();
    }
  }, [metaDescription]);

  useLayoutEffect(() => {
    const originalTitle = document.querySelector('title').innerText;
    switch (titleType) {
      case 'category':
      case 'post':
        setTitle(
          (titleAddon && titleAddon != '' ? titleAddon + ' | ' : '') +
            originalTitle,
        );
        break;
      case 'tag':
        setTitle(
          (titleAddon && titleAddon !== ''
            ? t('Blog.Meta.Tag.Title')?.replace('{0}', titleAddon) + ' | '
            : '') + originalTitle,
        );
        break;
      case 'list':
      default:
        setTitle(t('Blog.Meta.Title') + ' | ' + originalTitle);
        break;
    }
  }, [setTitle, titleType, titleAddon]);

  return (
    <Helmet>
      <title>{title}</title>
      {metaDescription && (
        <meta name="description" content={metaDescription}></meta>
      )}
    </Helmet>
  );
};

export default BlogHtmlMeta;
