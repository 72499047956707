import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import withStore from "../hoc/withStore";
import {addProduct, removeProduct} from "../store/wishlist";
import {makeIsProductAddedToWishlistSelector} from "../store/wishlist/selectors";
import {createStructuredSelector} from "reselect";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart} from '@fortawesome/pro-solid-svg-icons'
import {faHeart as farHeart} from '@fortawesome/pro-regular-svg-icons';

const Icon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.pink};
    transition: transform 0.2s ease-in-out;
    transform: scale(${props => props.shown ? 1 : 0});
    position: ${props => props.filling ? 'absolute' : 'relative'};
`;

const IconWrapper = styled.div`
    position: relative;
`;

const makeMapStateToProps = () => createStructuredSelector({
    isAdded: makeIsProductAddedToWishlistSelector()
});

const mapDispatchToProps = dispatch => ({
    addProduct: (productId) => dispatch(addProduct(productId)),
    removeProduct: (productId) => dispatch(removeProduct(productId)),
});

class AddToWishlistIcon extends Component {
    onClick = () => {
        const {productId} = this.props;
        if (this.props.isAdded) {
            this.props.removeProduct(productId);
        } else {
            this.props.addProduct(productId);
        }
    };

    render() {
        const {isAdded} = this.props;
        return <IconWrapper className="text-pink line-height-3 cursor-pointer d-flex align-items-center h-p20 w-p10" onClick={this.onClick}>
            <Icon icon={faHeart} shown={isAdded?1:0} filling={1} className='text-center'/>
            <Icon icon={farHeart} shown={1} filling={1} className='text-center'/>
        </IconWrapper>
    }
}

export default withStore(connect(makeMapStateToProps, mapDispatchToProps)(AddToWishlistIcon));
