import { object, string } from 'yup';
import { legacy_t as t} from '../../contexts/ResourceContext';
import { isSpaceFilled } from '../../utils/validation';

export default function createCustomerPersonalDataSchema() {
    return object({
        lastName: string()
            .transform(isSpaceFilled)
            .required(t('brendon.customer.validation.lastNameRequired')),
        firstName: string()
            .transform(isSpaceFilled)
            .required(t('brendon.customer.validation.lastNameRequired')),
        phone: string()
            .transform(isSpaceFilled)
            .required(t('brendon.customer.validation.phoneRequired'))
            .max(
                18,
                t('brendon.customer.validation.phoneLength').replace('#0#', 18),
            ),
    });
}
