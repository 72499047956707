import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

/* const paymentProcessors = {
  'BigFishBorgun': 'BigFishBorgun',
  'BigFishKH': 'BigFishKH',
  'BigFishPayPal': 'BigFishPayPal',
  'BigFishGlobalPay': 'BigFishGlobalPay',
  'KhVPos': 'KhVPos',
} */

const PaymentCallbackScreen = ({ id: paymentProcessor = '' }) => {
  const { t } = useResource();
  // TODO: not working / const { orderId, TransactionId, resultCode, resultMessage } = useParams();
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId');
  const transactionId = searchParams.get('TransactionId');
  const resultCode = searchParams.get('ResultCode');
  const resultMessage = searchParams.get('ResultMessage');

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
        <div className="mb-5">
          <div className="font-size-1 font-weight-medium text-uppercase">
            {t(`Plugins.Payments.${paymentProcessor}.CantQueryPaymentStatus`)}
          </div>
          <div className="font-size-0-875 pb-2">
            <div>
              {t('Checkout.OrderNumber')}: {orderId}
            </div>
            <div>
              {t(`Plugins.Payments.${paymentProcessor}.MoreInfo`)}
              <ul>
                <li>{transactionId}</li>
                <li>{resultCode}</li>
                <li>{resultMessage}</li>
              </ul>
            </div>
          </div>
        </div>
        <a href="/cart" className="btn btn-primary btn-lg w-100">
          {t(`Plugins.Payments.${paymentProcessor}.TryAgain`)}
        </a>
      </div>
    </div>
  );
};

export default PaymentCallbackScreen;
