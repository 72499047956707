import React from 'react';
import LazyImage from '../../elements/LazyImage';

const SafePayIcon = ({ icon, title, link }) => {
    const IconLoad = () => {
        return (
            <LazyImage
                src={`/images/footer_icons/${icon}.svg`}
                title={title}
                alt={title}
                className="h-100 w-100 object-fit-contain lazy"
            />
        );
    };

    return (
      <div className="col-4 text-center mb-2 px-1">
        <div className="bg-white rounded-5 h-p60 d-flex align-items-center ">
            {link ? (
                <a href={link} target="_blank">
                    <IconLoad />
                </a>
            ) : (
                <IconLoad />
            )}
        </div>
      </div>
    );
};

export default SafePayIcon;
