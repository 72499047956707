import React, { useMemo } from 'react';
import { useScript } from '../../hooks/useScript';

const SyndiGo = () => {
    useScript(
        `https://content.syndigo.com/site/db56df1f-006a-4486-a4b0-3c28da255408/tag.js?cv=${Math.floor(
            Date.now() / 86400000,
        )}`,
        false,
        true,
    );
    return <></>;
};

export default SyndiGo;
