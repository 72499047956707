import React, {Component} from 'react';
import Carousel from "../components/Carousel";

class RelatedProducts extends Component {
    render() {
        const {title, children} = this.props;
        return <>
            <h3 className="font-size-1 text-uppercase font-weight-normal border-bottom line-height-3 pb-2 mb-3">
                {title}
            </h3>
            <Carousel snap={true} wrapperClass="row flex-nowrap" elementWrapperClass="col-9 col-md-4 col-lg-3">
                {children}
            </Carousel>
        </>
    }
}

export default RelatedProducts;
