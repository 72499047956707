import React, { useState, useEffect, useCallback } from 'react';
import BlogListItem from './BlogListItem';
import SectionContainer from './MainSection/SectionContainer';
import BlogService from '../../services/blog';
import BlogTagsV2 from './BlogTagsV2';
import MoreButton from './MoreButton';
import { MAX_POST_ON_LIST } from '../../models/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogCategoryPostList } from '../../store/blog';
import { selectBlogCategoryPostList } from '../../store/blog/selectors';
import BlogPostCarousel from './BlogPostCarousel';
/* import CalculatorBox from './CalculatorBox'; */
import BlogCategoryMenu from './BlogCategoryMenu';
import SectionTitle from './MainSection/SectionTitle';
import { BLOG_CATEGORY_COLORS } from '../../models/constants';
import withResource from '../../hoc/withResource';
import BlogHtmlMeta from './BlogHtmlMeta';

const BlogCategoryPostList = ({ categoryId, t }) => {
    const [posts, setPosts] = useState([]);
    const [tags, setTags] = useState([]);
    const [category, setCategory] = useState({});
    const [pageNum, setPageNum] = useState(1);
    const [blogCategories, setBlogCategories] = useState([]);

    const dispatch = useDispatch();
    const blogCategoryPost = useSelector(selectBlogCategoryPostList);

    useEffect(() => {
        dispatch(getBlogCategoryPostList(categoryId));
    }, [dispatch, categoryId]);

    // All blog post from redux if it's not tag list
    useEffect(() => {
        const resultPostList = blogCategoryPost.get('posts');
        const resultTagList = blogCategoryPost.get('tagList');
        const resultCategory = blogCategoryPost.get('category');
        if (Array.isArray(resultPostList) && resultPostList?.length > 0) {
            resultPostList.sort(
                (a, b) => Number(a?.DisplayOrder) - Number(b?.DisplayOrder),
            );
        }
        setPosts(resultPostList);
        setTags(resultTagList);
        setCategory(resultCategory);
    }, [blogCategoryPost]);

    useEffect(() => {
        const fetchData = async () => {
            const { BlogCategories = [] } = await BlogService.getMain();
            setBlogCategories(BlogCategories);
        };
        fetchData();
    }, []);

    const showMore = useCallback(() => {
        setPageNum((p) => ++p);
    }, [setPageNum]);

    const colorId = BLOG_CATEGORY_COLORS[category?.BlogPostCategoryId] || 0;
    const visiblePostNum = pageNum * MAX_POST_ON_LIST;

    return (
        <>
        <BlogHtmlMeta titleType="category" titleAddon={category?.BlogCategory} />
        <div className="row">
            <div className="col-12">
                <SectionTitle
                    className="mb-3"
                    titlePrefixContent={
                        <span
                            className={`circle ${`bg-blog-color-${colorId}`} d-inline-block mr-2`}
                        ></span>
                    }
                    title={category?.BlogCategory}
                />
            </div>
            <div className="col-12 col-lg-8">
                <BlogPostCarousel
                    posts={posts?.slice(0, 3)}
                    containerClassName="mb-5 mb-md-7"
                />
{/*                 <div className="mb-3">
                    <span className="text-uppercase font-weight-bold font-size-0-875">
                        {category?.BlogPostCount}{' '}
                        {t('brendon.blog.post.postnum')}
                    </span>
                </div> */}
                <SectionContainer className="mb-7 d-md-none">
                    <BlogTagsV2
                        title={t('brendon.blog.categorylabels')}
                        className="px-3 pt-3 bg-gray-light rounded"
                        tags={tags}
                    />
                </SectionContainer>

                {posts?.slice(0, visiblePostNum)?.map((item, index) => {
                    return <BlogListItem item={item} key={index} />;
                })}

                {visiblePostNum < posts?.length && (
                    <MoreButton
                        label={t('brendon.blog.morepost')}
                        onClick={showMore}
                        icon={
                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                        }
                        btnClassName="btn-lg px-4"
                    />
                )}
            </div>
            <div className="col-12 col-lg-4">
                <SectionContainer className="mb-5 d-none d-md-block">
                    <BlogTagsV2
                        title={t('brendon.blog.categorylabels')}
/*                         content={category?.Description} */
                        className="px-3 pt-3 bg-gray-light rounded"
                        tags={tags}
                    />
                </SectionContainer>
{/*                 <CalculatorBox
                    className="bg-gray-light rounded mb-5"
                    image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                    title="Ovulációs kalkulátor"
                    iconName="Heart"
                    url="#"
                /> */}
                <SectionContainer className="pb-2">
                    <BlogCategoryMenu categories={blogCategories} />
                </SectionContainer>
            </div>
        </div>
        </>
    );
};

export default withResource(BlogCategoryPostList);
