import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart as farShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../elements/Button';
import { useResource } from '../../contexts/ResourceContext';

const AddToCartButton = ({ canAddToCart, addToCart, isLoading }) => {
    const { t } = useResource();
    const addToCartClick = () => {
        if (canAddToCart && addToCart) {
            addToCart();
        }
    };

    return (
        <Button
            onClick={addToCartClick}
            variant={`${canAddToCart ? 'primary' : 'none'}`}
            disabled={!canAddToCart}
            isLoading={isLoading}
            className={`${
                canAddToCart
                    ? 'cursor-pointer'
                    : 'bg-gray-light text-gray cursor-default bg-hover-light'
            } w-100 text-nowrap`}
        >
            {t('brendon.wishlist.AddToCart')}
            <FontAwesomeIcon
                icon={farShoppingCart}
                className={`ml-1 ${
                    canAddToCart ? 'text-white' : 'text-dark-gray'
                }`}
            />
        </Button>
    );
};

export default AddToCartButton;
