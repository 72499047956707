import React from "react";
import styled from 'styled-components';
import TileSize from "./TileSize";
import withThemeProvider from "../hoc/withThemeProvider";

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
`;

const StyledTileSize = styled(TileSize)`
    padding: 5px;
    flex: 0 0 25%;
    
    @media all and (min-width: ${props => props.theme.gridBreakpoints.md}) {
        flex: 0 0 20%;
    }
`;
 
function TileSizeSelector({value, name, sizes, onSizeChange}) {
    return <Wrapper>
        {sizes.map((size, index) => {
            let checked = false;
            if (value?.Name) {
                checked = value?.Name === size.Name
            }
            if (value?.name) {
                checked = value?.name === size.name
            }
            return <StyledTileSize key={index}
                    id={`${name}_${index}`}
                    name={name}
                    size={size}
                    onSizeChange={onSizeChange}
                    checked={checked}/>
            })
        }
    </Wrapper>
}

export default withThemeProvider(TileSizeSelector)
