import React, { Component } from 'react';
import DeleteButton from '../../../elements/DeleteButton';
import QuantityInput from './QuantityInput';
import { legacy_t as t} from '../../../contexts/ResourceContext';
import { formatPrice } from '../../../utils/price';
import ItemPrice from '../../../components/ShoppingCart/ItemPrice';
import gaService from '../../../services/gaEnhancedEcommerce';
import GA4Service from '../../../services/gaGA4';
import { getStoreCurrency } from '../../../utils/localization';
import ExtraInfoItem from '../../../components/ShoppingCart/ExtraInfoItem';

class OrderItem extends Component {
    removeItem = () => {
        const { removeItem, item } = this.props;
        removeItem(item.ProductId, item.AttributesXml);
        const productName = !item?.Name
            ? [item?.Brand, item?.Model, item?.Design, item?.Function].join(' ')
            : item.Name;

        gaService.removeProductFromCart({
            id: item.ProductId,
            name: productName,
        });
        GA4Service.gtagEvent('remove_from_cart', {
            currency: getStoreCurrency(),
            value:
                item?.DiscountedPrice > 0 ? item?.DiscountedPrice : item?.Price,
            items: [
                GA4Service.transformProductToItemListItem('cart', 'Cart', item),
            ],
        });
    };

    changeItem = (value) => {
        const { changeItem, item } = this.props;
        changeItem(item.ProductId, item.AttributesXml, value);
    };

    render() {
        const { item, showProductImages, isEditable, errorText } = this.props;

        const unitPriceLabel = t('ShoppingCart.UnitPrice');
        const itemTotalLabel = t('ShoppingCart.ItemTotal');
        const quantityLabel = t('ShoppingCart.Quantity');

        return (
            <div className="border rounded-lg my-2-5 p-2-5">
                <div className="d-flex flex-column flex-sm-row align-items-lg-center flex-lg-nowrap overflow-hidden position-relative">
                    {isEditable && !item.DisableRemoval && (
                        <div
                            className="position-absolute"
                            style={{ top: '5px', right: '5px' }}
                        >
                            <DeleteButton
                                type="button"
                                name="updatecart"
                                value="update"
                                onClick={this.removeItem}
                            />
                        </div>
                    )}
                    <div className=" mb-2-5 mb-sm-0 text-center flex-shrink-0">
                        {showProductImages && (
                            <div className="rounded">
                                <a className="" href={item?.Url}>
                                    <img
                                        className="cart-img"
                                        alt={item?.Url}
                                        src={item.Image}
                                        title={item?.Title}
                                    />
                                </a>
                            </div>
                        )}
                    </div>

                    <div className="flex-1 mx-sm-4">
                        <div className="d-flex flex-column">
                            <div className="mb-2-5 mb-lg-2">
                                <a
                                    href={item?.Url}
                                    className="text-dark line-height-rem-1"
                                >
                                    {!item?.Name ? (
                                        <div className="font-size-0-875">
                                            <div className="text-uppercase">
                                                {item?.Brand}
                                            </div>
                                            <div className="font-size-1 font-weight-medium">
                                                {item?.Model} {item?.Design}
                                            </div>
                                            <div className="font-size-1 font-weight-medium">
                                                {item?.Function}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="font-size-1 font-weight-medium">
                                            {item.Name}
                                        </div>
                                    )}
                                </a>
                            </div>

                            <div className="mb-2 mb-lg-0 fit-content">
                                {item?.Size && (
                                    <ExtraInfoItem
                                        message={`${t(
                                            'brendon.shoppingcart.size',
                                        )} ${item.Size}`}
                                        type="size"
                                    />
                                )}
                                {item?.ShoppingCartItemInfos?.map(
                                    (info, index) => (
                                        <ExtraInfoItem
                                            key={`eii_${index}`}
                                            message={info?.Message}
                                            type={info?.Type}
                                            extraInfo={info?.ExtraInfo}
                                        />
                                    ),
                                )}
                            </div>

                            <div className="d-sm-block d-lg-none">
                                <div className="d-flex align-items-center justify-content-between mb-2-5">
                                    <div className="text-center text-gray font-size-0-75 line-height-3">
                                        {quantityLabel}:
                                    </div>
                                    {isEditable ? (
                                        <QuantityInput
                                            item={item}
                                            changeItem={this.changeItem}
                                        />
                                    ) : (
                                        <div className="font-size-0-75 text-center line-height-3 line-height-lg-5">
                                            <span className="text-nowrap font-weight-bold">
                                                {item.Quantity}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex align-items-center justify-content-between text-right mb-2-5">
                                    <div className="text-gray font-size-0-75 line-height-3">
                                        {unitPriceLabel}:
                                    </div>
                                    <div className="font-size-0-875 text-nowrap text-right">
                                        <ItemPrice
                                            price={item.Price}
                                            discountedPrice={
                                                item.DiscountedPrice
                                            }
                                            superPrice={item.SuperPrice}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-gray font-size-0-75">
                                        {itemTotalLabel}:
                                    </div>
                                    <div className="font-size-1 text-right">
                                        <span className="text-nowrap font-weight-bold">
                                            {formatPrice(item.Subtotal)}
                                        </span>
                                        {item.Discount && (
                                            <div>{item.discountText}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-none d-lg-block">
                        <div className="d-flex">
                            <div className="mr-5">
                                <div className="text-center text-gray font-size-0-75 line-height-3">
                                    {quantityLabel}:
                                </div>
                                {isEditable ? (
                                    <QuantityInput
                                        item={item}
                                        changeItem={this.changeItem}
                                    />
                                ) : (
                                    <div className="font-size-1 text-center line-height-3 line-height-lg-5">
                                        <span className="text-nowrap font-weight-bold">
                                            {item.Quantity}
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className="mx-5 text-center">
                                <div className="text-gray font-size-0-75 line-height-3">
                                    {unitPriceLabel}:
                                </div>
                                <div className="font-size-0-875 text-nowrap line-height-3 w-100px">
                                    <ItemPrice
                                        price={item.Price}
                                        discountedPrice={item.DiscountedPrice}
                                        superPrice={item.SuperPrice}
                                    />
                                </div>
                            </div>

                            <div className="ml-5 text-center">
                                <div className="">
                                    <div className="text-gray font-size-0-75 line-height-3">
                                        {itemTotalLabel}:
                                    </div>
                                    <div className="font-size-0-875 text-center line-height-3 line-height-lg-5 w-100px">
                                        <span className="text-nowrap font-weight-bold font-size-1">
                                            {formatPrice(item.Subtotal)}
                                        </span>
                                        {item.Discount && (
                                            <div>{item.discountText}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderItem;
