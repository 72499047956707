import { callAxios } from '../helper/axiosHelper';
import Cookies from 'js-cookie';

export const TOKEN_DATA = {
  token: null,
  refreshToken: null,
};

const authEndpoint = `${process.env.API_SERVER}/Token/`;
const constructBearerToken = (token) => `Bearer ${token}`;

export const JWTAuth = {
  login: async ({ email, password }) => {
    const response = await callAxios('post', `${authEndpoint}signin`, {
      email,
      password,
    });
    if (response?.token) {
      JWTAuth.storeTokenData(response);
    }
    return response;
  },
  logout: () =>
    callAxios(
      'post',
      `${authEndpoint}revoke`,
      {},
      {
        Authorization: constructBearerToken(
          JWTAuth.getStoredTokenData()?.token,
        ),
      },
    ),
  user: () =>
    callAxios('get', `${authEndpoint}user`, null, {
      Authorization: constructBearerToken(JWTAuth.getStoredTokenData()?.token)
    }),
  refresh: () =>
    callAxios(
      'post',
      `${authEndpoint}refresh`,
      { ...JWTAuth.getStoredTokenData() },
      {
        Authorization: JWTAuth.getStoredTokenData()?.token,
      },
    ),
  storeTokenData: ({ token, refreshToken }) => {
    localStorage.setItem('tokenData', JSON.stringify({ token, refreshToken }));
    Cookies.set('tokenData', JSON.stringify({ token, refreshToken }), {
      sameSite: 'strict',
      secure: true,
      /*       HttpOnly: true, */
    });
  },
  getStoredTokenData: () => {
    try {
      const tokenData = Cookies.get('tokenData');
      console.log('tokenData', tokenData);
      return JSON.parse(tokenData);
    } catch (error) {
      return { ...TOKEN_DATA };
    }
  },
  clearStoredTokenData: () => {
    Cookies.remove('tokenData');
  },
};

export default JWTAuth;
