/**
 * @typedef {Object} ImpressionFieldObject
 * @property {string} id - Product ID/SKU.
 * @property {string} name - Product name.
 * @property {string} [list] - Product list or collection.
 * @property {string} [brand] - Product brand.
 * @property {string} [category] - Product category.
 * @property {string} [variant] - Product variant.
 * @property {number} [position] - Product position in list.
 * @property {number} [price] - Product price.
 */

/**
 * @typedef {Object} ProductFieldObject
 * @property {string} id - Product ID/SKU.
 * @property {string} name - Product name.
 * @property {string} [brand] - Product brand.
 * @property {string} [category] - Product category.
 * @property {string} [variant] - Product variant.
 * @property {number} [price] - Product price.
 * @property {number} [quantity] - Product quantity.
 * @property {number} [coupon] - Product coupon.
 * @property {number} [position] - Product position in list.
 */

/**
 * @typedef {Object} ProductCard
 * @property {string} ProductId - Product ID.
 * @property {string} Brand - Product brand.
 * @property {string} Model - Product model nameame.
 * @property {string} Function - Product function.
 * @property {string} Design - Product name.
 * @property {number} Price - Product default price.
 * @property {number} DiscountedPrice - Product discounted price.
 */

/**
 * @typedef {Object} ProductInfo
 * @property {string} ProductID - Product ID.
 * @property {string} Brand - Product brand.
 * @property {string} Name - Product name
 * @property {string} Function - Product function.
 * @property {number} Price - Product default price.
 * @property {number} DiscountedPrice - Product discounted price.
 */

/**
 * Reset ecommerce data.
 **/
const resetEcommerceData = () => window.dataLayer.push({ ecommerce: null });

/**
 * @param {ImpressionFieldObject[]} products  - Product list.
 * @param {string=} currency - Currency (HUF, EUR).
 * @param {boolean=} [needReset=true] - Reset ecommerce data before datalayer push.
 *
 */
const productImpressions = (products, currency, needReset = true) => {
  if (!products || !products[0]?.id) {
    return;
  }

  if (needReset) {
    resetEcommerceData();
  }

  window.dataLayer.push({
    ecommerce: {
      ...(currency ? { currencyCode: currency } : {}),
      impressions: products,
    },
  });
};

/**
 * @param {ProductFieldObject} product  - Product.
 * @param {string=} list - Product list or collection.
 * @param {boolean=} [needReset=true] - Reset ecommerce data before datalayer push.
 */
const productDetailImpressions = (product, list, needReset = true) => {
  if (!product || !product.id) {
    return;
  }

  if (needReset) {
    resetEcommerceData();
  }

  const data = {
    ecommerce: {
      detail: {
        products: [product],
      },
    },
  };
  if (list) {
    data.ecommerce.detail.actionField = { list };
  }
  window.dataLayer.push(data);
};

/**
 * @param {ProductFieldObject} product  - Product.
 * @param {string=} currency - Currency (HUF, EUR).
 * @param {boolean=} [needReset=true] - Reset ecommerce data before datalayer push.
 */

const addProductToCart = (product, currency, needReset = true) => {
  if (!product || !product.id) {
    return;
  }

  if (needReset) {
    resetEcommerceData();
  }

  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      ...(currency ? { currencyCode: currency } : {}),
      add: {
        products: [product],
      },
    },
  });
};

/**
 * @param {ProductFieldObject} product  - Product.
 * @param {boolean=} [needReset=true] - Reset ecommerce data before datalayer push.
 */
const removeProductFromCart = (product, needReset = true) => {
  if (!product || !product.id) {
    return;
  }

  if (needReset) {
    resetEcommerceData();
  }

  window.dataLayer.push({
    event: "removeFromCart",
    ecommerce: {
      remove: {
        products: [product],
      },
    },
  });
};

/**
 * @param {ProductFieldObject[]} products  - Product.
 * @param {boolean=} [needReset=true] - Reset ecommerce data before datalayer push.
 */
function purchaseCompleted(actionField, products, needReset = true) {
 
  if (needReset) {
    resetEcommerceData();
  }

  window.dataLayer.push({
    event: "ordercompleted",
    ecommerce: {
      'purchase': {
        'actionField': actionField,
        'products': products
      },
    },
  });
}

/**
 * Transform ProductCard to ProductFieldObject (productDetailsImpression)
 * .
 * @param {ProductCard} productCard  - Product list.
 * 
 * @return {ProductFieldObject} - Product data for gaEnchancedEcommerce.
 **/
const transformToProductFieldObject = (productCard) => {
  if (!productCard || !productCard.ProductId) {
    return null;
  }
  const productName = productCard?.Name ? productCard.Name : `${productCard.Brand} ${productCard.Model} ${productCard.Function} ${productCard.Design}`;
  return {
    id: productCard.ProductId,
    name: productName,
    brand: productCard.Brand,
    ...(productCard?.ParentCategory?.Name ?  { category: productCard?.ParentCategory?.Name } : {}),
    price: productCard.DiscountedPrice > 0 ? productCard.DiscountedPrice : productCard.Price,
  }
};

/**
 * Transform ProductInfo to ImpressionFieldObject (productImpressions)
 * 
 * @param {string} CategoryName - Category name.
 * @param {string} list - Product list or collection ('Category search' |  'Search result')
 * @param {ProductInfo} productInfo  - Product list.
 * 
 * @return {ImpressionFieldObject} - Product data for gaEnchancedEcommerce.
 **/

const transformProductInfoToIpmpressionFieldObject = (categoryName, list, productInfo) => ({
  id: productInfo.ProductID,
  name: productInfo.Name,
  brand: productInfo.Brand,
  price: productInfo.DiscountedPrice > 0 ? productInfo.DiscountedPrice : productInfo.Price,
  list,
  ...(categoryName ?  { categoryName } : {})
});

export default {
  productImpressions,
  productDetailImpressions,
  addProductToCart,
  removeProductFromCart,
  resetEcommerceData,
  transformToProductFieldObject,
  transformProductInfoToIpmpressionFieldObject,
  purchaseCompleted,
};
