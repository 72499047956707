import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { redirectToUrl } from '../../utils/controller';
import { Link } from 'react-router-dom';

const MoreLink = ({ classNameContainer, url, title }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = useCallback(() => {
        setHover(true);
    }, [hover]);

    const handleMouseLeave = useCallback(() => {
        setHover(false);
    }, [hover]);

    return (
        <div
            className={`font-size-0-75 font-weight-medium ${classNameContainer}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div>
                {title && (
                    <Link to={url} className="text-uppercase text-body">
                        {title}
                    </Link>
                )}
                <div
                    className={`circle d-inline-block font-size-1 cursor-pointer ml-3 ${
                        hover ? 'move-in' : 'move-out'
                    }`}
                >
                    <FontAwesomeIcon
                        icon={faLongArrowRight}
                        className=""
                        onClick={() => {
                            redirectToUrl(url);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default MoreLink;
