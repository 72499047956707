import React from 'react';
import ContentCarousel from '../Elements/ContentCarousel';
import ContentCarouselItem from '../Elements/ContentCarouselItem';
import BlogLead from './BlogLead';

const BlogPostCarousel = ({containerClassName, contentCarouselClassName, posts}) => (
    <>
        {Array.isArray(posts) && posts.length > 0 && (
            <div className={`${containerClassName}`}>
                <ContentCarousel
                    autoSliderSecond={4}
                    enableInfinity={true}
                    className={contentCarouselClassName}
                    classNameNav="mb-4 mb-md-0"
                >
                    {posts.map((post, index) => (
                        <ContentCarouselItem key={index} className="col-12">
                            <BlogLead
                                key={index}
                                title={post.Title}
                                text={post.BodyOverview}
                                url={post.Slug}
                                image={post.PictureUrl}
                            />
                        </ContentCarouselItem>
                    ))}
                </ContentCarousel>
            </div>
        )}
    </>
);

export default BlogPostCarousel;
