import React, {Component} from 'react';
import SubPage from "./SubPage";
import Page from "./Page";
import Content from "./Content";
import Subscriber from "./Subscriber";
import sendEvent from "../../tracking";
import withResource from '../../hoc/withResource';

class ParentPage extends Component {
    state = {
        page: 1,
    };
    
    secondPage = () => {
        this.setState({page: 2});
        sendEvent('intro', 'page', 'family');
    };

    render() {
        const {onClose, t} = this.props;
        const {page} = this.state;
        return <SubPage imageNumber="2" verticalPosition={42} currentPage={page} maxPage={2}>
            {page === 1 &&
            <Page title={t('brendon.intro.parent.newsletter.title')}>
                <Subscriber
                    subscribeButton={t('brendon.intro.parent.newsletter.subscribe')}
                    success={t('brendon.intro.parent.newsletter.success')}
                    description={t('brendon.intro.parent.newsletter.description')}
                    emailPlaceholder={t('brendon.intro.parent.newsletter.email')}
                    datePlaceholder={t('brendon.intro.parent.newsletter.date')}
                    failText={t('brendon.intro.parent.newsletter.failed')}
                    retryText={t('brendon.intro.parent.newsletter.retry')}
                    subscribeText={t('brendon.intro.parent.newsletter.subscribe')}
                    onSkip={this.secondPage}
                />
            </Page>
            }
            {page === 2 &&
            <Page
                title={t('brendon.intro.parent.points.title')}
            >
                <Content 
                    primaryButton={t('brendon.intro.parent.points.registration')}
                    link={t('brendon.intro.parent.points.link')}
                    onSkip={onClose}>
                    <span dangerouslySetInnerHTML={{__html: t('brendon.intro.parent.points.description')}}/>
                </Content>
            </Page>
            }
        </SubPage>
    }
}

export default withResource(ParentPage);
