import React from 'react';
import Header from "./Header";

function Page({title, children}) {
    return <div className="d-flex flex-column w-100">
        <Header>
            {title}
        </Header>
        <div className="d-flex flex-column justify-content-between flex-fill">
            {children}
        </div>
    </div>
}

export default Page;
