import { delay, put, select, takeLatest } from 'redux-saga/effects'
import {dismissMessage, initializeNotifications, showSuccess} from "./actions";
import {selectNotification} from "./selectors";

function* handleSuccessMessage() {
    yield delay(5000);
    const lastMessage = yield select(selectNotification);
    if(lastMessage?.get('type') === 'success') {
        yield put(dismissMessage())
    }
}

function* notificationSaga() {
    yield takeLatest([initializeNotifications, showSuccess], handleSuccessMessage);
}

export default notificationSaga;
