import React, { useEffect, useState, useCallback, useRef } from 'react';
import { billingAddress as BillingAddressService } from '../../../services/checkout';
import { initalOptionsCommand } from '../../../utils/controller';
import { useDispatch } from 'react-redux';
import { showError } from '../../../store/notification/actions';
import { updateDeliveryModeEnabled } from '../../../store/checkout/actions';
import withStore from '../../../hoc/withStore';
import AddressBox from '../../../components/Checkout/AddressBox';
import AddAddressBox from '../../../components/Checkout/AddAddressBox';
import AddressBoxBillingData from '../../../components/Checkout/AddressBoxBillingData';
import BillingAddressForm from '../../../components/Checkout/BillingAddressForm';
import { STORE_SETTINGS } from '../../../models/constants';
import { getShopStoreId } from '../../../store/shopstore/selectors';
import { useSelector } from 'react-redux';
import CheckoutStepTitle from '../../../components/Checkout/CheckoutStepTitle';
import gaGA4 from '../../../services/gaGA4';
import { useResource } from '../../../contexts/ResourceContext';

const CheckoutPartialBillingAddress = ({ nextStep }) => {
    const { t } = useResource();
    const [addresses, setAddresses] = useState([]);
    const [defaultAddressData, setDefaultAddressData] = useState(null);
    const [countries, setCountries] = useState([]);
    const [editingAddress, setEditingAddress] = useState(null);
    const [addressLoading, setAddressLoading] = useState(-1);
    const [editingNewAddress, setEditingNewAddress] = useState(false);
    const formWrapper = useRef(null);
    const storeId = useSelector(getShopStoreId);
    const dispatch = useDispatch();

    const getBillingAddresses = useCallback(async () => {
        const {
            BillingAddresses = [],
            Countries = [],
            InitOption = 'OK',
            IsDeliveryModeEnabled = false,
            ...data
        } = await BillingAddressService.get();
        if (!initalOptionsCommand(InitOption, null, nextStep)) {
            return;
        }
        if (BillingAddresses?.length > 0) {
            setAddresses(BillingAddresses);
            setEditingAddress(null);
        } else {
            setAddresses([]);
            setEditingNewAddress(true);
            setEditingAddress(null);
        }
        setCountries(Countries);
        setDefaultAddressData({
            NameOnBill: data?.DefaultNameOnTheBill || '',
            Email: data?.DefaultEmail || '',
            PhoneNumber: data?.DefaultPhoneNumber || '',
        });
        dispatch(updateDeliveryModeEnabled(IsDeliveryModeEnabled));
    }, [
        setAddresses,
        setEditingAddress,
        setDefaultAddressData,
        setCountries,
        dispatch,
        updateDeliveryModeEnabled,
    ]);

    useEffect(() => {
        getBillingAddresses();
    }, []);

    const handleDelete = useCallback(
        async (addressId) => {
            const { IsSuccess, Message } = await BillingAddressService.delete(
                addressId,
            );
            if (IsSuccess) {
                getBillingAddresses();
            } else {
                dispatch(showError(Message));
            }
        },
        [dispatch, getBillingAddresses],
    );

    const handleEdit = useCallback(
        (addressId) => {
            setEditingAddress(
                addresses?.find((address) => address.Id === addressId),
            );
        },
        [setEditingAddress, addresses],
    );

    const handleSelect = useCallback(
        async (addressId) => {
            setAddressLoading(addressId);
            const {
                IsSuccess,
                Message,
                NextSite,
            } = await BillingAddressService.select(addressId);
            if (IsSuccess) {
                gaGA4.gtagEvent('checkout_billing_selected');
                nextStep(NextSite);
            } else {
                dispatch(showError(Message));
            }
        },
        [dispatch, setAddressLoading],
    );

    const handleSave = useCallback(
        async (address) => {
            const apiAddressService =
                Number(address?.Id) > 0
                    ? BillingAddressService.update
                    : BillingAddressService.post;
            const { IsSuccess, NextSite, Message } = await apiAddressService(
                address,
            );
            if (IsSuccess) {
                gaGA4.gtagEvent('checkout_billing_selected');
                setEditingAddress(null);
                setEditingNewAddress(false);
                nextStep(NextSite);
            } else {
                dispatch(showError(Message));
            }
        },
        [setEditingAddress, setEditingNewAddress, dispatch, nextStep],
    );

    const clickNewAddress = useCallback(() => {
        setEditingNewAddress((prev) => !prev);
        setEditingAddress(null);
    }, []);

    const scrollToForm = useCallback(() => {
        window.requestAnimationFrame(() => {
        /*     formWrapper?.current?.scrollIntoView({
                behavior: 'smooth',
            }); */
            const element = formWrapper?.current;
            const pos = element?.getBoundingClientRect().top + window.scrollY;
            window?.scrollTo({
                top: pos > 100 ? pos - 100 : pos,
                behavior: 'smooth',
            });
        });
    }, [formWrapper]);

    useEffect(() => {
        if (editingNewAddress || editingAddress) {
            scrollToForm();
        }
    }, [editingNewAddress, editingAddress]);

    return (
        <>
            {addresses.length > 0 && (
                <CheckoutStepTitle title={t('Checkout.BillingAddress.Title')} />
            )}
            {addresses.length > 0 && (
                <div className="row mb-4">
                    {addresses.map((address) => (
                        <div
                            className="col-12 col-md-6 col-lg-4 d-flex mb-3"
                            key={address.Id}
                        >
                            <AddressBox
                                address={{
                                    ...address,
                                    Country: countries.find(
                                        (country) =>
                                            country.Id === address.CountryId,
                                    )?.CountryName,
                                }}
                                selectAddressText={t(
                                    'Checkout.BillingAddress.Select',
                                )}
                                countries={countries}
                                onDelete={handleDelete}
                                onEdit={handleEdit}
                                onSelect={handleSelect}
                                isLoading={addressLoading === address.Id}
                            >
                                <AddressBoxBillingData
                                    address={{
                                        ...address,
                                        Country: countries.find(
                                            (country) =>
                                                country.Id ===
                                                address.CountryId,
                                        )?.CountryName,
                                    }}
                                />
                            </AddressBox>
                        </div>
                    ))}

                    <div className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                        <AddAddressBox
                            selected={editingNewAddress}
                            title={t(
                                'brendon.checkout.billingaddress.addNewAddressTitle',
                            )}
                            onClick={clickNewAddress}
                        />
                    </div>
                </div>
            )}
            <div ref={formWrapper}>
                {(editingNewAddress || editingAddress) && (
                    <div className="row justify-content-center pt-2">
                        <div className="col-12 col-md-8 col-lg-10 text-center">
                            <h2 className="font-size-1 font-weight-medium text-uppercase line-height-3 mb-7">
                                {t('Checkout.EnterBillingAddress')}
                            </h2>
                        </div>
                    </div>
                )}
                {(editingNewAddress || editingAddress) && (
                    <>
                        <BillingAddressForm
                            countries={countries}
                            initialValues={{
                                Email: '',
                                PhoneNumber: '',
                                BillingAddressType: 0,
                                NameOnBill: '',
                                ZipPostalCode: '',
                                CountryId: countries?.[0]?.Id,
                                City: '',
                                Address: '',
                                InvoiceDesc: '',
                                TaxNumber: '',
                                CompanyNumber: '',
                                ...defaultAddressData,
                                ...editingAddress,
                            }}
                            showCompanyNumber={
                                STORE_SETTINGS[storeId]?.checkout
                                    ?.showCompanyNumber
                            }
                            nextButtonTitle={t(
                                'brendon.checkout.billingaddress.nextbutton',
                            )}
                            disableTaxNumberValidation={
                                STORE_SETTINGS[storeId]?.checkout
                                    ?.disableTaxNumberValidation
                            }
                            saveHandler={handleSave}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default withStore(CheckoutPartialBillingAddress);
