import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import rawResourcesJSON from '../../static/resources.json';
import PageServices from '../services/page';
import * as Sentry from "@sentry/react";

const resourcesJSON = Object.fromEntries(
  Object.entries(rawResourcesJSON).map(([key, value]) => [
    key.toLowerCase(),
    value,
  ]),
);

const getResource = (key, dictionary = resourcesJSON) => dictionary?.[key?.toLowerCase()];


function skipCapture(key) {
  return key?.startsWith('brendon.storeInformations.info');
}

/* Legacy resources (no refress)  */
export function legacy_t(key, ...rest) {
  // const parameters = [...arguments].slice(1);
  let string = getResource(key) || key.toLowerCase();
  
  rest.forEach((parameter, index) => {
     string = string.replace(`{${index}}`, parameter);
  });

  if (!getResource(key)) {
      if (window.location?.host?.startsWith('localhost')) {
          console.log(`Missing resource: ${key}, captured: ${!skipCapture(key)}`);
      } else {
          if (!skipCapture(key)) {
              Sentry.captureMessage(`Missing resource: ${key}`);
          }
      }
  }

  return string
}
/*****/

/* React resources */

export const ResourceContext = createContext();

export const ResourceProvider = ({ children }) => {
  const [loadedResources, setLoadedResources] = useState({});
  const resourcesBuilded = useMemo(
    () => resourcesJSON,
      /* Object.fromEntries(
        Object.entries(rawResourcesJSON).map(([key, value]) => [
          key.toLowerCase(),
          value,
        ]),
      ), */
    [],
  );

  const resourcesLoaded = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(loadedResources).map(([key, value]) => [
          key.toLowerCase(),
          value,
        ]),
      ),
    [loadedResources],
  );

  useEffect(() => {
    const loadResources = async () => {
      const { localeStringResource: lsr } =
        await PageServices.resourcesInfo.get();
      const { lastresourcedate: olsr } = resourcesBuilded;
      console.log('lsr: ', lsr, olsr);
      if (lsr != olsr) {
        const newResources = await PageServices.resources.get();
        setLoadedResources(newResources);
      }
    };
    loadResources();
  }, []);

  const resources = useMemo(
    () => ({
      ...resourcesBuilded,
      ...resourcesLoaded,
    }),
    [resourcesBuilded, resourcesLoaded],
  );
/* console.log('resources: ', resources) */
  const getResourceByKey = useCallback(
    (key) => getResource(key, resources),
    [resources],
  );

  const t = (key, ...rest) => {
    let string = getResourceByKey(key) || key.toLowerCase();

    rest.forEach((parameter, index) => {
      if (typeof parameter === 'string') {
        string = string.replace(`{${index}}`, parameter);
      }
    });

    if (!getResourceByKey(key)) {
      if (window.location?.host?.startsWith('localhost')) {
        console.log(`Missing resource: ${key}, captured: ${!skipCapture(key)}`);
      } else {
        if (!skipCapture(key)) {
          Sentry.captureMessage(`Missing resource: ${key}`);
        }
      }
    }

    return string;
  };

  return (
    <ResourceContext.Provider value={{ t, resources }}>
      {children}
    </ResourceContext.Provider>
  );
};

export const useResource = () => {
  const context = useContext(ResourceContext);
  if (!context) {
    throw new Error('useResource must be used within a ResourceProvider');
  }
  return context;
};
