import React, {Component} from 'react';
import {connect} from "react-redux";
import withStore from "../../hoc/withStore";
import {createStructuredSelector} from "reselect";
import {selectWishlistCount} from "../../store/wishlist/selectors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeart} from '@fortawesome/pro-light-svg-icons';

const mapStateToProps = createStructuredSelector({
        count: selectWishlistCount,
    });

class WishlistIcon extends Component {
    render() {
        const {count, url } = this.props;
        return <a href={url}
           className="mx-md-2 position-relative w-p40 h-p40 d-flex justify-content-center align-items-center text-dark">
            <FontAwesomeIcon icon={faHeart} />
            {count > 0 &&
            <div
                className="h-p16 w-p16 bg-primary text-white rounded-circle d-flex justify-content-center align-items-center position-absolute top right mt-1 font-size-0-625">
                {count}
            </div>
            }
        </a>
    }
}

export default withStore(connect(mapStateToProps)(WishlistIcon));
