import React, {Component} from "react";
import styled from 'styled-components';
import SquareCheckbox from "../../elements/SquareCheckbox";
import {Collapse} from 'react-collapse';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';

const CategoryWrapper = styled.div`
  transition: 0.2s ease-in-out all;
  
  opacity: ${props => props.checked ? 0.3 : 1};
`;

class Category extends Component {
    state = {
        checked: false,
        checking: false,
        showInfo: false,
    };

    constructor(props) {
        super(props);
        this.state.checked = props.category.IsChecked;
    }

    change = async (value) => {
        this.setState(() => ({
            checking: true,
        }));
        const {category} = this.props;
        await this.props.check(value, category);
        this.setState(() => ({
            checking: false,
        }));
    };

    toggleInfoBox = () => {
        this.setState(state => ({
            showInfo: !state.showInfo,
        }))
    };

    render() {
        const {checking, showInfo} = this.state;
        const {category} = this.props;
        const checked = category.IsChecked;

        const finallyChecked = (checking && !checked) || (!checking && checked);
        return <CategoryWrapper
            checked={finallyChecked}
            className={`${!checking && checked ? "" : "bg-gray-light"} rounded-sm mb-pixel p-2 line-height-3 d-flex justify-content-between position-relative`}>
            <div className="flex-shrink-0 mr-2">
                <SquareCheckbox value={checked}
                                onChange={this.change}
                                disabled={checking}
                />
            </div>
            <div
                className="flex-fill font-size-0-75">
                <span>
                {
                    category.Slug ?
                        <a className="text-dark"
                           href={`/${category.Slug}`}
                           target="_blank">
                            {category.CategoryName}
                            <FontAwesomeIcon icon={faChevronRight} className="ml-1 font-size-0-625" />
                        </a> : category.CategoryName
                }
                </span>
                {
                    category.CategoryDesc &&
                    <Collapse isOpened={ showInfo }>
                        <div className="text-gray font-weight-normal pt-2 description line-height-rem-1">
                            {category.CategoryDesc}
                        </div>
                    </Collapse>
                }
            </div>
            {
                category.CategoryDesc &&
                <FontAwesomeIcon icon={faInfoCircle} className="flex-shrink-0 ml-3 line-height-3 text-primary font-size-0-875 text-center cursor-pointer h-p20"
                onClick={this.toggleInfoBox}/>
            }
        </CategoryWrapper>
    }
}

export default Category;
