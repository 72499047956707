import React from "react";
import SocialButton from "./SocialButton";
import { useResource } from '../../contexts/ResourceContext';


const SocialButtons = ({  }) => {
    const { t } = useResource();
    
    const SocialButtonList = [
        {
            iconType: "fab",
            iconName: "facebook-f",
            title: t("Brendon.Footer.Facebook"),
            href:  t("Footer.FacebookLink"),
        },
        {
            iconType: "fab",
            iconName: "instagram",
            title: t("Brendon.Footer.Instagram"),
            href: t("Footer.InstagramLink"),
        },
        {
            iconType: "fab",
            iconName: "youtube",
            title: t("Brendon.Footer.Youtube"),
            href: t("Footer.YoutubeLink"),
        },
        {
            iconType: "far",
            iconName: "at",
            title: t("Brendon.Footer.Mail"),
            href: t("Footer.MailLink"),
        },
    ];
    
    return (
        <div className="row">
            {SocialButtonList.map((socialDetails, idx) => (
                <div key={ `sb_${idx}`} className="col-6 col-md-3 col-lg-12">
                    <SocialButton {...socialDetails} />
                </div>)
            )}
        </div>
    )
}

export default SocialButtons;
