import React from 'react';
import FullscreenModal from '../Elements/FullscreenModal';
import FullscreenModalItem from '../Elements/FullscreenModalItem';
import FilterRadio from './FilterRadio';
import { useResource } from '../../contexts/ResourceContext';
import Button from '../../elements/Button';

const MobileSortModal = ({
  isOpen,
  handleCancel,
  onSelect,
  sortOptions,
  preSelectedOrderBy,
}) => {
  const { t } = useResource();
  return (
    <FullscreenModal
      title={t('brendon.products.orderby.title')}
      isOpen={isOpen}
/*       rightTitleChildren={
         <>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleCancel} />
        </> 
        <Button
              variant="primary-bordered"
              className="px-3 font-size-0-875 mx-1 flex-1"
              size="md"
              onClick={handleCancel}
          >
              {t('brendon.products.filters.cancel')}
          </Button>
      } */
      navChildren={
        <Button
          variant="primary-bordered"
          className="px-3 font-size-0-875 mx-1 flex-1"
          size="md"
          onClick={handleCancel}
        >
          {t('brendon.products.filters.cancel')}
        </Button>
      }
    >
      {sortOptions.map((option) => (
        <FullscreenModalItem
          key={`sort_${option.Value}`}
          leftChildren={<div>{option.Text}</div>}
          onSelect={() => onSelect(option.Value)}
          rightChildren={
            <FilterRadio
              isChecked={option.Value === preSelectedOrderBy}
              value={option.Value}
            />
          }
          isSelected={option.Value === preSelectedOrderBy}
          selectedClassName="text-primary"
          className="font-weight-bold border-bottom p-3"
        />
      ))}
    </FullscreenModal>
  );
};

export default MobileSortModal;
