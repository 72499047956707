import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withStore from '../../hoc/withStore';
import styled from 'styled-components';
import CartCheckoutProgress from '../../components/Checkout/CartCheckoutProgress';
import OrderSummary from './order-summary/OrderSummary';
import CartService from '../../services/cart';
import DiscountBox from '../../components/ShoppingCart/DiscountBox';
import OrderTotals from '../../components/ShoppingCart/OrderTotals';
import CheckoutButton from './CheckoutButton';
import EmptyCart from './EmptyCart';
import Loader from '../../elements/Loader';
import { updateCartCount } from '../../store/cart/actions';
import { clearMessages, showError } from '../../store/notification/actions';
import { DEDICATED_URLS, CHECKOUT_STEP_TITLES } from '../../models/constants';
import ErrorMessages from '../../components/ShoppingCart/ErrorMessages';
import RewardPointRedeem from '../../components/ShoppingCart/RewardPointRedeem';
import CheckoutStepTitle from '../../components/Checkout/CheckoutStepTitle';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import gaGA4 from '../../services/gaGA4';
import { getStoreCurrency } from '../../utils/localization';
import { useResource } from '../../contexts/ResourceContext';
 
const StyledLoader = styled(Loader)`
    min-height: 100vh;
`;

const Cart = ({
    updateCartCount,
    showErrorMessage,
    clearAllMessages,
}) => {
    const [cart, setCart] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const { t } = useResource();

    const collectErrorMessages = (warnings) =>
        warnings?.map((warning) => warning.WarningText) || [];

    useEffect(() => {
        refreshCart();
    }, []);

    const refreshCart = () => {
        const fetchCart = async () => {
            clearAllMessages();
            const cartResponse = await CartService.evaluation();
            updateCartCount(cartResponse?.CartCount);
            setCartItems(cartResponse?.ShoppingCartItems || []);
            setCart(cartResponse);
            setLoading(false);
            const errors = collectErrorMessages(cartResponse.Warnings);
            setErrorMessages(errors);
            if (errors.length > 0) {
                showErrorMessage(errors?.join?.('<br>'));
            }
        };
        fetchCart();
    };

    const checkoutClickHandler = useCallback(async () => {
        setCheckoutLoading(true);
        clearAllMessages();
        const cartResponse = await CartService.evaluation();
        const { IsReadyForCheckout, IsCustomerLoggedIn } = cartResponse;
        if (IsReadyForCheckout) {
            // begin_checkout GA4 event
            gaGA4.gtagEvent(
                'begin_checkout',
                gaGA4.transformShoppingCartEvaluetionToItem(
                    cartResponse,
                    getStoreCurrency(),
                ),
            );
            if (IsCustomerLoggedIn) {
                window.location = DEDICATED_URLS.checkout;
            } else {
                window.location = DEDICATED_URLS.checkout_login;
            }
        } else {
            updateCartCount(cartResponse?.CartCount);
            setCartItems(cartResponse?.ShoppingCartItems || []);
            setCart(cartResponse);

            setCheckoutLoading(false);
            // showErrorMessage(Message);
            const errors = collectErrorMessages(cartResponse.Warnings);
            setErrorMessages(errors);
            if (errors.length > 0) {
                showErrorMessage(errors?.join?.('<br>'));
            }
        }
    }, [
        setLoading,
        showErrorMessage,
        collectErrorMessages,
        setErrorMessages,
        updateCartCount,
        setCartItems,
        setCart,
        setCheckoutLoading,
    ]);

    // ha true, akkor a kosárban lévő termékek mennyiségét lehet módosítani es torolni
    const isEditable = true;
 
    return (
        <>
            <MetaHeader title={t('pagetitle.shoppingcart')} showBrendonAddon />
            {loading && <StyledLoader isLoading />}
            <CartCheckoutProgress
                checkoutProgressStep={0}
                urls={{
                    cartUrl: '',
                    addressUrl: '',
                    paymentMethodUrl: '',
                    confirmUrl: '',
                }}
            />
            <CheckoutStepTitle title_resource_name={CHECKOUT_STEP_TITLES[0]} />
            {cartItems?.length > 0 && (
                <OrderSummary
                    items={cartItems}
                    legacy={false}
                    showProductImages={true}
                    isEditable={isEditable}
                    refreshCart={refreshCart}
                >
                    <div className={`row justify-content-between`}>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div>
                                <DiscountBox
                                    coupons={cart?.Coupons || []}
                                    refreshCart={refreshCart}
                                />
                            </div>
                            <div>
                                <RewardPointRedeem
                                    refreshCart={refreshCart}
                                    rewardPointRedeemPointNoText={
                                        cart?.RewardPointRedeemPointNoText
                                    }
                                    rewardPointRedeemText={
                                        cart?.RewardPointRedeemText
                                    }
                                    /*                 rewardPointRedeemPercentText = { cart?.RewardPointRedeemPercentText } */
                                    rewardPointRedeemGiftCardText={
                                        cart?.RewardPointRedeemGiftCardText
                                    }
                                    isRewardPointRedeem={
                                        cart?.IsRewardPointRedeem
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                            <OrderTotals
                                sumItems={cart?.SumItems}
                                sumAmountDiscounts={cart?.SumAmountDiscounts}
                                discountSubItems={cart?.Coupons?.map(
                                    (item) => ({
                                        item: item?.CouponCode,
                                        price: item?.Price,
                                    }),
                                )}
                                rewardPointRedeemedSum={
                                    cart?.RewardPointRedeemedSum
                                }
                                rewardPointRedeemPercentText={
                                    cart?.RewardPointRedeemPercentText
                                }
                                sumItemsReducedByDiscounts={
                                    cart?.SumItemsReducedByDiscounts
                                }
                                /* shippingFee={cart?.ShippingFee} */
                                shippingFee={0}
                                shippingFeeInfoText={cart?.ShippingFeeInfo?.map(
                                    (item) => item.ShippingFeeInfoText,
                                )}
                              /*   amountWithShippingFee={
                                    cart?.AmountWithShippingFee
                                } */
                                amountWithShippingFee={
                                    null
                                }
                            />
                            <CheckoutButton
                                checkoutDisabled={!cart?.IsReadyForCheckout}
                                checkoutClickHandler={checkoutClickHandler}
                                isLoading={checkoutLoading}
                            />

                            <ErrorMessages errorMessages={errorMessages} />
                        </div>
                    </div>
                </OrderSummary>
            )}
            {!loading && cartItems?.length == 0 && <EmptyCart />}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateCartCount: (cartCount) => dispatch(updateCartCount(cartCount)),
    showErrorMessage: (message) => dispatch(showError(message)),
    clearAllMessages: () => dispatch(clearMessages()),
});

export default withStore(connect(null, mapDispatchToProps)(Cart));
