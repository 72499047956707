import React from 'react';
import FormGroup from './FormGroup';
import { Field } from 'formik';
import variationTypes from './variationTypes';
import classNames from 'classnames';

const InputGroup = ({
    label,
    name,
    type,
    readonly,
    required,
    description,
    validatingMessage,
    formGroupClasses,
    maxLength,
    feedbackIcons=false,
    variation = 'default',
    errorType = 'label-after',
}) => {
    const selectedVariation =
        variationTypes?.[variation] || variationTypes['default'];

    return (
        <Field name={name}>
            {(fieldProps) => {
                const { field, form, meta } = fieldProps;
                const backendError =
                    (form.status && form.status.backendErrors[field.name]) ||
                    null;
                const error = meta.touched ? meta.error : backendError;

                return type === 'hidden' ? (
                    <input type="hidden" autoComplete="off" {...field} />
                ) : (
                    <FormGroup
                        required={required}
                        fieldProps={fieldProps}
                        label={label}
                        description={description}
                        validatingMessage={validatingMessage}
                        errorType={errorType}
                        feedbackIcons={feedbackIcons}
                        {...formGroupClasses}
                        {...selectedVariation}
                    >
                        <input
                            {...field}
                            type={type || 'text'}
                            autoComplete="off"
                            className={classNames(
                                selectedVariation?.input?.className,
                                error ? 'is-invalid' : null,
                                readonly
                                    ? 'is-pointer-none border-0'
                                    : 'is-pointer-auto',
                            )}
                            readOnly={readonly}
                            tabIndex={readonly ? -1 : null}
                            maxLength={maxLength || null}
                        />
                    </FormGroup>
                );
            }}
        </Field>
    );
};

export default InputGroup;
