import {handleActions} from "redux-actions";
import {
    checkAttributeFilter,
    checkSpecificationFilter, clearAllFilters, goToPage,
    initializeFilters,
    setFiltersPagination, 
    setLoading, 
    setOrderBy, 
    setProductContent, 
    setSortOptions,
    updateFilters
} from "./actions";
import {fromJS} from "immutable";
import {parseUrl} from "query-string";

const reducer = handleActions(
    {
        [setFiltersPagination]: (state, action) => {
            const {PageNumber, TotalPages, HasPreviousPage, HasNextPage, TotalItems} = action.payload;
            const pagination = {
                PageNumber,
                TotalPages,
                HasPreviousPage,
                HasNextPage,
                TotalItems,
            };

            return state.set('pagination', fromJS(pagination));
        },
        [initializeFilters]: (state, action) => {
            const {specificationFilters, attributeFilters, categoryId, categoryContextFilter} = action.payload;
            return state
                .set('attributeFilters', fromJS(attributeFilters || []))
                .set('specificationFilters', fromJS(specificationFilters || []))
                .set('categoryId', fromJS(categoryId))
                .set('categoryContextFilter', fromJS(categoryContextFilter));
        },
        [checkSpecificationFilter]: (state, action) => {
            const specificationFilters = state.get('specificationFilters');
            const filterIndex = specificationFilters.findIndex(filter => filter.get('Id') === action.payload.filterId);

            if(filterIndex > -1) {
                const filter = specificationFilters.get(filterIndex);

                const filterItemIndex = filter.get('FilterItems').findIndex(filterItem => filterItem.get('Id') === action.payload.filterItemId);

                if(filterItemIndex > -1) {
                    let filterItemState = filter.getIn(['FilterItems', filterItemIndex, 'FilterItemState']);

                    if (filterItemState === 0) {
                        filterItemState = 1
                    } else if (filterItemState === 1) {
                        filterItemState = 0
                    } else if (filterItemState === 2) {
                        filterItemState = 3
                    }

                    return state
                        .update('specificationFilters', prevSpecificationFilters => {
                            return prevSpecificationFilters.map((filter, index) => {
                                if (filterIndex === index) {
                                    return filter
                                        .set('IsMain', true)
                                        .setIn(['FilterItems', filterItemIndex, 'FilterItemState'], filterItemState);
                                } else {
                                    return filter.set('IsMain', false)
                                }
                            })
                        })
                        .update('attributeFilters', prevAttributeFilters => prevAttributeFilters.map(filter => filter.set('IsMain', false)));
                }   
            }
            
            return state;
        },
        [checkAttributeFilter]: (state, action) => {
            const attributeFilters = state.get('attributeFilters');
            const filterIndex = attributeFilters.findIndex(filter => filter.get('Id') === action.payload.filterId);

            if (filterIndex > -1) {
                const filter = attributeFilters.get(filterIndex);

                const filterItemIndex = filter.get('FilterItems').findIndex(filterItem => filterItem.get('ValueId') === action.payload.filterItemId);

                if(filterItemIndex > -1) {
                    let filterItemState = filter.getIn(['FilterItems', filterItemIndex, 'FilterItemState']);

                    if (filterItemState === 0) {
                        filterItemState = 1
                    } else if (filterItemState === 1) {
                        filterItemState = 0
                    } else if (filterItemState === 2) {
                        filterItemState = 3
                    }

                    return state
                        .update('attributeFilters', prevAttributeFilters => {
                            return prevAttributeFilters.map((filter, index) => {
                                if (filterIndex === index) {
                                    return filter
                                        .set('IsMain', true)
                                        .setIn(['FilterItems', filterItemIndex, 'FilterItemState'], filterItemState);
                                } else {
                                    return filter.set('IsMain', false)
                                }
                            })
                        })
                        .update('specificationFilters', prevSpecificationFilters => prevSpecificationFilters.map((filter) => filter.set('IsMain', false)));
                }
            }

            return state;
        },
        [updateFilters]: (state, action) => {

            const {specificationFilters, attributeFilters} = action.payload;

            return state
                .update('specificationFilters', prevSpecificationFilters => {
                    return prevSpecificationFilters.map(filter => {
                        const filterId = filter.get('Id');

                        const newFilter = specificationFilters.SpecificationFilterGroups.find(filter => filter.Id === filterId);

                        return !newFilter ? filter : filter.update('FilterItems', filterItems => {
                            return filterItems.map((filterItem) => {
                                const filterItemId = filterItem.get('Id');

                                const newFilterItem = newFilter.FilterItems.find(filterItem => filterItem.Id === filterItemId);

                                return filterItem.set('FilterItemState', newFilterItem.FilterItemState);
                            })
                        })

                    });
                })
                .update('attributeFilters', prevAttributeFilters => {
                    return prevAttributeFilters.map(filter => {
                        const filterId = filter.get('Id');

                        const newFilter = attributeFilters.AttributeFilterGroups.find(filter => filter.Id === filterId);

                        return !newFilter ? filter : filter.update('FilterItems', filterItems => {
                            return filterItems.map((filterItem) => {
                                const filterItemId = filterItem.get('ValueId');
                                
                                const newFilterItem = newFilter.FilterItems.find(filterItem => filterItem.ValueId === filterItemId);

                                return filterItem.set('FilterItemState', newFilterItem.FilterItemState);
                            })
                        })

                    });
                });
        },
        [goToPage]: (state, action) => {
            return state.setIn(['pagination', 'PageNumber'], action.payload);
        },
        [setSortOptions]: (state, action) => {
            const sortOptions = action.payload.map(({Value, ...rest}) => ({
                Value: parseUrl(Value).query.orderby,
                ...rest,
            }));
            return state
                .set('sortOptions', fromJS(sortOptions));
        },
        [setOrderBy]: (state, action) => {
            return state
                .update('sortOptions',
                    sortOptions => sortOptions.map(
                        option => {
                            if (option.get('Value') === action.payload.toString()) {
                                return option.set('Selected', true);
                            } else if (option.get('Selected')) {
                                return option.set('Selected', false);
                            } else {
                                return option;
                            }
                        }
                    )
                );
        },
        [setLoading]: (state, action) => state.set('isLoading', action.payload),
        [setProductContent]: (state, action) => state.set('productContent', action.payload),
        [clearAllFilters]: (state) => state
            .update('attributeFilters', prevAttributeFilters =>
                prevAttributeFilters.map(filter =>
                    filter
                        .set('IsMain', false)
                        .update('FilterItems', filterItems => filterItems.map((filterItem) => filterItem.set('FilterItemState', 0)))
                )
            )
            .update('specificationFilters', prevSpecificationFilters =>
                prevSpecificationFilters.map((filter) =>
                    filter
                        .set('IsMain', false)
                        .update('FilterItems', filterItems => filterItems.map((filterItem) => filterItem.set('FilterItemState', 0)))
                )
            )
    },
    fromJS({
        isLoading: false,
        isLoaderShown: false,
        specificationFilters: [],
        attributeFilters: [],
        categoryContextFilter: null,
    }),
    )
;

export default reducer;
