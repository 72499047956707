import React, { useEffect, useMemo, useState } from 'react';
import BlogTitle from '../../components/Blog/BlogTitle';
import BlogHtmlMeta from '../../components/Blog/BlogHtmlMeta';
import BlogCategoryMenu from '../../components/Blog/BlogCategoryMenu';
import BlogCategorySection from '../../components/Blog/BlogCategorySection';
import SectionTags from '../../components/Blog/MainSection/SectionTags';
/* import CalculatorBox from '../../components/Blog/CalculatorBox'; */
import SectionContainer from '../../components/Blog/MainSection/SectionContainer';
import SectionTitle from '../../components/Blog/MainSection/SectionTitle';
import BlogTagsV2 from '../../components/Blog/BlogTagsV2';
import BlogService from '../../services/blog';
import BlogPostCarousel from '../../components/Blog/BlogPostCarousel';
import {
  BLOG_CATEGORY_COLORS,
  MAX_TAGS_ON_MAIN_PAGE,
} from '../../models/constants';
import BannerZone from '../../banners/BannerZone';
import { removeDuplicateObjectsFromArray } from '../../utils/array';
import { getShopStoreId } from '../../store/shopstore/selectors';
import { useSelector } from 'react-redux';
import { useResource } from '../../contexts/ResourceContext';

const BlogMainScreen = () => {
  const { t } = useResource();
  const [blogCategories, setBlogCategories] = useState([]);
  const [blogPostsByCategory, setBlogPostsByCategory] = useState({});
  const [blogTagsByCategory, setBlogTagsByCategory] = useState({});
  const [tags, setTags] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([<div></div>]);
  const storeId = useSelector(getShopStoreId);

  useEffect(() => {
    const fetchData = async () => {
      const {
        BlogCategories = [],
        BlogPostTagsByCategory = [],
        BlogPostsByCategory = [],
        HighlightedBlogPosts = [],
      } = await BlogService.getMain();
      setBlogCategories(BlogCategories);
      setFeaturedPosts(HighlightedBlogPosts);
      const postByCategory = BlogPostsByCategory.reduce(
        (acc, post) => ({
          ...acc,
          [post.BlogPostCategoryId]: [
            ...(acc[post.BlogPostCategoryId]
              ? acc[post.BlogPostCategoryId]
              : []),
            post,
          ],
        }),
        {},
      );
      setBlogPostsByCategory(postByCategory);
      const tagsByCategory = BlogPostTagsByCategory.reduce(
        (acc, tag) => ({
          ...acc,
          [tag.BlogPostCategoryId]: [
            ...(acc[tag.BlogPostCategoryId] ? acc[tag.BlogPostCategoryId] : []),
            tag,
          ],
        }),
        {},
      );
      setBlogTagsByCategory(tagsByCategory);
      setTags(BlogPostTagsByCategory);
    };

    fetchData();
  }, []);

  const allTags = useMemo(
    () =>
      removeDuplicateObjectsFromArray(tags, 'BlogPostTag')?.sort((a, b) =>
        a.BlogPostTag.localeCompare(b.BlogPostTag, 'hu'),
      ),
    [tags],
  );

  if (!storeId) {
    return null;
  }
  return (
    <>
      <BlogHtmlMeta />
      <div className="blog">
        <BlogTitle storeId={storeId} />
        <div className="d-flex flex-wrap mb-4">
          <BlogPostCarousel
            posts={featuredPosts}
            containerClassName="col-12 col-lg-8 px-0 mb-4 mb-lg-0"
            contentCarouselClassName={'mr-lg-3'}
          />
          <div className="col-12 col-lg-4 px-0">
            <BlogCategoryMenu categories={blogCategories} />
          </div>
        </div>
        <div className="pb-4">
          <BannerZone bannerBlockId={19} />
        </div>
        {Array.isArray(blogCategories) &&
          blogCategories.length > 0 &&
          blogCategories.map((category, index) => (
            <React.Fragment key={index}>
              {blogPostsByCategory?.[category.BlogPostCategoryId] && (
                <BlogCategorySection
                  title={category.BlogCategory}
                  moreUrl={`/${category.Slug}`}
                  moreText={t('brendon.blog.moreposts')}
                  backgroundColorClass={`bg-blog-color-${
                    BLOG_CATEGORY_COLORS[category?.BlogPostCategoryId] || 0
                  }`}
                  items={blogPostsByCategory?.[category.BlogPostCategoryId]}
                  classNameSecond="d-flex flex-column p-3"
                  secondChildren={
                    blogTagsByCategory[category.BlogPostCategoryId]?.length >
                      0 && (
                      <SectionTags
                        title={t('brendon.blog.categorylabels')}
                        content={category.Description}
                        tags={blogTagsByCategory[category.BlogPostCategoryId]
                          ?.sort((a, b) =>
                            a.BlogPostTag.localeCompare(b.BlogPostTag, 'hu'),
                          )
                          ?.slice(0, MAX_TAGS_ON_MAIN_PAGE)}
                        moreLink={`/${category.Slug}`}
                        moreTitle={t('brendon.blog.moreposts')}
                        tagContainerClassName="mb-2 bg-white"
                      />
                    )
                  }
                  /* lastChildren={
                                <CalculatorBox
                                    className="bg-gray-light rounded"
                                    image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                                    title="Ovulációs kalkulátor"
                                    iconName="Heart"
                                />
                                <CalculatorBox
                                    className="bg-gray-light rounded"
                                    image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                                    title="Várandósság hétről hétre"
                                    iconName="Calendar"
                                />
                                 <CalculatorBox
                                    className="bg-gray-light rounded"
                                    image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                                    title="Baba fejlődése kalkulátor"
                                    iconName="Baby"
                                />
                            } */
                />
              )}
            </React.Fragment>
          ))}

        {/*  <SectionContainer className="mb-7">
                    <SectionTitle className="mb-3" title="KALKULÁTOROK" />
                    <div className="d-flex gap flex-wrap">
                        <CalculatorBox
                            className="bg-gray-lighter flex-1 rounded"
                            image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                            title="Ovulációs kalkulátor"
                            iconName="Heart"
                        />
                        <CalculatorBox
                            className="bg-gray-lighter flex-1 rounded"
                            image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                            title="Várandósság hétről hétre"
                            iconName="Calendar"
                        />

                        <CalculatorBox
                            className="bg-gray-lighter flex-1 rounded"
                            image="https://nopwebimages.azureedge.net/nopweb/BlogPost/469/default.jpg"
                            title="Baba fejlődése kalkulátor"
                            iconName="Baby"
                        />
                    </div>
                </SectionContainer> */}

        <SectionContainer className="mb-7">
          <SectionTitle
            className="mb-3"
            title={t('brendon.blog.popularposts')}
          />
          <BlogTagsV2
            className="px-3 pt-3 bg-gray-light rounded"
            tags={allTags}
          />
        </SectionContainer>
      </div>
    </>
  );
};

export default BlogMainScreen;
