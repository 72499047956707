import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    padding-top: 100%;
    position: relative;
`;

function Square({children, ...props}) {
    return <Wrapper {...props}>
        <div className="position-absolute w-100 h-100 top left">
            {children}
        </div>
    </Wrapper>
}

export default Square;