import React from 'react';
import ProductInfoBlock from './ProductInfoBlock';
import {
    faPercent,
    faExclamationCircle,
    faShippingFast,
    faStar,
    faExternalLinkAlt,
    faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { useResource } from '../contexts/ResourceContext';

function ProductInfoBlocks({ productData }) {
    const { t } = useResource();
    const {
        Discount,
        FreeShipping,
        CampaignDesc,
        ExtraInfo,
        Point,
    } = productData;

    return (
        <div className="font-size-0-75 mb-4">
            {Discount && (
                <ProductInfoBlock className="text-pink" icon={faPercent}>
                    {t('brendon.productInfo.discount')}&nbsp;
                    <span>{Discount}</span>
                </ProductInfoBlock>
            )}
            {CampaignDesc &&
                CampaignDesc.map(
                    (campaign, index) =>
                        campaign?.DescPage &&
                        campaign?.DescPage != '' && (
                            <ProductInfoBlock
                                key={index}
                                className="text-pink"
                                icon={faExclamationCircle}
                            >
                                {campaign.DescPage}
                            </ProductInfoBlock>
                        ),
                )}
            {FreeShipping && (
                <ProductInfoBlock
                    className="text-green-dark"
                    icon={faShippingFast}
                >
                    {t('brendon.productInfo.freeShipping')}
                </ProductInfoBlock>
            )}
            {Point && (
                <ProductInfoBlock className="text-green-dark" icon={faStar}>
                    {t('brendon.productInfo.familyPoint', Point)}
                </ProductInfoBlock>
            )}
            {ExtraInfo &&
                ExtraInfo.map((info, index) => (
                    <ProductInfoBlock
                        key={index}
                        link={info.Url || info?.url}
                        className={
                            info.Url || info?.url ? 'text-primary' : 'text-dark'
                        }
                        icon={
                            info.Url || info?.url
                                ? faExternalLinkAlt
                                : faInfoCircle
                        }
                        iconClass={info.Url || info?.url ? '' : 'text-gray'}
                    >
                        {info.Info || info?.Content}
                    </ProductInfoBlock>
                ))}
        </div>
    );
}

export default ProductInfoBlocks;
