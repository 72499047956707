import React, {Component} from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    text-transform: none;
    line-height: 0;
`;


class ButtonGroupButton extends Component {

    onClick = (value) => {
        const {onChange} = this.props;
        if (onChange) {
            onChange(value.toString());
        }
    }
    onClickEvent = (e) => {
        const value = e.target?.value || e.currentTarget?.value;
        this.onClick(value);
    }
    render() {
        const {value, label, selected,className } = this.props;

        return <div className={className}>
                <StyledButton type="button" className={ `btn btn-outline-primary btn-block border h-p30 ${selected?'active' : ''}` } onClick={ this.onClickEvent }  value={ value }><span>{ label }</span></StyledButton>
               </div>
    }
}

export default ButtonGroupButton;
