import React, {Component} from 'react';
import styled from 'styled-components';
import {transparentize} from 'polished';
import withThemeProvider from "../hoc/withThemeProvider";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft,faChevronRight} from '@fortawesome/pro-solid-svg-icons';

const Wrapper = styled.div`
    opacity: ${props => props.hide ? 0 : 1};
    pointer-events: ${props => props.hide ? 'none' : 'auto'};
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    background-color: ${props => transparentize(0.5, props.theme.light)};
`;


class ArrowPager extends Component {
    render() {
        const {right, hide, hideOnMobile, ...props} = this.props;
        return <Wrapper
            hide={hide}
            {...props}
            className={`${hideOnMobile ? 'd-none d-lg-flex' : 'd-flex'} w-p30 h-p40 justify-content-center align-items-center ${right ? 'right rounded-left' : 'left rounded-right'}`}>
            <FontAwesomeIcon icon={right?faChevronRight:faChevronLeft} className="font-size-1" />
        </Wrapper>
    }
}

export default withThemeProvider(ArrowPager);