import { legacy_t as t} from '../contexts/ResourceContext';
import { getURLPath } from '../utils/staticPaths';

export const SEARCH_INPUT_MAX_LENGTH = 200;

export const DEDICATED_URLS = {
    'cart_login': '/login/checkoutasguest?returnUrl=%2Fcart',
    'checkout_login': '/login/checkoutasguest?returnUrl=%2Fcart',
    'cart': '/cart',
    'checkout': "/checkout",
}

export const CHECKOUT_STEPS = {
    'CART': 0,
    'ADDRESS': 1,
    'SHIPPING': 2,
    'DELIVERYMODE': 3,
    'SHOPLIST': 4,
    'GLSDROPOFF': 5,
    'PAYMENT': 6,
    'CONFIRM': 7,
    'COMPLETE': 8,
}
export const CHECKOUT_STEP_TITLES = {
    [CHECKOUT_STEPS.CART]: 'checkout.progress.cart',
    [CHECKOUT_STEPS.ADDRESS]: 'brendon.newcheckout.progress.address',
    [CHECKOUT_STEPS.SHIPPING]: 'checkout.progress.delivery',
    [CHECKOUT_STEPS.DELIVERYMODE]: 'checkout.progress.delivery',
    [CHECKOUT_STEPS.SHOPLIST]: 'checkout.progress.delivery',
    [CHECKOUT_STEPS.GLSDROPOFF]: 'checkout.progress.delivery',
    [CHECKOUT_STEPS.PAYMENT]: 'checkout.progress.payment',
    [CHECKOUT_STEPS.CONFIRM]: 'checkout.progress.confirm',
    [CHECKOUT_STEPS.COMPLETE]: 'checkout.progress.complete',
}

export const CHECKOUT_STEP_NAMES = {
    [CHECKOUT_STEPS.CART]: 'cart',
    [CHECKOUT_STEPS.ADDRESS]: 'address',
    [CHECKOUT_STEPS.SHIPPING]: 'shipping',
    [CHECKOUT_STEPS.DELIVERYMODE]:'deliverymode',
    [CHECKOUT_STEPS.SHOPLIST]: 'shoplist',
    [CHECKOUT_STEPS.GLSDROPOFF]: 'glsdropoff',
    [CHECKOUT_STEPS.PAYMENT]: 'payment',
    [CHECKOUT_STEPS.CONFIRM]: 'confirm',
    [CHECKOUT_STEPS.COMPLETE]: 'complete',
}

export const STORE_DELIVERY_SYSTEM_NAME = 'StoreDelivery';

export const DISCOUNTED_ATTRIBUTE_ID = 4000000;
export const DISCOUNTED_ATTRIBUTE_IDS = [4000000, 'F_4000000'];
export const isDiscounted = (filterId) => DISCOUNTED_ATTRIBUTE_IDS.includes(filterId);

export const MAX_POST_ON_LIST = 10;
export const MAX_LATEST_POST_ON_LIST = 10;
export const MAX_TAGS_ON_MAIN_PAGE = 6;

export const SEARCH_RESULT_SETTINGS = {
    'desktop': {
        'max_categories': 10,
        'max_blogposts': 10,
    },
    'mobile': {
        'max_categories': 10,
        'max_blogposts': 10,
    },
};

export const DOMAIN_STORES = {
    'brendon.hu': 1,
    'brendon.sk': 2,
};

export const BLOG_CATEGORY_COLORS = {
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    7: 0,
    8: 1,
    9: 2,
    10: 3,
    11: 4,
    12: 5,
    13: 0,
    14: 1,
}
export const STORE_SETTINGS = {
    1: {
        blog: {
            root: '/babablog',
        },
        checkout: {
            disableTaxNumberValidation: false,
            showCompanyNumber: false,
        },
        localization: {
            defaultLanguage: 'hu',
            defaultCurrency: 'Ft',
            currency: 'HUF',
            enableDecimal: false,
            dateFormat:'yyyy-MM-dd',
            dateFormatVisual:'yyyy. MMMM dd.',
            dateFormatDateTime: 'yyyy. MM. dd. HH:mm',
            dateFormatCalendar:'yyyy. MMMM',
            yearMonthFormat: 'yyyy. MM',
            lastNameFirst: true,
        }
    },
    2: {
        blog: {
            root: '/blog-pre-babatka',
        },
        checkout: {
            disableTaxNumberValidation: true,
            showCompanyNumber: true,
        },
        localization: {
            defaultLanguage: 'sk',
            defaultCurrency: '€',
            currency: 'EUR',
            enableDecimal: true,
            dateFormat:'dd-MM-yyyy',
            dateFormatVisual:'dd. MMMM yyyy.',
            dateFormatDateTime: 'dd. MM. yyyy. HH:mm',
            dateFormatCalendar:'MMMM yyyy.',
            yearMonthFormat: 'MM yyyy.',
            lastNameFirst: false,
        }
    }, 
}

export const DESKTOP_OR_MOBILE = {
    ONLY_DESKTOP: 1,
    ONLY_MOBILE: 2,
    BOTH: 3,
};

export const BLOCK_TYPE = {
    ONLY_TEXT: 1,
    FULL_WIDTH: 2,
    HALF_WIDTH: 3,
    THIRD_WIDTH: 4,
    QUARTER_WIDTH: 5,
};

export const HUNGARY_COUNTRY_ID = '244';

export const ADDRESS_TYPE_ENUM = {
    PERSON: 1,
    LEGAL_PERSON: 2,
    HEALTH_FUND: 3,
    SHIPPING_ADDRESS: 4,
};

export const ADDRESS_TYPE = ADDRESS_TYPE_ENUM; // legacy

export const ADDRESS_TYPES = [
    {
        Id: ADDRESS_TYPE_ENUM.PERSON,
        Name: t('brendon.checkout.billingaddress.personal'),
    },
    {
        Id: ADDRESS_TYPE_ENUM.LEGAL_PERSON,
        Name: t('brendon.checkout.billingaddress.taxpayer'),
    }
];

export const FORM_FIELD_LENGTHS = {
    EMAIL: 100,
    ZIP: 10,
}

export const OVULATION_CALCULATOR_MAX_MONTHS = 3;
export const MIN_CYCLE_LENGTH = 21;
export const MAX_CYCLE_LENGTH = 35;

export const HOST_SETTINGS = {
    'localhost': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 153,
        }
    },
    'www.brendon.hu': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 153,
        }
    },
    'brendon.hu': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 153,
        }
    },
    'dev.brendon.hu': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 154,
        }
    },
    'stage.brendon.hu': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 153,
        }
    },
    'www.brendon.sk': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 218,
        }
    },
    'brendon.sk': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 218,
        }
    },
    'dev.brendon.sk': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 218,
        },
    },
    'stage.brendon.sk': {
        blog: {
            'search_url': '/blogsearch',
            'pregnancy_calculator_tag_id': 218,
        },
    },
}

export const customerMenu = [
    {
      "url": getURLPath('customerProfile'),
      "title": t('brendon.customer.menu.info'),
      "icon": "user",
      "tab": "Info"
    },
    {
      "url": getURLPath('customerOrders'),
      "title": t('brendon.customer.menu.orders'),
      "icon": "shopping-cart",
      "tab": "Orders"
    },
    {
      "url":  getURLPath('customerRewards'),
      "title": t('brendon.customer.menu.rewardpoints'),
      "icon": "stars",
      "tab": "RewardPoints"
    },
    {
      "url":  getURLPath('customerChangePassword'),
      "title": t('brendon.customer.menu.changepassword'),
      "icon": "lock-alt",
      "tab": "ChangePassword"
    }
  ];