import React from 'react';
import LogoPath from '../../../assets//brendon-logo.svg';
import SearchAutoCompleteBox from '../../components/SearchAutoCompleteBox/SearchAutoCompleteBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useResource } from '../../contexts/ResourceContext';
 
function PageNotFound() {
  const { t } = useResource();
  return (
    <div className="main-content-wrapper">
      <div className="row ml-0 mr-0 h-100">
        <div className="col-12 pr-0 pl-0 d-block d-sm-none d-md-none d-lg-none">
          <div className="background_mobile" />
        </div>
        <div className="col-12 pr-0 pl-0 d-none d-sm-block d-md-block d-lg-none">
          <div className="background_tablet" />
        </div>
        <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-3 mt-7 mt-xs-4">
          <a href="/">
            <img src={LogoPath} className="h-p30" />
          </a>
          <h1 className="font-size-1-5 font-weight-bold mt-7 mt-xs-4 mb-4 mb-xs-2">
            {t('brendon.pagenotfound.title')}
          </h1>
          <div className="font-size-0-875 mb-7 mb-xs-4">
            <p>{t('brendon.pagenotfound.description')}</p>
          </div>
          <div className="mb-4 mb-xs-2">
            <SearchAutoCompleteBox />
          </div>

          <ul className="d-flex flex-wrap mb-7 mb-xs-4">
            <li className="flex-grow-1 mb-2 w-100">
              <a
                href={t('brendon.pagenotfound.menu.link1')}
                className="bg-primary text-white btn btn-outline-info btn-sm w-100"
              >
                <div className="d-flex">
                  <div className="col-10 p-0 d-flex np-item-left font-size-0-875 text-truncate">
                    {t('brendon.pagenotfound.menu.title1')}
                  </div>
                  <div className="col-2 p-0 d-flex arrow-right">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </a>
            </li>
            <li className="flex-grow-1 mb-2 w-100">
              <a
                href={t('brendon.pagenotfound.menu.link2')}
                className="btn btn-outline-info btn-sm w-100"
              >
                <div className="d-flex">
                  <div className="col-10 p-0 d-flex np-item-left font-size-0-875 text-truncate">
                    {t('brendon.pagenotfound.menu.title2')}
                  </div>
                  <div className="col-2 p-0 d-flex arrow-right">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </a>
            </li>
            <li className="flex-grow-1 mb-2 w-100">
              <a
                href={t('brendon.pagenotfound.menu.link3')}
                className="btn btn-outline-info btn-sm w-100"
              >
                <div className="d-flex">
                  <div className="col-10 p-0 d-flex np-item-left font-size-0-875 text-truncate">
                    {t('brendon.pagenotfound.menu.title3')}
                  </div>
                  <div className="col-2 p-0 d-flex arrow-right">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </a>
            </li>
            <li className="flex-grow-1 mb-2 w-100">
              <a
                href={t('brendon.pagenotfound.menu.link4')}
                className="btn btn-outline-info btn-sm w-100"
              >
                <div className="d-flex">
                  <div className="col-10 p-0 d-flex np-item-left font-size-0-875 text-truncate">
                    {t('brendon.pagenotfound.menu.title4')}
                  </div>
                  <div className="col-2 p-0 d-flex arrow-right">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div>
            <a
              href="/"
              className="text-nowrap font-size-0-875 align-middle mb-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} />{' '}
              {t('brendon.pagenotfound.backtomain')}
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-6 offset-lg-2 pr-0 h-100vh d-none d-lg-block">
          <div className="background_default h-100" />
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
