import {createActions} from 'redux-actions';

export const {
    changeSearchQuery,
    changeNewQueryMode,
    setSearchResult,
} = createActions({
    CHANGE_SEARCH_QUERY: (query) => query,
    CHANGE_NEW_QUERY_MODE: (newQueryMode) => newQueryMode,
    SET_SEARCH_RESULT: (query, result) => ({
        query,
        result,
    }),
});