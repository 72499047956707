import React from 'react';
import MoreLink from '../MoreLink';
import SectionTitleHeader from './SectionTitleHeader';
import { Link } from 'react-router-dom';

const SectionTags = ({
    title,
    content,
    tags,
    moreLink,
    moreTitle,
    tagContainerClassName = 'bg-white',
}) => {
    if (!Array.isArray(tags) || tags.length === 0) {
        return null;
    }
    return (
        <>
            {title && (
                <SectionTitleHeader title={title} content={content || ' '} />
            )}
            <div className="blog-tag-inner flex-fill">
                {Array.isArray(tags) &&
                    tags.map((tag, index) => (
                        <div
                            key={`mbt_${index}`}
                            className={`d-inline-block line-height-rem-0-875 rounded-pill py-2 px-2-5 mr-2 mb-2 ${
                                tagContainerClassName || ''
                            }`}
                        >
                            <Link
                                to={`/${tag.Slug}`}
                                className="text-body text-hover-gray font-weight-medium font-size-0-875"
                            >
                                # {tag.BlogPostTag}
                            </Link>
                        </div>
                    ))}
            </div>
            {moreLink && (
                <div className="mt-3">
                    <MoreLink url={moreLink} title={moreTitle} />
                </div>
            )}
        </>
    );
};

export default SectionTags;
