import React, { useEffect } from 'react';
import MegaMenu from '../MegaMenu/MegaMenu';
import MobileMenu from '../MegaMenu/MobileMenu';
import Logo from './Logo';
import { useSelector, useDispatch } from 'react-redux';
import {isMegaMenuLoaded, isLoading} from "../../store/megamenu/selectors";
import { getMegaMenu as getMegaMenuAction } from '../../store/megamenu';
import withStore from '../../hoc/withStore';
 
const TopMenu = () => {
    const dispatch = useDispatch();
    const isLoaded = useSelector(isMegaMenuLoaded);
    const loading = useSelector(isLoading);

    useEffect(() => {
        if (!loading && !isLoaded) {
            dispatch(getMegaMenuAction());
        }
    }, []);

    return (
        <>
            <div className="col-12 order-last d-none d-lg-block">
                <div className="row line-height-4 py-2 my-1">
                    <div className="col-12">
                        <MegaMenu />
                    </div>
                </div>
            </div>
            <div className="col-auto d-flex align-items-center pr-0 pr-sm-2">
                <div className="d-lg-none mr-3 ml-sm-3">
                    <MobileMenu>
                        <Logo />
                    </MobileMenu>
                </div>
                <Logo />
            </div>
        </>
    );
};

export default withStore(TopMenu);
