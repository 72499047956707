import React, { Component } from 'react';
import Banner from './Banner';
import BannerService from '../services/banners';

class BannerZone extends Component {
    state = {
        bannerZones: null,
    };

    async componentDidMount() {
        const {
            bannerBlockId,
            categoryId,
            topicId,
            productId,
            containerClass = 'mt-3',
        } = this.props;
        const { BannerZones } = await BannerService.getBanners(bannerBlockId, {
            categoryId,
            topicId,
            productId,
        });

        this.setState({
            bannerZones: BannerZones,
            containerClass,
        });
    }

    render() {
        const { bannerZones, containerClass } = this.state;
        const { bannerBlockId, noBannerChildren = null } = this.props;

        return bannerZones?.length > 0 ? (
            <div
                className={`row ${containerClass}`}
                data-banner-block-id={bannerBlockId}
            >
                <div className="col-12">
                    {bannerZones.map((banners, zoneIndex) => (
                        <div className="row text-dark" key={zoneIndex}>
                            {banners?.map((banner) => (
                                <Banner
                                    banner={banner}
                                    key={banner.BannerID}
                                    bannerId={banner.BannerID}
                                    target={
                                        banner?.NewWindow ? '_blank' : '_self'
                                    }
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            noBannerChildren
        );
    }
}

export default BannerZone;
