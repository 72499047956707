import React from 'react';
import Icon from '../Icon';

const SocialButton = ({ iconType, iconName, title, href  }) => (
    <a target="_blank" rel="noopener" href={href} className="px-1 mb-2 text-white bg-primary d-flex h-p40 rounded-sm align-items-center overflow-hidden">
        <Icon
            iconType={iconType}
            iconName={iconName}
            className="w-p30 text-center font-size-1-25 mr-2 my-auto h-p20"
        />
        <span className="font-weight-bold font-size-0-875">
            {title}
        </span>
    </a>
);

export default SocialButton;
