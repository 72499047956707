import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/pro-solid-svg-icons'
import {faShoppingCart as farShoppingCart} from '@fortawesome/pro-regular-svg-icons';
 
const Icon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.white};
    margin-right: 5px;
    font-size: 1.2rem;
    display: ${props => props.shown ? 'block' : 'none'};
    line-height: inherit;
    ${props => props.filling ? `
    animation: scale-up 0.3s 0s ease-in-out 2;
    animation-direction: alternate;
    ` : ''}
`;

const IconWrapper = styled.span`
    position: relative;
`;
  
const AddGiftCardToCartButton = ({title, isAdded, disabled}) => {
    return (<button type="submit" className="btn btn-lg btn-primary font-size-0-875 d-flex justify-content-center align-items-center flex-grow-1" disabled={disabled}>
        <span className="mr-2">{title}</span>
        <IconWrapper>
            <Icon icon={faShoppingCart} shown={isAdded?1:0} filling={1}/>
            <Icon icon={farShoppingCart} shown={!isAdded?1:0} filling={0}/>
        </IconWrapper>
    </button>);
};

export default AddGiftCardToCartButton;
