
import React from "react";
 
function BannerLink({targetUrl, target, children}) {
    const className=`d-block overflow-hidden font-size-0-875 line-height-3 text-dark text-child-hover-primary`

    return (
        targetUrl && targetUrl!=''?<a href={targetUrl} className={className} target={target}>{children}</a> : <div className={className}>{children}</div>
    )
}

export default BannerLink;