/**
 *
 * @param {string} startDate
 * @param {number} numWeeks
 *
 * @returns {Array<{start: string, end: string}>}
 */
const calculateDatesForWeeks = (startDate, numWeeks = 40) => {
    const start = new Date(startDate);
    const dayOfWeek = start.getDay();
    const firstWeekStart = new Date(start);
    firstWeekStart.setDate(start.getDate() - dayOfWeek + 1);

    const weekDateRanges = Array(numWeeks)
        .fill()
        .map((_, i) => {
            const weekStart = new Date(firstWeekStart);
            weekStart.setDate(firstWeekStart.getDate() + i * 7);
            const weekEnd = new Date(
                weekStart.getTime() + 6 * 24 * 60 * 60 * 1000,
            );
            return {
                start: weekStart.toISOString().split('T')[0],
                end: weekEnd.toISOString().split('T')[0],
            };
        });

    return [
        {
            start: firstWeekStart.toISOString().split('T')[0],
            end: new Date(firstWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000)
                .toISOString()
                .split('T')[0],
        },
        ...weekDateRanges.slice(1),
    ];
};

/**
 *
 * @param {string} dateString Example: 2019-01-01T00:00:00
 */
function countDown(dateString) {
    if (!dateString) {
        return null;
    }

    const countDownDate = new Date(dateString).getTime();

    if (isNaN(countDownDate)) {
        return null;
    }
    const now = new Date().getTime();
    const distance = countDownDate - now;

    return {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
        expired: distance < 0,
    };
}

/**
 *
 * @param {string} format - Format string, only replace:  yyyy-MM-dd , yyyy -> year, MM -> month, dd -> day
 * @param {string} dateString - Date string, example: 2019-01-01
 *
 * @returns {string} Formatted date string
 *
 * @example
 * formatDate('yyyy-MM-dd', '2019-01-01') // 2019-01-01
 * formatDate('yyyy.MM.dd', '2019-01-01') // 2019.01.01
 * formatDate('dd.MM.yyyy', '2019-01-01') // 01.01.2019
 **/

function formatDate(format, dateString) {
    if (!dateString) return;
    const dateParts = dateString.split('-');
    console.log('formatDate', format, dateParts)
    return format
        .replace('yyyy', dateParts[0])
        .replace('MM', dateParts[1])
        .replace('dd', dateParts[2])
        .replaceAll('-', '.');
}

function formatTime(format, timeString) {
    if (!timeString) return;
    const timeParts = timeString.split(':');
    return format
        .replace('HH', timeParts[0])
        .replace('mm', timeParts[1])
        .replace('ss', timeParts[2]);
}

function formatDateTime(format, dateTimeString) {
    if (!dateTimeString) return;
    const withoutMS = dateTimeString.split('.')[0];
    const parts = withoutMS.split('T');
    const formatedDate = formatDate(format, parts[0]);
    console.log('dts', dateTimeString, withoutMS, parts, formatedDate)
    return formatTime(formatedDate, parts[1]);
}

function toISOString(date) {
var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
        return (num < 10 ? '0' : '') + num;
    };

return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
}

export {
    countDown,
    calculateDatesForWeeks,
    formatDate,
    formatTime,
    formatDateTime,
    toISOString,
};
