import React, { useCallback } from "react";
import Button from "../../elements/Button";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import { useResource } from '../../contexts/ResourceContext';

const AddressBox = ({ address, selectAddressText, onDelete, onEdit, onSelect, isLoading, children }) => {
    const { t } = useResource();
    
    const deleteAddress = useCallback(() => {
        if (onDelete && address && address.Id) {
            onDelete(address.Id);
        }
    }, [onDelete, address]);

    const editAddress = useCallback(() => {
        if (onEdit && address && address.Id) {
            onEdit(address.Id);
        }
    },[onEdit, address]);

    const selectAddress = useCallback(() => {
        if (onSelect && address && address.Id) {
            onSelect(address.Id);
            }
    }, [onSelect, address]);

    return (
        <div className="border p-3 rounded-sm w-100 d-flex flex-column">
            <div className="row flex-fill mb-3">
                <div className="font-size-0-875 text-gray col">
                    {children}
                </div>
                <div className="col-auto">
                    <DeleteButton onClick={deleteAddress} className="mb-2"/>
                    <EditButton onClick={editAddress}/>
                </div>
            </div>
            <div>
                <Button
                    text={ selectAddressText }
                    variant="primary"
                    size="sm"
                    onClick={ selectAddress }
                    isLoading={ isLoading }
                />
            </div>
        </div>
    )
}

export default AddressBox;
