import React from 'react';
import ArrowButton from '../components/Elements/ArrowButton';

function BreadcrumbV2({ breadcrumbInfos }) {
  if (!breadcrumbInfos) {
    return null;
  }

  return (
    <nav className="font-size-0-75 text-dark line-height-4">
      {breadcrumbInfos.map((breadcrumbInfo, index) => (
        <div className="d-flex align-items-center" key={index}>
          <ArrowButton
            to={`/${breadcrumbInfo.Slug}`}
            leftSide
            title={breadcrumbInfo.Title}
          >
            {breadcrumbInfo.Title}
          </ArrowButton>
        </div>
      ))}
    </nav>
  );
}

export default BreadcrumbV2;
