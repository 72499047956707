import axios from 'axios';

async function getCategory(categoryId, page, order, filters = []) {
    const { data } = await axios.get('/Catalog/GetCategoryProducts', {
        params: {
            c: categoryId,
            p: page,
            o: order,
            f: filters.join(';'),
        },
    });

    if (data) {
        if (data?.ProductInfo) {
            data.ProductInfo = data.ProductInfo.map((product) => {
                try {
                    return Object.assign(
                        {},
                        product,
                        JSON.parse(product.ShortDescription),
                    );
                } catch (e) {
                    return product;
                }
            })
                // Fix API interface, Id is something else, it's not the product ID
                .map((product) =>
                    Object.assign({}, product, {
                        Id: product.ProductID,
                    }),
                );
        }
        if (data?.FilterInfo) {
            data.FilterInfo = data.FilterInfo.map((filter) => ({
                ...filter,
                IsFilterChecked: filter.IsCheckbox ? filter?.FilterOption?.[0]?.IsFilterOptionChecked : filter?.IsFilterChecked,
                Disabled: filter.IsCheckbox ? filter?.FilterOption?.[0]?.Disabled : filter?.Disabled || false,
                FilterID: filter.IsCheckbox
                    ? filter?.FilterOption?.[0]?.FilterOptionID ||
                      filter.FilterID
                    : filter.FilterID,
            }));
        }
    }

    return data;
}

async function getCategoryInfo(categoryId) {
    const { data } = await axios.get(`/Catalog/GetCategoryInfo/${categoryId}`);

    return data;
}

export const CategoryService = {
    getCategory,
    getCategoryInfo,
};

export default CategoryService;
