import { all } from 'redux-saga/effects';
import filterSaga from './filters/saga';
import searchSaga from './search/saga';
import cartSaga from './cart/saga';
import notificationSaga from './notification/saga';
import blogSaga from './blog/saga';
import megaMenuSaga from './megamenu/saga';
import wishlistSaga from './wishlist/saga';
import { initStoreSaga } from './shopstore/saga';

function* rootSaga() {
  yield all([
    filterSaga(),
    searchSaga(),
    cartSaga(),
    notificationSaga(),
    blogSaga(),
    megaMenuSaga(),
    wishlistSaga(),
    initSaga(),
  ]);
}

function* initSaga() {
  yield all([
    initStoreSaga(),
  ])
}

export default rootSaga;
