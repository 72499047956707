import React, { useState, useCallback } from 'react';
import SquareCheckbox from '../elements/SquareCheckbox';

const RememberMe = ({ name, children }) => {
    const [isChecked, setIsChecked] = useState(true);

    const changeHandler = useCallback(
        (value) => {
            setIsChecked(value);
        },
        [setIsChecked],
    );

    return (
        <SquareCheckbox name={name} value={isChecked} onChange={changeHandler}>
            {children}
        </SquareCheckbox>
    );
};

export default RememberMe;
