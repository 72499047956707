import axios from 'axios';
import JWTAuth from '../auth/JWTAuth';

axios.interceptors.request.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await JWTAuth.refresh();
      return axios({
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: JWTAuth.getStoredTokenData()?.token,
        },
      });
    }
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error?.response?.status || error?.response?.status === 0) {
      console.log('error', error);
    }
    return Promise.reject(error);
  },
);
