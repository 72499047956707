import React from 'react';
import Icons from './Icons';

export const ICON_SIZES = {
  small: {
    width: 16,
    height: 16,
  },
  medium: {
    width: 24,
    height: 24,
  },
  large: {
    width: 32,
    height: 32,
  },
  xlarge: {
    width: 48,
    height: 48,
  },
  xxlarge: {
    width: 64,
    height: 64,
  },
  cartsize: {
    width: 35,
    height: 35,
  },
};

const SVGIcon = ({ size, iconName, width, height, viewBox, className }) => {
  /*     console.log('iconName', size, iconName) */
  return (
    <svg
      id={`${iconName}-icon`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || `0 0 ${width} ${height}`}
      className={className}
    >
      {Icons?.[size === 'custom' ? 'small' : size]?.[`${iconName}-icon`]}
    </svg>
  );
};

const COMMON_ICONS = [
  'PenToSquare',
  'TrashCanLight',
  'ArrowDownWide',
  'SliderSimple',
  'Bars',
  'AddToCartList',
  'AddToCartCircle',
  'AddToCartInCart',
  'Exclamation',
  'Discount',
  'Shipping',
  'Point',
  'Info',
  'Color',
  'CircleInfo',
];

const ViewBoxes = {
  ArrowDownWide: '0 0 576 512',
  Bars: '0 0 448 512',
  SliderSimple: '0 0 512 512',
  TrashCanLight: '0 0 448 512',
  PenToSquare: '0 0 512 512',
};

/**
 *
 * @param {string} iconName - Name of the icon to render values: discount-icon | gls-logo
 * @param {string} - Size of the icon to render values: small | medium | large | xlarge | xxlarge | cartsize | custom
 * @param {string} className - Additional class name
 * @param {number} width - Width of the icon, if size is 'custom'
 * @param {number} height - Height of the icon, if size is 'custom'
 *
 * @returns React Component
 */
/* TODO: 
   Simple refactor:
    - remove size, just use width and height
    - default viewBox 512x512 or custom for each icon
    - remove size from ICON_SIZES
    - remove size from SVGIcon
    - remove size from CustomIcons
    - if in Icons.js the size is defined or specified paths or others, it will be a custom icon
*/
const CustomIcons = ({ iconName, size, width, height, className }) => {
  /*   console.log('iconName', iconName, COMMON_ICONS.includes(iconName)) */
  if (COMMON_ICONS.includes(iconName)) {
    return (
      <SVGIcon
        iconName={iconName?.toLowerCase()}
        {...(size === 'custom' ? { width, height } : ICON_SIZES[size])}
        size={size}
        className={className}
        viewBox={ViewBoxes[iconName] || null}
      />
    );
  }

  switch (iconName) {
    case 'GLSDropoff':
      return (
        <SVGIcon size="medium" iconName="gls-logo" width="30" height="10.2" />
      );
  }
  return null;
};

export default CustomIcons;
