import React, { useState, useCallback } from 'react';
import BlogLatestItem from './BlogLatestItem';
import { MAX_LATEST_POST_ON_LIST } from '../../models/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import MoreButton from './MoreButton';
import { useResource } from '../../contexts/ResourceContext';

const BlogLatest = ({ posts, className }) => {
  const [pageNum, setPageNum] = useState(1);
  const { t } = useResource();

  const showMore = useCallback(() => {
    setPageNum((p) => ++p);
  }, [setPageNum]);
  const visiblePostNum = pageNum * MAX_LATEST_POST_ON_LIST;

  return (
    <div className={`blog-latest ${className || ''}`}>
      <div className="text-uppercase font-size-1-25 font-weight-normal m-0 pb-2">
        {t('brendon.blog.relatedposts')}
      </div>
      {posts?.slice(0, visiblePostNum)?.map((post, index) => (
        <BlogLatestItem key={index} item={post} />
      ))}
      {visiblePostNum < posts?.length && (
        <MoreButton
          label={t('brendon.blog.morepost')}
          onClick={showMore}
          icon={<FontAwesomeIcon icon={faPlus} className="mr-1" />}
          btnClassName="btn-lg px-4"
        />
      )}
    </div>
  );
};

export default BlogLatest;
