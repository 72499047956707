import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import CustomerMainContainer from '../../components/CustomerNew/CustomerMainContainer';
import CustomerTitle from '../../components/CustomerNew/CustomerTitle';
import CustomerInfo from '../../components/CustomerNew/CustomerInfo';
import CustomerBackButton from '../../components/CustomerNew/CustomerBackButton';
import { useResource } from '../../contexts/ResourceContext';

const CustomerInfoScreen = () => {
    const { t } = useResource();
    return (
        <>
            <MetaHeader title={t('PageTitle.Account')} showBrendonAddon />
            <CustomerMainContainer>
                <CustomerBackButton />
                <CustomerTitle title={t('brendon.customer.info.title')} />
                <CustomerInfo />
            </CustomerMainContainer>
        </>
    );
};

export default CustomerInfoScreen;
