import React from 'react';
import SearchItem from './SearchItem';

export default function SearchSection({
    selectedIndex,
    groupIndex,
    items,
/*     moreItems, */
    title,
    onHover,
    truncate = false,
}) {
    if (!items.length) {
        return null;
    }
 
    return (
        <div className="search-item-group">
            <div className="font-size-0-75 line-height-rem-0-875 text-uppercase font-weight-bold mb-2">
                {title}
            </div>
            <div className="line-height-rem-1">
                {items.map((item, index) => (
                    <SearchItem
                        slug={item.slug}
                        onHover={() => onHover(groupIndex, index)}
                        name={item.name}
                        key={index}
                        selected={index === selectedIndex}
                        truncate={truncate}
                        type={item?.type || 'Item'}
                    />
                ))}
            </div>
{/*             {moreItems && (
                <div className="line-height-rem-1">
                    <a href={moreItems.Slug} className="text-dark">
                        {moreItems.Title}
                        <FontAwesomeIcon icon={faLongArrowRight} className="ml-2" />
                    </a>
                </div>
            )} */}
        </div>
    );
}
