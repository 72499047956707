import React, { useState, useCallback } from 'react';
import InputGroup from './InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';

const PasswordGroup = ({ defaultVisible = false, ...props }) => {
    const [showPassword, setShowPassword] = useState(defaultVisible);

    const togglePasswordVisibility = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, [setShowPassword]);

    return (
        <div className="position-relative">
            <InputGroup {...props} type={showPassword ? 'text' : 'password'} />
            <div
                className="position-absolute password-group-eye top right cursor-pointer"
                onClick={togglePasswordVisibility}
            >
                <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="font-size-0-875 mx-1 mw-14px"
                />
            </div>
        </div>
    );
};

export default PasswordGroup;
