import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/pro-light-svg-icons';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.modalContainer = React.createRef();
    }

    closeOnOverlay = (e) => {
        if (!this.modalContainer.current.contains(e.target)) {
            this.close();
        }
    };

    close = () => {
        const {onClosed} = this.props;
        if (onClosed) {
            onClosed();
        }
    };

    render() {
        const {opened, children, containerClasses, closeText} = this.props;

        return opened ? <div
            className="position-fixed top left vw-100 vh-100 bg-transparent-dark zindex-9000 d-flex align-items-center justify-content-center"
            onClick={this.closeOnOverlay}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className={`${containerClasses}`} ref={this.modalContainer}>
                        <div className="py-2 bg-white rounded-lg">
                            <div className="d-flex justify-content-end align-items-center text-primary text-hover-blue-medium font-size-0-75 text-uppercase px-1 cursor-pointer" onClick={this.close}>
                                {closeText}
                                <FontAwesomeIcon icon={faTimesCircle} className="font-size-1 ml-2" />
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div> : null;
    }
}

export default Modal;
