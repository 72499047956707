import React from 'react'
import { useResource } from '../contexts/ResourceContext';

function NotPublished({published}) {
    // TODO: change to text  props
    const { t } = useResource();
    if (published) return null;

    return <div className="font-size-0-75 text-gray mb-2 px-3pixel">
        <div className="stock font-weight-medium">
            <div className="text-dark">{ t('products.discontinued') }</div>
        </div>
    </div>
}

export default NotPublished;
