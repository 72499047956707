import React, { useState } from 'react';
import styled from 'styled-components';
import UserMenuItem from './UserMenuItem';
import OutsideClickDetector from '../../elements/OutsideClickDetector';
import withThemeProvider from '../../hoc/withThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCog,
  faSignIn,
  faSignOut,
  faPencil,
  faStars,
} from '@fortawesome/pro-regular-svg-icons';
import { faUser, faUserCheck } from '@fortawesome/pro-light-svg-icons';

const Arrow = styled.div`
  position: absolute;
  top: -9px;
  margin-left: 50%;
  left: -5px;
  border-color: transparent;
  border-style: solid;
  border-width: 0 5px 8px;
  border-bottom-color: ${(props) => props.theme.grayMedium};

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -5px;
    border-color: transparent;
    border-style: solid;
    border-width: 0 5px 8px;
    border-bottom-color: white;
  }
`;
const UserMenu = ({
  isLoggedIn,
  accountTitle,
  accountUrl,
  logoutTitle,
  logoutUrl,
  loginUrl,
  loginTitle,
  registrationUrl,
  registrationTitle,
  rewardPointUrl,
  rewardPointTitle,
}) => {
  const [opened, setOpened] = useState(false);

  const toggle = () => {
    setOpened((prev) => !prev);
  };

  const close = () => {
    setOpened(false);
  };

  return (
    <OutsideClickDetector
      onClickOutside={close}
      className="d-flex flex-column justify-content-start align-items-center mx-md-2"
    >
      <a
        className="position-relative w-p40 h-p40 d-flex justify-content-center align-items-center text-dark cursor-pointer"
        onClick={toggle}
      >
        <FontAwesomeIcon icon={isLoggedIn ? faUserCheck : faUser} />
      </a>
      {opened && (
        <div className="top mt-5 position-absolute zindex-7000 d-flex flex-column bg-white border rounded shadow">
          <Arrow />
          {isLoggedIn ? (
            <>
              <UserMenuItem url={accountUrl}>
                <FontAwesomeIcon icon={faUserCog} className="mr-1 w-p20" />
                {accountTitle}
              </UserMenuItem>
              <UserMenuItem url={rewardPointUrl}>
                <FontAwesomeIcon icon={faStars} className="mr-1 w-p20" />
                {rewardPointTitle}
              </UserMenuItem>
              <UserMenuItem url={logoutUrl}>
                <FontAwesomeIcon icon={faSignOut} className="mr-1 w-p20" />
                {logoutTitle}
              </UserMenuItem>
            </>
          ) : (
            <>
              <UserMenuItem url={loginUrl}>
                <FontAwesomeIcon icon={faSignIn} className="mr-1 w-p20" />
                {loginTitle}
              </UserMenuItem>
              <UserMenuItem url={registrationUrl}>
                <FontAwesomeIcon icon={faPencil} className="mr-1 w-p20" />
                {registrationTitle}
              </UserMenuItem>
            </>
          )}
        </div>
      )}
    </OutsideClickDetector>
  );
};
/* 
class UserMenu extends Component {
    state = {
        opened: false,
    };

    toggle = () => {
        this.setState(({ opened }) => ({
            opened: !opened,
        }));
    };

    close = () => {
        this.setState({
            opened: false,
        });
    };

    render() {
        const {
            isLoggedIn,
            accountTitle,
            accountUrl,
            logoutTitle,
            logoutUrl,
            loginUrl,
            loginTitle,
            registrationUrl,
            registrationTitle,
            rewardPointUrl,
            rewardPointTitle,
        } = this.props;
        const { opened } = this.state;
        return (
            <OutsideClickDetector
                onClickOutside={this.close}
                className="d-flex flex-column justify-content-start align-items-center mx-md-2"
            >
                <a
                    className="position-relative w-p40 h-p40 d-flex justify-content-center align-items-center text-dark cursor-pointer"
                    onClick={this.toggle}
                >
                    <FontAwesomeIcon icon={isLoggedIn ? faUserCheck : faUser} />
                </a>
                {opened && (
                    <div className="top mt-5 position-absolute zindex-7000 d-flex flex-column bg-white border rounded shadow">
                        <Arrow />
                        {isLoggedIn ? (
                            <>
                                <UserMenuItem url={accountUrl}>
                                    <FontAwesomeIcon
                                        icon={faUserCog}
                                        className="mr-1 w-p20"
                                    />
                                    {accountTitle}
                                </UserMenuItem>
                                <UserMenuItem url={rewardPointUrl}>
                                    <FontAwesomeIcon
                                        icon={faStars}
                                        className="mr-1 w-p20"
                                    />
                                    {rewardPointTitle}
                                </UserMenuItem>
                                <UserMenuItem url={logoutUrl}>
                                    <FontAwesomeIcon
                                        icon={faSignOut}
                                        className="mr-1 w-p20"
                                    />
                                    {logoutTitle}
                                </UserMenuItem>
                            </>
                        ) : (
                            <>
                                <UserMenuItem url={loginUrl}>
                                    <FontAwesomeIcon
                                        icon={faSignIn}
                                        className="mr-1 w-p20"
                                    />
                                    {loginTitle}
                                </UserMenuItem>
                                <UserMenuItem url={registrationUrl}>
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        className="mr-1 w-p20"
                                    />
                                    {registrationTitle}
                                </UserMenuItem>
                            </>
                        )}
                    </div>
                )}
            </OutsideClickDetector>
        );
    }
} */

export default withThemeProvider(UserMenu);
