import React, { useState, useMemo, useCallback } from 'react';
import CheckboxComboIconWrapper from './CheckboxComboIconWrapper';
import CircleLink from '../CircleLink';
import ComboListDetails from './ComboListDetails';
import classNames from 'classnames';

/**
 * @typedef {Object} Item
 * @property {string} name - Item name.
 * @property {string} description - Item description.
 * @property {string} subName - Item sub name.
 * @property {string} beforeDescription - Item before description.
 * @property {boolean} enableDetails - Item enable details.
 * @property {Array} rows - Item rows.
 * @property {boolean} isEnabled - Item is enabled.
 * @property {object} icon - Item icon.
 * 
/**
 * 
 * @param {Item} item 
 * @param {number} index
 * @param {number} selectedIndex
 * @param {boolean} disabled
 * @param {function} onClick
 * @param {string} containerHoverClassName - Container hover class name.
 * @param {string} textHoverClassName - Text and icon hover class name.
 * @param {string} detailsLinkText - Details link text.
 * 
 * @returns 
 */
const LinkComboListItem = ({
    item,
    index,
    selectedIndex,
    disabled,
    onClick,
    containerHoverClassName = 'border-hover-dark',
    textHoverClassName = '',
    itemTextClassName,
    itemNameClassName,
    iconContainerClassName,
    detailsLinkText,
    iconType,
}) => {
    const selected = useMemo(() => selectedIndex === index || false, [
        selectedIndex,
        index,
    ]);
    const [hover, setHover] = useState(false);
    const handleMouseEnter = useCallback(() => {
        setHover(true);
    }, [setHover]);

    const handleMouseLeave = useCallback(() => {
        setHover(false);
    }, [setHover]);

    const getTextHoverClassName = useMemo(() => {
        return hover && !disabled ? textHoverClassName : '';
    }, [hover, textHoverClassName, disabled]);

    return (
        <li
            className={`mb-2-5 py-2-5 border rounded cursor-pointer d-flex ${
                selected
                    ? 'border-primary border-hover-primary border-2px'
                    : `${!disabled && containerHoverClassName}`
            } ${
                disabled
                    ? 'disabled-no-hover cursor-default border-gray-medium border-hover-gray-medium '
                    : ''
            }`}
            key={index}
            onClick={disabled ? null : onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="d-flex align-items-center flex-1">
                <div className="flex-1 d-flex flex-column">
                    <div className="d-flex">
                        {(item?.icon || item?.customIconChildren) && (
                            <CheckboxComboIconWrapper
                                icon={item?.icon}
                                customIconChildren={item?.customIconChildren}
                                containerClass={
                                    classNames(
                                        'mx-2-5',
                                        iconContainerClassName,
                                        selected ? 'text-primary' : '',
                                        disabled ? 'text-gray-medium' : '',
                                    )
                                  }
                            />
                        )}
                        
                        <div
                            className={`d-flex font-size-0-875 flex-column flex-1 align-self-center ${
                                selected ? 'text-primary' : ''
                            } ${getTextHoverClassName} ${
                                itemTextClassName || ''
                            }`}
                        >
                            <span
                                className={`font-weight-medium line-height-rem-1-125 ${
                                    itemNameClassName || ''
                                }`}
                            >
                                {item?.name}
                            </span>
                            {item?.subName && (
                                <span className="font-weight-medium font-size-0-75 line-height-rem-1-125">
                                    {item?.subName}
                                </span>
                            )}
                            {item?.beforeDescription && (
                                <span
                                    className="font-size-0-75 line-height-rem-1-125"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.beforeDescription,
                                    }}
                                />
                            )}
                            {item?.description && (
                                <span
                                    className="font-size-0-75 line-height-rem-1-125"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                    }}
                                />
                            )}
                            {Array.isArray(item?.rows) &&
                                item.rows.map((row, index) => (
                                    <span key={`${index}_lcirow`} className={row?.className}>
                                        {row?.content}
                                    </span>
                                ))}
                        </div>
                    </div>
                    {item?.enableDetails && (
                        <div className="ml-2-5">
                            <ComboListDetails
                                detailsLinkText={detailsLinkText}
                                detailsChildren={item?.detailsChildren}
                            />
                        </div>
                    )}
                </div>
                <CircleLink
                    value={selected}
                    className={`ml-auto mr-3 line-height-rem-1-75 ${
                        hover ? 'bg-dark text-white' : 'bg-gray-light'
                    }`}
                    disabled={disabled}
                    visible={!disabled}
                    animation={false}
                    iconType={iconType}
                />
            </div>
        </li>
    );
};

export default LinkComboListItem;
