import React from 'react';
import SquareCheckbox from '../../elements/SquareCheckbox';
import PaymentMethodIcons from './PaymentMethodIcons';

const PaymentMethodItem = ({
    paymentMethod,
    paymentMethodIndex,
    selectedPaymentMethod,
    disabled,
    onClick,
}) => {
    const selected = selectedPaymentMethod === paymentMethodIndex || false;
    return (
        <li
            className={`mb-2-5 py-2-5 border d-flex align-items-center rounded cursor-pointer ${
                selected
                    ? 'border-primary border-hover-primary border-2px'
                    : 'border-hover-dark'
            } ${
                disabled
                    ? 'text-gray-medium border-gray-medium border-hover-gray-medium cursor-default'
                    : ''
            }`}
            key={paymentMethodIndex}
            onClick={disabled ? null : onClick}
        >
            <PaymentMethodIcons
                iconName={paymentMethod?.IconName}
                containerClass={`mx-2-5 ${selected ? 'text-primary' : ''}`}
            />
            <div
                className={`d-flex font-size-0-875 d-flex flex-column flex-1 align-self-center
                 ${selected ? 'text-primary' : ''}
                 ${disabled ? 'text-body' : ''}
                `}
            >
                <span className="font-weight-medium line-height-rem-1-125">
                    {paymentMethod?.PaymentMethodName}
                </span>
                <span
                    className="font-size-0-75 line-height-rem-1-125"
                    dangerouslySetInnerHTML={{
                        __html: paymentMethod?.PaymentMethodDescription,
                    }}
                />
            </div>
            {!disabled && (
                <SquareCheckbox
                    value={selected}
                    className={'ml-auto mr-2-5'}
                    disabled={disabled}
                />
            )}
        </li>
    );
};

export default PaymentMethodItem;
