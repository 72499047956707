import React, {useRef} from "react";
import {Form, Formik} from "formik";
import Section from "../../elements/Layout/Section";
import InputGroup from "../../elements/forms/InputGroup";
import SelectGroup from "../../elements/forms/SelectGroup";
import ButtonGroup from "../../elements/forms/ButtonGroup";
import TextareaGroup from "../../elements/forms/TextareaGroup";
import FormText from "../../elements/forms/FormText";
import validateHungarianTaxNumber from '../../models/validation/hungarianTaxNumberValidator';
import createBillingAddressSchema from "../../models/validation/billingAddressSchema";
import ErrorFocus from "../../elements/forms/ErrorFocus";
import TaxService from "../../services/tax";
import { ADDRESS_TYPE_ENUM, ADDRESS_TYPES, HUNGARY_COUNTRY_ID, FORM_FIELD_LENGTHS } from "../../models/constants";
import { executeCallback } from "../../utils/function";
import { useResource } from '../../contexts/ResourceContext';

function BillingAddressForm({
    countries,
    nextButtonTitle,
    initialValues,
    disableTaxNumberValidation,
    showCompanyNumber,
    saveHandler,
}) {
    const { t } = useResource();
    const form = useRef(null);
    const schema = createBillingAddressSchema({
        taxNumber: 'TaxNumber',
        companyNumber: 'CompanyNumber',
        addressType: 'BillingAddressType',
    }, disableTaxNumberValidation);
 
    const initialStatus = {
        backendErrors: {},
    };

    return <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={initialValues}
        initialStatus={initialStatus}
        onSubmit={async (values, {setSubmitting}) => {
            values.InvoiceDesc = values.InvoiceDesc?.replace(/\r?\n|\r/g, '');
            executeCallback(saveHandler, values);
            setSubmitting(false);
        }}
    >
        {({values, setFieldValue, validateForm}) => {
            React.useEffect(() => {
                async function fillTaxInfoField() {
                    const {QueryTaxpayerResult, TaxpayerValidity, TaxpayerData} = await TaxService.getTaxInfo(values?.TaxNumber?.slice(0, 8));
                    if (QueryTaxpayerResult !== 0 || !TaxpayerValidity) {
                        return;
                    }

                    const {
                        Name,
                    } = TaxpayerData;
                    const {
                        PostalCode,
                        City,
                        StreetName,
                        PublicPlaceCategory,
                        Number,
                        Building,
                        Staircase,
                        Floor,
                        Door,
                    } = TaxpayerData.TaxpayerAddressList[0];
                    setFieldValue('NameOnBill', Name);
                    setFieldValue('ZipPostalCode', PostalCode);
                    setFieldValue('City', City);

                    const address = [
                        StreetName,
                        PublicPlaceCategory,
                        Number,
                    ];

                    if (Building) {
                        address.push(Building)
                    }

                    if (Staircase) {
                        address.push(Staircase)
                    }

                    if (Floor) {
                        address.push(Floor)
                    }

                    if (Door) {
                        address.push(Door)
                    }

                    setFieldValue('Address', address.join(' '));
                }

                if (Number(values?.BillingAddressType) === ADDRESS_TYPE_ENUM.LEGAL_PERSON &&
                    values?.CountryId?.toString() === HUNGARY_COUNTRY_ID &&
                    validateHungarianTaxNumber(values?.TaxNumber)) {
                    fillTaxInfoField();
                }

                validateForm();

            }, [values?.BillingAddressType, values?.TaxNumber, values?.CountryId, setFieldValue]);

            return <div>
                <Form ref={form} autoComplete="off">
                <ErrorFocus/>
                <Section title={t('Checkout.BillingAddress.AvailabilityTitle')}>
                    <InputGroup
                        variation="checkout"
                        name="Email"
                        label={t('Address.Fields.Email')}
                        maxLength={FORM_FIELD_LENGTHS.EMAIL}
                        errorType="input-after"
                        required        
                    />
                    <InputGroup
                        variation="checkout"
                        name="PhoneNumber"
                        label={t('address.fields.phonenumber')}
                        errorType="input-after"
                        maxLength={18}
                        required
                    />
                </Section>

                <Section title={t('Checkout.BillingAddress.BillingDataTitle')}>
                    <ButtonGroup
                        variation="checkout"
                        label={t('checkout.billingaddress.addresstypelabel')}
                        name="BillingAddressType"
                        options={ADDRESS_TYPES}
                        errorType="input-after"
                        required
                    />
                    <FormText
                        side="r"
                        className="font-size-0-75 text-gray"
                        containerClassName="mt-n2">
                            <>{t("brendon.checkout.billingaddress.legalpersoninfo")}</>
                    </FormText>
                    {
                        Number(values?.BillingAddressType) === ADDRESS_TYPE_ENUM.PERSON && <>
                            <InputGroup
                                variation="checkout"
                                name="NameOnBill"
                                label={t('brendon.checkout.billingaddress.addressTitleLabel')}
                                errorType="input-after"
                                maxLength={100}
                                required
                            />
                            <SelectGroup
                                variation="checkout"
                                name="CountryId"
                                label={t('Address.Fields.Country')}
                                options={countries}
                                optionName="CountryName"
                                optionId="Id"
                                errorType="input-after"
                                required
                            />
                            <InputGroup
                                variation="checkout"
                                name="ZipPostalCode"
                                label={t('Address.Fields.ZipPostalCode')}
                                maxLength={FORM_FIELD_LENGTHS.ZIP}
                                errorType="input-after"
                                required
                            />
                            
                            <InputGroup
                                variation="checkout"
                                name="City"
                                label={t('Address.Fields.City')}
                                maxLength={50}
                                errorType="input-after"
                                required
                            />
                            <InputGroup
                                variation="checkout"
                                name="Address"
                                label={t('address.fields.address1')}
                                maxLength={50}
                                errorType="input-after"
                                required
                            />
                            <TextareaGroup
                                variation="checkout"
                                name="InvoiceDesc"
                                placeholder={t('brendon.checkout.billingaddress.legalinvoicedescriptionplaceholder')}
                                errorType="input-after"
                                maxLength={100}
                                disableNewLines
                            />
                        </>
                    } 
                    {Number(values?.BillingAddressType) === ADDRESS_TYPE_ENUM.LEGAL_PERSON && <>
                            <SelectGroup
                                variation="checkout"
                                name="CountryId"
                                label={t('Address.Fields.Country')}
                                options={countries}
                                optionName="CountryName"
                                optionId="Id"
                                errorType="input-after"
                                required
                            />
                            <InputGroup
                                variation="checkout"
                                name="TaxNumber"
                                label={t('checkout.billingaddress.taxnumberlabel')}
                                required={values?.CountryId?.toString() === HUNGARY_COUNTRY_ID}
                                validatingMessage={t('brendon.checkout.billingaddress.validatingTaxNumber')}
                                errorType="input-after"
                            />
                            {showCompanyNumber && 
                                <InputGroup
                                    variation="checkout"
                                    label={t('checkout.billingaddress.companynumberlabel')}
                                    name="CompanyNumber"
                                    errorType="input-after"
                                />
                            }
                            <InputGroup
                                variation="checkout"
                                name="NameOnBill"
                                label={t('brendon.checkout.billingaddress.addressTitleLabel')}
                                errorType="input-after"
                                maxLength={100}
                                required
                            />
                            <InputGroup
                                variation="checkout"
                                name="ZipPostalCode"
                                label={t('Address.Fields.ZipPostalCode')}
                                maxLength={FORM_FIELD_LENGTHS.ZIP}
                                errorType="input-after"
                                required
                            />
                            <InputGroup
                                variation="checkout"
                                name="City"
                                label={t('Address.Fields.City')}
                                maxLength={50}
                                errorType="input-after"
                                required
                            />
                            <InputGroup
                                variation="checkout"
                                name="Address"
                                label={t('address.fields.address1')}
                                maxLength={50}
                                errorType="input-after"
                                required
                            />
                            <TextareaGroup
                                variation="checkout"
                                name="InvoiceDesc"
                                placeholder={t('brendon.checkout.billingaddress.legalinvoicedescriptionplaceholder')}
                                errorType="input-after"
                                maxLength={100}
                                disableNewLines
                            />
                        </>
                    }
                </Section>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-4">
                    { /* TODO: in ternary false case it's need a empty div, because of google translet (removeChild bug)  */}
                       {Number(values?.BillingAddressType) === ADDRESS_TYPE_ENUM.LEGAL_PERSON ? <div className="font-size-0-75 text-gray line-height-3 py-1 text-center"><span id="translate_fix1">{t("brendon.checkout.billingaddress.invoicesubmitinfo")}</span></div> : <div></div> }
                        <input type="submit" name="nextstep" value={nextButtonTitle}
                               className="btn btn-primary w-100"/>
                    </div>
                </div>
            </Form>
            </div>
        }
        }
    </Formik>
}

export default BillingAddressForm;