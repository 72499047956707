import axios from 'axios';

let numberOfAjaxCallPending = 0;

axios.interceptors.request.use(function (config) {
    numberOfAjaxCallPending++;
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    numberOfAjaxCallPending--;
    return response;
}, function (error) {
    numberOfAjaxCallPending--;
    return Promise.reject(error);
});

function hasPendingRequest() {
    return numberOfAjaxCallPending > 0;
}

export default hasPendingRequest;
