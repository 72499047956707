import React from 'react';

function CategoryGroupName({children}) {
    return <div
        className="font-size-0-75 font-weight-medium line-height-2-5 py-2 mb-npixel d-flex justify-content-between align-items-center">
        {children}
    </div>
}

export default CategoryGroupName;
