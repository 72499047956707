import React from 'react';
import UserMenu from './UserMenu';
import WishlistIcon from './WishlistIcon';
import CartIcon from './CartIcon';
import { getURLPath } from '../../utils/staticPaths';
import { useResource } from '../../contexts/ResourceContext';
import { useAuth } from '../../contexts/AuthProvider';

const HeaderLinks = () => {
    const { t } = useResource();
    const { isLogged } = useAuth();

    return (
        <div className="px-2 px-md-0">
            <div className="row justify-content-end">
                <UserMenu
                    isLoggedIn={isLogged}
                    accountTitle={t('Account.MyAccount')}
                    accountUrl={getURLPath('customerDashboard')}
                    rewardPointTitle={t('Account.RewardPoints')}
                    rewardPointUrl={getURLPath('customerRewards')}
                    registrationTitle={t('Account.Register')}
                    registrationUrl={getURLPath('register')}
                    loginTitle={t('Account.Login')}
                    loginUrl={getURLPath('login')}
                    logoutTitle={t('Account.Logout')}
                    logoutUrl={getURLPath('logout')}
                />
                <WishlistIcon url={getURLPath('wishlist')} />
                <CartIcon url={getURLPath('cart')}/>
            </div>
        </div>
    );
};

export default HeaderLinks;
