import React, {Component} from 'react';
import styled from 'styled-components';
import withThemeProvider from "../hoc/withThemeProvider";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSortDown} from '@fortawesome/pro-solid-svg-icons';

const Select = styled.select`
    opacity: 0;
`;

const Selector = styled.div`
    height: 38px;
`

const OptionsContainer = styled.div`
    margin-right: -1px;
`;

const OptionsWrapper = styled.div`
    position: relative;
    top: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
`;

const Option = styled.div`
    &:hover {
        color: ${props => props.theme.primary};
    }
    
    width: 20%;
`;

class SizeSelector extends Component {
    state = {
        opened: false,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                opened: false
            });
        }
    };

    changeSize = (size) => {
        const {onSizeChange} = this.props;
        onSizeChange(size);
        this.setState({opened: false});
    };

    render() {
        const {opened} = this.state;
        const {sizes, name, value, className, orderableText, notOrderableText, chooseSizeText} = this.props;

        return (<div className={`${className} font-size-0-75 font-weight-medium h-p40 position-relative`}>
            <div
                className={`d-md-block overflow-hidden ${opened ? 'zindex-4000 shadow-sm' : 'h-p40'} line-height-5 border rounded-sm bg-white position-relative`}>
                <Selector
                    className={`cursor-pointer d-flex px-2 justify-content-between align-items-center ${value ? (value.IsOrderable ? 'text-primary' : 'text-gray bg-gray-light') : ''}`}
                    onClick={() => this.setState({opened: !opened})}>
                    <div className="text-truncate">
                        {value
                            ? (
                                (orderableText && notOrderableText)
                                    ? (`${value.SizeName} - ${value.IsOrderable ? orderableText : notOrderableText}`)
                                    : value.SizeName
                            )
                            : chooseSizeText
                        }
                    </div>
                    <FontAwesomeIcon icon={faSortDown} className="ml-2" />
                </Selector>
                <OptionsContainer>
                    <OptionsWrapper className={`${opened ? '' : 'd-none'} border-top w-100`}>
                        {sizes.map((size) => <Option key={size.SizeName}
                                                     className={`${size.IsOrderable ? 'text-dark' : 'text-gray-medium'} text-hover-primary cursor-pointer text-center border-bottom border-right`}
                                                     onClick={() => this.changeSize(size)}>
                            {size.SizeName}
                        </Option>)}
                    </OptionsWrapper>
                </OptionsContainer>
            </div>
            <Select className="d-md-none w-100 position-absolute top left h-100"
                    onChange={(e) => this.changeSize(sizes.find(size => parseInt(size.id) === parseInt(e.target.value) || parseInt(size.SizeName) === parseInt(e.target.value)) || null)}
                    name={name}
                    value={value?.id || value?.SizeName}
            >
                <option key="" value="">{chooseSizeText}</option>
                {sizes.map((size) => <option key={size.SizeName} value={size.id || size.SizeName}>
                    {
                        (orderableText && notOrderableText)
                            ? (`${size.SizeName} - ${size.IsOrderable ? orderableText : notOrderableText}`)
                            : size.SizeName
                    }</option>)}
            </Select>
        </div>)
    }
}

export default withThemeProvider(SizeSelector);
