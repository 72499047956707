import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/pro-light-svg-icons';

 
function DeleteButton({...props}) {
    return <button 
                   className="btn btn-outline-delete px-2 text-nowrap cart-delete-btn"
                   {...props}
    >
        <FontAwesomeIcon icon={faTrashAlt} className="text-danger"  style={{width:"13px", height:"15px"}} />
    </button>
}

export default DeleteButton;
