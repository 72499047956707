import {handleActions} from "redux-actions";
import {
    changeNewQueryMode,
    changeSearchQuery, setSearchResult,
} from "./actions";
import {fromJS} from "immutable";

const reducer = handleActions(
    {
        [changeSearchQuery]: (state, action) => state
            .set('query', action.payload),
        [changeNewQueryMode]: (state, action) => state
            .set('newQueryMode', action.payload),        
        [setSearchResult]: (state, action) => state
            .update('lastLoadedResult', result => {
                if (state.get('query') === action.payload.query) {
                    return action.payload.result;
                }
                return result;
            })
            .setIn(['results', action.payload.query], action.payload.result),
    },
    fromJS({
        query: '',
        newQueryMode: false,
        lastLoadedResult: null,
        results: {},
    }),
);

export default reducer;
