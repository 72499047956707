import React, { useLayoutEffect } from 'react';
import classnames from 'classnames';
import DOMHelper from '../../utils/DOMHelper';

const FullscreenMobilModal = ({
    title,
    rightTitleChildren,
    navChildren,
    children,
    isOpen,
    className = 'd-md-none flex-column',
    fullHeight = false,
}) => {
    if (!isOpen) {
        return null;
    }

    useLayoutEffect(() => {
        DOMHelper.addBodyOverflowHidden();
        return () => {
            DOMHelper.removeBodyOverflowHidden();
        };
    }, []);

    useLayoutEffect(() => {

    }, [fullHeight]);
    return (
        <div
            className={classnames(
                `position-fixed top left bottom right bg-white zindex-7000`,
                className,
            )}
        >
            <div className={classnames("overflow-y-auto h-100", fullHeight ? 'pb-full-height-60px' : null)}>
                {title && (
                    <div className="d-flex justify-content-between align-items-center font-size-1-25 font-weight-medium text-uppercase border-bottom p-3">
                        <span>{title}</span>
                        {rightTitleChildren}
                    </div>
                )}
                {children}
                {navChildren && (
                    <div className="position-fixed bottom left d-flex justify-center w-100 py-3 bg-white shadow-top">
                        {navChildren}
                    </div>
                )}
            </div>
        </div>
    );
};
FullscreenMobilModal.displayName = 'FullscreenMobilModal';
export default FullscreenMobilModal;
