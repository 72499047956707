import { handleActions } from 'redux-actions';
import {
    dismissMessage,
    initializeNotifications,
    showError,
    showSuccess,
    clearMessages,
} from './actions';
import { fromJS } from 'immutable';

const createMessage = (type) => (text) => ({
    type,
    text,
});

const reducer = handleActions(
    {
        [initializeNotifications]: (state, action) => {
            const { error, warning, success } = action.payload;

            const messages = error
                .map(createMessage('error'))
                .concat(warning.map(createMessage('warning')))
                .concat(success.map(createMessage('success')));

            return state.set('messages', fromJS([messages[0]]));
        },
        [showError]: (state, action) => {
            return state.set(
                'messages',
                fromJS([
                    {
                        type: 'error',
                        text: action.payload,
                    },
                ]),
            );
        },
        [showSuccess]: (state, action) => {
            return state.set(
                'messages',
                fromJS([
                    {
                        type: 'success',
                        text: action.payload,
                    },
                ]),
            );
        },
        [dismissMessage]: (state) => {
            return state.update('messages', (messages) => messages.pop());
        },
        [clearMessages]: (state) => state.set('messages', fromJS([])),
    },
    fromJS({
        messages: [],
    }),
);
export default reducer;
