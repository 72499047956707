import React from 'react';
import classnames from 'classnames';
import {Link} from "react-router-dom";

const ProductBoxName = ({ product, name, productUrl, className = "mb-2" }) => {
    const model = product?.Model || product?.model || null;
    return (
        <>
            {model != '' ?
                <>
                    <Link to={productUrl} className={ classnames("d-block text-dark line-height-rem-1-125", className) }>
                        <h2 className="mb-0 font-size-0-875 font-weight-normal">
                            <div className="font-weight-medium text-uppercase">
                                {product?.Brand || product?.brand}
                            </div>
                            <div>
                                {product?.Model || product?.model} {product?.Design || product?.design}
                            </div>
                            <div>
                                {product?.ModelFunction || product['Function'] ? product['Function'] : ''}
                            </div>
                        </h2>
                    </Link>
                </>
                : <>
                    <h2 className={ classnames("font-weight-medium overflow-hidden font-size-0-875", className) }>
                        <Link to={productUrl}
                            className="text-dark">{name}</Link>
                    </h2>
                </>}
        </>);
}

export default ProductBoxName;
