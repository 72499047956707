import React, { useRef, useState } from 'react';
import { default as registrationValidationSchema } from './RegistrationSchema';
import { Form, Formik } from 'formik';
import InputGroup from '../elements/forms/InputGroup';
import RegistrationButton from '../registration/RegistrationButton';
import RegistrationService from '../services/register';
import { isLastNameFirst } from '../utils/localization';
import { useNavigate } from 'react-router-dom';
import { getURLPath } from '../utils/staticPaths';
import { useResource } from '../contexts/ResourceContext';

function RegistrationForm() {
    const { t } = useResource();
    const [registrationResponse, setRegistrationResponse] = useState({
        IsSuccess: 0,
        MessageType: 0,
    });
    const navigateTo = useNavigate();

    const initalValues = {
        FirstName: '',
        LastName: '',
        Email: '',
        ConfirmEmail: '',
        Password: '',
        ConfirmPassword: '',
        PhoneNumber: '',
    };
    const form = useRef(null);

    return (
        <Formik
            enableReinitialize
            initialValues={initalValues}
            validationSchema={registrationValidationSchema()}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const responseData = await RegistrationService.RegisterUser(
                        values,
                    );
                    if (responseData.IsSuccess == 1) {
                        navigateTo(getURLPath('registerSuccess'));
                    } else {
                        setRegistrationResponse(responseData);
                    }
                } catch (e) {
                    console.log(e);
                }

                setSubmitting(false);
            }}
        >
            {(formik) => (
                <>
                    {registrationResponse.IsSuccess == 0 &&
                        registrationResponse.MessageType != 0 && (
                            <div className="mb-3 text-danger validation-summary-errors">
                                <ul>
                                    <li>
                                        {t(
                                            `brendon.registrationclaim.message.${registrationResponse.MessageType}`,
                                        )}
                                    </li>
                                </ul>
                            </div>
                        )}
                    {registrationResponse.IsSuccess == 0 && (
                        <Form
                            onSubmit={formik.handleSubmit}
                            method="POST"
                            ref={form}
                            autoComplete="off"
                        >
                            {isLastNameFirst() && (
                                <>
                                    <InputGroup
                                        name="LastName"
                                        label={`${t(
                                            'Account.Fields.LastName',
                                        )}:`}
                                        maxLength={45}
                                        required={true}
                                    />
                                    <InputGroup
                                        name="FirstName"
                                        label={`${t(
                                            'Account.Fields.FirstName',
                                        )}:`}
                                        maxLength={45}
                                        required={true}
                                    />
                                </>
                            )}
                            {!isLastNameFirst() && (
                                <>
                                    <InputGroup
                                        name="FirstName"
                                        label={`${t(
                                            'Account.Fields.FirstName',
                                        )}:`}
                                        maxLength={45}
                                        required={true}
                                    />
                                    <InputGroup
                                        name="LastName"
                                        label={`${t(
                                            'Account.Fields.LastName',
                                        )}:`}
                                        maxLength={45}
                                        required={true}
                                    />
                                </>
                            )}
                            <InputGroup
                                name="PhoneNumber"
                                maxLength={18}
                                label={`${t('account.fields.phone')}:`}
                            />
                            <InputGroup
                                name="Email"
                                label={`${t('Account.Fields.Email')}:`}
                                description={`${t(
                                    'brendon.registration.email.info',
                                )}:`}
                                formGroupClasses={{
                                    descriptionClass:
                                        'col-12 text-gray font-size-0-75',
                                }}
                                maxLength={254}
                                required={true}
                            />
                            <InputGroup
                                name="ConfirmEmail"
                                label={`${t('Account.Fields.ConfirmEmai')}:`}
                                required={true}
                            />
                            <InputGroup
                                name="Password"
                                type="password"
                                label={`${t('Account.Fields.Password')}:`}
                                required={true}
                            />
                            <InputGroup
                                name="ConfirmPassword"
                                type="password"
                                label={`${t(
                                    'account.fields.confirmpassword',
                                )}:`}
                                required={true}
                            />

                            <RegistrationButton
                                registrationButtonText={t(
                                    'Account.Register.Button',
                                )}
                                privacyText={t("Account.Fields.AcceptPrivacyPolicy")}
                            />
                        </Form>
                    )}
                </>
            )}
        </Formik>
    );
}

export default RegistrationForm;
