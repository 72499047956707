
import React from "react";
import InfoTooltip from '../components/InfoTooltip';
import { formatPrice } from '../utils/price';
import { useResource } from '../contexts/ResourceContext';

export default function ProductPrice({ productData }) {
    const { t } = useResource();
    const { price, discountedprice, PriceFormatted, DiscountedPriceFormatted, SuperPrice, RRP = 0 } = productData

    const discount = (discountedprice || DiscountedPriceFormatted)
    const priceValue = (price || PriceFormatted)

    return <div className="font-size-1-25 line-height-3 my-3">
        {RRP > 0 && <div className="font-size-0-875">RRP: { formatPrice(RRP) } <InfoTooltip content={t('brendon.product.rrp')} className="ml-1" /> </div>}
        {discount && discount != '' && SuperPrice !== true &&  <div className="text-line-through font-size-0-875">{ priceValue }</div>}
        {SuperPrice === true && <div className="text-pink font-size-0-875"> {t('brendon.product.superprice')} </div>}
        <div className={ `font-weight-medium ${(discount && discount != '') ? "text-pink" : ""}` }>
        {discount && discount != '' && discount}
        {(!discount || discount == '') && priceValue } 
        </div>
    </div>;
}
 
    