import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/pro-light-svg-icons';

class EditButton extends Component {
    render() {
        const {className, ...props} = this.props;

        return <div
            className={`${className || ''} border-hover-primary text-primary h-p30 w-p30 border rounded-sm font-size-0-75 d-flex align-items-center justify-content-center cursor-pointer`} {...props}>
            <FontAwesomeIcon icon={faEdit} />
        </div>
    }
}

export default EditButton;
