import React from 'react';

export default {
    cartsize: {
        'addtocartlist-icon': (
            <g transform="translate(-583 -591)">
                <circle
                    id="cart-bg"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(583 591)"
                    fill="#00acc6"
                />
                <path
                    id="cart-shopping-regular"
                    d="M.714,0a.714.714,0,0,0,0,1.429H2.069a.242.242,0,0,1,.235.193L3.84,9.689a2.143,2.143,0,0,0,2.1,1.741h8.582a.714.714,0,1,0,0-1.429H5.944a.717.717,0,0,1-.7-.58l-.161-.848h8.588a2.138,2.138,0,0,0,2.069-1.587l1.22-4.533a1.19,1.19,0,0,0-1.149-1.5H3.575A1.664,1.664,0,0,0,2.069,0ZM3.9,2.381H15.5l-1.14,4.233a.714.714,0,0,1-.691.53H4.81ZM5.239,15.24A1.429,1.429,0,1,0,3.81,13.812,1.429,1.429,0,0,0,5.239,15.24Zm10-1.429a1.429,1.429,0,1,0-1.429,1.429A1.429,1.429,0,0,0,15.24,13.812Z"
                    transform="translate(591.3 601.501)"
                    fill="#fff"
                />
            </g>
        ),
        'addtocartcircle-icon': (
            <circle cx="17.5" cy="17.5" r="17.5" fill="#00acc6" />
        ),
        'addtocartincart-icon': (
            <g transform="translate(-583 -591)">
                <circle
                    id="cart-bg"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(583 591)"
                    fill="#00acc6"
                />
                <path
                    id="check-solid"
                    d="M16.569,96.381a1.216,1.216,0,0,1,0,1.719l-9.713,9.713a1.216,1.216,0,0,1-1.719,0L.281,102.956A1.215,1.215,0,0,1,2,101.237l4,4,8.856-8.852a1.216,1.216,0,0,1,1.719,0Z"
                    transform="translate(592.075 506.975)"
                    fill="#fff"
                />
            </g>
        ),
    },
    small: {
        'pentosquare-icon': (
           <path d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144V432c0 44.2 35.8 80 80 80H368c44.2 0 80-35.8 80-80V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"/>
        ),
        'trashcanlight-icon': (
            <path d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zm80 80V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
        ),
        'arrowdownwide-icon': (
            <path d="M235.3 379.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0l-96-96c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L112 425.4V48c0-8.8 7.2-16 16-16s16 7.2 16 16V425.4l68.7-68.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM304 464c-8.8 0-16-7.2-16-16s7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H304zm0-128c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H304zm0-128c-8.8 0-16-7.2-16-16s7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H304zm0-128c-8.8 0-16-7.2-16-16s7.2-16 16-16H560c8.8 0 16 7.2 16 16s-7.2 16-16 16H304z"/>
        ),
        'slidersimple-icon': (
            <path d="M80 320a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm78.4 32L496 352c8.8 0 16 7.2 16 16s-7.2 16-16 16l-337.6 0c-7.4 36.5-39.7 64-78.4 64c-44.2 0-80-35.8-80-80s35.8-80 80-80c38.7 0 71 27.5 78.4 64zM384 144a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm-30.4-16C361 91.5 393.3 64 432 64c44.2 0 80 35.8 80 80s-35.8 80-80 80c-38.7 0-71-27.5-78.4-64L16 160c-8.8 0-16-7.2-16-16s7.2-16 16-16l337.6 0z"/>
        ),
        'bars-icon': (
            <path d="M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM192 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H168c13.3 0 24 10.7 24 24z" />
        ),
        'addtocart-icon': (
            <g transform="translate(-583 -591)">
                <circle
                    id="cart-bg"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(583 591)"
                    fill="#00acc6"
                />
                <path
                    id="cart-shopping-regular"
                    d="M.714,0a.714.714,0,0,0,0,1.429H2.069a.242.242,0,0,1,.235.193L3.84,9.689a2.143,2.143,0,0,0,2.1,1.741h8.582a.714.714,0,1,0,0-1.429H5.944a.717.717,0,0,1-.7-.58l-.161-.848h8.588a2.138,2.138,0,0,0,2.069-1.587l1.22-4.533a1.19,1.19,0,0,0-1.149-1.5H3.575A1.664,1.664,0,0,0,2.069,0ZM3.9,2.381H15.5l-1.14,4.233a.714.714,0,0,1-.691.53H4.81ZM5.239,15.24A1.429,1.429,0,1,0,3.81,13.812,1.429,1.429,0,0,0,5.239,15.24Zm10-1.429a1.429,1.429,0,1,0-1.429,1.429A1.429,1.429,0,0,0,15.24,13.812Z"
                    transform="translate(591.3 601.501)"
                    fill="#fff"
                />
            </g>
        ),
        'addtocartcircle-icon': (
            <circle cx="17.5" cy="17.5" r="17.5" fill="#00acc6" />
        ),
        'addtocartincart-icon': (
            <g transform="translate(-583 -591)">
                <circle
                    id="cart-bg"
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    transform="translate(583 591)"
                    fill="#00acc6"
                />
                <path
                    id="check-solid"
                    d="M16.569,96.381a1.216,1.216,0,0,1,0,1.719l-9.713,9.713a1.216,1.216,0,0,1-1.719,0L.281,102.956A1.215,1.215,0,0,1,2,101.237l4,4,8.856-8.852a1.216,1.216,0,0,1,1.719,0Z"
                    transform="translate(592.075 506.975)"
                    fill="#fff"
                />
            </g>
        ),
        'circleinfo-icon': (
            <path
                d="M8,1A7,7,0,1,1,1,8,7,7,0,0,1,8,1ZM8,16A8,8,0,1,0,0,8,8,8,0,0,0,8,16ZM6.5,11a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1h-1V7.5A.5.5,0,0,0,8,7H6.75a.5.5,0,1,0,0,1H7.5v3ZM8,5.75A.75.75,0,1,0,7.25,5,.75.75,0,0,0,8,5.75Z"
                fill="#00acc6"
            />
        ),
        'color-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(60 570)"
                    fill="#9778d3"
                />
                <path
                    d="M10,5v.053a1.289,1.289,0,0,1-1.369,1.2H6.719a.938.938,0,0,0-.937.938,1.047,1.047,0,0,0,.02.193,3.038,3.038,0,0,0,.211.584,2.076,2.076,0,0,1,.236.82A1.152,1.152,0,0,1,5.205,10c-.068,0-.137,0-.207,0a5,5,0,1,1,5-5Zm-7.5.625a.625.625,0,1,0-.625.625A.625.625,0,0,0,2.5,5.625Zm0-1.875a.625.625,0,1,0-.625-.625A.625.625,0,0,0,2.5,3.75ZM5.625,1.875A.625.625,0,1,0,5,2.5.625.625,0,0,0,5.625,1.875ZM7.5,3.75a.625.625,0,1,0-.625-.625A.625.625,0,0,0,7.5,3.75Z"
                    transform="translate(63 572.999)"
                    fill="#fff"
                />
            </g>
        ),
        'info-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(60 570)"
                    fill="#777"
                />
                <path
                    d="M.9,32.9a.9.9,0,1,1,.9.9A.9.9,0,0,1,.9,32.9ZM0,35.6A.6.6,0,0,1,.6,35H1.8a.6.6,0,0,1,.6.6v4.2H3A.6.6,0,1,1,3,41H.6a.6.6,0,0,1,0-1.2h.6V36.2H.6A.6.6,0,0,1,0,35.6Z"
                    transform="translate(66.4 541.6)"
                    fill="#fff"
                />
            </g>
        ),
        'point-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(60 570)"
                    fill="#83cf91"
                />
                <path
                    d="M28.579.273a.485.485,0,0,0-.873,0l-.974,2L24.556,2.6a.485.485,0,0,0-.27.824l1.579,1.562-.373,2.207A.486.486,0,0,0,26.2,7.7l1.944-1.038L30.088,7.7a.486.486,0,0,0,.707-.509l-.374-2.207L32,3.422a.485.485,0,0,0-.27-.824l-2.177-.321Z"
                    transform="translate(39.856 574)"
                    fill="#fff"
                />
            </g>
        ),
        'shipping-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    data-name="Ellipse 15"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(60 570)"
                    fill="#83cf91"
                />
                <path
                    d="M6.6.9H2.4a.3.3,0,0,0-.3.3v.6H4.5a.3.3,0,0,1,0,.6H.3a.3.3,0,1,1,0-.6h.9V1.2A1.2,1.2,0,0,1,2.4,0H6.6A1.2,1.2,0,0,1,7.8,1.2v.6h.8a1.049,1.049,0,0,1,.742.308l1.749,1.749A1.049,1.049,0,0,1,11.4,4.6V6.9h.15a.45.45,0,1,1,0,.9H10.8a1.8,1.8,0,0,1-3.6,0H4.8a1.8,1.8,0,0,1-3.6,0V5.4h.9v.84a1.8,1.8,0,0,1,2.46.66H6.6a.3.3,0,0,0,.3-.3V1.2A.3.3,0,0,0,6.6.9Zm3.857,3.592L8.708,2.743A.149.149,0,0,0,8.6,2.7H7.8V4.5h2.662l0,0,0,0ZM3.9,7.8a.9.9,0,1,0-.9.9A.9.9,0,0,0,3.9,7.8ZM9,8.7a.9.9,0,1,0-.9-.9A.9.9,0,0,0,9,8.7ZM.9,3H5.1a.3.3,0,1,1,0,.6H.9A.3.3,0,0,1,.9,3ZM.3,4.2H4.5a.3.3,0,0,1,0,.6H.3a.3.3,0,1,1,0-.6Z"
                    transform="translate(62.2 573.5)"
                    fill="#fff"
                />
            </g>
        ),
        'exclamation-icon': (
            <path
                d="M8,16A8,8,0,1,0,0,8,8,8,0,0,0,8,16ZM8,4a.748.748,0,0,1,.75.75v3.5a.75.75,0,1,1-1.5,0V4.75A.748.748,0,0,1,8,4ZM7,11a1,1,0,1,1,1,1A1,1,0,0,1,7,11Z"
                fill="#e24585"
            />
        ),
        'discount-icon': (
            <g transform="translate(-28 -572)">
                <circle
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(28 572)"
                    fill="#e24585"
                />
                <path
                    d="M14.269,72.8a.42.42,0,0,0-.593-.593l-5.6,5.6a.42.42,0,0,0,.593.593Zm-4.5.263a.84.84,0,1,0-.84.84A.84.84,0,0,0,9.771,73.066Zm4.48,4.48a.84.84,0,1,0-.84.84A.84.84,0,0,0,14.251,77.546Z"
                    transform="translate(25.05 504.463)"
                    fill="#fff"
                />
            </g>
        ),
    },
    medium: {
        'arrowdownwide-icon': (
            <path d="M235.3 379.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0l-96-96c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L112 425.4V48c0-8.8 7.2-16 16-16s16 7.2 16 16V425.4l68.7-68.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM304 464c-8.8 0-16-7.2-16-16s7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H304zm0-128c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H304zm0-128c-8.8 0-16-7.2-16-16s7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H304zm0-128c-8.8 0-16-7.2-16-16s7.2-16 16-16H560c8.8 0 16 7.2 16 16s-7.2 16-16 16H304z"/>
        ),
        'slidersimple-icon': (
            <path d="M80 320a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm78.4 32L496 352c8.8 0 16 7.2 16 16s-7.2 16-16 16l-337.6 0c-7.4 36.5-39.7 64-78.4 64c-44.2 0-80-35.8-80-80s35.8-80 80-80c38.7 0 71 27.5 78.4 64zM384 144a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm-30.4-16C361 91.5 393.3 64 432 64c44.2 0 80 35.8 80 80s-35.8 80-80 80c-38.7 0-71-27.5-78.4-64L16 160c-8.8 0-16-7.2-16-16s7.2-16 16-16l337.6 0z"/>
        ),
        'bars-icon': (
            <path d="M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM192 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H168c13.3 0 24 10.7 24 24z" />
        ),
        'color-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(60 570)"
                    fill="#9778d3"
                />
                <path
                    d="M16,8v.084A2.062,2.062,0,0,1,13.809,10H10.75a1.5,1.5,0,0,0-1.5,1.5,1.675,1.675,0,0,0,.031.309,4.861,4.861,0,0,0,.337.934A3.321,3.321,0,0,1,10,14.056a1.842,1.842,0,0,1-1.669,1.938C8.219,16,8.109,16,8,16a8,8,0,1,1,8-8ZM4,9a1,1,0,1,0-1,1A1,1,0,0,0,4,9ZM4,6A1,1,0,1,0,3,5,1,1,0,0,0,4,6ZM9,3A1,1,0,1,0,8,4,1,1,0,0,0,9,3Zm3,3a1,1,0,1,0-1-1A1,1,0,0,0,12,6Z"
                    transform="translate(63.749 573.749)"
                    fill="#fff"
                />
            </g>
        ),
        'info-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(60 570)"
                    fill="#777"
                />
                <path
                    d="M1.5,33.5A1.5,1.5,0,1,1,3,35,1.5,1.5,0,0,1,1.5,33.5ZM0,38a1,1,0,0,1,1-1H3a1,1,0,0,1,1,1v7H5a1,1,0,0,1,0,2H1a1,1,0,0,1,0-2H2V39H1A1,1,0,0,1,0,38Z"
                    transform="translate(69.2 542.625)"
                    fill="#fff"
                />
            </g>
        ),
        'point-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(60 570)"
                    fill="#83cf91"
                />
                <path
                    d="M10.279.469V2.344h1.875a.469.469,0,0,1,0,.937H10.279V5.156a.469.469,0,0,1-.937,0V3.281H7.466a.469.469,0,1,1,0-.937H9.341V.469a.469.469,0,0,1,.937,0ZM4.443,7.778a.936.936,0,0,1-.706.513l-2.563.375,1.857,1.813a.936.936,0,0,1,.27.829l-.439,2.557,2.291-1.207a.939.939,0,0,1,.873,0l2.291,1.207-.439-2.557a.939.939,0,0,1,.27-.829L10,8.665,7.44,8.293a.943.943,0,0,1-.706-.513L5.589,5.455,4.443,7.781Zm.516-3.167a.7.7,0,0,1,1.263,0L7.578,7.365l3.032.442a.7.7,0,0,1,.39,1.2L8.805,11.15l.519,3.026a.7.7,0,0,1-1.019.741l-2.713-1.43-2.716,1.43a.7.7,0,0,1-1.019-.741l.519-3.026L.178,9.005a.7.7,0,0,1,.39-1.2L3.6,7.365,4.956,4.611Zm8.132.076a.47.47,0,0,1,.469.469v.937H14.5a.469.469,0,1,1,0,.937H13.56v.937a.469.469,0,0,1-.937,0V7.031h-.937a.469.469,0,0,1,0-.937h.937V5.156A.47.47,0,0,1,13.091,4.687Z"
                    transform="translate(64.234 574)"
                    fill="#fff"
                />
            </g>
        ),
        'shipping-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(60 570)"
                    fill="#83cf91"
                />
                <path
                    d="M9.35,1.275H3.4a.426.426,0,0,0-.425.425v.85h3.4a.425.425,0,0,1,0,.85H.425a.425.425,0,1,1,0-.85H1.7V1.7A1.7,1.7,0,0,1,3.4,0H9.35a1.7,1.7,0,0,1,1.7,1.7v.85h1.134a1.485,1.485,0,0,1,1.052.436l2.478,2.478a1.485,1.485,0,0,1,.436,1.052V9.775h.212a.637.637,0,1,1,0,1.275H15.3a2.55,2.55,0,1,1-5.1,0H6.8a2.55,2.55,0,0,1-5.1,0V7.65H2.975V8.84a2.555,2.555,0,0,1,3.485.935H9.35a.426.426,0,0,0,.425-.425V1.7A.426.426,0,0,0,9.35,1.275Zm5.464,5.089L12.336,3.886a.211.211,0,0,0-.151-.061H11.05v2.55h3.772l-.005-.005-.005-.005ZM5.525,11.05A1.275,1.275,0,1,0,4.25,12.325,1.275,1.275,0,0,0,5.525,11.05Zm7.225,1.275a1.275,1.275,0,1,0-1.275-1.275A1.275,1.275,0,0,0,12.75,12.325ZM1.275,4.25h5.95a.425.425,0,1,1,0,.85H1.275a.425.425,0,1,1,0-.85Zm-.85,1.7h5.95a.425.425,0,0,1,0,.85H.425a.425.425,0,1,1,0-.85Z"
                    transform="translate(64 575)"
                    fill="#fff"
                />
            </g>
        ),
        'exclamation-icon': (
            <g transform="translate(-60 -570)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    transform="translate(60 570)"
                    fill="#e24585"
                />
                <path
                    d="M-5.589,33.071A1.07,1.07,0,0,0-6.661,32a1.07,1.07,0,0,0-1.071,1.071v8.571a1.07,1.07,0,0,0,1.071,1.071,1.07,1.07,0,0,0,1.071-1.071ZM-6.661,47a1.339,1.339,0,0,0,1.339-1.339,1.339,1.339,0,0,0-1.339-1.339A1.339,1.339,0,0,0-8,45.661,1.339,1.339,0,0,0-6.661,47Z"
                    transform="translate(78.74 543)"
                    fill="#fff"
                />
            </g>
        ),
        'discount-icon': (
            <>
                <circle cx="12" cy="12" r="12" fill="#e24585" />
                <path
                    d="M17.758,73.2a.651.651,0,0,0-.921-.921l-8.7,8.691a.651.651,0,0,0,.921.921Zm-6.982.408a1.3,1.3,0,1,0-1.3,1.3A1.3,1.3,0,0,0,10.777,73.606Zm6.955,6.955a1.3,1.3,0,1,0-1.3,1.3A1.3,1.3,0,0,0,17.731,80.561Z"
                    transform="translate(-0.95 -65.089)"
                    fill="#fff"
                />
            </>
        ),
        'gls-logo-icon': (
            <>
                <path
                    id="Path_1"
                    d="M555.059,409.88h-2.088V408.5c-.165.2-.292.374-.448.525a3.546,3.546,0,0,1-2.039.962,5.931,5.931,0,0,1-1.092.069,4.447,4.447,0,0,1-2.534-.869,4.229,4.229,0,0,1-1.537-2.216,4.43,4.43,0,0,1-.219-1.244c0-.226-.04-.452-.033-.678a5.125,5.125,0,0,1,1.087-3.168,5.073,5.073,0,0,1,2.179-1.56,6.054,6.054,0,0,1,1.887-.4,11.539,11.539,0,0,1,1.4-.029,10.173,10.173,0,0,1,2.217.377c.122.037.163.08.162.2,0,.818,0,1.636,0,2.474-.31-.075-.611-.136-.9-.22a6.884,6.884,0,0,0-2.364-.235,2.356,2.356,0,0,0-1.6.677,2.136,2.136,0,0,0-.566,1.141,5.035,5.035,0,0,0-.079,1.009,2.47,2.47,0,0,0,.446,1.44,1.619,1.619,0,0,0,1.033.631,1.992,1.992,0,0,0,1.566-.243c.127-.089.24-.2.4-.329h-1.881v-2.2h5.014v5.264Z"
                    transform="translate(-545.068 -399.871)"
                    fill="#343434"
                />
                <path
                    id="Path_2"
                    d="M1056.023,400.1v2.579c-.175-.035-.34-.071-.506-.1-.478-.082-.956-.173-1.437-.238a11.315,11.315,0,0,0-1.214-.1,2.56,2.56,0,0,0-.653.1.455.455,0,0,0-.369.4.45.45,0,0,0,.365.39c.431.116.864.221,1.3.322a5.316,5.316,0,0,1,1.9.733,2.394,2.394,0,0,1,1.063,1.787,5.048,5.048,0,0,1,.035.875,2.837,2.837,0,0,1-.656,1.678,3.96,3.96,0,0,1-2.294,1.255,8.222,8.222,0,0,1-2.168.131,10.469,10.469,0,0,1-2.424-.353c-.052-.015-.1-.031-.161-.049v-2.668c.621.133,1.24.282,1.866.395a6.808,6.808,0,0,0,1.767.112,1.93,1.93,0,0,0,.688-.219.468.468,0,0,0,.187-.327.274.274,0,0,0-.241-.285c-.37-.094-.737-.2-1.111-.278a5.928,5.928,0,0,1-2.188-.791,2.524,2.524,0,0,1-1.014-1.405,3.251,3.251,0,0,1-.1-1.289,3.017,3.017,0,0,1,1.083-2.1,3.9,3.9,0,0,1,1.794-.806,7.5,7.5,0,0,1,1.9-.1c.571.032,1.138.132,1.706.209.3.04.59.1.885.144Z"
                    transform="translate(-1030.775 -399.731)"
                    fill="#343434"
                />
                <path
                    id="Path_3"
                    d="M849.227,414.183H842.45V404.35h3.241v7.268h3.536Z"
                    transform="translate(-831.796 -404.179)"
                    fill="#343434"
                />
                <path
                    id="Path_4"
                    d="M1286.6,589.568a1.721,1.721,0,1,1-1.876-1.7,1.686,1.686,0,0,1,1.876,1.7Z"
                    transform="translate(-1256.601 -581.159)"
                    fill="#343434"
                />
            </>
        ),
    },
};
