import React from 'react';
import BlogSubTitle from './BlogSubTitle';

const BlogTagTitle = ({ title, tag }) => (
    <>
        <BlogSubTitle title={title}>
            <div className="mr-2 mb-2 px-2 py-1 text-nowrap align-self-center">
                <div
                    className={`d-inline-block line-height-rem-0-875 rounded-pill py-2 px-2-5 mr-2 bg-gray-light`}
                >
                    <span className="text-body font-weight-medium font-size-0-875">
                        {tag}
                    </span>
                </div>
            </div>
        </BlogSubTitle>
    </>
);

export default BlogTagTitle;
