import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import userAuthService from '../services/auth/JWTAuth';
import AuthError from '../models/errors/AuthError';
import { useResource } from './ResourceContext';

import { useNavigate } from 'react-router-dom';
import { getURLPath } from '../utils/staticPaths';

export const NULL_USER = {};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(NULL_USER);
  const t = useResource();
  const [isLogging, setIsLogging] = useState(true);
  const [isLogged, setIsLogged] = useState(false);

  const login = useCallback(
    async ({ email, password }) => {
      const { token } = await userAuthService.login({
        email,
        password,
      });
      if (!token) {
        throw new AuthError(message);
      }
      setIsLogged(true);
      return true;
    },
    [setIsLogged],
  );

  const logout = useCallback(() => {
    userAuthService.logout();
    setUser(NULL_USER);
    setIsLogged(false);
  }, [setUser, setIsLogged]);

  const profile = useCallback(async () => {
    try {
      const user = await userAuthService.user();
      console.log('user logged', user);
      setIsLogged(true);
      setUser(user);
    } catch (error) {
      console.log('user get error', error); // ignoring errors
      setIsLogged(false);
    } finally {
      setIsLogging(false);
    }
  }, [setUser]);

  useEffect(() => {
    setIsLogging(true);
    profile();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        isLogging,
        login,
        setIsLogging,
        logout,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const withAuthGuard = (Component) => {
  return function (props) {
    const { isLogged, isLogging } = useAuth();
    const navigateTo = useNavigate();

    useEffect(() => {
      console.log('isLogged', isLogged, 'isLogging', isLogging);
      if (!isLogged && !isLogging) {
        navigateTo(getURLPath('login'));
      }
    }, [isLogging, isLogged]);

    if (isLogging) {
      return null;
    }

    return <Component {...props} />;
  };
};

export const useAuth = () => useContext(AuthContext);
