import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';
import Carousel from "../components/Carousel";
import LazyImage from "../elements/LazyImage";
import ProductVideoWithThumbnail from './ProductVideoWithThumbnail';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/pro-solid-svg-icons'

const Square = styled.div`
    position: relative;
    width: 100%;
    padding-top: 100%;
`;

const Dot = styled.div`
    width: 5px;
    height: 5px;
`;

const IconLayer = styled.div`
    position: absolute;
    top: 0;
`;

const PlayButton = styled.div`
    border-radius: 50%;
    cursor: pointer;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    height: 15px;
    width: 15px;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 15px;
    width: 15px;
    padding-left: 1px;
`;

function ProductGallery({pictures, videos = []}) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [visitedIndexes, setVisitedIndexes] = useState({});

    const select = useCallback((index) => {
        setSelectedIndex(index);
        setVisitedIndexes((visitedIndexes) => ({
            ...visitedIndexes,
            [index]: true
        }));
    }, [setSelectedIndex, setVisitedIndexes]);

    useEffect(() => {
        select(0);
    }, [select])

    const hasVisited = useCallback((index) => visitedIndexes[index] || false, [visitedIndexes])
    const carouselItems = videos.concat(pictures)

    return <>
        {carouselItems.length > 0 && <div className="mb-3 product-gallery">
            <Carousel snap={true} wrapperClass="row flex-nowrap" currentIndex={selectedIndex} onChange={select}
                        hidePagerOnMobile elementWrapperClass="col-12"
                        placeholder={ <div className="col-12" ><Square></Square></div> }>
                {carouselItems.map((item, index) =>
                    <div className="col-12" key={index} >
                            { item?.Embedcode ? 
                                <ProductVideoWithThumbnail
                                    thumbnailAlt={item.AlternateText}
                                    thumbnailUrl={item.ImageUrl}
                                    disabledLoad={!hasVisited(index)}
                                    embedCode={ item.Embedcode }
                                    visible={ selectedIndex == index }
                                    className="w-100 h-100 position-absolute top left object-fit-contain"/>
                                :
                                <Square>
                                    <LazyImage
                                    alt={item.AlternateText}
                                    title={item.AlternateText}
                                    src={item.ImageUrl}
                                    disabledLoad={!hasVisited(index)}
                                    className="w-100 h-100 position-absolute top left object-fit-contain"/>
                                </Square>
                            }
                    </div>
                )}
            </Carousel>
        </div>}
        {carouselItems.length > 1 && <div className="mb-3 mb-md-5 product-gallery">
            <div className="d-none d-lg-block">
                <Carousel snap={true} wrapperClass="row flex-nowrap" onSelect={select}
                            elementWrapperClass="col-2">
                    {carouselItems.map((item, index) =>
                        <div className="col-2" key={index}>
                            <Square>
                                <LazyImage alt={item.AlternateText}
                                        title={item.AlternateText}
                                        src={item.ThumbImageUrl}
                                        className={`cursor-pointer rounded-sm overflow-hidden w-100 h-100 position-absolute top left object-fit-contain border ${index === selectedIndex ? 'border-primary' : 'border-transparent'}`}/>
                                { item?.Embedcode && 
                                <IconLayer className="w-100 h-100">
                                    <PlayButton icon={faPlay} className='text-center btn-primary'>
                                        <IconContainer className="w-100">
                                            <FontAwesomeIcon  icon={faPlay} className="font-size-0-5 align-center m-auto" />
                                        </IconContainer>
                                    </PlayButton>
                                </IconLayer>}
                            </Square>
                        </div>
                    )}
                </Carousel>
            </div>
            <div className="d-flex d-lg-none justify-content-center flex-wrap">
                {carouselItems.map((picture, index) =>
                    <div className="p-1 cursor-pointer" onClick={() => select(index)} key={index}>
                        <Dot
                            className={`rounded-circle ${index === selectedIndex ? 'bg-primary' : 'bg-gray-medium'}`}/>
                    </div>
                )}
            </div>
        </div>}
    </>
}

export default ProductGallery;
