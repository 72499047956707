import React, {Component} from "react";
import loadable  from '@loadable/component'
import CategoryService from "../services/category";
import queryString from 'query-string';
const FilterPanel = loadable(() => import('./FilterPanel'))
import ProductList from "./ProductList";
import FilterOrderBy from "../filter/FilterOrderBy";
import Pager from "../elements/Pager";
import CategoryTitle from "./CategoryTitle";
import CategoryDescription from "./CategoryDescription";
import HelpButton from "./HelpButton";
const CategoryList = loadable(() => import('./CategoryList'));
const RelatedCategories = loadable(() => import('../product/RelatedCategories'));
import scrollToElement from "../scrollToElement";
import { isMobile } from "../utils/mobile";
const FilterTags = loadable(() => import('./FilterTags'));
import Loader from "../elements/Loader";
import BannerZone from "../banners/BannerZone";
import CategoryMeta from "./CategoryMeta";
import CountDownTimer from "../components/CountDownTimer";
const ProductCategoryList = loadable(() => import('./CategoryTypes/ProductCategoryList'))
const Marketing = loadable(() => import('./CategoryTypes/Marketing'))
const ABCList = loadable(() => import('./CategoryTypes/ABCList'))
const Subcategories = loadable(() => import('./CategoryTypes/Subcategories'))
/* const ProductModels = loadable(() => import('./CategoryTypes/ProductModels')) */
const SubcategoriesList = loadable(() => import('./CategoryTypes/SubcategoriesList'));
import gaService from '../services/gaEnhancedEcommerce';
import { getStoreCurrency } from '../utils/localization';
import GA4Service from '../services/gaGA4';
import {legacy_t as t} from '../contexts/ResourceContext';
import { isNewFilterEnabled } from '../services/featureFlagService';
import CustomIcons from '../components/Elements/CustomIcons/CustomIcons';
import MobileSortModal from '../components/FilterNew/MobileSortModal';
import MobileFilterModal from '../components/FilterNew/MobileFilterModal';
import FilterButton from '../components/FilterNew/FilterButton';
import DOMHelper from '../utils/DOMHelper';
import FilterButtonContainerWithNav from '../components/FilterNew/FilterButtonContainerWithNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import BreadcrumbV2 from './BreadcrumbV2';

const SORT_OPTIONS = [
    {
        Text: t('brendon.products.orderby.priceAscending'),
        Value: 'price',
    },
    {
        Text: t('brendon.products.orderby.priceDescending'),
        Value: 'pricedesc',
    },
    {
        Text: t('brendon.products.orderby.stock'),
        Value: 'stock',
    },
    {
        Text: t('brendon.products.orderby.age'),
        Value: 'age',
    },
    {
        Text: t('brendon.products.orderby.name'),
        Value: 'name',
    },
];

const SORT_OPTIONS_MODEL = [
    {
        Text: t('brendon.products.orderby.model'),
        Value: 'model',
    }
];

class CategoryPage extends Component {
    state = {
        currentPage: 1,
        totalPages: 1,
        totalProducts: null,
        selectedFilters: [],
        originalSelectedFilters: [],
        products: null,
        filters: null,
        selectedOrderBy: 'stock',
        preSelectedOrderBy: 'stock',
        categoryInfo: null,
/*         brandList: null, */
        openedFilters: [],
        clickedFilter: null,
    };

    componentDidMount = async () => {
        window.addEventListener('popstate', this.refresh);
        window.addEventListener('hashchange', this.hashChange);
        // return Promise.all([this.refresh(), this.refreshCategoryInfo()]);
        this.refreshCategoryInfo();
    }

    UNSAFE_componentWillUnmount() {
        window.removeEventListener('popstate', this.refresh);
        window.removeEventListener('hashchange', this.hashChange);
    }

/*     getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.categoryId !== prevState.categoryId) {
            return {
                categoryId: nextProps.categoryId,
            };
        }
        return null;
    } */

/*     componentWillReceiveProps = (nextProps) => {
        if (nextProps.categoryId !== this.props.categoryId) {
            this.setState({
                categoryInfo: null,
                products: null,
            }, async () => {
                await this.refreshCategoryInfo();
            });
        }
    } */

    hashChange = (event) => {
        const { newURL = '', oldURL = '' } = event;
        const newHash = newURL.split('#')[1];
        const oldHash = oldURL.split('#')[1];
        if (newHash !== 'openedorder' && oldHash === 'openedorder') {
            this.closeOrderSelect();
        }
        if (newHash !== 'openedfilter' && oldHash === 'openedfilter') {
            this.cancelFilterFilterSelect();
        }
    }
    refreshCategoryInfo = async () => {
        const {categoryId} = this.props;
        const {selectedOrderBy} = this.state;
        const categoryInfo = await CategoryService.getCategoryInfo(categoryId);
/*      let brandList = null;
        if (categoryInfo !== null && categoryInfo?.CategoryType == 'ProductModels') {
            brandList = await BrandsService.getBrands()
        }
*/
        const newSelectedOrderby = categoryInfo?.ListByModels ? 'model' : (categoryInfo?.DefaultProductOrder || selectedOrderBy);
        this.setState({
            categoryInfo,
            /* brandList, */
            preSelectedOrderBy: newSelectedOrderby,
            selectedOrderBy: newSelectedOrderby, 
        }, async () => {
            // return this.categoryInfoUpdated(categoryInfo);
            // return Promise.all([this.refresh(), this.categoryInfoUpdated(categoryInfo)]);
            if (!(categoryInfo && categoryInfo?.NoProduct)) {
                return this.refresh();
            }
        });
    };
 

    refresh = (event) => {

        const {filters, orderBy = null, pageNumber = null} = queryString.parse(location.search);
        const {categoryInfo, selectedOrderBy : defaultSelectedOrderBy } = this.state;
        const refressUpdaterResult = categoryInfo !== null && (categoryInfo?.CategoryType == 'ProductModels' ||  categoryInfo?.NoProduct) ? false : true;

        const selectedFilters = filters ? filters.split(';') : [];
        const selectedOrderBy = orderBy || (categoryInfo?.ListByModels ? 'model' : (categoryInfo?.DefaultProductOrder || defaultSelectedOrderBy));
        const currentPage = pageNumber || 1;

        this.setState(state => ({
            selectedFilters,
            preSelectedOrderBy: selectedOrderBy,
            selectedOrderBy,
            currentPage,
            filters: this.getFiltersState(state.filters, selectedFilters),
        }), async () => {
            if (refressUpdaterResult) {
                return this.updateResults(true);
            }
        });
    };

    updateResults = async (doNotUpdateUrl) => {
        const {categoryId} = this.props;
        const {categoryInfo} = this.state;
        const {NoOfPages, NoOfProducts, FilterInfo, ProductInfo} = await CategoryService.getCategory(categoryId, this.state.currentPage, this.state.selectedOrderBy, this.state.selectedFilters);
        this.setState({
            totalPages: NoOfPages,
            totalProducts: NoOfProducts,
            products: ProductInfo,
            filters: FilterInfo?.map(filter => ({
                ...{...filter, Id: filter.FilterID, Name: filter.FilterName},
                FilterItems: filter.FilterOption.map(filterOption => ({
                    FilterItemState: !filterOption.Disabled ? (!filterOption.IsFilterOptionChecked ? 0 : 1) : (filterOption.IsFilterOptionChecked ? 2 : 3),
                    Id: filterOption.FilterOptionID,
                    Name: filterOption.FilterOptionName,
                    Disabled: filterOption?.Disabled || false,
                }))
            })),
        });

        if (!doNotUpdateUrl) {
            const selectedFilters = FilterInfo?.reduce((acc, filter) => {
                const filters = filter.FilterOption
                    .filter(filterOption => filterOption.IsFilterOptionChecked)
                    .map(filterOption => filterOption.FilterOptionID);
                return acc.concat(filters);
            }, []);

            this.setState({
                selectedFilters,
            });

            this.updateUrl(selectedFilters);
        }

        const impressionProducts = ProductInfo?.map(product => gaService.transformProductInfoToIpmpressionFieldObject(categoryInfo?.Name, 'Category list page', product));
        gaService.productImpressions(impressionProducts, getStoreCurrency());
        const ga4ItemListItems = ProductInfo?.map(product => GA4Service.transformProductInfoToItemListItem('category_list_page', 'Category list page', categoryInfo?.Name, product));
        GA4Service.gtagEvent('view_item_list', {
            item_list_id: 'category_list_page',
            item_list_name: 'Category list page',
            items: ga4ItemListItems
        });
    };

    updateUrl = (selectedFilters) => {
        const parsed = queryString.parse(location.search);
 
        if (selectedFilters?.length) {
            parsed.filters = selectedFilters.sort().join(';');
        } else {
            delete parsed.filters;
        }
 
        if (this.state.currentPage) {
            parsed.pageNumber = this.state.currentPage;
            parsed.pageSize = 30;
        }
        if ( this.state.selectedOrderBy) {
            parsed.orderBy =  this.state.selectedOrderBy
        }

        const query = queryString.stringify(parsed);

        const queryParameter = (query ? `?${query}` : '');

        if (queryParameter !== window.location.search) {
            const url = window.location.protocol + "//" + window.location.host + window.location.pathname + queryParameter;
            window.history.pushState({path: url}, '', url);
        }
    };

    clearFilters = () => {
        this.setState({
            selectedFilters: [],
            currentPage: 1,
        }, async () => {
            await this.updateResults();
            if (isMobile()) {
                this.scrollToFilters();
            }
        });
    };
    
    onFilterRemoved = (filterItemId) => {
        this.setState(state => {
            const newSelectedFilters = state.selectedFilters.filter(filter => filter !== filterItemId);

            return {
                selectedFilters: newSelectedFilters,
                filters: this.getFiltersState(state.filters, newSelectedFilters),
                currentPage: 1,
            }
        }, async () => {
            this.updateUrl(this.state.selectedFilters);
            await this.updateResults();

            if (isMobile()) {
                this.scrollToFilters();
            }
        });
    };


    cancelFilterFilterSelect = () => {
        this.setState(state => ({
            selectedFilters: state.originalSelectedFilters,
            openedFilters: [], /* state.originalOpenedFilters, */
            showFilterSelect: false,
        }), async () => {
            await this.updateResults();
            if (isMobile()) {
                this.scrollToFilters();
                DOMHelper.removeBodyOverflowHidden();
            }
        });
    };

    onFilterChanged = (_, filterOptionId) => {
        this.setState(state => {
            let newSelectedFilters = null;
            if (state.selectedFilters.indexOf(filterOptionId) === -1) {
                newSelectedFilters = [
                    ...state.selectedFilters,
                    filterOptionId
                ];
            } else {
                newSelectedFilters = state.selectedFilters.filter(filter => filter !== filterOptionId);
            }

            return {
                selectedFilters: newSelectedFilters,
                filters: this.getFiltersState(state.filters, newSelectedFilters),
                currentPage: 1,
            }
        }, async () => {
            this.updateUrl(this.state.selectedFilters);
            await this.updateResults();

            if (isMobile()) {
                this.scrollToFilters();
            }

        });
    };

    getFiltersState = (filters, selectedFilters) => {
        return filters?.map(filter => ({
            ...filter,
            FilterItems: filter.FilterItems.map(filterItem => ({
                ...filterItem,
                FilterItemState: selectedFilters.indexOf(filterItem.Id) !== -1 ? 1 : 0,
            }))
        }))
    };

    onPageSelected = (page) => {
        this.setState({
            currentPage: page,
        }, async () => {
            await this.updateResults();
            this.scrollToFilters();
        });
    }

    onOrderBySelected = (orderBy) => {
        this.setState({
            preSelectedOrderBy: orderBy,
            selectedOrderBy: orderBy,
            currentPage: 1,
        }, async () => {
            await this.updateResults();
            this.scrollToFilters();
        });
    };

    preSelectOrderBy = (orderBy) => {
        this.setState({
            preSelectedOrderBy: orderBy,
        });
    };
    applyOrderBy = () => {
        this.onOrderBySelected(this.state.preSelectedOrderBy);
    };

    scrollToFilters = () => {
        scrollToElement('#category-top');
    };

    checkIfFilterIsSelected = (filterId) => {
        return this.state.selectedFilters.indexOf(filterId) !== -1;
    };

    isInstantCheckableParent = (filterId) => {
        const filter = this.state.filters.find(filter => filter.Id === filterId);
        return /* !filter?.IsFilterChecked && */ (filter?.Description === null || filter?.Description === "") && filter?.IsCheckbox === true; 
    };

    onFilterButtonClicked = (id) => {
        switch (id) {
            case 'order':
                window.location.hash = '#openedorder';
                this.showOrderSelect();
                break;
            case 'all':
                window.location.hash = '#openedfilter';
                this.showFilterSelect();
                break;
            default: {
                if (this.isInstantCheckableParent(id)) {
                    this.onFilterChanged(null, id);
                } else {
                    window.location.hash = '#openedfilter';
                    this.showFilterSelect(id);
                }
            }
        }
    }
    showOrderSelect = () => {
        this.setState({
            showOrderSelect: true,
        });
    }
    closeOrderSelect = () => {
        this.setState({
            showOrderSelect: false,
            preSelectedOrderBy: this.state.selectedOrderBy
        }, async () => {
            DOMHelper.removeBodyOverflowHidden();
        })
    }
    showFilterSelect = (id) => {
        this.setState(state => ({
            showFilterSelect: true,
            clickedFilter: id,
            originalSelectedFilters: state.selectedFilters,
            openedFilters: [...state.openedFilters.filter(filterId => filterId !== id), id],
        }));
    }

    render() {
        const {
            currentPage,
            totalPages,
            totalProducts = 0,
            products,
            filters,
            selectedFilters,
            selectedOrderBy,
            preSelectedOrderBy,
            categoryInfo,
/*             showBanners,
            brandList, */
        } = this.state;

        const {
            categoryId,
/*             titleSeparator */
        } = this.props;

        const showSubcategories = () => {
            const searchParams = new URLSearchParams(window.location.search)
            const pictureCategoryDisplayed = categoryInfo?.SubCategoriesDisplayedWithPictures === true && categoryInfo?.NoProduct === false ? false : true;
            return searchParams.get('no-subcategories') === '1'?false:pictureCategoryDisplayed;
        }

        if (categoryInfo !== null) {
            switch (categoryInfo?.CategoryType) {
                case "ProductCategoryList":
                    return <ProductCategoryList categoryId={categoryId} categoryInfo={categoryInfo} showSubcategories={ showSubcategories() } />
                case "Marketing":
                    return <Marketing categoryId={categoryId} categoryInfo={categoryInfo} />
                case "ABCList":
                    return <ABCList categoryId={categoryId} categoryInfo={categoryInfo} />
                case "Subcategories":
                    return <Subcategories categoryId={categoryId} categoryInfo={categoryInfo} showSubcategories={ showSubcategories() } />
               /*  case "ProductModels":
                    if (!brandList) return <div></div>

                    return <ProductModels
                        categoryId={categoryId}
                        categoryInfo={categoryInfo}
                        titleSeparator={titleSeparator}
                        showBanners={showBanners}
                        brandList={brandList} /> */
            }
            if (categoryInfo?.SubCategoriesDisplayedWithPictures === true && categoryInfo?.NoProduct === true) {
                return <Subcategories categoryId={categoryId} categoryInfo={categoryInfo} showSubcategories={ showSubcategories() } />     
            }
        }

        const enableLeftSide =
            categoryInfo?.SubCategories?.length > 0 ||
            filters?.length > 0 ||
            categoryInfo?.RecommendedCategories?.length > 0 ||
            categoryInfo?.CheckTheseOutTooCategories?.length > 0;

        let helpPosition = null;
        if (categoryInfo?.BottomDescription && categoryInfo?.BottomDescription!=='') {
            helpPosition = 'bottom'
        } else if (categoryInfo?.TopDescription && categoryInfo?.TopDescription!='') {
            helpPosition = 'top'
        }

        const enabledSortOptions = categoryInfo?.ListByModels ? [...SORT_OPTIONS?.filter(opt => opt?.Value !== 'stock'), ...SORT_OPTIONS_MODEL] : SORT_OPTIONS

        const safeFilters = typeof filters === 'undefined' ? [] : filters 
        const highlightedFilters = safeFilters?.filter(filter => filter?.IsHighlighted)
        const enableAllFilters = highlightedFilters?.length > 1 || (highlightedFilters?.length <= 1 && safeFilters?.length > highlightedFilters?.length)

        return (categoryInfo === null || (categoryInfo?.NoProduct === false && products === null))
            ? <Loader isLoading/>
            : 
            <>
                <div>
                    <CategoryMeta categoryInfo={categoryInfo} />
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <BreadcrumbV2 breadcrumbInfos={categoryInfo?.BredcrumbInfos}/>
                        </div>
                        {categoryInfo?.CustomerHelp && <div className="col-auto d-none d-md-block">
                            <HelpButton position={ helpPosition } className="px-4" />
                        </div>}
                    </div>
                    <CategoryTitle name={categoryInfo?.Name} productCount={null}/>
                    <CountDownTimer timerDate={ categoryInfo?.TimerTime || ''} className="mb-3" />
                    <BannerZone bannerBlockId={4} categoryId={categoryId}/>
                    {categoryInfo?.TopDescription && <div className="row">
                        <div className="col-12">
                            <CategoryDescription position="top" enableHelp={ categoryInfo?.CustomerHelp } description={categoryInfo?.TopDescription}/>
                        </div>
                    </div>}
                    <BannerZone bannerBlockId={16} categoryId={categoryId}/>
                    { categoryInfo?.SubCategoriesDisplayedWithPictures  && <SubcategoriesList showSubcategories={true} categoryInfo={categoryInfo} /> }
                    <div className="d-md-none">
                        {showSubcategories() && <CategoryList categories={categoryInfo?.SubCategories} title={t('brendon.subCategories.title')}/>}
                        <CategoryList
                            className="d-block d-md-none"
                            categories={categoryInfo?.RecommendedCategories}
                            titleClassName="d-block d-md-none"
                            title={t('brendon.relatedCategories.title')}/>
                        {categoryInfo?.CustomerHelp && <HelpButton position={ helpPosition } className="w-100 mt-2 mb-4 mb-md-2"/>}
                    
                    </div>
                    <div id="category-top">
                        {Number(totalProducts) > 0 && <div className="row justify-content-between mb-4 product-selectors d-none d-md-flex mx-0 align-items-center">
                            <div className="font-size-0-875 font-weight-medium">
                                {t('brendon.filters.products.count', totalProducts)}
                                {this.state.selectedFilters?.length > 1 && <span className="ml-3 text-uppercase text-underline cursor-pointer" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</span>}
                            </div>
                            <div className="d-flex">
                                <div className="col-auto">
                                    <FilterOrderBy
                                        sortOptions={enabledSortOptions}
                                        selectedSortOption={selectedOrderBy}
                                        onOrderBySelected={this.onOrderBySelected}
                                        /* title={t('brendon.products.orderby.title')} */
                                        />
                                </div>
                                <div className="col-auto">
                                    <Pager currentPage={currentPage} totalPages={totalPages} onPageSelected={this.onPageSelected}/>
                                </div>
                            </div>
                        </div>}
                        {isNewFilterEnabled() && safeFilters?.length > 0 && <div className="d-md-none mb-4">
                            <div className="d-flex justify-content-between align-items-center mb-2-5">
                                <h3 className="font-size-1-25 mb-0">{t('brendon.products.filters.title')}:</h3>
                                {this.state.selectedFilters?.length > 1 && <span className="font-size-0-875 font-weight-medium text-uppercase text-underline cursor-pointer" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</span>}
                            </div>
                            <FilterButtonContainerWithNav onClick={this.onFilterButtonClicked} items={[
                                ...(highlightedFilters?.map(filterItem => (
                                {
                                    id: filterItem.Id,
                                    label: filterItem.Name,
                                    selected: filterItem.FilterItems.some(filterItem => filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2),
                                    icon: null,
                                    afterIcon: (filterItem?.Description && filterItem?.Description !== '') && <FontAwesomeIcon icon={faInfoCircle} />,
                                }))),
                                ...(enableAllFilters ? [{
                                    id: 'all',
                                    label: t('brendon.products.filter.all'),
                                    selected: false,
                                    icon: <CustomIcons iconName="SliderSimple" size="small" className="mr-2" />,
                                }]:[])
                                ]} />
                        </div>}
                        <FilterTags filters={filters} onFilterRemoved={this.onFilterRemoved} isNewFilterEnabled={isNewFilterEnabled()}/>
                        {isNewFilterEnabled()  && 
                        <div className="d-md-none d-flex justify-content-between font-size-0-875 font-weight-medium mb-4">
                            <FilterButton
                                onClick={this.onFilterButtonClicked} 
                                variant="link"
                                {...{
                                    id: 'order',
                                    label: enabledSortOptions.find(opt => opt.Value === this.state.selectedOrderBy)?.Text || t('brendon.products.orderby.title'),
                                    selected: false,
                                    icon: <CustomIcons iconName="ArrowDownWide" size="small" className="mr-2" /> ,
                                }} />

                            <div className="align-self-center">{t('brendon.filters.products.count', totalProducts)}</div>
                        </div>}
                    </div>
                    <div className="row">
                        {enableLeftSide && <div className="col-3">
                        {showSubcategories() &&
                            <CategoryList className="d-none d-md-block" categories={categoryInfo?.SubCategories}
                                        title={t('brendon.subCategories.title')}/>}
                            <CategoryList className="d-none d-md-block" categories={categoryInfo?.RecommendedCategories}
                                        title={t('brendon.relatedCategories.title')}/>
                            {filters?.length > 0 && <FilterPanel filters={filters}
                                        totalProducts={totalProducts}
                                        selectedFilters={selectedFilters}
                                        sortOptions={SORT_OPTIONS}
                                        selectedSortOption={selectedOrderBy}
                                        onClearFilters={this.clearFilters}
                                        onFilterChanged={this.onFilterChanged}
                                        onOrderChanged={this.onOrderBySelected}
                            />}
                            {categoryInfo?.CheckTheseOutTooCategories?.length > 0 && <CategoryList className="d-none d-md-block" categories={categoryInfo?.CheckTheseOutTooCategories}
                                        title={t('brendon.checkoutThese.title')}/>}
                        </div>}
                        <div className={`col-12 ${enableLeftSide?'col-md-9':''}`}>
                            <div className="mx-n2 border-bottom d-md-none"></div>
                            <div className="mb-4">
                                <ProductList
                                    products={products}
                                    legacy={ false } 
                                    enableLeftSide={ enableLeftSide }
                                    selectedOrderBy={selectedOrderBy}
                                    categoryName={categoryInfo?.Name}
                                    gaEEList={"Category list page"} />
                            </div>
                            <Pager className="mb-4 justify-content-center justify-content-md-end" currentPage={currentPage}
                                totalPages={totalPages} onPageSelected={this.onPageSelected}/>
                            <BannerZone bannerBlockId={17} categoryId={categoryId}/>
                            <BannerZone bannerBlockId={9} categoryId={categoryId}/>
                            {categoryInfo?.BottomDescription && <CategoryDescription position="bottom" enableHelp={ categoryInfo?.CustomerHelp } description={categoryInfo?.BottomDescription}/> }
                        </div>
                    </div>
                    <div className="d-md-none">
                        {showSubcategories() &&
                        categoryInfo?.CheckTheseOutTooCategories?.length > 0 &&
                        <RelatedCategories
                            categories={categoryInfo?.CheckTheseOutTooCategories}
                            title={t('brendon.checkoutThese.title')}
                        />}
                    </div>
                </div>
                <MobileFilterModal
                    isOpen={this.state.showFilterSelect && isNewFilterEnabled()}
                    clickedFilter={this.state.clickedFilter}
                    rightTitleChildren={
                        <div className="text-uppercase text-underline font-size-0-875 cursor-pointer" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</div>
                    }
                    handleCancel={this.cancelFilterFilterSelect}
                    handleApply={() => {
                        this.setState({
                            showFilterSelect: false,
                            openedFilters: []
                        }, async () => {
                            DOMHelper.removeBodyOverflowHidden();
                        })
                    }}
                    onSelect={(id) => {
                        this.onFilterChanged(null, id);
                    }}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    totalProducts={totalProducts}
                    openedFilters={this.state.openedFilters}
                    onOpen={(id, opened) => {
                        this.setState(state => ({
                            openedFilters: [...state.openedFilters.filter(filterId => filterId !== id), ...(opened ? [id] : [])]
                        }))
                    }}
                    fullHeight={true}
                />
                <MobileSortModal
                    isOpen={this.state.showOrderSelect && isNewFilterEnabled()}
                    handleCancel={() => {
                        this.closeOrderSelect();
                    }}
                    onSelect={(selectedOrderBy) => {
                        this.onOrderBySelected(selectedOrderBy);
                        this.setState({showOrderSelect: false}, async () => {
                            DOMHelper.removeBodyOverflowHidden();
                        });
                    }}
                    /* onSelect={this.preSelectOrderBy} */
                    sortOptions={enabledSortOptions}
                    preSelectedOrderBy={preSelectedOrderBy}
                />
            </>
    }
}

export default CategoryPage;
