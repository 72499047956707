import {put, takeLatest,} from 'redux-saga/effects'
import {success} from "../actions";
import {ADD_BLOG_POST_LIST} from "./index";
 

function* handleAddBlogPostList({result}) {
    const {success, message} = result;
/*     if(success) {
        yield put(showSuccess(message));
    } else {
        for(const m of message) {
            yield put(showError(m)); 
        }
    } */
}
 

function* blogSaga() {
    yield takeLatest(success(ADD_BLOG_POST_LIST), handleAddBlogPostList);
}

export default blogSaga;