import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import scrollToElement from "../scrollToElement";
import { useResource } from '../contexts/ResourceContext';

function HelpButton({className, position, ...props}) {
    const { t } = useResource();

    const scrollTo = () => {
        scrollToElement(`#category-help${position?`-${position}`:''}`)
    }

    return <div className={`btn btn-outline-primary btn-sm mb-2 ${className || ''}`} {...props} onClick={ scrollTo }>
        {t('Brendon.CategoryPage.Help')}
        <FontAwesomeIcon icon={faInfoCircle} className="text-primary ml-1" />
    </div>
}

export default HelpButton