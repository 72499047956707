import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { paymentMethod as paymentMethodService } from '../../../services/checkout';
import CartService from '../../../services/cart';
import { initalOptionsCommand } from '../../../utils/controller';
import OrderTotalItem from '../../../components/ShoppingCart/OrderTotalItem';
import PaymentMethodItem from '../../../components/Checkout/PaymentMethodItem';
import GiftCardRedeem from '../../../components/Checkout/GiftCardRedeem';
import Button from '../../../elements/Button';
import { useDispatch } from 'react-redux';
import { showError } from '../../../store/notification/actions';
import withStore from '../../../hoc/withStore';
import Loader from '../../../components/Loader';
import gaGA4 from '../../../services/gaGA4';
import { getStoreCurrency } from '../../../utils/localization';
import { useResource } from '../../../contexts/ResourceContext';

const CheckoutPaymentMethodPartial = ({ nextStep }) => {
    const { t } = useResource();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodTotal, setPaymentMethodTotal] = useState({
        AmountWithShippingFee: null,
        AmountToPay: null,
    });
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [enableGiftCard, setEnableGiftCard] = useState(false);
    const [giftCardCodes, setGiftCardCodes] = useState([]);
    const [noGiftCardText, setNoGiftCardText] = useState('');
    const [giftCardOpen, setGiftCardOpen] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isPaymentMethodsDisabled, setPaymentMethodsDisabled] = useState(
        false,
    );
    const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(null);
    const dispatch = useDispatch();

    const getPaymentMethods = useCallback(async () => {
        const {
            PaymentModes = [],
            InitOption = 'OK',
            IsPossiblePaymentWithGiftcard = false,
            NotPossiblePaymentWithGiftcardText = '',
            GiftCards = [],
            AmountWithShippingFee = null,
            AmountToPay = null,
            GiftCardAmountUsed = null,
            PaymentMethodDefaultId = null,
        } = await paymentMethodService.get();

        if (!initalOptionsCommand(InitOption, null, nextStep)) {
            return;
        }
        setPaymentMethods(PaymentModes);
        setPageLoading(false);

        if (!PaymentModes?.[selectedPaymentMethod] === undefined) {
            setSelectedPaymentMethod(null);
        }
        setPaymentMethodTotal({
            AmountWithShippingFee,
            GiftCardAmountUsed,
            AmountToPay,
        });
        if (IsPossiblePaymentWithGiftcard) {
            setEnableGiftCard(true);
            setGiftCardCodes(GiftCards);
            if (GiftCards.length) {
                setGiftCardOpen(true);
            }
        } else {
            setNoGiftCardText(NotPossiblePaymentWithGiftcardText);
        }
        if (Number(AmountToPay) === 0 && PaymentMethodDefaultId !== null) {
            setPaymentMethodsDisabled(true);
            setDefaultPaymentMethodId(PaymentMethodDefaultId);
        } else {
            setPaymentMethodsDisabled(false);
            setDefaultPaymentMethodId(null);
        }
    }, [
        setPaymentMethods,
        setPaymentMethodsDisabled,
        setGiftCardOpen,
        setGiftCardCodes,
        setNoGiftCardText,
        setEnableGiftCard,
        setPaymentMethodTotal,
        selectedPaymentMethod,
        setDefaultPaymentMethodId,
    ]);

    useEffect(() => {
        getPaymentMethods();
    }, []);

    const paymentMethodOnClick = useCallback(
        (paymentMethodIndex) => () =>
            setSelectedPaymentMethod(paymentMethodIndex),
        [setSelectedPaymentMethod],
    );

    const giftCardToggle = useCallback(() => {
        setGiftCardOpen((prev) => !prev);
    }, [setGiftCardOpen]);

    const giftCardChangeHandler = useCallback(() => {
        getPaymentMethods();
    }, [getPaymentMethods]);

    const ga4PaymentInfoEvent = useCallback(async (paymentType) => {
        const cartResponse = await CartService.evaluation();
        gaGA4.gtagEvent('add_payment_info', {
                ...gaGA4.transformShoppingCartEvaluetionToItem(
                    cartResponse,
                    getStoreCurrency(),
                ),
                paymentType,
        });
    }, []);

    const handleSelect = useCallback(async () => {
        setLoading(true);
        const paymentMethodId =
            paymentMethods[selectedPaymentMethod]?.Id || defaultPaymentMethodId;
        if (paymentMethodId !== null) {
            const {
                IsSuccess,
                Message,
                NextSite,
            } = await paymentMethodService.select(paymentMethodId);
            if (IsSuccess) {
                try {
                    const paymentType = paymentMethods?.find(method => method.Id === paymentMethodId)?.PaymentMethodName;
                    await ga4PaymentInfoEvent(paymentType);
                } catch (error) {
                    console.log('ga4:payment_info:', error);
                }
                nextStep(NextSite);
                return;
            } else {
                dispatch(showError(Message));
            }
        }
        setLoading(false);
    }, [selectedPaymentMethod, dispatch, nextStep, defaultPaymentMethodId]);

    const isEnableNextStep = useMemo(
        () => selectedPaymentMethod !== null || defaultPaymentMethodId !== null,
        [selectedPaymentMethod, defaultPaymentMethodId],
    );

    return (
        <>
            {isPageLoading ? (
                <Loader isLoading />
            ) : (
                <>
                    <div className="payment-methods row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row justify-content-between">
                                <div className="col-12 offset-sm-1 col-sm-10 col-md-10 offset-lg-0 col-lg-6">
                                    <div>
                                        {paymentMethods?.length > 0 && (
                                            <>
                                                <ul className="mb-4">
                                                    <GiftCardRedeem
                                                        enableGiftCard={
                                                            enableGiftCard
                                                        }
                                                        noGiftCardText={
                                                            noGiftCardText
                                                        }
                                                        giftCardCodes={
                                                            giftCardCodes
                                                        }
                                                        giftCardToggle={
                                                            giftCardToggle
                                                        }
                                                        open={giftCardOpen}
                                                        onChange={
                                                            giftCardChangeHandler
                                                        }
                                                    />
                                                    {paymentMethods?.map(
                                                        (
                                                            paymentMethod,
                                                            index,
                                                        ) => (
                                                            <React.Fragment
                                                                key={index}
                                                            >
                                                                <PaymentMethodItem
                                                                    key={index}
                                                                    disabled={
                                                                        isPaymentMethodsDisabled ||
                                                                        !paymentMethod.IsEnabled
                                                                    }
                                                                    paymentMethod={
                                                                        paymentMethod
                                                                    }
                                                                    paymentMethodIndex={
                                                                        index
                                                                    }
                                                                    selectedPaymentMethod={
                                                                        selectedPaymentMethod
                                                                    }
                                                                    onClick={paymentMethodOnClick(
                                                                        index,
                                                                    )}
                                                                />
                                                            </React.Fragment>
                                                        ),
                                                    )}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 offset-sm-1 col-sm-10 col-md-10 offset-lg-0 col-lg-6 col-xl-5">
                                    {giftCardCodes.length > 0 && (
                                        <OrderTotalItem
                                            itemName={t(
                                                'brendon.checkout.paymentmethods.total.amountwithshippingfee',
                                            )}
                                            price={
                                                paymentMethodTotal?.AmountWithShippingFee
                                            }
                                            visibleWhenPrice={true}
                                            visibleWhenItems={false}
                                        />
                                    )}
                                    <OrderTotalItem
                                        itemName={t(
                                            'brendon.checkout.paymentmethods.total.giftcard',
                                        )}
                                        price={null}
                                        visibleWhenPrice={false}
                                        visibleWhenSubItems={true}
                                        subItems={giftCardCodes?.map(
                                            (giftCard) => ({
                                                item: giftCard.GiftCardCode,
                                                price:
                                                    giftCard.GiftCardAmountUsed
                                            }),
                                        )}
                                    />
                                    <OrderTotalItem
                                        itemName={t(
                                            'brendon.checkout.paymentmethods.total.giftcardamountused',
                                        )}
                                        price={
                                            paymentMethodTotal?.GiftCardAmountUsed
                                        }
                                        visibleWhenPrice={true}
                                    />
                                    <OrderTotalItem
                                        itemName={t(
                                            'brendon.checkout.paymentmethods.total.amounttopay',
                                        )}
                                        price={paymentMethodTotal?.AmountToPay}
                                        visibleWhenPrice={false}
                                        visibleZeroPrice={true}
                                        visibleWhenItems={false}
                                    />

                                    <div>
                                        <Button
                                            name="nextstep"
                                            text={t(
                                                'brendon.checkout.paymentmethods.nextbutton',
                                            )}
                                            variant={
                                                !isEnableNextStep
                                                    ? 'gray-darker disabled'
                                                    : 'primary'
                                            }
                                            size="md"
                                            className="btn-block p-2 mt-2-5"
                                            onClick={handleSelect}
                                            disabled={!isEnableNextStep}
                                            isLoading={isLoading}
                                        />
                                        {!isEnableNextStep && (
                                            <div className="text-default text-center font-size-0-75 pt-2">
                                                {t(
                                                    'brendon.checkout.paymentmethods.notselected',
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default withStore(CheckoutPaymentMethodPartial);
