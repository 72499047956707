export function request(name) {
  return `${name}_REQUEST`
}

export function success(name) {
  return `${name}_SUCCESS`
}

export function failure(name) {
  return `${name}_FAILURE`
}

export const apiRequestAction = (name, doRequest) => () => async dispatch => {
  try {
    dispatch({ type: request(name) });
    const result = await doRequest(dispatch);
    dispatch({ type: success(name), result });
  } catch (error) {
    dispatch({ type: failure(name), error });
  }
};

export const addDataAction = (name, data) => () => async dispatch => {
  try {
    dispatch({ type: request(name) });
    dispatch({ type: success(name), data });
  } catch (error) {
    dispatch({ type: failure(name), error });
  }
}
