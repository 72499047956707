import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { giftCard as giftCardService } from '../../services/checkout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import PaymentMethodIcons from './PaymentMethodIcons';
import { formatPrice } from '../../utils/price';
import GiftCardHelper from './GiftCardHelper';
import withStore from '../../hoc/withStore';
import { showError, showSuccess } from '../../store/notification/actions';
import { useResource } from '../../contexts/ResourceContext';

const GiftCardRedeem = ({
    enableGiftCard,
    noGiftCardText,
    giftCardCodes,
    giftCardToggle,
    onChange,
    open,
    showErrorMessage,
    showSuccessMessage,
}) => {
    const [code, setCode] = useState('');
    const { t } = useResource();

    const handleChange = useCallback(
        (e) => {
            setCode(e.target.value);
        },
        [setCode],
    );

    const handleAdd = useCallback(async () => {
        if (code) {
            const response = await giftCardService.post({
                GiftCardCode: code,
            });

            if (response?.IsSuccess === true) {
                /* showSuccessMessage(response?.Message || ''); */
                setCode('');
                onChange();
            } else {
                showErrorMessage(response?.Message || '');
            }
        }
    }, [code]);

    const handleDelete = useCallback(
        (giftCardId) => async () => {
            if (giftCardId) {
                const response = await giftCardService.delete(giftCardId);
                if (response?.IsSuccess === true) {
                    showSuccessMessage(response?.Message || '');
                    onChange();
                } else {
                    showErrorMessage(response?.Message || '');
                }
            }
        },
        [],
    );
    return (
        <li
            className={`giftcard-redeem mb-2-5 py-2-5 border border-hover-dark d-flex flex-wrap align-items-center rounded cursor-pointer
                ${
                    !enableGiftCard
                        ? 'text-gray-medium border-gray-medium border-hover-gray-medium cursor-default'
                        : ''
                }`}
        >
            <div className="d-flex w-100 align-items-center">
                <PaymentMethodIcons
                    iconName={'GiftCard'}
                    containerClass={`mx-2-5`}
                    onClick={giftCardToggle}
                />
                <div
                    className={`d-flex font-size-0-875 d-flex flex-column`}
                    onClick={giftCardToggle}
                >
                    <span className={`font-weight-medium line-height-rem-1-125 ${!enableGiftCard ? 'text-body' : ''}`}>
                        {t('brendon.checkout.paymentmethods.giftcard.title')}{' '}
                        {giftCardCodes.length > 0 &&
                            ` (${giftCardCodes.length})`}
                    </span>
                    {noGiftCardText && (
                        <span
                            className="text-body font-size-0-75 line-height-rem-1-125"
                            dangerouslySetInnerHTML={{
                                __html: noGiftCardText,
                            }}
                        />
                    )}
                </div>
                {enableGiftCard && (
                    <div
                        className="flex-grow-1 mr-2-5 text-right"
                        onClick={giftCardToggle}
                    >
                        <FontAwesomeIcon
                            icon={open ? faChevronUp : faChevronDown}
                            className={`font-size-1-25`}
                        />
                    </div>
                )}
            </div>
            {enableGiftCard && (
                <div className={`w-100 px-2-5 closed ${open ? 'opened' : ''}`}>
                    <div className="row align-items-center pt-2-5">
                        <div className="col-8">
                            <input
                                className="form-control"
                                placeholder={t(
                                    'brendon.checkout.paymentmethods.giftcard.placeholder',
                                )}
                                name="code"
                                value={code}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-4">
                            <button
                                className="btn btn-xs p-1 btn-primary btn-block"
                                onClick={handleAdd}
                            >
                                {t(
                                    'brendon.checkout.paymentmethods.giftcard.apply',
                                )}
                            </button>
                        </div>
                    </div>
                    {giftCardCodes.length > 0 && (
                        <div className="mt-2-5">
                            {giftCardCodes.map((giftCard, index) => (
                                <div className="d-flex" key={index}>
                                    <div className="font-size-0-875">
                                        {t(
                                            'brendon.checkout.paymentmethods.giftcard.card',
                                        )}{' '}
                                        {giftCard?.GiftCardCode} -
                                        <span className="font-weight-bold">
                                            {Number(
                                                giftCard?.GiftCardAmountRemain,
                                            ) >= 0 && (
                                                <>
                                                    (
                                                    {t(
                                                        'brendon.checkout.paymentmethods.giftcard.remain',
                                                    )}{' '}
                                                    {formatPrice(
                                                        giftCard?.GiftCardAmountRemain,
                                                    )}
                                                    )
                                                </>
                                            )}
                                            {Number.isNaN(
                                                Number(
                                                    giftCard?.GiftCardAmountRemain,
                                                ),
                                            ) && (
                                                <>
                                                    (
                                                    {formatPrice(
                                                        giftCard?.GiftCardAmountRemain ||
                                                            giftCard?.GiftCardAmount,
                                                    )}
                                                    )
                                                </>
                                            )}
                                        </span>
                                    </div>
                                    <div className="ml-2">
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="text-primary cursor-pointer"
                                            onClick={handleDelete(
                                                giftCard?.GiftCardId,
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <GiftCardHelper />
                </div>
            )}
        </li>
    );
};

const mapDispatchToProps = (dispatch) => ({
    showErrorMessage: (message) => dispatch(showError(message)),
    showSuccessMessage: (message) => dispatch(showSuccess(message)),
});

export default withStore(connect(null, mapDispatchToProps)(GiftCardRedeem));
