import React, {Component} from 'react';

class SelectOption extends Component {

    onSelected = () => {
        const {onSelected, value} = this.props;
        onSelected(value)
    };

    render() {
        const {name, sm} = this.props;

        return <div onClick={this.onSelected} className={`pl-1 pr-4 d-flex align-items-center rounded-sm text-hover-white bg-hover-blue-medium cursor-pointer ${sm ? 'h-p25' : 'h-p30'}`}>{name}</div>
    }
}

export default SelectOption;
