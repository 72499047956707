import React, { useLayoutEffect } from "react";
import {Helmet} from "react-helmet";
 
export default ({productData, productMeta}) => {
    const {
        HtmlLang='hu',
        MetaKeywords='',
        MetaDescription='',
        GoogleBreadcrumb={},
        GoogleProduct={},
        CanonicalUrl='',
        OpenGraph={}
    } = productMeta;
    
    const {
        Image='',
        Sku='',
        ProductId='',
        Price='',
        Title,
    } = productData;
 
    useLayoutEffect(()=>{
        if (MetaKeywords != null) {
            document.querySelector('meta[name="keywords"]:not([data-react-helmet])')?.remove()
        }
        if (MetaDescription != null) {
            document.querySelector('meta[name="description"]:not([data-react-helmet])')?.remove()
        }
    })

    const renderItemList = () => {
        return GoogleBreadcrumb.ItemListElement?.map((item) => {
            return `{"@type":"ListItem",
            "position":"${item.Position}",
            "name":"${item.Name}",
            "item":"${item.Item}"
            }`
        })
    }
    return (<Helmet>
        {HtmlLang && <html lang={ HtmlLang } />}
        {Title && <title>{ Title }</title>}
        {MetaDescription != '' && <meta name="description" content={ MetaDescription } />}
        {MetaKeywords != '' && <meta name="keywords" contenct={ MetaKeywords } />}
        
        <meta property="og:type" content="product" />
        <meta property="og:title" content={ MetaKeywords } />
        <meta property="og:description" content={ OpenGraph?.OgDescription } />
        <meta property="og:image" content={ Image } />
        <meta property="og:url" content={ CanonicalUrl } />
        <meta property="og:site_name" content="Brendon" />
​
        {CanonicalUrl!='' && <link rel="canonical" href={ CanonicalUrl } />}
        {GoogleBreadcrumb?.ItemListElement && GoogleBreadcrumb.ItemListElement.length > 0 && <script type="application/ld+json">{`
            {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":[${renderItemList()}]
            }
        `}
        </script>}
        <script type="application/ld+json">{`
            {"@context":"http://schema.org/",
            "@type":"Product",
            "name":"${MetaKeywords}",
            "image":"${Image}",
            "description":"${MetaDescription}",
            "sku":"${Sku}",
            "mpn":"${ProductId}",
            "brand":{
            "@type":"Thing",
            "name": "Brand"},
            "offers":{
            "@type":"Offer",
            "priceCurrency":"${GoogleProduct?.Offers?.PriceCurrency}",
            "price":"${Price}",
            "itemCondition":"https://schema.org/NewCondition",
            "url":"${CanonicalUrl}",
            "priceValidUntil":"${GoogleProduct?.Offers?.PriceValidUntil}",
            "availability":"http://schema.org/InStock",
            "seller":{
            "@type":"Organization",
            "name":"${GoogleProduct?.Offers?.Seller?.Name}",
            "url":"${GoogleProduct?.Offers?.Seller?.Url}"}}}
        `}</script>
    </Helmet>)
}