import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShoppingCart,
    faCreditCard,
    faClipboardCheck,
    faAddressCard,
    faTruck,
} from '@fortawesome/pro-light-svg-icons';
import { CHECKOUT_STEPS as STEPS } from '../../models/constants';

const CartCheckoutProgress = ({
    checkoutProgressStep,
    stepTitles,
    stepHandlers,
    children,
}) => {
    const active = (step) =>
        step < checkoutProgressStep && checkoutProgressStep != STEPS.COMPLETE;
    const isCurrent = (step) => step == checkoutProgressStep;

    const activeClass = 'border-primary';
    const inactiveClass = 'border-transparent';
    const linkClass =
        'border-bottom d-block font-weight-bold text-uppercase font-size-0-625 font-size-md-0-75 font-size-lg-0-875 px-2 py-1 py-sm-2';
    const lastIconClass = 'text-primary';
    const inactiveLinkClass = 'cursor-default text-hover-gray-medium';
    const notSelectedLinkClass =
        'border-transparent text-gray-medium text-hover-primary';
    const lastLinkClass = 'text-primary border-primary';

    const handlerCart = active(STEPS.CART) ? stepHandlers?.cart : void 0;
    const handlerAddress = active(STEPS.ADDRESS)
        ? stepHandlers?.address
        : void 0;
    const handlerShipping =
        active(STEPS.SHIPPING) ||
        active(STEPS.DELIVERYMODE) ||
        active(STEPS.SHOPLIST)
            ? stepHandlers?.shipping
            : void 0;
    const handlerPaymentMethod = active(STEPS.PAYMENT)
        ? stepHandlers?.payment
        : void 0;
    const handlerConfirm = active(STEPS.CONFIRM)
        ? stepHandlers?.confirm
        : void 0;

    const RenderStep = ({
        isSelected,
        isNotActive,
        clickHandler,
        faIcon,
        title,
    }) => {
        return (
            <div
                className={`col border-bottom ${
                    isSelected ? activeClass : inactiveClass
                }`}
            >
                <div
                    className={`border-bottom ${
                        isSelected ? 'border-primary' : 'border-gray-light'
                    }`}
                >
                    <a
                        className={`${linkClass} ${
                            isNotActive && !isSelected
                                ? inactiveLinkClass
                                : 'cursor-pointer'
                        } ${isSelected ? lastLinkClass : notSelectedLinkClass}`}
                        onClick={clickHandler}
                    >
                        <FontAwesomeIcon
                            icon={faIcon}
                            className={`mb-1 font-size-1-875 d-block mx-auto ${
                                isSelected ? lastIconClass : ''
                            }`}
                        />
                        <span className="d-none d-sm-block">{title}</span>
                    </a>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="row justify-content-center text-center text-gray-medium mt-2 mt-md-5 mb-4 sticky-checkout">
                <div className="col-12 col-md-10">
                    <div className="row no-gutters flex-nowrap overflow-hidden">
                        <RenderStep
                            isSelected={isCurrent(STEPS.CART)}
                            isNotActive={
                                !active(STEPS.CART) && !isCurrent(STEPS.CART)
                            }
                            clickHandler={handlerCart}
                            faIcon={faShoppingCart}
                            title={stepTitles?.cart}
                        />
                        <RenderStep
                            isSelected={isCurrent(STEPS.ADDRESS)}
                            isNotActive={
                                !active(STEPS.ADDRESS) &&
                                !isCurrent(STEPS.ADDRESS)
                            }
                            clickHandler={handlerAddress}
                            faIcon={faAddressCard}
                            title={stepTitles?.address}
                        />

                        <RenderStep
                            isSelected={
                                isCurrent(STEPS.SHIPPING) ||
                                isCurrent(STEPS.DELIVERYMODE) ||
                                isCurrent(STEPS.SHOPLIST)
                            }
                            isNotActive={
                                !active(STEPS.SHIPPING) &&
                                !isCurrent(STEPS.SHIPPING) &&
                                !active(STEPS.DELIVERYMODE) &&
                                !isCurrent(STEPS.DELIVERYMODE) &&
                                !active(STEPS.SHOPLIST) &&
                                !isCurrent(STEPS.SHOPLIST)
                            }
                            clickHandler={handlerShipping}
                            faIcon={faTruck}
                            title={stepTitles?.shipping}
                        />

                        <RenderStep
                            isSelected={isCurrent(STEPS.PAYMENT)}
                            isNotActive={
                                !active(STEPS.PAYMENT) &&
                                !isCurrent(STEPS.PAYMENT)
                            }
                            clickHandler={handlerPaymentMethod}
                            faIcon={faCreditCard}
                            title={stepTitles?.payment}
                        />

                        <RenderStep
                            isSelected={isCurrent(STEPS.CONFIRM)}
                            isNotActive={
                                !active(STEPS.CONFIRM) &&
                                !isCurrent(STEPS.CONFIRM)
                            }
                            clickHandler={handlerConfirm}
                            faIcon={faClipboardCheck}
                            title={stepTitles?.confirm}
                        />

                     {/*    <RenderStep
                            isSelected={isCurrent(STEPS.COMPLETE)}
                            isNotActive={
                                !active(STEPS.COMPLETE) &&
                                !isCurrent(STEPS.COMPLETE)
                            }
                            clickHandler={handlerConfirm}
                            faIcon={faCheckCircle}
                            title={stepTitles?.complete}
                        /> */}
                    </div>
                    {children}
                </div>
            </div>

            <div className="font-size-1-5 font-weight-bold line-height-3 text-center text-uppercase mb-4 d-sm-none">
                {isCurrent(STEPS.CART) ? stepTitles?.cart : ''}
                {isCurrent(STEPS.ADDRESS) ? stepTitles?.address : ''}
                {isCurrent(STEPS.PAYMENT) ? stepTitles?.payment : ''}
                {isCurrent(STEPS.CONFIRM) ? stepTitles?.confirm : ''}
{/*                 {isCurrent(STEPS.COMPLETE) ? stepTitles?.complete : ''} */}
            </div>
        </>
    );
};

export default CartCheckoutProgress;
