import React, {Component} from 'react';
import SubPage from "./SubPage";
import Subscriber from "./Subscriber";
import Page from "./Page";
import Content from "./Content";
import sendEvent from "../../tracking";
import withResource from '../../hoc/withResource';

class PregnantPage extends Component {
    state = {
        page: 1,
    };
    
    secondPage = () => {
        this.setState({page: 2});
        sendEvent('intro', 'page', 'kit');
    };

    render() {
        const {onClose, t} = this.props;
        const {page} = this.state;
        return <SubPage imageNumber="1" verticalPosition={90} currentPage={page} maxPage={2}>
            {page === 1 &&
            <Page title={t('brendon.intro.pregnant.newsletter.title')}>
                <Subscriber
                    subscribeButton={t('brendon.intro.pregnant.newsletter.subscribe')}
                    success={t('brendon.intro.pregnant.newsletter.success')}
                    description={t('brendon.intro.pregnant.newsletter.description')}
                    emailPlaceholder={t('brendon.intro.pregnant.newsletter.email')}
                    datePlaceholder={t('brendon.intro.pregnant.newsletter.date')}
                    failText={t('brendon.intro.pregnant.newsletter.failed')}
                    retryText={t('brendon.intro.pregnant.newsletter.retry')}
                    subscribeText={t('brendon.intro.pregnant.newsletter.subscribe')}
                    pregnancyNewsletter={true}
                    onSkip={this.secondPage}
                />
            </Page>
            }
            {page === 2 &&
            <Page
                title={t('brendon.intro.pregnant.kit.title')}
            >
                <Content primaryButton={t('brendon.intro.pregnant.kit.linkText')}
                         link={t('brendon.intro.pregnant.kit.link')}
                         onSkip={onClose}>
                    <span dangerouslySetInnerHTML={{__html: t('brendon.intro.pregnant.kit.description')}}/>
                </Content>
            </Page>
            }
        </SubPage>
    }
}

export default withResource(PregnantPage);
