import React, { useCallback, useState, useEffect, useMemo } from 'react';
import VariantComboList from '../../../components/Elements/ComboList/VariantComboList';
import { faTruck } from '@fortawesome/pro-regular-svg-icons';
import { faStoreAlt } from '@fortawesome/pro-solid-svg-icons';
import { deliveryMode as DeliveryModeService } from '../../../services/checkout';
import CheckoutStepTitle from '../../../components/Checkout/CheckoutStepTitle';
import { showError } from '../../../store/notification/actions';
import { useDispatch } from 'react-redux';
import { initalOptionsCommand } from '../../../utils/controller';
import Loader from '../../../components/Loader';
import gaGA4 from '../../../services/gaGA4';
import CustomIcons from '../../../components/Elements/CustomIcons/CustomIcons';
import { useResource } from '../../../contexts/ResourceContext';

const DeliveryMode = ({ step, changeStep, nextStep }) => {
    const { t } = useResource();
    const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(null);
    const [deliveryModes, setDeliveryModes] = useState([]);
    const dispatch = useDispatch();

    const getDeliveryMode = useCallback(async () => {
        const { InitOption, DeliveryModes } = await DeliveryModeService.get();
        if (!initalOptionsCommand(InitOption, null, nextStep)) {
            return;
        }
        setDeliveryModes(DeliveryModes);
    }, [setDeliveryModes]);

    useEffect(() => {
        getDeliveryMode();
    }, []);

    const Icons = useMemo(
        () => ({
            HomeDelivery: faTruck,
            StoreDelivery: faStoreAlt,
        }),
        [],
    );

    const selectHandler = useCallback(
        (index) => () => setSelectedDeliveryMode(index),
        [setSelectedDeliveryMode],
    );

    useEffect(() => {
        const deliveryModeSelectRequest = async (deliveryModeId) => {
            const {
                IsSuccess,
                Message,
                NextSite,
            } = await DeliveryModeService.select(deliveryModeId);
            if (IsSuccess) {
                gaGA4.gtagEvent('checkout_delivery_mode_selected');
                nextStep(NextSite);
                return;
            } else {
                dispatch(showError(Message));
            }
        };

        const { Id: deliveryModeId = null } =
            deliveryModes[selectedDeliveryMode] || {};

        if (deliveryModeId !== null) {
            deliveryModeSelectRequest(deliveryModeId);
        }
    }, [selectedDeliveryMode, dispatch, nextStep, showError, deliveryModes]);

    return (
        <>
            {deliveryModes.length === 0 ? (
                <Loader isLoading />
            ) : (
                <>
                    <CheckoutStepTitle
                        description={t(
                            'brendon.checkout.deliverymode.description',
                        )}
                    />
                    <div className="row justify-content-center">
                        <VariantComboList
                            variant="link"
                            items={deliveryModes.map(
                                ({
                                    DeliveryMethodName,
                                    DeliveryMethodDescription,
                                    IsEnabled,
                                    IconName,
                                }) => ({
                                    name: DeliveryMethodName,
                                    description: DeliveryMethodDescription,
                                    isEnabled: IsEnabled,
                                    icon: Icons[IconName],
                                    customIconChildren: !Icons[IconName] && (
                                        <CustomIcons
                                            iconName={IconName}
                                        />
                                    )
                                }),
                            )}
                            onSelect={selectHandler}
                            containerClassName="col-12 col-sm-8 col-md-8 col-lg-6"
                            containerHoverClassName="border-hover-dark"
                            textHoverClassName="text-dark"
                            itemTextClassName="mr-2-5"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default DeliveryMode;
