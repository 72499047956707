import React, { useState, useRef, useCallback } from 'react';
import SelectorPage from './SelectorPage';
import PregnantPage from './PregnantPage';
import ParentPage from './ParentPage';
import GiftPage from './GiftPage';
import Cookies from 'js-cookie';
import sendEvent from '../../tracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { useResource } from '../../contexts/ResourceContext';

const Intro = () => {
  const { t } = useResource();
  const [opened, setOpened] = useState(
    t('brendon.intro.cookie.link') !== window.location.pathname,
  );
  const [type, setType] = useState('');
  const hide = Cookies.get('is-cookies-accepted');
  const modalContainer = useRef(null);

  const close = useCallback(
    (overlay = false) => {
      setOpened(false);
      Cookies.set('is-cookies-accepted', true);
      sendEvent('intro', 'close', overlay ? 'overlay' : 'button');
    },
    [setOpened],
  );

  const closeOnOverlay = useCallback(
    (e) => {
      if (!modalContainer.current.contains(e.target)) {
        close(true);
      }
    },
    [modalContainer, close],
  );

  const select = useCallback(
    (selectedType) => {
      sendEvent('intro', 'select', selectedType);
      setType(selectedType);
    },
    [setType],
  );

  if (hide) {
    return null;
  }

  return (
    <>
      {opened ? (
        <div
          className="position-fixed top left vw-100 vh-100 bg-transparent-dark zindex-9000"
          onClick={closeOnOverlay}
        >
          <div className="container h-100 d-flex align-items-center">
            <div className="row flex-fill justify-content-center">
              <div className="col-12 col-sm-10 col-lg-8 col-xl-6">
                <div
                  style={{ minHeight: '350px' }}
                  className="bg-white rounded-lg shadow-lg position-relative d-flex flex-column"
                  ref={modalContainer}
                >
                  {!type && <SelectorPage onSelect={select} />}
                  {type === 'pregnant' && <PregnantPage onClose={close} />}
                  {type === 'parent' && <ParentPage onClose={close} />}
                  {type === 'gift' && <GiftPage onClose={close} />}
                  <div
                    className="position-absolute right top mt-2 mr-2 text-primary text-hover-blue-medium cursor-pointer d-flex line-height-3"
                    onClick={close}
                  >
                    {!type && (
                      <span className="font-size-0-75 text-uppercase mr-2">
                        {t('brendon.intro.close')}
                      </span>
                    )}
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={`${
                        type ? 'font-size-1' : 'font-size-2'
                      } line-height-3`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Intro;
