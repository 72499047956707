import axios from 'axios';

async function getBanners(bannerBlockId, options = {}) {
    const {
        categoryId = null,
        topicId = null,
        productId = null
    } = options;

    const {data} = await axios.post('/Banner/GetBannerBlock', {
        BannerblockID: bannerBlockId,
        CategoryID: categoryId,
        TopicID: topicId,
        ProductID: productId,
    });
    
    const bannerZones = [];
    
    let lastBannerTypeId = null;
    
    data?.Banners?.forEach(banner => {
        if(banner.BannerTypeID !== lastBannerTypeId) {
            lastBannerTypeId = banner.BannerTypeID;
            bannerZones.push([]);
        }
        bannerZones.slice(-1)[0].push(banner);
    });

    data.BannerZones = bannerZones;
    
    return data;
}

export const BannerService = {
    getBanners,
};

export default BannerService;