import React from "react";
import { useResource } from '../contexts/ResourceContext';

function CategoryTitle({name, productCount}) {
    const { t } = useResource();

    return <div className="row">
        <div className="col-12">
            <h1 className="mb-4">
                <span className="font-size-1-5 font-weight-bold">{name}</span>
                {productCount !== null && <span
                    className="font-weight-light font-size-0-75 ml-2">({productCount}&nbsp;{t('brendon.products.piecesText')})</span>}
            </h1>
        </div>
    </div>
}

export default CategoryTitle;