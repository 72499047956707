import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import { useParams } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

const ForgotPasswordConfirmScreen = () => {
    const { t } = useResource();
    const { token, email } = useParams();
    const [disableFrom, setDisableForm] = useState(false);
    const [message, setMessage] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const onSubmitHandler = (data) => {
        console.log(data);
        // if error
        // setDisableForm(true);
        // setMessage(response.message)

        // if success
        setDisableForm(true);
        setMessage(t('account.passwordrecovery.passwordhasbeenchanged'));
    };

    useEffect(() => {
        if (token && email) {
            // check the token validation
            // if not valid
            setDisableForm(true);
            setMessage(
                t('account.passwordrecovery.passwordalreadyhasbeenchanged'),
            );
        }
    }, [token, email]);
    return (
        <>
            <MetaHeader title={t('PageTitle.PasswordRecovery')} showBrendonAddon />
            <div class="row justify-content-center">
                <div class="col-12 col-sm-8 col-md-6 col-lg-4 mt-5">
                    <h1 class="font-size-1-125 mb-4 text-center">
                        {t('Account.PasswordRecovery')}
                    </h1>
                    {message && <div class="text-center">{message}</div>}

                    {!disableFrom && (
                        <form
                            autoComplete="off"
                            className="font-size-0-75"
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >
                            <div class="row form-group multiline">
                                <div class="col-12 text-gray d-flex justify-content-between">
                                    <div>
                                        <label htmlFor="newPassword">
                                            {t(
                                                'account.passwordrecovery.newpassword',
                                            )}
                                            : *
                                        </label>
                                    </div>
                                    {errors?.newPassword && (
                                        <span className="field-validation-error">
                                            {t(
                                                'account.passwordrecovery.newpassword.required',
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div class="col-12">
                                    <input
                                        name="newPassword"
                                        class={classNames(
                                            'form-control',
                                            errors?.newPassword &&
                                                'input-validation-error',
                                        )}
                                        {...register('newPassword', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>

                            <div class="row form-group multiline">
                                <div class="col-12 text-gray d-flex justify-content-between">
                                    <div>
                                        <label htmlFor="confirmNewPassword">
                                            {t(
                                                'account.fields.confirmpassword',
                                            )}
                                            : *
                                        </label>
                                    </div>
                                    {errors?.confirmNewPassword && (
                                        <span className="field-validation-error">
                                            {t(
                                                'account.passwordrecovery.confirmnewpassword.required',
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div class="col-12">
                                    <input
                                        name="confirmNewPassword"
                                        class={classNames(
                                            'form-control',
                                            errors?.confirmNewPassword &&
                                                'input-validation-error',
                                        )}
                                        {...register('confirmNewPassword', {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </div>

                            <input
                                type="submit"
                                name="set-password"
                                class="btn btn-primary w-100 mb-4"
                                value={t(
                                    'Account.PasswordRecovery.ChangePasswordButton',
                                )}
                            />

                            <div class="font-italic text-gray">
                                {t('brendon.form.requiredField')}
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordConfirmScreen;
