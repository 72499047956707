import {object, string, number} from 'yup';
import { legacy_t as t} from '../contexts/ResourceContext';
import { isValidEmail, isPriceValid } from '../utils';


function GiftCardValidationSchema(enteredPriceMin, enteredPriceMax, enableDecimal) {
    return object({
        RecipientName: string()
            .required(t('brendon.giftcard.fields.recipientName.required'))
            .max(45,t('brendon.giftcard.fields.recipientName.required.lengthvalidation').replace('#0#',45)),
        SenderName: string()
            .required(t('brendon.giftcard.fields.senderName.required'))
            .max(45,t('brendon.giftcard.fields.senderName.required.lengthvalidation').replace('#0#',45)),
        RecipientEmail: string()
            .required(t('brendon.giftcard.fields.email.required'))
            .test('Email', t('brendon.giftcard.fields.email.wrongemail'), isValidEmail)
            .max(200,t('brendon.giftcard.fields.email.lengthvalidation').replace('#0#',200)),
        SenderEmail: string()
            .required(t('brendon.giftcard.fields.email.required'))
            .test('Email', t('brendon.giftcard.fields.email.wrongemail'), isValidEmail)
            .max(200,t('brendon.giftcard.fields.email.lengthvalidation').replace('#0#',200)),
        Message: string()
            .max(1000,t('brendon.giftcard.fields.message.lengthvalidation').replace('#0#',1000)),
        Amount: number()
            .typeError(t('brendon.giftcard.fields.enteredPrice.mustbenumber'))
            .required(t('brendon.giftcard.fields.enteredPrice.required'))
            .test('EnteredPrice', t('brendon.giftcard.fields.enteredPrice.onlyinteger'), (price) => isPriceValid(price, enableDecimal))
            .min(enteredPriceMin,t('brendon.giftcard.fields.enteredPrice.min').replace('#0#',enteredPriceMin))
            .max(enteredPriceMax,t('brendon.giftcard.fields.enteredPrice.max').replace('#0#',enteredPriceMax)),
    });
}

export default GiftCardValidationSchema; 