import React, {Component} from "react";
import SquareCheckbox from "../../elements/SquareCheckbox";
import gaGA4 from '../../services/gaGA4';

class CheckoutConfirm extends Component {
    state = {
        termsAndConditionsAccepted: false,
        showHint: false,
        submitted: false,
    };

    onChange = (accepted) => {
        this.setState({
            termsAndConditionsAccepted: accepted,
            showHint: !accepted && this.state.showHint,
        })
    };

    handleSubmit = (event) => {
        if (this.state.submitted) {
            event.preventDefault();
        } else {
            if (!this.state.termsAndConditionsAccepted) {
                this.setState({
                    showHint: true
                });
                event.preventDefault();
            } else {
                gaGA4.gtagEvent('checkout_confirm');
                this.setState({
                    submitted: true,
                });
            }   
        }
    };

    render() {
        const {
            antiforgery,
            confirmButtonTitle,
            acceptText,
            hint,
        } = this.props;

        const {
            termsAndConditionsAccepted,
            showHint,
        } = this.state;

        return <form action="/checkout/confirm" method="post" onSubmit={this.handleSubmit}>
            <div className="mb-3">
                <SquareCheckbox value={termsAndConditionsAccepted} onChange={this.onChange}>
                    <div className="font-size-0-75 text-gray" dangerouslySetInnerHTML={{__html: acceptText}}/>
                </SquareCheckbox>
            </div>
            {showHint && <div className="text-danger font-size-0-75 mb-3">
                {hint}
            </div>}
            <input type="submit" name="nextstep" value={confirmButtonTitle}
                   className="btn btn-lg btn-primary w-100 rounded"/>
          {/*   <input type="hidden" name={antiforgery.FormFieldName} value={antiforgery.RequestToken}/> */}
        </form>
    }
}

export default CheckoutConfirm;
