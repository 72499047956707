import React, { Component } from 'react';
import withStore from '../../hoc/withStore';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    selectSearchQuery,
    selectSearchResult,
} from '../../store/search/selectors';
import {
    changeSearchQuery,
    changeNewQueryMode,
} from '../../store/search/actions';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import OutsideClickDetector from '../../elements/OutsideClickDetector';
import SearchSection from './SearchSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { SEARCH_INPUT_MAX_LENGTH } from '../../models/constants';
import { getURLPath } from '../../utils/staticPaths';
import withResource from '../../hoc/withResource';

const Input = styled.input`
    ${(props) =>
        props.opened
            ? `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
`
            : ''}
`;

const Form = styled.form`
    height: 35px;
`;

const DropdownWrapper = styled.div`
    transition: all 250ms ease-in-out;
`;

class SearchAutoCompleteBox extends Component {
    state = {
        focused: false,
        selectedItem: {
            groupIndex: null,
            itemIndex: null,
        },
    };
    componentDidMount() {
        const { setNewQueryMode } = this.props;
        setNewQueryMode(true);
    }
    onChangeQuery = (e) => {
        const { changeSearchQuery } = this.props;
        if (e.target.value.length <= SEARCH_INPUT_MAX_LENGTH) {
            this.setSelectedItem(null, null);
            changeSearchQuery(e.target.value);
        }
    };

    focus = () => {
        this.setState({
            focused: true,
        });
    };

    blur = () => {
        this.setState({
            focused: false,
        });
    };

    submit = (e) => {
        const { selectedItem } = this.state;
        // console.log('submit selectedItem', selectedItem)
        if (
            selectedItem.groupIndex === null ||
            selectedItem.itemIndex === null
        ) {
            if (this.props.query.length < 3) {
                e.preventDefault();
            }
            return false;
        }

        e.preventDefault();
        window.location = this.getSelectedURL();
        return false;
    };

    onKeyDown = (e) => {
        const { results } = this.props;
        const { items = {} } = results || {};
        const {
            selectedItem: { groupIndex, itemIndex },
        } = this.state;
        const maxCountsInGroups = {};
        const maxGroups = Object.keys(items).length;
        Object.entries(items).forEach(([groupIndex, groupItems]) => {
            maxCountsInGroups[groupIndex] = groupItems.length;
        });
        // console.log('onKeyDown selectedItem',  { groupIndex, itemIndex }, e.key)
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (groupIndex === null || itemIndex === null) {
                this.setSelectedItem(0, 0);
            } else {
                if (itemIndex + 1 < maxCountsInGroups[groupIndex]) {
                    this.setSelectedItem(groupIndex, itemIndex + 1);
                } else if (groupIndex + 1 < maxGroups) {
                    this.setSelectedItem(groupIndex + 1, 0);
                } else {
                    this.setSelectedItem(0, 0);
                }
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (groupIndex === null || itemIndex === null) {
                this.setSelectedItem(0, 0);
            } else {
                if (itemIndex - 1 >= 0) {
                    this.setSelectedItem(groupIndex, itemIndex - 1);
                } else if (groupIndex - 1 >= 0) {
                    this.setSelectedItem(
                        groupIndex - 1,
                        maxCountsInGroups[groupIndex - 1] - 1,
                    );
                } else {
                    this.setSelectedItem(
                        maxGroups - 1,
                        maxCountsInGroups[maxGroups - 1] - 1,
                    );
                }
            }
        } /* else if (groupIndex > 0 && e.key !== 'Enter') {
            this.removeSelectedItem();
        } */
        return true;
    };

    removeSelectedItem = () => {
        this.setState({
            selectedItem: {
                groupIndex: null,
                itemIndex: null,
            },
        });
    };

    setSelectedItem = (groupIndex, itemIndex) => {
        // console.log('setSelectedItem', groupIndex, itemIndex)
        this.setState({
            selectedItem: {
                groupIndex: groupIndex !== null ? Number(groupIndex) : null,
                itemIndex: itemIndex !== null ? Number(itemIndex) : null,
            },
        });
    };

    getSelectedURL = () => {
        const { results } = this.props;
        const { items = {} } = results || {};
        const {
            selectedItem: { groupIndex, itemIndex },
        } = this.state;

        return groupIndex === null && itemIndex === null
            ? null
            : items[groupIndex]?.[itemIndex]?.Slug;
    };

    mouseLeaveHandler = () => {
        this.removeSelectedItem();
        this.focus();
    };
    render() {
        const {
            query,
            results,
            t,
        } = this.props;
        const { itemGroups = [], items = {}, moreItems = {} } = results || {};
        const { focused, selectedItem } = this.state;
        const hasResults = results && itemGroups.length > 0;
        const opened = query.length >= 3 && focused && hasResults;

        return (
            <Form action={getURLPath('search')} method="get" onSubmit={this.submit}>
                <DropdownWrapper
                    className={`position-relative rounded zindex-1000 ${
                        opened ? 'shadow' : ''
                    }`}
                >
                    <OutsideClickDetector
                        onClick={this.focus}
                        onClickOutside={this.blur}
                        className="position-relative"
                    >
                        <Input
                            type="text"
                            className={`form-control pr-5 border shadow-none`}
                            opened={opened}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            name="q"
                            placeholder={t("Search.SearchBox.Tooltip")}
                            aria-label={t("Search.SearchBox.Text.Label")}
                            value={query}
                            onChange={this.onChangeQuery}
                            onFocus={this.focus}
                            onKeyDown={this.onKeyDown}
                            maxLength={SEARCH_INPUT_MAX_LENGTH}
                        />
                        <button
                            type="submit"
                            className="position-absolute top right border-0 bg-transparent h-100 p-0 w-p40"
                            value="Search"
                            aria-label={t("Search.SearchBox.Tooltip")}
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </OutsideClickDetector>
                    {opened && (
                        <div
                            className="bg-white border-left border-right border-bottom rounded-bottom position-relative zindex-7000 p-2-5"
                            onMouseLeave={this.mouseLeaveHandler}
                        >
                            {Object.entries(itemGroups).map(
                                ([groupIndex, groupData]) => (
                                    <SearchSection
                                        key={groupIndex}
                                        groupIndex={groupIndex}
                                        title={groupData?.Title}
                                        selectedIndex={
                                            Number(selectedItem?.groupIndex) ===
                                            Number(groupIndex)
                                                ? selectedItem?.itemIndex
                                                : null
                                        }
                                        onHover={(groupIndex, itemIndex) => {
                                            // console.log('onHover', groupIndex, itemIndex)
                                            this.setSelectedItem(
                                                groupIndex,
                                                itemIndex,
                                            )
                                            }
                                        }
                                        items={
                                            Array.isArray(items[groupIndex]) &&
                                            items[groupIndex].map((item) => ({
                                                name: item?.Title,
                                                slug: item?.Slug,
                                                type: item?.Type || 'Item',
                                            }))
                                        }
                                     /*    moreItems={moreItems[groupIndex]} */
                                    />
                                ),
                            )}
                            {/*    <SearchSection title={topCategoriesTitle}
                                   selectedIndex={index - 1}
                                   onHover={(index) => this.setIndex(index + 1)}
                                   items={results.Categories.map(category => ({
                                       id: category.CategoryId,
                                       name: category.CategoryName,
                                       slug: category.SlugURL,
                                   }))}/>
                    <SearchSection title={topProductsTitle}
                                   selectedIndex={index - 1 - results.Categories.length}
                                   onHover={(index) => this.setIndex(index + 1 + results.Categories.length)}
                                   items={results.Products.map(product => ({
                                       id: product.ProductId,
                                       name: product.Name,
                                       slug: product.Slug,
                                   }))}/> */}
                        </div>
                    )}
                </DropdownWrapper>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    query: selectSearchQuery(state),
    results: selectSearchResult(state),
});

const mapDispatchToProps = (dispatch) => ({
    setNewQueryMode: (mode) => dispatch(changeNewQueryMode(mode)),
    changeSearchQuery: (query) => dispatch(changeSearchQuery(query)),
});

export default withResource(withStore(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withImmutablePropsToJS(SearchAutoCompleteBox)),
));
