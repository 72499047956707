const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// TODO: need remove one of email validation
export const validateEmail = (input) => EMAIL_REGEX.test(input);
export const isValidEmail = async (email) =>
  email?.match(
    /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@(([a-zA-Z0-9-]+\.)+(([a-zA-Z0-9-]+){2,}))))*$/,
  )
    ? true
    : false;
export const isSpaceFilled = (value) =>
  value.replace(/ /g, '') !== '' ? value : '';
export const isValidPhone = async (phone) =>
  phone?.match(/^(\+)?([0-9]){8,18}$/) ? true : false;
export const isValidHungarianPhone = async (phone) =>
  phone?.match(/^(\+)?(36|06)(20|30|31|50|70)([0-9]){7}$/) ? true : false;
export const isValidSlovakianMobilePhone = async (phone) =>
  phone?.match(/^(\+421)(9)(([0,1,3,4][0-9])|(5[0-8]))([0-9]){6}$/)
    ? true
    : false;

export default {
  validateEmail,
  isSpaceFilled,
  isValidEmail,
  isValidPhone,
  isValidHungarianPhone,
  isValidSlovakianMobilePhone,
};
