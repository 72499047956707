import React from 'react';
import { legacy_t as t} from '../contexts/ResourceContext';

// TODO: change to text  props
const ProductNotAvailable = () => (<div className="mx-n2 mx-md-0">
        <div className="overflow-hidden">
            <div className="row mb-3 mb-md-0">
              <div className="p-2 text-center text-primary font-size-1-125 flex-fill">
                  {t('Brendon.Product.CurrentlyNotAvailable')}
              </div>
            </div>
        </div>
    </div>)

export default ProductNotAvailable;