import axios from 'axios';

async function getMenu() {
    const {data} = await axios.get('/BrendonAjax/GetTopMenu');
    return data;
}

export const MenuService = {
    getMenu,
};

export default MenuService;
