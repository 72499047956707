import React, { useState, useCallback } from 'react';
import { redirectToUrl } from '../../utils/controller';
import CircleLink from './CircleLink';

const LinkWithCircleArrow = ({ href, text, className }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, [setIsHovered]);

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, [setIsHovered]);

    const handleClick = useCallback(() => {
        redirectToUrl(href);
    }, [href]);

    return (
        <div
            className={`d-flex justify-between justify-content-between align-items-center py-2 px-2 rounded border ${
                isHovered ? 'cursor-pointer' : ''
            } ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <div>
                <a
                    href={`${href}`}
                    className="font-size-0-75 text-gray-dark font-weight-medium"
                >
                    {text}
                </a>
            </div>
            <div>
                <CircleLink
                    className={`${
                        isHovered ? 'move-in' : 'move-out'
                    } ml-auto cursor-pointer bg-gray-dark text-white`}
                    disabled={true}
                    animation={true}
                />
            </div>
        </div>
    );
};

export default LinkWithCircleArrow;
