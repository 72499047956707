import React, { useState, useCallback, useMemo } from 'react';
import CircleLink from '../Elements/CircleLink';
import { redirectToUrl } from '../../utils/controller';
import { getDomainStoreId } from '../../utils/localization';
import { useResource } from '../../contexts/ResourceContext';

const ShopCard = ({ index, shop }) => {
    const { t } = useResource();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, [setIsHovered]);

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, [setIsHovered]);

    const isEven = useMemo(() => index % 2 === 0, [index]);

    const fullAddress = useMemo(
        () =>
            getDomainStoreId() == 1
                ? `${shop?.City} ${shop?.Postcode} ${shop?.Address}`
                : `${shop?.Address} ${shop?.Postcode} ${shop?.City}`,
        [shop],
    );

    return (
        <div
            className={`${isEven ? 'mr-lg-2' : 'ml-lg-2'} 
            ${
                isHovered ? 'cursor-pointer' : ''
            } mb-3 p-2-5 bg-gray-light d-flex flex-column flex-sm-row rounded-lg`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => redirectToUrl(`/${shop?.Slug}`)}
        >
            {shop?.PictureUrl && (
                <img
                    src={shop?.PictureUrl}
                    className="img-150 rounded"
                    alt={shop?.StoreName}
                    title={shop?.StoreName}
                />
            )}
            <div className="flex-1 ml-sm-4 font-size-1 line-height-rem-1-25 mt-2-5 mt-sm-0 pt-2">
                <h3 className="font-size-1 font-weight-bold line-height-rem-1-25 mb-1 text-uppercase">
                    <a href={`/${shop?.Slug}`} className="text-body">
                        {shop?.StoreName}
                    </a>
                </h3>
                <h4 className="font-size-1 font-weight-medium line-height-rem-1-25 mb-2">
                    <a href={`/${shop?.Slug}`} className="text-body">
                        {shop?.ShopType}
                    </a>
                </h4>
                <div className="mb-2">
                    {fullAddress}
                    <br />
                    {shop?.AddressExtension}
                </div>
                <div className="mb-1">
                    {t('brendon.shops.openinfo.now')}{' '}
                    <span
                        className={`${shop.IsOpen ? 'text-green' : 'text-red'}`}
                    >
                        {shop?.OpenLabel}
                    </span>
                </div>
                <div>{shop?.OpenInfo}</div>
            </div>
            <div className="align-self-start align-self-sm-center mt-2-5 mt-sm-0">
                <a href={`/${shop?.Slug}`}>
                    <CircleLink
                        className={`${
                            isHovered
                                ? 'bg-gray-dark text-white'
                                : 'bg-white text-gray-dark'
                        } ml-auto cursor-pointer`}
                        disabled={true}
                        animation={false}
                    />
                </a>
            </div>
        </div>
    );
};

export default ShopCard;
