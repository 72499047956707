import React from 'react';
import MoreLink from '../MoreLink';
import { useResource } from '../../../contexts/ResourceContext';
import { Link } from 'react-router-dom';

const SectionCard = ({
    className,
    containerClassName,
    item,
    imgClassName = 'rounded-top',
}) => {
    const { t } = useResource();
    return (
        <div className={`section-card-item ${containerClassName || ''}`}>
            <div className={`${className} h-fix rounded d-flex flex-column`}>
                <div>
                    <Link to={item.Slug}><img
                        src={item.PictureUrl}
                        className={`blog-card-img ${imgClassName}`}
                        alt={item.Title}
                        title={item.Title}
                    /></Link>
                </div>
                <div className="p-3 line-height-rem-1-325 flex-grow-1 d-flex flex-column">
                    <Link to={item.Slug} className="title"><h2 className="font-size-1-25">{item.Title}</h2></Link>
                    <p className="m-0 mb-3">{item.BodyOverview}</p>
                    <MoreLink
                        classNameContainer="text-right mt-auto"
                        url={item.Slug}
                        title={t('brendon.blog.readmore')}
                    />
                </div>
            </div>
        </div>
    );
};

export default SectionCard;
