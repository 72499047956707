import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-light-svg-icons';

const AddAddressBox = ({selected, title, ...props}) => {
    return (
        <div
            className={`cursor-pointer border p-3 rounded-sm w-100 d-flex flex-column ${selected ? "border-primary" : ""}`} {...props}>
            <div className={`font-size-0-875 font-weight-medium text-center ${selected ? "text-primary" : ""}`}>
                {title}
            </div>
            <div className="d-flex justify-content-center align-items-center flex-fill">
                <FontAwesomeIcon icon={faPlus} className="text-blue-medium my-5 font-size-5"/>
            </div>
        </div>
    );
};

export default AddAddressBox;
