import React from 'react';
import BlogListByQuery from './BlogList/BlogListByQuery';
import BlogListByStore from './BlogList/BlogListByStore';
import BlogListByTag from './BlogList/BlogListByTag';

const BlogList = ({ storeId, searchString, tagId, setTag }) => {
    return (
        <>
            {searchString ? (
                <BlogListByQuery storeId={storeId} searchString={searchString} />
            ) : tagId ? (
                <BlogListByTag
                    storeId={storeId}
                    tagId={tagId}
                    setTag={setTag}
                />
            ) : (
                <BlogListByStore storeId={storeId} />
            )}
        </>
    );
};

export default BlogList;
