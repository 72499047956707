import React from 'react';
import { useResource } from '../contexts/ResourceContext';

const withResource = (Component) => {
  return (props) => {
    const { t } = useResource();
    return <Component t={t} {...props} />;
  };
};

export default withResource;
