import axios from 'axios';
import { callAxios } from './helper/axiosHelper'


 
export const ChecklistService = {
    get: async () => callAxios('get', '/Checklist/PageData'),
    list: async () => callAxios('get', '/Checklist/List'),
    set: async (data) => callAxios('post', '/Checklist/Check', data),
    /* Dependent services */
    // https://www.brendon.hu/BrendonAjax/Checklist?checklistid=1&ischecked=true
    checked: async ({checklistid,ischecked}) => {
        const {data} = await axios.get(`/BrendonAjax/Checklist?checklistid=${checklistid}&ischecked=${ischecked}`);
        return data;
    }
    
}

export default ChecklistService;