import React, {Component} from 'react'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle,faBars} from '@fortawesome/pro-light-svg-icons';
import { connect } from 'react-redux';
import withStore from "../../hoc/withStore";
import {createStructuredSelector} from "reselect";
import { selectCategoryMenu, selectTopMenu } from "../../store/megamenu/selectors";
import { Link } from 'react-router-dom';

const Popup = styled.div`
    transition: all 250ms ease-in-out;
    transform: translate(${props => props.opened ? 0 : '-120%'});
    z-index: 9050;
    box-shadow: 0 3px 6px rgba(0,0,0,0.4);
`;

const Overlay = styled.div`
    z-index: 9000;
    transition: all 250ms ease-in-out;
    opacity: ${props => props.opened ? 0.35 : 0};
    pointer-events: ${props => props.opened ? 'default' : 'none'};
`;

class MobileMenu extends Component {
    state = {
        opened: false,
        categories: null,
        topMenu: [],
    };

    open = () => {
        this.setState({
            opened: true,
        })
    };

    close = () => {
        this.setState({
            opened: false,
        })
    };

    render() {
        const {children, links, defaultLink, defaultTitle, categories, topMenu} = this.props;
        const {opened} = this.state;

        return <>
            <FontAwesomeIcon icon={faBars} className="d-flex justify-content-center align-items-center w-24px h-24px" onClick={this.open} />
            <Overlay className="position-fixed top left h-100 w-100 bg-dark" opened={opened} onClick={this.close}/>
            <Popup className="position-fixed bg-white top left h-100 mr-5 container w-auto" opened={opened}>
                <div className="row py-2 align-items-center border-bottom">
                    <div className="col">
                        {children}
                    </div>
                    <div className="col-auto">
                        <FontAwesomeIcon icon={faTimesCircle} className="w-p40 d-flex justify-content-center align-items-center" onClick={this.close} />
                    </div>
                </div>
                <div className="row text-uppercase font-size-0-75 font-weight-medium">
                    {categories && 
                        <Link
                            to={categories ? `/${categories.SeName}` : defaultLink}
                            className="col-12 border-bottom border-light h-p40 line-height-5">
                                {categories ? categories.Name : defaultTitle}
                        </Link>
                    }
                    {Array.isArray(topMenu) && topMenu.map(menu => (<Link to={`/${menu.Slug}`} key={`menu${menu.OrderNo}`} className="col-12 border-bottom border-light h-p40 line-height-5">{menu.MenuName}</Link>))}
                    {!Array.isArray(topMenu) && links.map(({Key, Value}, index) => <Link
                                to={Key} key={index}
                                className="col-12 border-bottom border-light h-p40 line-height-5">
                        {Value}
                    </Link>)}
                </div>
            </Popup>
        </>;
    }
}

const mapStateToProps = () => createStructuredSelector({
    categories: selectCategoryMenu,
    topMenu: selectTopMenu,
});

export default withStore(connect(mapStateToProps, null)(MobileMenu));
