import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { CustomerRewardPointsService } from '../../services/customer';
import classNames from 'classnames';
import PageLoader from '../PageLoader';
import { useResource } from '../../contexts/ResourceContext';

const CustomerRewardPoints = () => {
    const [qrUrl, setQrUrl] = useState(null);
    const [rewardDetails, setRewardDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useResource();

    useEffect(() => {
        const getRewardDetails = async () => {
            setRewardDetails(await CustomerRewardPointsService.get());
            setIsLoading(false);
        };
        setIsLoading(true);
        getRewardDetails();
    }, []);

    useEffect(() => {
        const generateQR = async (text) => {
            try {
                setQrUrl(
                    await QRCode.toDataURL(text, { scale: 16, margin: 2 }),
                );
            } catch (err) {
                console.error(err);
            }
        };
        if (rewardDetails?.IsCustomerHasALoyaltyCard) {
            generateQR(rewardDetails?.CardCode);
        }
    }, [rewardDetails]);

    const hasCard = rewardDetails?.IsCustomerHasALoyaltyCard && qrUrl;
    return (
        <>
            <PageLoader isLoading={isLoading}>
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-8 offset-md-2">
                        <div className="row">
                            {hasCard && (
                                <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-4 mb-2-5 text-center ">
                                    <div className="border border-gray rounded">
                                        <img
                                            src={qrUrl}
                                            alt="Reward Points"
                                            className="img-fluid rounded"
                                            style={{ width: '600px' }}
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                className={classNames(
                                    'col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-6',
                                    hasCard ? 'offset-lg-0' : 'offset-lg-3',
                                )}
                            >
                                <div>{t('brendon.customer.reward.code')}</div>
                                <div className="font-weight-bold text-center bg-light rounded p-2 mb-2-5">
                                    {rewardDetails?.IsCustomerHasALoyaltyCard
                                        ? rewardDetails?.CardCode
                                        : t('brendon.customer.reward.nocard')}
                                </div>
                                <div>
                                    {t('brendon.customer.reward.pointinfo')}
                                </div>
                                <div className="font-weight-bold text-center bg-light rounded p-2 mb-2-5">
                                    {Number(rewardDetails?.PointNo || 0) > 0
                                        ? `${rewardDetails?.PointNo} ${t(
                                              'brendon.customer.reward.point',
                                          )}`
                                        : t('brendon.customer.reward.nopoint')}
                                </div>
                                <div className="font-size-1-125">
                                    {t('brendon.customer.reward.info').replace(
                                        '{0}',
                                        Number(rewardDetails?.PointValue || 0),
                                    )}
                                </div>
                                <div className="mt-2">
                                    <a
                                        href={t(
                                            'brendon.customer.reward.topic.link',
                                        )}
                                    >
                                        {t('brendon.customer.reward.help')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>
        </>
    );
};

export default CustomerRewardPoints;
