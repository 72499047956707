import React, {Component} from 'react';
import SquareCheckbox from "../elements/SquareCheckbox";

class RegistrationButton extends Component {
    state = {
        privacyPolicyAccepted: false,
        showInvalid: false,
    };

    toggleAcceptPrivacyPolicy = () => {
        this.setState(state => ({
            privacyPolicyAccepted: !state.privacyPolicyAccepted,
            showInvalid: state.showInvalid && !state.privacyPolicyAccepted
        }));
    };

    submit = (e) => {
        this.setState({
            showInvalid: true,
        });
        
        if(!this.state.privacyPolicyAccepted) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    render() {
        const {privacyPolicyAccepted, showInvalid} = this.state;
        const {privacyText, registrationButtonText} = this.props;
        
        return <>
            <div className="mb-3">
                <SquareCheckbox invalid={showInvalid && !privacyPolicyAccepted} value={privacyPolicyAccepted}
                                onChange={this.toggleAcceptPrivacyPolicy}>
                    <span dangerouslySetInnerHTML={{__html: privacyText}}/>
                </SquareCheckbox>
            </div>
            <input type="submit" name="register-button"
                   className="btn btn-primary w-100 mb-4"
                   value={registrationButtonText}
                   onClick={this.submit}/>
        </>
    }
}

export default RegistrationButton;