import React from 'react';
import Registration from '../../customer/Registration';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import { useResource } from '../../contexts/ResourceContext';

const RegisterScreen = () => {
    // TODO: change to title props
    const { t } = useResource();
    return (
        <>
            <MetaHeader title={t('PageTitle.Register')} showBrendonAddon />
            <Registration />
        </>
    );
};

export default RegisterScreen;
