import React, { useMemo } from 'react';
import CustomIcons from './Elements/CustomIcons/CustomIcons';
import classNames from 'classnames';
import InfoTooltip from './InfoTooltip';
import uniqid from 'uniqid';

const INFO_STRIP_VARIANTS = {
    normal: {
        iconSize: 'medium',
        className: 'px-2 py-8px bg-gray-light rounded-5 mb-1',
        linkClassName: 'text-gray-dark text-underline ml-1',
        noTextLinkClassName: 'text-gray-dark text-underline',
        iconTextColors: {
            Exclamation: {
                colorClassName: 'text-pink',
                linkClassName: 'text-pink text-underline ml-1',
                noTextLinkClassName: 'text-pink text-underline',
            },

            Discount: {
                colorClassName: 'text-pink',
                linkClassName: 'text-pink text-underline ml-1',
                noTextLinkClassName: 'text-pink text-underline',
            },
        },
    },
    list: {
        iconSize: 'small',
        className: 'mb-1',
        linkClassName: 'text-gray-dark text-underline ml-1',
        noTextLinkClassName: 'text-gray-dark text-underline',
        iconTextColors: {
            Exclamation: {
                colorClassName: 'text-pink',
                linkClassName: 'text-pink text-underline ml-1',
                noTextLinkClassName: 'text-pink text-underline',
            },
            Discount: {
                colorClassName: 'text-pink',
                linkClassName: 'text-pink text-underline ml-1',
                noTextLinkClassName: 'text-pink text-underline',
            },
            Point: {
                colorClassName: 'text-green-dark',
                linkClassName: 'text-green-dark text-underline ml-1',
                noTextLinkClassName: 'text-green-dark text-underline',
            },
            Shipping: {
                colorClassName: 'text-green-dark',
                linkClassName: 'text-green-dark text-underline ml-1',
                noTextLinkClassName: 'text-green-dark text-underline',
            },
        },
    },
};
/**
 *
 * @param {string} variant - Variant of the strip : normal | list
 * @param {string} icon - Icon to be displayed in the strip and its color
 * @param {ReactNode} children - Children to be displayed in the strip
 * @param {string} className - Class name addon for the strip container
 * @param {string} url - Url to be added after the text of strip
 * @param {string} urlText - Text to be displayed in link the text of strip
 * @param {string} infoText - Tooltip text to be displayed in the strip with info icon
 */
const ProductInfoStrip = ({
    variant = 'normal',
    icon,
    children,
    className = '',
    url,
    urlText,
    tooltipIdPrefix = 'pibv2',
    infoText,
    linkClassName,
    iconSize = 'medium',
    text,
}) => {
    const tooltipId = uniqid.process(tooltipIdPrefix);

    const settings = useMemo(() => {
        return {
            ...INFO_STRIP_VARIANTS?.[variant],
            ...(INFO_STRIP_VARIANTS?.[variant]?.iconTextColors?.[icon] || {}),
        };
    }, [variant, icon]);

    return (
        <div className={classNames('d-flex', settings?.className || className)}>
            <div className="mr-2">
                <CustomIcons
                    iconName={icon}
                    size={settings?.iconSize || iconSize}
                />
            </div>
            <div
                className={classNames(
                    'font-size-0-875 align-self-center',
                    settings?.colorClassName,
                )}
            >
                {children}
                {url && urlText && (
                    <>
                        <a
                            href={url}
                            className={classNames(
                                'font-weight-medium',
                                (text && text !='' ? settings?.linkClassName : settings?.noTextLinkClassName) || linkClassName,
                            )}
                        >
                            {urlText}
                        </a>
                    </>
                )}
                {infoText && (
                    <InfoTooltip id={tooltipId} content={infoText}>
                        <span id={tooltipId} className="ml-1">
                            <CustomIcons iconName="CircleInfo" size="small" />
                        </span>
                    </InfoTooltip>
                )}
            </div>
        </div>
    );
};

export default ProductInfoStrip;
