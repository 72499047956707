import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import DOMHelper from './utils/DOMHelper';
import { set } from 'js-cookie';

const DevAuthGuard = ({ children }) => {
  const [devLogged, setDevLogged] = useState(false);
  const devAuthEnabled = process.env.DEV_AUTH_ENABLED === 'true';
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [shake, setShake] = useState(false);
console.log('devAuthEnabled', process.env.DEV_AUTH_ENABLED);
  const onSubmitHandler = useCallback(
    async (data) => {
      if (
        data.username === process.env.DEV_AUTH_USER &&
        data.password === process.env.DEV_AUTH_PASSWORD
      ) {
        setDevLogged(true);
        localStorage.setItem('devLogged', true);
        DOMHelper.removeBodyOverflowHidden();
      } else {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 500);
      }
    },
    [setShake],
  );

  useEffect(() => {
    const devLogged = localStorage.getItem('devLogged');
    if (devLogged) {
      setDevLogged(true);
    } else {
      DOMHelper.addBodyOverflowHidden();
    }
  }, []);

 

  console.log('shake', shake, errors);
  return (
    <>
      {children}
      {devAuthEnabled && !devLogged && (
        <div className="da-bg">
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className={`form-inline m-2 ${shake ? 'da-shake' : ''}`}
          >
            <input
              className="form-control mx-1"
              type="username"
              placeholder="Username"
              {...register('username', { required: true })}
            />
            <input
              className="form-control mx-1"
              type="password"
              placeholder="Password"
              {...register('password', { required: true })}
            />
            <button type="submit" className="btn btn-primary px-4 mx-1">Log In</button>
          </form>
        </div>
      )}
    </>
  );
};

export default DevAuthGuard;
