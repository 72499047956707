import axios from 'axios';

async function getSearchCategory(query) {
  const { data } = await axios.get('/BrendonAjax/GetCategoryRecommendations', {
    params: {
      q: query,
    },
  });

  return data.map((category) => ({
    Id: category.CategoryId,
    Name: category.CategoryName,
    Slug: category.SlugURL,
  }));
}

async function getSearchProducts(query, page, order, filters = []) {
  const { data } = await axios.get('/Catalog/GetSearchProducts', {
    params: {
      q: query,
      p: page,
      o: order,
      f: filters.join(';'),
    },
  });

  if (data) {
    if (data?.ProductInfo) {
      data.ProductInfo = data.ProductInfo.map((product) => {
        try {
          return Object.assign(
            {},
            product,
            JSON.parse(product.ShortDescription),
          );
        } catch (e) {
          return product;
        }
      })
        // Fix API interface, Id is something else, it's not the product ID
        .map((product) =>
          Object.assign({}, product, {
            Id: product.ProductID,
          }),
        );
    }
    if (data?.FilterInfo) {
      data.FilterInfo = data.FilterInfo.map((filter) => ({
        ...filter,
        IsFilterChecked: filter.IsCheckbox
          ? filter?.FilterOption?.[0]?.IsFilterOptionChecked
          : filter?.IsFilterChecked,
        Disabled: filter.IsCheckbox
          ? filter?.FilterOption?.[0]?.Disabled
          : filter?.Disabled || false,
        FilterID: filter.IsCheckbox
          ? filter?.FilterOption?.[0]?.FilterOptionID || filter.FilterID
          : filter.FilterID,
      }));
    }
  }

  return data;
}

async function getSearchRecommendations(query) {
  const { data } = await axios.get('/GetSearchRecommendations', {
    params: {
      q: query,
    },
  });

  return {
    categories: data?.CategoryRecommendations?.map((category) => ({
      id: category.CategoryId,
      title: category.Title,
      slug: category.Slug,
    })),
    blogPosts: data?.BlogPostRecommendations?.map((blogPost) => ({
      id: blogPost.BlogPostId,
      title: blogPost.Title,
      slug: blogPost.Slug,
    })),
  };
}

export const SearchService = {
  getSearchProducts,
  getSearchCategory,
  getSearchRecommendations,
};

export default SearchService;
