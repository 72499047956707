import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';

const SectionTitle = ({
    titlePrefixContent,
    title,
    moreUrl,
    moreText,
    className,
    moreClassName,
}) => {
    return (
        <div
            className={`blog-section-title d-flex justify-content-between align-items-center ${
                className || ''
            }`}
        >
            {title && <div className="d-flex align-items-center">
                {titlePrefixContent}
                <Link to={moreUrl} className="text-dark-no-hover">
                    <h2 className="text-uppercase font-size-1-25 font-weight-normal m-0">
                        {' '}
                        {title}
                    </h2>
                </Link>
            </div>}
            {(moreText || moreUrl) && (
                <div className={moreClassName}>
                    <Link to={moreUrl} className="text-body">
                        {moreText}
                        <FontAwesomeIcon
                            icon={faLongArrowRight}
                            className="ml-2"
                        />
                    </Link>
                </div>
            )}
        </div>
    );
};

export default SectionTitle;
