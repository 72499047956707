import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import CustomerMainContainer from '../../components/CustomerNew/CustomerMainContainer';
import CustomerTitle from '../../components/CustomerNew/CustomerTitle';
import CustomerChangeEmail from '../../components/CustomerNew/CustomerChangeEmail';
import CustomerBackButton from '../../components/CustomerNew/CustomerBackButton';
import { useResource } from '../../contexts/ResourceContext';

const CustomerChangeEmailScreen = () => {
    // TODO: change to text  props
    const { t } = useResource();
    return (
        <>
            <MetaHeader title={t('PageTitle.Account')} showBrendonAddon />
            <CustomerMainContainer>
                <CustomerBackButton />
                <CustomerTitle title={t('brendon.customer.emailchange.title')} />
                <CustomerChangeEmail />
            </CustomerMainContainer>
        </>
    );
};

export default CustomerChangeEmailScreen;
