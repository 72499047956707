import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import FormGroup from "./FormGroup";
import { Field } from "formik";
import variationTypes from './variationTypes';

const StyledTextArea = styled.textarea`
    padding-top: 4px;
    padding-bottom: 4px;
    ::placeholder,
    ::-webkit-input-placeholder {
        color: #777;
        font-style: italic;
    }
    :-ms-input-placeholder {
        color: #777;
        font-style: italic;
    }
`;
const TextareaGroup = ({
    name,
    readonly,
    required,
    description,
    placeholder,
    maxLength,
    disableNewLines,
    label,
    formGroupClasses,
    variation = 'default',
    errorType = 'label-after',
}) => {
    const ref = useRef(null);
    const disableEnter = useCallback((e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }, []);
    const removeNewLines = useCallback((e) => {
        e.preventDefault();

        const pasteData = (e.clipboardData || window.clipboardData).getData('text');

        const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;

        nativeTextAreaValueSetter.call(e.target, pasteData?.replace(/\r?\n|\r/g, '')?.substring(0, maxLength));

        const event = new Event('input', { bubbles: true });
        e.target.dispatchEvent(event);

        return false;

    }, []);

    useEffect(() => {
        if (disableNewLines && ref.current) {
            ref.current.addEventListener('keypress', disableEnter);
            ref.current.addEventListener('paste', removeNewLines);
        }
        return () => {
            if (disableNewLines && ref.current) {
                ref.current.removeEventListener('keypress', disableEnter);
                ref.current.removeEventListener('paste', removeNewLines);
            }
        }
    }, [ref, disableNewLines]);

    return <Field name={name}>
        {(fieldProps) => {
            const { field, meta } = fieldProps;
            const error = meta.touched ? meta.error : null;

            const selectedVariation = variationTypes?.[variation] || variationTypes['default'];

            return <FormGroup
                required={required}
                fieldProps={fieldProps}
                description={description}
                errorType={errorType}
                label={label}
                {...formGroupClasses}
                {...selectedVariation}>
                <StyledTextArea {...field}
                    autoComplete="off"
                    rows="4"
                    className={`form-control font-size-0-75 line-height-3 ${error ? 'is-invalid' : ''}`}
                    readOnly={readonly} tabIndex={readonly ? -1 : null}
                    placeholder={placeholder}
                    maxLength={maxLength || null}
                    ref={ref} />
            </FormGroup>
        }}
    </Field>
}

export default TextareaGroup;
