import React, { Component } from 'react';
import Newsletter from '../services/newsletter';
import Checkbox from './Checkbox';
import Input from '../elements/Input';
import DatePicker from '../components/Elements/Datepicker';
import { showError, showSuccess } from '../store/notification/actions';
import withStore from '../hoc/withStore';
import { connect } from 'react-redux';
import { isValidEmail } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';

class NewsletterForm extends Component {
    state = {
        email: '',
        invalidEmail: false,
        pregnancyNewsletter: false,
        accepted: false,
        birthday: '',
        submitted: false,
    };

    subscribe = async () => {
        const { email, pregnancyNewsletter, accepted, birthday } = this.state;

        this.setState({
            submitted: true,
            invalidEmail: !(await isValidEmail(email)),
        });

        if (
            !(await isValidEmail(email)) ||
            !accepted ||
            (pregnancyNewsletter && !birthday)
        ) {
            return;
        }

        const newsletterData = {
            Email: email,
            PregnancyNewsletter: pregnancyNewsletter,
            EstimatedBirthDate:
                (birthday && birthday?.toISOString?.().substring?.(0, 10)) ||
                birthday,
            Accepted: accepted,
        };

        const { showError, showSuccess } = this.props;

        try {
            const { Hide, Result } = await Newsletter.subscribe(newsletterData);
            if (Hide) {
                showSuccess(Result);
                this.setState({
                    email: '',
                    pregnancyNewsletter: false,
                    accepted: false,
                    birthday: '',
                    submitted: false,
                });
            } else {
                showError(Result);
            }
        } catch (e) {
            showError(this.props.errorText);
        }
    };

    changeEmail = async (value) => {
        this.setState({
            email: value,
            invalidEmail: !(await isValidEmail(value)),
        });
    };

    changePregnancyNewsletter = (e) => {
        this.setState({
            pregnancyNewsletter: e.target.checked,
        });
    };

    changeAccepted = async (e) => {
        this.setState({
            accepted: e.target.checked,
            invalidEmail: !(await isValidEmail(this.state.email)),
        });
    };

    changeBirthday = async (value) => {
        this.setState({
            birthday: value,
            invalidEmail: !(await isValidEmail(this.state.email)),
        });
    };

    render() {
        const {
            title,
            subscribeButtonTitle,
            emailPlaceholder,
            pregnancyLabel,
            pregnancyDescription,
            birthdayPlaceholder,
            consentText,
        } = this.props;
        const {
            submitted,
            email,
            invalidEmail,
            pregnancyNewsletter,
            accepted,
            birthday,
        } = this.state;
        return (
            <div className="text-white">
                <div className="row">
                    <h5 className="col-12 mb-3 d-flex align-items-center">
                        <FontAwesomeIcon
                            icon={faEnvelopeOpenText}
                            className="text-primary font-size-1-875 font-size-lg-2-5 mr-3"
                        />
                        <span className="font-weight-medium font-size-0-875 font-size-md-1 text-uppercase">
                            {title}
                        </span>
                    </h5>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="form-wrapper">
                            <div className="mb-3">
                                <Input
                                    type="text"
                                    value={email}
                                    white
                                    invalid={invalidEmail}
                                    onChange={this.changeEmail}
                                    placeholder={emailPlaceholder}
                                />
                            </div>
                            <Checkbox
                                className="mb-2"
                                id="pregnancy-newsletter-checkbox"
                                value={pregnancyNewsletter}
                                onChange={this.changePregnancyNewsletter}
                                label={pregnancyLabel}
                            >
                                <div className="position-relative">
                                    <div
                                        className={`position-absolute top left mb-3 w-100 ${
                                            pregnancyNewsletter
                                                ? ''
                                                : 'invisible'
                                        }`}
                                    >
                                        <DatePicker
                                            white
                                            value={birthday}
                                            sm
                                            onChange={this.changeBirthday}
                                            size="10"
                                            maxLength="10"
                                            invalid={
                                                submitted &&
                                                pregnancyNewsletter &&
                                                !birthday
                                            }
                                            placeholder={birthdayPlaceholder}
                                        />
                                    </div>
                                    <div
                                        className={`font-size-0-875 ${
                                            pregnancyNewsletter
                                                ? 'invisible'
                                                : ''
                                        }`}
                                    >
                                        {pregnancyDescription}
                                    </div>
                                </div>
                            </Checkbox>
                            <Checkbox
                                className="mb-2"
                                id="accept-newsletter-checkbox"
                                invalid={submitted && !accepted}
                                value={accepted}
                                onChange={this.changeAccepted}
                                labelHtml={consentText}
                            />
                            <div
                                onClick={this.subscribe}
                                className="rounded-sm bg-primary text-center text-uppercase w-100 font-size-0-75 font-weight-bold h-p40 line-height-5 cursor-pointer"
                            >
                                {subscribeButtonTitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showError(message)),
    showSuccess: (message) => dispatch(showSuccess(message)),
});

export default withStore(connect(null, mapDispatchToProps)(NewsletterForm));
