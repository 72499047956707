import React from 'react';
import LoadingIcon from './Icons/LoadingIcon';

const buttonVariants = {
    'none': {
        className: '',
        disabledClassName: '',
    },
    'primary': {
        className: 'btn-primary',
        disabledClassName: '',
    },
    'secondary': {
        className: 'btn-secondary',
        disabledClassName: '',
    },
    'success': {
        className: 'btn-success',
        disabledClassName: '',
    },
    'danger': {
        className: 'btn-danger',
        disabledClassName: '',
    },
    'warning': {
        className: 'btn-warning',
        disabledClassName: '',
    },
    'info': {
        className: 'btn-info',
        disabledClassName: '',
    },
    'light': {
        className: 'btn-light',
        disabledClassName: '',
    },
    'dark': {
        className: 'btn-dark',
        disabledClassName: '',
    },
    'link': {
        className: 'btn-link',
        disabledClassName: '',
    },
    'primary-bordered': {
        className: 'btn-primary-bordered border border-dark rounded',
        disabledClassName: '',
    },
    'primary-disabled-gray': {
        className: 'btn-primary',
        disabledClassName: 'btn-gray text-gray-medium',
    }
};

/**
 * Generic button component
 *
 * @param {string} id - id of the button
 * @param {string} name - name of the button
 * @param {string} text - Button text
 * @param {'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link' | 'none' | 'primary-disabled-gray'} variant - Button variant
 * @param {'none' | 'sm' | 'md' | 'lg'} size - Button size
 * @param {string} className - Additional class name
 * @param {boolean} isLoading - Is parent loading
 * @param {boolean} disabled - Is button disabled
 * @param {beforeChildren} beforeChildren - Button children, e.g icon, it's added to left side of the button
 * @param {children} children - Button children, e.g icon, it's added to right side of the button
 * @param {any} props - Other props
 * @returns
 */
const Button = ({
    buttonType = 'button',
    text,
    size,
    variant,
    children,
    className,
    isLoading,
    disabled,
    beforeChildren = false,
    ...props
}) => {
    const getButtonVariant = (variant, disabled) => buttonVariants?.[variant]?.[disabled ? 'disabledClassName' : 'className'] || '';
    const btnSize = size && size !== 'none' ? `btn-${size}` : '';

    return (
        <button
            type={buttonType}
            className={`btn ${btnSize} ${getButtonVariant(variant, disabled)} ${className || ''}`}
            {...props}
            disabled={isLoading || disabled}
        >
            {isLoading ? (
                <LoadingIcon size={size} />
            ) : (
                <div>
                    {beforeChildren && children}
                    <span>{text}</span>
                    {!beforeChildren && children}
                </div>
            )}
        </button>
    );
};

export default Button;
