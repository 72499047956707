import React from 'react';

const ConfirmDataBox = ({ title, children }) => {
    return (
        <div className="col-12 col-sm-6 col-lg-3 mb-3 d-flex">
            <div className="bg-gray-light rounded p-3 flex-1">
                <div className="text-uppercase font-weight-light line-height-3 pb-2 font-size-0-875 text-gray">
                    {title}
                </div>
                {children}
            </div>
        </div>
    );
};

export default ConfirmDataBox;
