import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import OrderTotalItem from './OrderTotalItem';
import { useResource } from '../../contexts/ResourceContext';

const OrderTotals = ({
    sumItems,
    sumAmountDiscounts,
    /*     discountSubItems, */
    rewardPointRedeemedSum,
    rewardPointRedeemPercentText,
    sumItemsReducedByDiscounts,
    shippingFee,
    shippingFeeInfoText,
    amountWithShippingFee,
    giftCardCodes,
    amountToPay,
}) => {
    const { t } = useResource();

    const disabledShippingFeeLabel =
        shippingFee === undefined || shippingFee === null;

    return (
        <>
            <div className="text-uppercase font-weight-light line-height-3 font-size-1 mb-1 pl-1">
                {t('Brendon.ShoppingCart.Totals')}
            </div>
            <div className="mb-2-5">
                <div className="line-height-rem-1 pb-1">
                    <OrderTotalItem
                        itemName={t('brendon.shoppingcart.totals.sumitems')}
                        price={sumItems}
                        visibleWhenPrice={true}
                        visibleWhenItems={false}
                    />

                    <OrderTotalItem
                        itemName={t('brendon.shoppingcart.totals.discount')}
                        price={sumAmountDiscounts}
                        visibleWhenPrice={true}
                        /*                         visibleWhenSubItems={true}
                        subItemClassName="text-uppercase"
                        subItems={discountSubItems} */
                    />

                    <OrderTotalItem
                        itemName={t(
                            'brendon.shoppingcart.totals.redeemrewards',
                        )}
                        price={rewardPointRedeemedSum}
                        visibleWhenPrice={true}
                        visibleZeroPrice={true}
                        /*      visibleWhenSubItems={true}
                        visibleWhenSubItemPrice={true}
                        subItems={[
                            {
                                item: t(
                                    'brendon.shoppingcart.totals.brendonfamily',
                                ),
                                price: rewardPointRedeemedSum,
                            },
                        ]} */
                    >
                        {rewardPointRedeemPercentText && (
                            <div
                                className={"font-size-0-875 font-weight-normal"}
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="fa-w-14 text-green mr-2"
                                />
                                <span className="font-size-0-75">{rewardPointRedeemPercentText}</span>
                            </div>
                        )}
                    </OrderTotalItem>

                    <OrderTotalItem
                        itemName={t(
                            'brendon.shoppingcart.totals.sumitemsreducedbydiscounts',
                        )}
                        price={sumItemsReducedByDiscounts}
                        visibleWhenPrice={true}
                    />

                    <OrderTotalItem
                        itemName={t('brendon.shoppingcart.totals.shippingfee')}
                        price={shippingFee}
                        disabled={disabledShippingFeeLabel}
                        visibleWhenPrice={false}
                        visibleZeroPrice={false}
                    >
                        {shippingFeeInfoText?.length > 0 &&
                            shippingFeeInfoText?.map?.((shippingText, idx) => (
                                <div
                                    key={`sht_${idx}`}
                                    className={`font-size-0-875 line-height-0 font-weight-normal ${
                                        idx === shippingFeeInfoText.length - 1
                                            ? ''
                                            : 'pb-2'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="fa-w-14 text-green mr-2"
                                    />
                                    <span className="font-size-0-75">{shippingText}</span>
                                </div>
                            ))}
                    </OrderTotalItem>

                    <OrderTotalItem
                        itemName={t(
                            'brendon.checkout.paymentmethods.total.giftcard',
                        )}
                        price={null}
                        visibleWhenPrice={false}
                        visibleWhenSubItems={true}
                        subItems={giftCardCodes?.map((giftCard) => ({
                            item: giftCard.GiftCardCode,
                            price:
                                giftCard.GiftCardAmountUsed
                        }))}
                    />

                    <OrderTotalItem
                        itemName={t(
                            'brendon.shoppingcart.totals.amountwithshippingfee',
                        )}
                        price={amountWithShippingFee}
                        visibleWhenPrice={true}
                    />

                    <OrderTotalItem
                        itemName={t(
                            'brendon.checkout.paymentmethods.total.amounttopay',
                        )}
                        price={amountToPay}
                        visibleWhenPrice={true}
                        visibleZeroPrice={true}
                        visibleWhenItems={false}
                    />
                </div>
            </div>
        </>
    );
};

export default OrderTotals;
