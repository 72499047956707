import React from 'react';
import { useResource } from '../../../../contexts/ResourceContext';

const CalendarDateTypes = [
  'lastMenstrualPeriod',
  'fertileDays',
  'veryFertileDays',
  'ovulationDay',
  'embeddingDays',
  'nextMenstruationDate',
  'pregnancyTestCanUse',
];

const CalendarExplanation = ({ className, accepted = {} }) => {
  const { t } = useResource();
  return (
    <div className={className || ''}>
      {CalendarDateTypes.map((type, index) => (
        <React.Fragment key={index}>
          {accepted[type] && (
            <div className="d-flex mb-1">
              <div
                className={`calendar-month__day-circle ${`bg-${type}`}`}
              ></div>
              <div className="ml-2 font-size-0-875">
                {t(`brendon.calculator.ovulation.${type}`)}
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CalendarExplanation;
