import Wishlist from '../../services/wishlist';
import { apiRequestAction, success } from '../actions';
import { fromJS, List } from 'immutable';
import {
  dismissMessage,
  showError,
  showSuccess,
} from '../notification/actions';
// TODO: possible to solve this without legacy_t / resource?
import { legacy_t as t} from '../../contexts/ResourceContext';
import { updateWishlistProducts } from './actions';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

export const addProductToCart = (
  productId,
  size,
  successMessage,
  errorMessage,
) =>
  apiRequestAction(ADD_TO_CART, async (dispatch) => {
    try {
      const result = await Wishlist.addToCart(productId, size);
      if (Number(result?.Success) === 1) {
        dispatch(showSuccess(successMessage));
      } else {
        if (result?.ErrorMessage !== '') {
          dispatch(showError(t(result.ErrorMessage)));
        }
      }
      return result;
    } catch (err) {
      dispatch(showError(errorMessage));
      throw err;
    }
  })();
export const addProduct = (productId) =>
  apiRequestAction(ADD_PRODUCT, async () => Wishlist.addProduct(productId))();
export const removeProduct = (productId) =>
  apiRequestAction(REMOVE_PRODUCT, async () =>
    Wishlist.removeProduct(productId),
  )();

function mapProducts(products) {
  return products.map((product) => {
    let productData;
    let ShortDescription = product.ShortDescription;
    try {
      productData = JSON.parse(product.ShortDescription);
      ShortDescription = productData.ShortDescription;
    } catch (e) {
      productData = null;
    }

    return Object.assign({}, product, {
      productData,
      ShortDescription,
    });
  });
}

export default function wishlist(state = List(), action) {
  switch (action.type) {
    case updateWishlistProducts.toString():
      return fromJS(action.payload);
    case success(ADD_TO_CART):
      return fromJS(mapProducts(action.result.wishlist.Products));
    case success(ADD_PRODUCT):
    case success(REMOVE_PRODUCT):
      // TODO: ha visszajonne a termek lista, akkor itt atlehet adni es bekerul wishlist state-be
      // ebben az esetben mar nem kellene a updateWishlistProducts action / saga hivas
      // torles es hozzaadas eseten is
      return state;

    default:
      return state;
  }
}
