import React from 'react';
import PageNotFound from '../screens/PageNotFound';
import { useLoaderData } from 'react-router-dom';
import PageServices from '../../services/page';
import { DynamicRouterDescriptor } from '../../routes/dynamicRoutes';

export const dynamicSreenLoader = async ({ params: { path }}) => {
  console.log('dynamicSreenLoader', path, window.location.pathname );
  return await PageServices.pageMeta.post({ path: `/${path}` });
}

const DynamicScreens = () => {
  const { screenType = null, ...props } = useLoaderData();
  if (screenType === null || DynamicRouterDescriptor[screenType] === undefined) {
    return <PageNotFound />;
  }

  /* TODO: Need Redirect handling  */
  const DynamicLoadedScreen = DynamicRouterDescriptor[screenType] || <PageNotFound />;
  // TODO: key needs for re-rendering
  return <DynamicLoadedScreen key={props?.id || '0'} {...props} />;
};

export default DynamicScreens;
