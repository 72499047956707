import React, {Component} from 'react';
import styled from 'styled-components';
import Carousel from "../components/Carousel";
import LazyImage from "../elements/LazyImage";

const Square = styled.div`
    position: relative;
    width: 100%;
    padding-top: 100%;
`;

class RelatedColors extends Component {
    render() {
        const {currentProductId, title, products, showPrice} = this.props;
        return <>
            <h3 className="font-size-1 text-uppercase font-weight-normal border-bottom line-height-3 pb-2 mb-2">
                {title}
            </h3>
            <Carousel snap={true} wrapperClass="row flex-nowrap mx-n1" elementWrapperClass="col-5 col-md-3">
                {products.map((product, index) =>
                    <div className="col-5 col-md-3 px-1" key={index}>
                        <a href={`/${product?.Url || product?.url}`} className={`d-block text-dark rounded-sm ${product?.RelatedProductId === currentProductId || product?.ProductId === currentProductId ? 'border border-primary' : ''}`}>
                            <Square>
                                <LazyImage
                                    alt={product?.Alt || product?.alt}
                                    title={product?.Alt || product?.alt}
                                    src={product?.Image || product?.image}
                                    className="lazy w-100 h-100 position-absolute top left object-fit-contain rounded-sm"/>
                            </Square>
                            <div className="p-1">
                                <div
                                    className={`font-size-0-625 ${showPrice ? 'mb-1' : ''}`}>{product.ProductDesign ? product.ProductDesign : (product?.Design ? product?.Design : product.Title) }</div>
                                {showPrice && <>
                                    {product.OldPrice !== 0 && <div
                                        className="text-line-through font-size-0-625 line-height-rem-0-875">{product.OldPrice}</div>}
                                    <div
                                        className={`font-size-0-75 line-height-rem-0-875 ${product.OldPrice !== 0 ? 'text-pink' : ''}`}>{product.Price}</div>
                                </>}
                            </div>
                        </a>
                    </div>
                )}
            </Carousel>
        </>
    }
}

export default RelatedColors;
