import React, { useState } from 'react';

const ComboListDetails = ({detailsLinkText, detailsChildren}) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    setShowDetails((prev) => !prev);
  };
  return (
    <div className="pt-2 combo-list-details line-height-rem-1">
      <a
        className={`font-size-0-75 text-dark text-underline closed ${
            showDetails ? 'opened' : ''
        }`}
        onClick={handleClick}
      >{detailsLinkText}
      </a>
      <div
                className={`font-size-0-75 closed ${
                    showDetails ? 'opened' : ''
                }`}
            >
                {detailsChildren}
            </div>
    </div>
  );
}

export default ComboListDetails;
