const staticUrls ={
  hu: {
    cart: '/cart',
    checkout: '/checkout',
    wishlist: '/wishlist',
    customerProfile: '/customer/profile',
    customerRewards: '/customer/rewardpoints',
    customerOrders: '/customer/orders',
    customerOrderDetails: '/customer/orders/:orderId',
    customerChangePassword: '/customer/changepassword',
    logout: '/customer/logout',
    register: '/register',
    registerSuccess: '/register/success',
    login: '/login',
    loginWithGuest: '/login/checkoutasguest',
    customerDashboard: '/customer/dashboard',
    customerChangeEmail: '/customer/changeemail',
    forgotPassword: '/passwordrecovery',
    forgotPasswordConfirm: '/passwordrecovery/confirm',
    introcookie: '/eucookielawaccept',
    contactUs: '/contactus',
    newbornChecklist: '/checklist',
    search: '/search',
    blog: '/babablog',
    blogSearch: '/blogsearch',
    checkoutCompleted: '/checkout/completed/:orderId',
    upsell: '/upsell/:orderId',
  },
  sk: {
    cart: '/cart',
    checkout: '/checkout',
    wishlist: '/wishlist',
    customerProfile: '/customer/profile',
    customerRewards: '/customer/rewardpoints',
    customerOrders: '/customer/orders',
    customerOrderDetails: '/customer/orders/:orderId',
    customerChangePassword: '/customer/changepassword',
    logout: '/customer/logout',
    register: '/register',
    registerSuccess: '/register/success',
    login: '/login',
    loginWithGuest: '/login/checkoutasguest',
    customerDashboard: '/customer/dashboard',
    customerChangeEmail: '/customer/changeemail',
    forgotPassword: '/passwordrecovery',
    forgotPasswordConfirm: '/passwordrecovery/confirm',
    introcookie: '/eucookielawaccept',
    contactUs: '/contactus',
    newbornChecklist: '/checklist',
    search: '/search',
    blog: '/blog-pre-babatka',
    blogSearch: '/blogsearch',
    checkoutCompleted: '/checkout/completed/:orderId',
    upsell: '/upsell/:orderId',
  },
};

export default staticUrls[process.env.LANGUAGE || 'hu'];
