import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { addProductToCart, addProductToCartWithSize } from '../store/cart';
import withStore from '../hoc/withStore';
import { createStructuredSelector } from 'reselect';
import { cartLoading, selectCartSuccess } from '../store/cart/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import { faShoppingCart as farShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import {
    serializeFormData,
    createQueryString,
    selectedRadioValue,
} from '../utils';
import { showError } from '../store/notification/actions';
import { legacy_t as t} from '../contexts/ResourceContext';
import gaService from '../services/gaEnhancedEcommerce';
import { getStoreCurrency } from '../utils/localization';
import GA4Service from '../services/gaGA4';
import LoadingIcon from '../elements/Icons/LoadingIcon';
import { redirectToUrl } from '../utils/controller';

const Icon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.white};
    margin-right: 5px;
    font-size: 1.2rem;
    display: ${(props) => (props.shown ? 'block' : 'none')};
    line-height: inherit;
    ${(props) =>
        props.filling
            ? `
    animation: scale-up 0.3s 0s ease-in-out 2;
    animation-direction: alternate;
    `
            : ''}
`;

const IconWrapper = styled.span`
    position: relative;
`;

const mapStateToProps = createStructuredSelector({
    isAdded: selectCartSuccess,
    isCartLoading: cartLoading,
});

const mapDispatchToProps = (dispatch) => ({
    addProductToCartLegacy: (productId, data) =>
        dispatch(addProductToCart(productId, data)),
    addProductToCartWithSize: (productId, data, qty) =>
        dispatch(addProductToCartWithSize(productId, data, qty)),
    showError: (message) => dispatch(showError(message)),
});

class AddToCartButton extends Component {
    onClick = () => {
        const { isCartLoading } = this.props;
        if (isCartLoading) {
            return;
        }

        const data = createQueryString(
            serializeFormData('#product-details-form'),
        );
        const selectedSize =
            selectedRadioValue('#product-details-form') || false;
        const {
            productId,
            productData,
            legacy = true,
            isSizesProduct,
            showError,
            enableUpsell = true,
        } = this.props;
        if (!legacy && isSizesProduct && !selectedSize) {
            showError(t('shoppingcart.selectattribute'));
        } else {
            const ga4sem = {
                sent: false,
            }
            legacy
                ? this.props.addProductToCartLegacy(productId, data)
                : this.props.addProductToCartWithSize(
                      productId,
                      selectedSize,
                      1,
                  );
            gaService.addProductToCart(
                gaService.transformToProductFieldObject(productData),
                getStoreCurrency(),
            );

            GA4Service.gtagEvent('add_to_cart', {
                currency: getStoreCurrency(),
                value:
                    productData.DiscountedPrice > 0
                        ? productData.DiscountedPrice
                        : productData.Price,
                items: [
                    GA4Service.transformProductToItemListItem(
                        'product-details',
                        'Product details',
                        productData,
                    ),
                ],
            });
            // TODO: timeout because of GA4 event
            // TODO: tudni kell hogy a meretes termek az rendelheto-e (szurke) mert ha nem rendelheto akkor upsell-t nem kell hivni!
            setTimeout(() => {
                if (enableUpsell && productData?.IsUpsell) {
                    redirectToUrl(`/upsell/${productId}`);
                }
            }, 10);
        }
    };

    render() {
        const { title, isAdded, isCartLoading } = this.props;
        return (
            <div
                onClick={this.onClick}
                className="btn btn-lg btn-primary font-size-0-875 d-flex justify-content-center align-items-center"
            >
                <span className="mr-2">{title}</span>
                <IconWrapper>
                    {isCartLoading && <LoadingIcon size="s20" />}
                    {!isCartLoading && (
                        <>
                            <Icon
                                icon={faShoppingCart}
                                shown={isAdded ? 1 : 0}
                                filling={1}
                            />
                            <Icon
                                icon={farShoppingCart}
                                shown={!isAdded ? 1 : 0}
                                filling={0}
                            />
                        </>
                    )}
                </IconWrapper>
            </div>
        );
    }
}

export default withStore(
    connect(mapStateToProps, mapDispatchToProps)(AddToCartButton),
);
