import React from "react";
import { formatPrice } from "../../utils/price";
import { useResource } from '../../contexts/ResourceContext';

export default function ItemPrice({
  price,
  discountedPrice,
  superPrice = false,
}) {
  // TODO: change to price text props
  const { t } = useResource();
  return (
    <>
      {discountedPrice && superPrice === false && (
        <div className="text-line-through font-size-0-875">
          {formatPrice(price)}
        </div>
      )}
      {superPrice === true && (
        <div className="font-weight-medium text-pink font-size-0-875 ">
          {" "}
          {t("brendon.product.superprice")}{" "}
        </div>
      )}
      <div className={` font-weight-bold font-size-1 ${discountedPrice ? "text-pink line-height-lg-3" : "line-height-lg-5"}`}>
        {formatPrice(discountedPrice)}
        {!discountedPrice && formatPrice(price)}
      </div>
    </>
  );
}
