import axios from 'axios';

async function getTopic(topicId) {
    const {data} = await axios.get(`/BrendonTopic/GetTopicPage/${topicId}`);

    return data;
}

export const TopicService = {
    getTopic,
};

export default TopicService;
