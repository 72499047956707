import React, {Component } from 'react'
import withStore from "../hoc/withStore";
import {connect} from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import {setSelectedSize} from "../store/product/actions";
import {selectProductSelectedSize} from "../store/product/selectors";
import TileSizeSelector from "./TileSizeSelector";

class ProductSizeSelector extends Component {
    changeSize = (size) => {
        const {setSelectedSize} = this.props;

        setSelectedSize(size);
    };
    componentDidUpdate(prevProps) {
        const { selectedSize, sizes } = this.props;
        const { prevSelectedSize } = prevProps;

        if (!prevSelectedSize && !selectedSize && Array.isArray(sizes) && sizes.length > 0) {
            this.changeSize(sizes.filter(size => size.available || size.Available)[0])
        }
    };
    render() {
        const {
            selectedSize,
            sizes,
            sizeAttributeName,
        } = this.props;
   
        return <div className="mb-3">
            <TileSizeSelector name={sizeAttributeName}
                              value={selectedSize}
                              sizes={sizes}
                              onSizeChange={this.changeSize}
            />
        </div>
    }
}

const mapStateToProps = state => ({
    selectedSize: selectProductSelectedSize(state),
});

const mapDispatchToProps = dispatch => ({
    setSelectedSize: size => dispatch(setSelectedSize(size)),
});

export default withStore(connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ProductSizeSelector)));
