import React, { useCallback, useState } from 'react';
import { getURLPath } from '../../utils/staticPaths';
import { Link } from 'react-router-dom';
import RememberMe from '../../login/RememberMe';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';
import { useAuth } from '../../contexts/AuthProvider';

const LoginBox = () => {
    const { t } = useResource();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [isLoginError, setIsLoginError] = useState(false);
    const { returnUrl } = useParams();
    const navigateTo = useNavigate();
    const auth = useAuth();

    const onSubmitHandler = useCallback( async (data) => {
        console.log(data);
        try {
            await auth.login(data);
            if (returnUrl && returnUrl !== '') {
                navigateTo(returnUrl);
            } else {
                navigateTo(getURLPath('customerDashboard'));
            } 
        } catch (error) {
            console.log(error);
            setIsLoginError(true);
        }
 
    }, []);

    return (
        <div className="col-sm-8 p-2 p-sm-3 m-2 border border-light rounded ">
            <div className="font-size-0-75">
                <h1 className="font-size-1-125 text-center">
                    {t('Account.Login.ReturningCustomer')}
                </h1>
                <form id="loginForm" onSubmit={handleSubmit(onSubmitHandler)}>
                    {isLoginError && (
                        <>
                            <div
                                className="text-danger"
                                dangerouslySetInnerHTML={{
                                    __html: t('Account.Login.Unsuccessful'),
                                }}
                            ></div>

                            <div className="text-danger mb-3">
                                <a
                                    href={getURLPath('contactUs')}
                                    target="_blank"
                                    className="font-weight-medium"
                                >
                                    {t('Account.Login.Unsuccessful.Helplink')}
                                </a>
                            </div>
                        </>
                    )}

                    <div className="row form-group multiline">
                        <div className="col-12 text-gray d-flex justify-content-between">
                            <div>
                                <label htmlFor="email">
                                    {t('Account.Login.Fields.Email')}: *
                                </label>
                            </div>
                            {errors?.email && <span className="field-validation-error">
                                {t('Account.Login.Fields.Email.Required')}
                            </span>}
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                className={classNames("form-control", { "input-validation-error": errors?.email })}
                                autoComplete="email"
                                {...register('email', { required: true })}
                            />
                        </div>
                    </div>
                    <div className="row form-group multiline">
                        <div className="col-12 text-gray d-flex justify-content-between">
                            <div>
                                <label htmlFor="password">
                                    {t('Account.Login.Fields.Password')}: *
                                </label>
                            </div>
                            {errors?.password && <span className="field-validation-error">
                                {t('account.fields.password.required')}
                            </span>}
                        </div>
                        <div className="col-12">
                            <input
                                className={classNames("form-control", { "input-validation-error": errors?.password })}
                                autoComplete="password"
                                type="password"
                                {...register('password', { required: true })}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                        <RememberMe name="rememberMe">
                            {t('Account.Login.Fields.RememberMe')}
                        </RememberMe>
                        <Link
                            to={getURLPath('forgotPassword')}
                            className="ml-2 line-height-3"
                        >
                            {t('Account.Login.ForgotPassword')}
                        </Link>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary w-100"
                        value={t('Account.Login.LoginButton')}
                        id="loginButton"
                    >
                        {t('Account.Login.LoginButton')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginBox;
