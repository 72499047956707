import {fromJS } from "immutable";
import {updateDeliveryModeEnabled} from "./actions";
 
const initialState = fromJS({
    deliveryModeEnabled: false,
});
 
export default function checkout(state = initialState, action) {
    switch (action.type) {
        case updateDeliveryModeEnabled.toString():
            return state.set('deliveryModeEnabled', action.payload);
        default:
            return state
    }
}