import React, { useRef, useState } from 'react';
import {Form, Formik, Field} from "formik";
import GiftCardValidationSchema from './GiftCardValidationSchema';
import AddGiftCardToCartButton from './AddGiftCardToCartButton'
import InputGroup from '../elements/forms/InputGroup'
import CartServices from '../services/cart';
import {showError, showSuccess} from "../store/notification/actions";
import {updateCartCount} from '../store/cart/actions';
import withStore from "../hoc/withStore";
import {connect} from "react-redux";
import { useResource } from '../contexts/ResourceContext';

const labelClass = "col-12 font-size-0-75 text-gray line-height-3 d-flex justify-content-between";

const mapDispatchToProps = dispatch => ({
    showError: (message) => dispatch(showError(message)),
    showSuccess: (message) => dispatch(showSuccess(message)),
    updateCartCount: (count) => dispatch(updateCartCount(count)),
});

const GiftCardInfo = ({
    productId,
    addButtonTitle,
    minPrice,
    maxPrice,
    enableDecimal,
    showError, 
    showSuccess,
    updateCartCount,
    minPriceText,
    maxPriceText,
}) => {
    const { t } = useResource();
    const [isAdded, setIsAdded] = useState(false);
    const form = useRef(null);

    return (
        <Formik
            initialValues={{
                RecipientName: '',
                RecipientEmail: '',
                SenderName: '',
                SenderEmail: '',
                Message: '',
                Amount: minPrice,
            }}
            validationSchema={ GiftCardValidationSchema(minPrice, maxPrice, enableDecimal) }
            onSubmit={async (values, {setSubmitting}) => {
                try {
                    const result = await CartServices.AddGiftCardToCart({
                        ...values,
                        ProductID: productId
                    });
                    if (Number(result?.Success) === 1) {
                        setIsAdded(true);
                        showSuccess(result?.Message);
                        updateCartCount(result?.CartCount);
                    } else {
                        showError(result?.Message);
                    }
                } catch (e) {
                    console.log(e)
                }
                
                setSubmitting(false);
            }}
        >
            {formik => (
            <>
                <Form onSubmit={formik.handleSubmit} method="POST" ref={form} autoComplete="off">
                    <div className="mt-3">
                        <InputGroup 
                            name="RecipientName"
                            label={ t("products.giftcard.recipientname") }
                            maxLength={45}
                            required={ true }
                            formGroupClasses={ 
                                {
                                    labelClass
                                }
                             } />
                        
                        <InputGroup 
                            name="RecipientEmail"
                            label={ t("products.giftcard.recipientemail") }
                            required={ true }
                            formGroupClasses={ 
                                {
                                    labelClass,
                                    descriptionClass: `col-12 font-size-0-625 text-gray pt-1`,
                                    errorContainerClass: 'text-right',
                                    labelItemClass: 'text-left',
                                }
                            }
                            description={ t("products.giftcard.recipientemailinfo") }
                             />
                        
                        <InputGroup 
                            name="SenderName"
                            label={ t("products.giftcard.sendername") }
                            maxLength={45}
                            required={ true }
                            formGroupClasses={ 
                                {
                                    labelClass
                                }
                             } />
                        
                        <InputGroup 
                            name="SenderEmail"
                            label={ t("products.giftcard.senderemail") }
                            required={ true }
                            formGroupClasses={ 
                                {
                                    labelClass,
                                }
                            } />     

                        <Field name={"Message"}>
                            {(fieldProps) => (
                                <div className="row form-group">
                                    <div className={ labelClass }>
                                        <label htmlFor="Message">{ t("products.giftcard.message") }:</label>
                                    </div>
                                    <div className="col-12">
                                        <textarea {...fieldProps?.field} htmlFor="Message" autoComplete="off" className="form-control" rows="5"></textarea>
                                    </div>
                                </div>
                            )}
                        </Field>

                        <InputGroup 
                            name="Amount"
                            label={ t("products.enterproductprice") }
                            required={ true }
                            formGroupClasses={ 
                                {
                                    labelClass,
                                    descriptionClass: `col-12 font-size-0-625 text-gray pt-1` 
                                }
                            }
                            description={ t("products.enterproductprice.range")?.replace('{0}', minPriceText).replace('{1}', maxPriceText) } 
                        />    
 
                    </div>
                    <div className="mb-2 d-flex">
                        <AddGiftCardToCartButton
                            title={ addButtonTitle }
                            productId={ productId }
                            disabled={formik.isSubmitting}
                            isAdded={isAdded}
                        />
                    </div>
                </Form>
            </>
            )}
        </Formik>
    )
}
 
export default withStore(connect(null, mapDispatchToProps)(GiftCardInfo));
