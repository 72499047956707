import React from 'react';
import ShopFinder from '../../components/Shops/ShopFinder';
import ShopDetails from '../../components/Shops/ShopDetails';

const ShopsScreen = ({ id: shopId }) => {
     if (shopId) {
        return (
            <ShopDetails shopId={shopId} />
        );
     } else {
        return (
            <ShopFinder />
        );
     }
};

export default ShopsScreen;
