import React, { useMemo } from 'react';
import { getStoreDateFormat } from '../../../../utils/localization';
import { formatDate } from '../../../../utils/date';
import { useResource } from '../../../../contexts/ResourceContext';

const PragnancyResultBox = ({ data }) => {
  const { t } = useResource();

  if (data?.expectedDateOfBirth === '') {
    return null;
  }

  const weeks = Number(data?.embryoAge?.weeks) || 0;
  const days = Number(data?.embryoAge?.days) || 0;
  const isOverdue =
    (data?.embryoAge?.weeks <= 0 && data?.embryoAge?.days <= 0) || weeks > 40;

  const format = useMemo(() => getStoreDateFormat().replaceAll('-', '. '), []);

  return (
    <div className="pregnancy-calculator__result p-4 d-flex rounded mb-3">
      <div className="pregnancy-calculator__result-inner mx-auto p-4 bg-white rounded text-center">
        <div className="mb-3">
          <div className="mb-1">
            {t('brendon.calculator.pregnancy.expectedbirth')}
          </div>
          <span className="font-size-1-25 font-weight-bold">
            {formatDate(format, data?.expectedDateOfBirth)}.
          </span>
        </div>
        {!isOverdue && (
          <>
            <div className="mb-1">
              {t('brendon.calculator.pregnancy.weekprogress')}
            </div>
            {weeks > 0 && (
              <span className="font-size-1-25 font-weight-bold">
                {weeks} {t('brendon.calculator.pregnancy.week')}{' '}
              </span>
            )}
            {days > 0 && (
              <span className="font-size-1-25 font-weight-bold">
                {data?.embryoAge?.days} {t('brendon.calculator.pregnancy.day')}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PragnancyResultBox;
