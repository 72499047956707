import React, { useEffect, useState } from 'react';
import { getURLPath } from '../../utils/staticPaths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import { useNavigate } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

const ForgotPasswordScreen = () => {
  const { t } = useResource();
  const [recoveryState, setRecoveryState] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigateTo = useNavigate();

  const onSubmitHandler = (data) => {
    console.log(data);
    // 0 - default state
    // 1 - valid email and sent
    // 2 - invalid email
    setRecoveryState(1);
  };

  useEffect(() => {
    if (recoveryState === 1) {
      navigateTo(getURLPath('forgotPasswordConfirm'));
    }
  }, [recoveryState]);
  const email = watch('email');

  return (
    <>
      <MetaHeader title={t('PageTitle.PasswordRecovery')} showBrendonAddon />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8 col-md-6 col-lg-4 mt-5">
          <h1 className="font-size-1-125 mb-4 text-center">
            {t('Account.PasswordRecovery')}
          </h1>
          {recoveryState === 1 && (
            <div className="text-center mb-4">
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                className="font-size-5 text-blue-medium"
              />
            </div>
          )}
          <div className="font-size-0-75">
            <form onSubmit={handleSubmit(onSubmitHandler)} className="mb-2">
              {recoveryState === 1 && (
                <>
                  <div className="mb-2 text-center">
                    {t('Brendon.PasswordRecovery.Sent')}
                  </div>
                  <div className="mb-4 font-size-1 text-center font-weight-bold">
                    {email}
                  </div>
                  <div className="formatted-content font-size-0-75">
                    <ul className="gray-bullet">
                      <li>{t('Brendon.PasswordRecovery.SentInfo1')}</li>
                      <li>{t('Brendon.PasswordRecovery.SentInfo2')}</li>
                      <li>
                        {t('Brendon.PasswordRecovery.ContactUs')}{' '}
                        <a
                          href={getURLPath('contactUs')}
                          target="_blank"
                          className="font-weight-medium font-normal"
                        >
                          {t('Brendon.PasswordRecovery.ContactUsLink')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {recoveryState !== 1 && (
                <>
                  <div className="mb-4">
                    {t('Account.PasswordRecovery.Tooltip')}
                  </div>

                  <div className="row form-group multiline">
                    <div className="col-12 text-gray d-flex justify-content-between">
                      <div>
                        <label htmlFor="email">
                          {t('Account.Login.Fields.Email')}: *
                        </label>
                      </div>
                      <span className="field-validation-error">
                        {errors?.email &&
                          t('account.passwordrecovery.email.required')}
                        {recoveryState === 2 && t('common.wrongemail')}
                      </span>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className={classNames('form-control', {
                          'input-validation-error':
                            errors?.email || recoveryState === 2,
                        })}
                        autoComplete="email"
                        {...register('email', {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    name="send-email"
                    className="btn btn-primary w-100 mb-4"
                    value={t('Account.PasswordRecovery.RecoverButton')}
                  />

                  {recoveryState === 0 && (
                    <div className="font-italic text-gray">
                      {t('brendon.form.requiredField')}
                    </div>
                  )}
                  {recoveryState === 2 && (
                    <div className="text-gray">
                      {t('Brendon.PasswordRecovery.ContactUs')}{' '}
                      <a
                        href={getURLPath('contactUs')}
                        target="_blank"
                        className="font-weight-medium"
                      >
                        {t('Brendon.PasswordRecovery.ContactUsLink')}
                      </a>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordScreen;
