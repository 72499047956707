import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import createEmailChangeSchema from '../../models/validation/customerEmailChangeSchema';
import {
  CustomerEmailChangeService,
  CustomerBaseDataService,
} from '../../services/customer';
import { executeCallback } from '../../utils/function';
import withStore from '../../hoc/withStore';
import { useDispatch } from 'react-redux';
import { showError } from '../../store/notification/actions';
import InputGroup from '../../elements/forms/InputGroup';
import PageLoader from '../PageLoader';
import InlineNotify from '../Elements/InlineNotify';
import { useResource } from '../../contexts/ResourceContext';

const CustomerChangeEmail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [successChange, setSuccessChange] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const dispatch = useDispatch();
  const { t } = useResource();

  useEffect(() => {
    const fetchData = async () => {
      const baseData = await CustomerBaseDataService.get();
      setUserData(baseData);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const save = useCallback(async (data) => {
    setIsLoading(true);
    const { Success, Errors } = await CustomerEmailChangeService.post(data);
    if (Success) {
      setSuccessChange(true);
      setNewEmail(data.Email);
    } else {
      if (Errors?.length > 0) {
        dispatch(showError(Errors.join(' ')));
      } else {
        dispatch(showError(t('brendon.customer.emailchange.error')));
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <PageLoader isLoading={isLoading}>
      <div className="row justify-content-center">
        <Formik
          enableReinitialize
          validationSchema={createEmailChangeSchema()}
          initialValues={{
            actualEmail: userData?.Email?.Email || '',
            email: successChange ? newEmail : '',
            emailConfirm: '',
          }}
          onSubmit={async (values, setSubmitting) => {
            executeCallback(save, {
              Email: values.email,
            });
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, validateForm }) => {
            return (
              <Form
                autoComplete="off"
                className="col-12 col-sm-6 col-md-5 col-lg-4"
              >
                <InputGroup
                  label={t('brendon.account.fields.currentemail')}
                  variation="wide"
                  name="actualEmail"
                  readonly
                />
                {successChange ? (
                  <>
                    <InputGroup
                      label={t('brendon.account.fields.newemail')}
                      variation="wide"
                      name="email"
                      readonly
                    />
                    <InlineNotify
                      message={t('brendon.customer.emailchange.needconfirm')}
                      variant={'success-light'}
                      className="mt-n2"
                    />
                  </>
                ) : (
                  <>
                    <InputGroup
                      label={t('brendon.account.fields.newemail')}
                      variation="wide"
                      name="email"
                      errorType="input-after-always"
                      feedbackIcons
                      required
                    />

                    <InputGroup
                      label={t('brendon.account.fields.emailconfirm')}
                      variation="wide"
                      name="emailConfirm"
                      errorType="input-after-always"
                      feedbackIcons
                      required
                    />
                    <div className="row form-group mt-4 mb-0">
                      <div className="col-12">
                        <input
                          type="submit"
                          value={t('Common.Save')}
                          name="save-info-button"
                          className="btn btn-primary btn-lg rounded-5 w-100 font-size-1"
                        />
                      </div>
                    </div>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </PageLoader>
  );
};

export default withStore(CustomerChangeEmail);
