import {handleActions} from "redux-actions";
import {
    setSelectedSize,
} from "./actions";
import {fromJS} from "immutable";

const reducer = handleActions(
    {
        [setSelectedSize]: (state, action) => state.set('selectedSize', action.payload || null),
    },
    fromJS({
        selectedSize: null,
    }),
    )
;

export default reducer;
