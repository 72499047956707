import React, { useState, useEffect } from 'react';
import { countDown } from '../utils/date';
import { splitStringifyNumber } from '../utils/string';
import withResource from '../hoc/withResource';

const CountDownTimer = ({timerDate, className, t}) => {
    const [countDownTime, setCountDownTime] = useState(countDown(timerDate));

    useEffect(() => {
        const interval = setInterval(() => {
            const result = countDown(timerDate);
            setCountDownTime(result);
            if (!result || result?.expired) {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [timerDate, setCountDownTime]);
 
 
    const days = splitStringifyNumber(countDownTime?.expired ? 0 : countDownTime?.days);
    const hours = splitStringifyNumber(countDownTime?.expired ? 0 : countDownTime?.hours);
    const minutes = splitStringifyNumber(countDownTime?.expired ? 0 : countDownTime?.minutes);
    const seconds = splitStringifyNumber(countDownTime?.expired ? 0 : countDownTime?.seconds);

    return (
        <>
        {countDownTime ? 
            <div className={ `countdown-timer ${className || ''}` }>
                <div className="countdown-timer__item">
                    <div className="countdown-timer__item-value">
                        <div className="countdown-timer__item-value-number">{days?.[0]}</div>
                        <div className="countdown-timer__item-value-number">{days?.[1]}</div>
                        <div className="countdown-timer__item-value-text">{ t('brendon.countdown.days') }</div>
                    </div>
                    <div className="countdown-timer__item-value">
                        <div className="countdown-timer__item-value-number">{hours?.[0]}</div>
                        <div className="countdown-timer__item-value-number">{hours?.[1]}</div>
                        <div className="countdown-timer__item-value-text">{ t('brendon.countdown.hours') }</div>
                    </div>
                    <div className="countdown-timer__item-value">
                        <div className="countdown-timer__item-value-number">{minutes?.[0]}</div>
                        <div className="countdown-timer__item-value-number">{minutes?.[1]}</div>
                        <div className="countdown-timer__item-value-text">{ t('brendon.countdown.minutes') }</div>
                    </div>
                    <div className="countdown-timer__item-value">
                        <div className="countdown-timer__item-value-number">{seconds?.[0]}</div>
                        <div className="countdown-timer__item-value-number">{seconds?.[1]}</div>
                        <div className="countdown-timer__item-value-text">{ t('brendon.countdown.seconds') }</div>
                    </div>
                </div>
            </div>
        : null}
        </>
    )
};

export default withResource(CountDownTimer);
