const axios = require('axios');
 
async function RegisterUser(postData) {
    const { data } = await axios.post(`/BrendonAjax/Register`, postData);
    return data
}
 
export const RegiserService = {
    RegisterUser,
};

export default RegiserService;