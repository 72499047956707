import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import LoginBox from '../../components/Login/LoginBox';
import RegisterBox from '../../components/Login/RegisterBox';
import GuestLogin from '../../components/Login/GuestLogin';
import { useLocation } from 'react-router-dom';
import { getURLPath } from '../../utils/staticPaths';
import { useResource } from '../../contexts/ResourceContext';

const LoginScreen = () => {
    const { t } = useResource();
    const location = useLocation();

    return (
        <>
            <MetaHeader title={t('PageTitle.Login')} showBrendonAddon />
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-5">
                    <div className="row justify-content-center">
                        <LoginBox />
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-5">
                    <div className="row justify-content-center">
                        <RegisterBox />
                    </div>
                    {location?.pathname === getURLPath('loginWithGuest') && (
                        <div className="row justify-content-center mt-5">
                            <GuestLogin />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LoginScreen;
