import React, {
    useState,
    createContext,
    useMemo,
    useCallback,
    useEffect,
} from 'react';
import ContentCarouselNav from './ContentCarouselNav';
import Carousel from '../Carousel';

export const ContentCarouselContext = createContext();

const ContentCarousel = ({
    className,
    classNameNav,
    defaultSelected = 0,
    enableInfinity = true,
    autoSliderSecond,
    navPosition = 'bottom',
    navItemConfig = {},
    children,
    scrollSnapAlign = 'start',
}) => {
    const [selected, setSelected] = useState(0);
    const [newSelected, setNewSelected] = useState(0);
    const maxItemNumber = useMemo(() => {
        return children?.length;
    }, [children]);

    useEffect(() => {
        setNewSelected(defaultSelected);
    }, [defaultSelected, setNewSelected, children]); 

    // no usecallback because of autoSliderSecond won't work if use useCallback
    const handleNext = () => {
        if (selected + 1 < maxItemNumber) {
            setSelected((prevState) => prevState + 1);
        } else if (enableInfinity) {
            setSelected(0);
        }
    };

    useEffect(() => {
        if (autoSliderSecond) {
            const interval = setInterval(() => {
                handleNext();
            }, autoSliderSecond * 1000);
            return () => clearInterval(interval);
        }
    }, [autoSliderSecond, handleNext]);

    /*    const handleNext = useCallback(() => {

        if (selected + 1 < maxItemNumber) {
            setSelected((prevState) => prevState + 1);
        } else if (enableInfinity) {
            setSelected(0);
        }
    }, [maxItemNumber, setSelected, selected, enableInfinity]);
 */
    const handlePrev = useCallback(() => {
        if (selected > 0) {
            setSelected((prevState) => prevState - 1);
        } else if (enableInfinity) {
            setSelected(maxItemNumber - 1);
        }
    }, [handleNext, setSelected, selected, enableInfinity]);

    const select = useCallback(
        (index) => {
            setSelected(index);
        },
        [setSelected, children],
    );

    return (
        <ContentCarouselContext.Provider value={{ selected, setSelected }}>
            <div className={`content-carousel ${className || ''}`}>
                {navPosition === 'top' && (
                    <ContentCarouselNav
                        className={classNameNav}
                        selected={selected}
                        maxItemNumber={maxItemNumber}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        navItemConfig={{ ...navItemConfig,
                            left: {
                                ...navItemConfig?.left,
                                visible: enableInfinity || (!enableInfinity && selected > 0),
                            },
                            right: {
                                ...navItemConfig?.right,
                                visible: enableInfinity || (!enableInfinity && selected < maxItemNumber - 1),
                            }
                        }}
                    />
                )}
                <Carousel
                    wrapperClass="content-carousel-wrap row flex-nowrap"
                    currentIndex={selected}
                    outSideSelectedIndex={newSelected}
                    onChange={select}
                    hidePager={true}
                    elementWrapperClass="col-12"
                    placeholder={<div className="col-12"></div>}
                    scrollSnapAlign={scrollSnapAlign}
                >
                    {children}
                </Carousel>
                {navPosition === 'bottom' && (
                    <ContentCarouselNav
                        className={classNameNav}
                        selected={selected}
                        maxItemNumber={maxItemNumber}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        navItemConfig={{ ...navItemConfig,
                            left: {
                                ...navItemConfig?.left,
                                visible: enableInfinity || (!enableInfinity && selected > 0),
                            },
                            right: {
                                ...navItemConfig?.right,
                                visible: enableInfinity || (!enableInfinity && selected < maxItemNumber - 1),
                            }
                        }}
                    />
                )}
            </div>
        </ContentCarouselContext.Provider>
    );
};

export default ContentCarousel;
