import React from 'react';
import BannerZone from '../../banners/BannerZone';
import TopMenu from './TopMenu';
import SearchAutoCompleteBox from '../SearchAutoCompleteBox/SearchAutoCompleteBox';
import HeaderLinks from './HeaderLinks';

const MainHeader = () => {
    return (
        <header className="container d-flex flex-column justify-content-between">
            <BannerZone bannerBlockId={7} />
            <div className="row align-items-center justify-content-md-between py-2 py-md-3 pb-lg-0">
                <TopMenu />
                <div className="col-12 col-md-4 col-lg-6 order-1 order-md-0 mt-2 mt-md-0">
                    <SearchAutoCompleteBox />
                </div>
                <div className="col col-md-auto">
                    <HeaderLinks />
                </div>
            </div>
            <div className="mx-n2 mx-sm-0 flex-grow-1 d-flex flex-column justify-content-end">
                <div className="border-bottom"></div>
            </div>
        </header>
    );
};

export default MainHeader;
