import React, {Component} from 'react';
import styled from 'styled-components';
import ArrowPager from "../elements/ArrowPager";

const Slider = styled.div`
    overflow: -moz-scrollbars-none;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-snap-type: x mandatory;
    
    ::-webkit-scrollbar { 
        display: none; 
    }
    
    > div {
        scroll-snap-align: ${props => props.scrollSnapAlign || 'start'};
    }
`;

const SliderWrapper = styled.div`
   
`;

const ArrowWrapper = styled.div`
    pointer-events: none;
`;

const ArrowSpacer = styled.div`
    padding-top: 100%;
`;

class Carousel extends Component {
    state = {
        currentIndex: 0,
        maxOffset: 0,
        maxIndex: 0,
        lastIndex: 0,
    };

    sliderRef = React.createRef();

    componentDidMount() {
        window.addEventListener('resize', this.refresh);

        if (window.ResizeObserver) {
            this.observer = new window.ResizeObserver(() => {
                this.refresh(null, this.props.currentIndex - this.state.currentIndex);
            });

            this.observer.observe(this.sliderRef.current, {attributes: true, childList: true, subtree: true});
        } else {
            this.interval = setTimeout(() => this.refresh(), 1000);
        }

        this.refresh();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.refresh);

        if (this.observer) {
            this.observer.disconnect();
        }

        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    componentDidUpdate(prevProps) {
        const { currentIndex, children } = this.props;

        if (prevProps.children?.length !== children?.length) {
            this.refresh();
        }
        if (currentIndex !== null && currentIndex !== prevProps.currentIndex && this.state.currentIndex !== currentIndex) {
            this.scrollTo(currentIndex);
        }
        if (prevProps.outSideSelectedIndex !== this.props.outSideSelectedIndex) {
             this.scrollTo(this.props.outSideSelectedIndex);
        }
    }

    refresh = (event, newIndex = 0) => {
        this.updateIndexes();
        this.scrollBy(newIndex);
    };

    scrollBy = (difference = 0) => {
        this.scrollTo(this.state.currentIndex + difference);
    };

    scrollTo = (index) => {
        const newIndex = this.clampIndex(index);

        const offset = this.getClampedOffset(newIndex);

        if (this.props.currentIndex !== newIndex) {
            this.onChange(index);
        }

        this.sliderRef.current.scroll({
            top: 0,
            left: offset,
            behavior: 'smooth',
        });
    };

    clampIndex = (index) => {
        return Math.min(Math.max(0, index), this.state.maxIndex);
    };

    updateIndexes = () => {
        const maxOffset = this.getMaxOffset();
        const width = this.getChildWidth();
        if (width !== 0) {
            const maxIndex = Math.round(maxOffset / width);
            this.setState({
                maxIndex,
            });
        }
    };

    getIndexFromOffset = (offset) => Math.round(offset / this.getChildWidth());

    getClampedIndexFromOffset = (offset) => this.clampIndex(this.getIndexFromOffset(offset));

    getLastOffset = () => this.getOffset(this.sliderRef.current.childNodes.length - 1);

    getLastWidth = () => this.getChildWidth(this.sliderRef.current.childNodes.length - 1);

    getMaxOffset = () => this.sliderRef.current ? Math.max(0, this.getLastOffset() + this.getLastWidth() - this.getSliderWidth()) : null;

    getSliderWidth = () => this.sliderRef.current.getBoundingClientRect().width;

    getChildWidth = (index = 0) => this.sliderRef.current.childNodes[index].getBoundingClientRect().width;

    getOffset = (index = 0) => !this.sliderRef.current ? 0 : (!this.sliderRef.current.childNodes[index] ? 0 : (this.sliderRef.current.childNodes[index].offsetLeft - this.sliderRef.current.offsetLeft));

    getClampedOffset = (index = 0) => Math.min(this.getMaxOffset(), this.getOffset(index));

    next = () => {
        this.scrollBy(1)
    };

    prev = () => {
        this.scrollBy(-1)
    };

    onScroll = (e) => {
        const offset = e.target.scrollLeft;
        const clampedIndex = this.getClampedIndexFromOffset(offset);
     
        this.setState({
            currentIndex: clampedIndex,
        });
        this.onChange(clampedIndex);
    };

    onChange = (index) => {
        if (this.props.onChange && this.state.currentIndex !== index) {
            this.props.onChange(index);
        }
    };
    render() {
        const {children, wrapperClass, onSelect, hidePagerOnMobile, elementWrapperClass, hidePager = false, scrollSnapAlign = 'start'} = this.props;
        const {currentIndex, maxIndex,} = this.state;

        return <div className="d-flex position-relative mx-n2 mx-sm-0 overflow-hidden">
            <div className="d-flex overflow-hidden flex-fill">
                <SliderWrapper className="w-100 px-2 px-sm-0">
                    <Slider
                        className={wrapperClass}
                        ref={this.sliderRef}
                        onScroll={this.onScroll}
                        scrollSnapAlign={scrollSnapAlign}>
                        {children.map((child, index) => <child.type {...child.props} key={index}
                                                                    onClick={() => onSelect && onSelect(index)}/>)}
                    </Slider>
                </SliderWrapper>
            </div>
            {!hidePager && <ArrowWrapper className="position-absolute w-100">
                <div className="row">
                    <div className={elementWrapperClass}>
                        <ArrowSpacer/>
                    </div>
                </div>
                <div className="position-absolute top left h-100 w-100">
                    <div className="d-flex align-items-center h-100">
                        <div className={`position-absolute`}>
                            <ArrowPager onClick={this.prev} hide={currentIndex === 0}
                                        hideOnMobile={hidePagerOnMobile}/>
                        </div>
                        <div className={`position-absolute right justify-content-end d-flex`}>
                            <ArrowPager onClick={this.next} hide={currentIndex === maxIndex} right
                                        hideOnMobile={hidePagerOnMobile}/>
                        </div>
                    </div>
                </div>
            </ArrowWrapper>}
        </div>
    }
}

export default Carousel;
