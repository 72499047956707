import React, {Component} from 'react';
import PagerItem from "./PagerItem";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft,faChevronRight,faChevronDoubleLeft,faChevronDoubleRight} from '@fortawesome/pro-solid-svg-icons';

const showPagesCount = 5;

class Pager extends Component {

    onPageSelected = (pageNumber) => {
        const {onPageSelected} = this.props
        onPageSelected(pageNumber)
    };

    render() {
        const {currentPage = 1, totalPages = 1, className = ''} = this.props;
        const firstPageNumber = Math.max(1, Math.min(totalPages - showPagesCount + 1, currentPage - Math.floor(showPagesCount / 2)));
        const lastPageNumber = Math.min(firstPageNumber + showPagesCount - 1, totalPages);

        const numberOfShownPages = lastPageNumber - firstPageNumber + 1;

        if (numberOfShownPages === 1) {
            return null;
        }

        return <div className={`d-flex mr-n1 ${className}`}>
            {firstPageNumber > 1 && <PagerItem pageNumber={1} className="mr-1" data-test-id="first-button" onPageSelected={this.onPageSelected}>
                <FontAwesomeIcon icon={faChevronDoubleLeft} />
            </PagerItem>}
            {currentPage > 1 && <PagerItem pageNumber={currentPage -1} className="mr-1" data-test-id="prev-button" onPageSelected={this.onPageSelected}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </PagerItem>}
            {Array.from(Array(numberOfShownPages).keys())
                .map(index => index + firstPageNumber)
                .map(currentPageNumber =>
                    <PagerItem key={currentPageNumber}
                               pageNumber={currentPageNumber}
                               isSelected={currentPageNumber === Number(currentPage)}
                               className="mr-1"
                               onPageSelected={this.onPageSelected}
                    >
                        {currentPageNumber}
                    </PagerItem>
                )
            }
            {currentPage < totalPages && <PagerItem pageNumber={currentPage + 1} className="mr-1" data-test-id="next-button" onPageSelected={this.onPageSelected}>
                <FontAwesomeIcon icon={faChevronRight} />
            </PagerItem>}
            {lastPageNumber < totalPages && <PagerItem pageNumber={totalPages} className="mr-1" data-test-id="last-button" onPageSelected={this.onPageSelected}>
                <FontAwesomeIcon icon={faChevronDoubleRight} />
            </PagerItem>}
        </div>
    }
}

export default Pager;
