import React, {Component} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
    pointer-events: none;
    padding: 10px;
`;

const Content = styled.div`
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.show ? 1 : 0};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
`;

class Popup extends Component {
    constructor(props) {
        super(props);
        this.popup = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.popup.current && !this.popup.current.contains(event.target)) {
            const {onClose, show} = this.props;
            if (onClose && show) {
                onClose();
            }
        }
    };

    render() {
        const {description, loginButtonText, loginUrl, show, onClose} = this.props;

        return <Container ref={this.popup}>
            <Content className={`shadow-lg bg-white p-3 position-relative rounded-lg`}
                     show={show}>
                <FontAwesomeIcon icon={faTimes} className="text-primary w-md h-md d-flex justify-content-center align-items-center cursor-pointer position-absolute right top mt-2 mr-2"
                         onClick={onClose}/>
                <div className="px-3 pt-3">
                    <div className="text-center">
                        <div className="mb-3 line-height-3">
                            {description}
                        </div>
                        <a href={loginUrl}
                           className="btn btn-primary w-auto px-4">
                            {loginButtonText}
                        </a>
                    </div>
                </div>
            </Content>
        </Container>
    }
}

export default Popup;
