import React from 'react';
import SocialButtons from './SocialButtons';
import { connect } from 'react-redux';
import withStore from "../../hoc/withStore";
import { selectFooterTopicList} from "../../store/megamenu/selectors";
import withThemeProvider from "../../hoc/withThemeProvider";
import {createStructuredSelector} from "reselect";
import { useResource } from '../../contexts/ResourceContext';
import {Link} from "react-router-dom";

const FooterTopics = ({ footerTopics  }) => {
    const { t } = useResource();
    return (
        <div className="row mb-md-4">
            <div className="col-12 col-md-4 col-lg-3">
                <h6 className="font-weight-normal text-uppercase line-height-4">{ t("Footer.Information") }</h6>
                <div className="line-height-4 font-size-0-8125 font-weight-medium mb-4">
                    { footerTopics && footerTopics?.map((topic, idx) => topic?.FooterColumn === 1 && <Link key={`fc1_${idx}`} to={`/${topic?.Slug}`} className="text-dark d-block">{topic?.Title}</Link>) }
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
                <h6 className="font-weight-normal text-uppercase line-height-4">{ t("Footer.CustomerService") }</h6>
                <div className="line-height-4 font-size-0-8125 font-weight-medium mb-4">
                    { footerTopics && footerTopics?.map((topic, idx) => topic?.FooterColumn === 2 && <Link key={`fc2_${idx}`} to={`/${topic?.Slug}`} className="text-dark d-block">{topic?.Title}</Link>) }
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
                <h6 className="font-weight-normal text-uppercase line-height-4">{ t("Footer.MyAccount") }</h6>
                <div className="line-height-4 font-size-0-8125 font-weight-medium mb-4">
                    { footerTopics && footerTopics?.map((topic, idx) => topic?.FooterColumn === 3 && <Link key={`fc3_${idx}`} to={`/${topic?.Slug}`} className="text-dark d-block">{topic?.Title}</Link>) }
                </div>
            </div>
            <div className="col-12 col-lg-3 mb-3 mt-md-4 mt-lg-0">
                <h6 className="font-weight-normal text-uppercase line-height-4">{ t("Footer.FollowUs") }</h6>
                <SocialButtons />
            </div>
        </div>
    )
}

const mapStateToProps = () => createStructuredSelector({
    footerTopics: selectFooterTopicList,
/*     isMegaMenuLoaded: isMegaMenuLoaded,
    isLoading: isLoading, */
});

/* const mapDispatchToProps = dispatch => ({
    getMegaMenu: () => dispatch(getMegaMenuAction()),
});
 */

export default withStore(connect(mapStateToProps, null)(withThemeProvider(FooterTopics)));
 