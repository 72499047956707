import { put, takeLatest } from 'redux-saga/effects';
import { success } from '../actions';
import { REMOVE_PRODUCT, ADD_PRODUCT } from './index';
import { updateWishlistCount } from '../cart/actions';
import { updateWishlistProducts } from './actions';

function* handleWishlistCount({ type, result }) {
  if (result?.WishListProductIds) {
    yield put(
      updateWishlistProducts(
        result?.WishListProductIds?.map((id) => ({ ProductId: id })) || [],
      ),
    );
  }
  yield put(updateWishlistCount(result?.WishlistCount || 0));
}

function* wishlistSaga() {
  yield takeLatest(success(ADD_PRODUCT), handleWishlistCount);
  yield takeLatest(success(REMOVE_PRODUCT), handleWishlistCount);
}

export default wishlistSaga;
