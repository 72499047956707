import React from 'react';
import { Outlet } from 'react-router-dom';
import RootLayoutContainer from './RootLayoutContainer';

const MainNoHeightLayout = () => {
  return (
    <RootLayoutContainer>
      <div className="main-content-no-height">
        <div className="mt-2 mb-4 mb-md-4">
          <Outlet />
        </div>
      </div>
    </RootLayoutContainer>
  );
};

export default MainNoHeightLayout;
