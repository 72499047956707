/**
 * @type {string} [EventType='view_item_list' | 'view_item' | 'add_to_cart' | 'purchase' | 'remove_from_cart']
 */

/**
 * @typedef {Object} PurchaseItem
 *
 * @property {string} item_id - Item ID. Required.
 * @property {string} item_name - Item name. Required.
 * @property {string} affiliation - Item affiliation.
 * @property {string} coupon - Coupon code.
 * @property {number} discount - Discount amount. E.g.: 10.5
 * @property {string} item_brand - Item brand.
 * @property {string} item_category - Item category.
 * @property {string} item_category2 - Item category2.
 * @property {string} item_category3 - Item category3.
 * @property {string} item_category4 - Item category4.
 * @property {string} item_category5 - Item category5.
 * @property {string} item_list_id - Item list ID, where the item was presented.
 * @property {string} item_list_name - Item list name, where the item was presented.
 * @property {string} item_variant - The item variant or unique code or description for additional item details/options.
 * @property {string} location_id - Physical location / Google Place ID
 * @property {number} price - Item price. E.g.: 10.5
 * @property {number} quantity - Item quantity. E.g.: 2
 */

/**
 * @typedef {Object} PurchaseEventParam
 *
 * @property {string} transaction_id - Transaction ID. Required.
 * @property {string} currency - Currency (HUF, EUR). Required.
 * @property {number} value - Transaction value /  Currency is required if you set value. Required.
 * @property {string} coupon - Coupon code.
 * @property {number} shipping - Shipping cost. E.g.: 10.5
 * @property {number} tax - Tax cost. E.g.: 10.5
 * @items {PurchaseItem[]} items - Purchase items.
 *
 */

/**
 * @typedef {Object} ItemListItem
 *
 * @property {string} item_id - Item ID. Required.
 * @property {string} item_name - Item name. Required.
 * @property {string} affiliation - Item affiliation.
 * @property {string} coupon - Coupon code.
 * @property {number} discount - Discount amount. E.g.: 10.5
 * @property {string} item_brand - Item brand.
 * @property {string} item_category - Item category.
 * @property {string} item_category1 - Item category.
 * @property {string} item_category2 - Item category.
 * @property {string} item_category3 - Item category.
 * @property {string} item_category4 - Item category.
 * @property {string} item_category5 - Item category.
 * @property {string} item_list_id - Item list ID, where the item was presented.
 * @property {string} item_list_name - Item list name, where the item was presented.
 * @property {string} item_variant - The item variant or unique code or description for additional item details/options.
 * @property {string} location_id - Physical location / Google Place ID
 * @property {number} price - Item price. E.g.: 10.5
 * @property {number} quantity - Item quantity. E.g.: 2
 *
 */

/**
 * @typedef {Object} ItemList
 *
 * @property {string} item_list_id - Item list ID. Required.
 * @property {string} item_list_name - Item list name. Required.
 * @property {ItemListItem[]} items - Item list items.
 */

/**
 * @param {string} event - Just 'event' string.
 * @param {string} eventName - Event name. (e.g. 'add_to_cart')
 * @param {PurchaseEventParam | ItemList} eventParam - Depends on event type e.g: screen_view event:  {'app_name': 'myAppName', 'screen_name': 'Home'}
 */

const resetEcommerceData = () => window.dataLayer.push({ ecommerce: null });

function gTagPush() {
    // automatic GA4 or UA selection
    if (!window.gtag) {
        return null;
    }
    return window.gtag.apply(null, arguments);
}

/**
 * @param {EventType} eventName - Event name. (e.g. 'add_to_cart')
 * @param {PurchaseEventParam} eventParam - Depends on event type e.g: screen_view event:  {'app_name': 'myAppName', 'screen_name': 'Home'}
 */
function gtagEvent(eventName, eventParam, eventCallback = () => {}, eventMode = 'dataLayer') {
    if (eventMode === 'dataLayer') {
        resetEcommerceData();
        return window.dataLayer.push({
            event: eventName,
            ecommerce: { ...eventParam },
            eventCallback,
        });
    } else if (eventMode === 'gtag') {
        return gTagPush('event', eventName, eventParam);
    }
}

function transformOrderData(orderId, orderData) {
    return {
        transaction_id: orderId,
        currency: orderData?.CurrencyCode,
        value: orderData?.Revenue,
        shipping: orderData?.Shipping,
        items: orderData?.Products?.map((product) => ({
            item_id: product?.ProductId,
            item_name: product?.ProductName,
            item_brand: product?.Brand,
            item_category: product?.CategoryName,
            item_variant: product?.Variant,
            price: product?.Price,
            quantity: product?.Quantity,
        })),
    };
}

/**
 * @typedef {Object} ProductInfo
 * @property {string} ProductID - Product ID.
 * @property {string} Brand - Product brand.
 * @property {string} Name - Product name
 * @property {string} Function - Product function.
 * @property {number} Price - Product default price.
 * @property {number} DiscountedPrice - Product discounted price.
 * @property {string} CategoryName - Product category name.
 */

/**
 *
 * @param {string} itemListId - Item list ID. Required.
 * @param {string} itemListName - Item list name. Required.
 * @param {string} categoryName - Item category.
 * @param {ProductInfo} productInfo - Item list items.
 */

function transformProductInfoToItemListItem(
    itemListId,
    itemListName,
    categoryName,
    productInfo,
) {
    return {
        item_id: productInfo.ProductID,
        item_name: productInfo.Name,
        item_brand: productInfo.Brand,
        price:
            productInfo.DiscountedPrice > 0
                ? productInfo.DiscountedPrice
                : productInfo.Price,
        discount:
            productInfo.DiscountedPrice > 0
                ? productInfo.Price - productInfo.DiscountedPrice
                : 0,
        item_list_id: itemListId,
        item_list_name: itemListName,
        item_category: categoryName,
        quantity: 1,
    };
}

/**
 * @typedef {Object} ProductCard
 * @property {string} ProductId - Product ID.
 * @property {string} Brand - Product brand.
 * @property {string} Model - Product model nameame.
 * @property {string} Function - Product function.
 * @property {string} Design - Product name.
 * @property {number} Price - Product default price.
 * @property {number} DiscountedPrice - Product discounted price.
 * @property {any} ParentCategory - Product parent category.
 */

/**
 * @param {string} itemListId - Item list ID. Required.
 * @param {string} itemListName - Item list name. Required.
 * @param {string} categoryName - Item category.
 * @param {ProductCard} product - Item list items.
 */

function transformProductToItemListItem(itemListId, itemListName, product) {
    if (!product) return {};
    const productName = product?.Name
        ? product.Name
        : `${product.Brand} ${product.Model} ${product.Function} ${product.Design}`;
    return {
        item_id: product.ProductId,
        item_name: productName,
        item_brand: product.Brand,
        price:
            product.DiscountedPrice > 0
                ? product.DiscountedPrice
                : product.Price,
        discount:
            product.DiscountedPrice > 0
                ? product.Price - product.DiscountedPrice
                : 0,
        item_list_id: itemListId,
        item_list_name: itemListName,
        item_category: product?.ParentCategory?.Name || null,
        quantity: product?.Quantity || 1,
    };
}

function transformShoppingCartEvaluetionToItem(shoppingCart, currency) {
    return {
        currency: currency,
        value: shoppingCart?.AmountWithShippingFee,
        items: shoppingCart?.ShoppingCartItems?.map((item) => ({
            ...transformProductToItemListItem(
                'shopping-cart-items',
                'Shopping cart items',
                item,
            ),
            variant: item?.Size || null,
        })),
    };
}

export default {
    gtagEvent,
    transformOrderData,
    transformProductInfoToItemListItem,
    transformProductToItemListItem,
    transformShoppingCartEvaluetionToItem,
};
