import React from "react";
import LazyImage from "../elements/LazyImage";
import AddToWishlistIcon from "./AddToWishlistIcon";
import AddToCartIcon from "./AddToCartIcon";
import ProductInfo from "../product/ProductInfo";
import ProductBoxName from "./ProductBoxName";
import ProductBoxModel from "./ProductBoxModel";
import { formatPrice } from '../utils/price';
import InfoTooltip from '../components/InfoTooltip';
import { useResource } from '../contexts/ResourceContext';
import MoreCategories from './MoreCategories';
import {Link} from "react-router-dom";

function ProductBox({product, categoryName, useBoxOnMobile, selectedOrderBy, gaEEList, enableUpsell }) {
    const { t } = useResource();

    // TODO: need to change to 'const' and useMemo 
    let name;
    let productUrl;
    let pictureUrl;
    let productId;
    let productData;

    
    name = product.Model ? `${product.Brand} ${product.Model} ${product.Design} ${product.Function}` : product.Name;
    productUrl = product?.ProductUrl ? `/${product.ProductUrl}`: `/${product.Url}`;
    pictureUrl = product.Image;
    productId = product.ProductId || product.ProductID;
    productData = {
        OnlyInStore: product?.Stockinfo?.toLowerCase() === 'onlyinstore' ? true: false,
        Discount: product?.DiscountValue ? product?.DiscountValueFormatted : (product?.DiscountPercent ? product?.DiscountPercentFormatted : ''),
        CampaignDesc: product?.Campaigns,
        Informations: product?.Informations,
        ColorNo: product?.ColorCount,
        Sizes: product?.Sizes,
        CardExtraInfo: product?.CardExtraInfo,
    }
{/* <div className="row"> */}
    return <div className={`row ${useBoxOnMobile ? "flex-column" : "flex-row flex-md-column"} overflow-hidden p-2 text-break flex-1`}>
        <div className={`${useBoxOnMobile ? " col-auto" : " col-5 col-md-auto pr-0 pr-md-2"} w-100`}>
            <div className="overflow-hidden position-relative">
                <Link to={productUrl}
                   title={name}
                   className="aspect-ratio-square d-block">
                    <LazyImage alt={name} title={name} src={pictureUrl} />
                </Link>
            </div>
        </div>
        <div className={`${useBoxOnMobile ? "col-auto" : "col-7 col-md-auto"} w-100 d-flex flex-fill`}>
            <div className="d-flex flex-column pt-md-2 flex-fill">
                <div className="pb-2 flex-fill">
                    {/* {product?.Model || product?.model ?
                        <>
                            <Link to={productUrl}
                                className="d-block text-dark mb-2 line-height-rem-1-125">
                                <h2 className="mb-0 font-size-0-875 font-weight-normal">
                                    <div className="font-weight-medium text-uppercase">
                                        {product?.Brand || product?.brand}
                                    </div>
                                    <div>
                                        {product?.Model || product?.model} {product?.Design || product?.design}
                                    </div>
                                    <div>
                                        {product?.ModelFunction || product['Function'] ? product['Function'] : ''}
                                    </div>
                                </h2>
                            </Link>
                        </>
                        : <>
                            <h2 className="mb-2 font-weight-medium overflow-hidden font-size-0-875">
                                <Link to={productUrl}
                                    className="text-dark">{name}</Link>
                            </h2>
                        </>
                    } */}
                    {selectedOrderBy === 'model' ?
                        <ProductBoxModel product={product} name={name} productUrl={productUrl} />
                        :
                        <ProductBoxName product={product} productUrl={productUrl} name={name}/>
                    }
                    {productData
                        ? <ProductInfo productData={productData}/>
                        : <div className="font-size-0-75 formatted-content text-gray mb-2"
                               dangerouslySetInnerHTML={{__html: product.ShortDescription}}/>}
                </div>
                <div className="row justify-content-between align-items-end">
                    <div className="col font-size-0-875 font-weight-medium line-height-3 text-dark">
                        {product.RRP > 0 && <div className="font-size-0-75 font-weight-normal">
                            RRP: {formatPrice(product.RRP)} <InfoTooltip id={`productrrp_${productId}`} content={t('brendon.product.rrp')} className="ml-1"/>
                        </div>}
                        {product?.DiscountedPriceFormatted && product?.DiscountedPriceFormatted != ""
                            ? <>
                                { product?.SuperPrice
                                ? <div className="text-pink">{t('brendon.product.superprice')}</div>
                                :
                                <div className="text-line-through font-size-0-75 font-weight-normal">
                                    {product.PriceFormatted}
                                </div>
                                }
                                <div className="font-size-1 text-pink">{product.DiscountedPriceFormatted}</div>
                            </>
                            : Number(product?.Price) > 0 && <div className="font-size-1">{product?.PriceFormatted}</div>
                        }
                    </div>
                    <div className="col flex-grow-0">
                        <div className="d-flex mx-n2 pr-2 align-items-center">
                            {product.IsWishlistButton && <div className="px-2">
                                <AddToWishlistIcon productId={ productId }/>
                            </div>}
                            {(!product.DisableBuyButton && ['onlyinstore', 'notavailable'].indexOf(product?.Stockinfo?.toLowerCase()) === -1) && <div className="pl-2-5">
                                <AddToCartIcon productId={ productId } product={product} categoryName={categoryName} gaEEList={gaEEList} className="px-2" enableUpsell={enableUpsell} />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="d-none d-sm-block d-md-none"><MoreCategories text={product.CategoryText} link={`/${product.CategorySlug}`} /></div>

            </div>
        </div>
    </div>
}

export default ProductBox;