import { callAxios } from './helper/axiosHelper'

const pageMeta = {
    post: async (data) => callAxios('post', `/Api/PageMeta`, data),
}

const resourcesInfo = {
    get: async () => callAxios('get', process.env.BACKEND_API_REFRESH_RESOURCES),
}
const resources = {
    get: async () => callAxios('get', process.env.BACKEND_API_RESOURCES),
}

export default {
    pageMeta,
    resourcesInfo,
    resources,
}