import {Component} from 'react'
import {connect} from 'formik'

function getNames(errors) {
    const keys = Object.keys(errors);

    const names = [];
    
    keys.forEach(key => {
        if (typeof errors[key] === 'object') {
            const subNames = getNames(errors[key]);
            subNames.forEach(name => {
               names.push(key + '.' + name); 
            });
        } else {
            names.push(key);
        }
    });

    return names;
}

class ErrorFocus extends Component {
    componentDidUpdate(prevProps) {
        const {isSubmitting, isValidating, errors} = prevProps.formik
        const invalidInputNames = getNames(errors);

        if (invalidInputNames.length > 0 && isSubmitting && !isValidating) {
            const invalidInputs = [];

            document.querySelectorAll('input').forEach(input => {
                if (invalidInputNames.includes(input.name)) {
                    invalidInputs.push(input);
                }
            });
            
            if(invalidInputs.length > 0) {
                const formGroup = invalidInputs[0].closest('.form-group');
                if(formGroup) {
                    setTimeout(() => {
                        formGroup.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }, 0);   
                }
            }
        }
    }

    render() {
        return null
    }
}

export default connect(ErrorFocus)