import React from 'react';
import ProductInfoBlocksV2 from './ProductInfoBlocksV2';
import { useResource } from '../contexts/ResourceContext';

function ProductInfo({ productData }) {
  const { t } = useResource();
  const {
    Sizes,
    OnlyInStore,
    CardExtraInfo,
  } = productData;

  return (
    <div className="font-size-0-75">
      {Array.isArray(Sizes) && Sizes.length > 0 && (
        <div className="mb-1">
          {t('brendon.productInfo.sizes')}&nbsp;
          {Sizes.map((size, index) => (
            <span
              key={index}
              className={
                size?.Available || parseInt(size?.available, 10) === 1
                  ? 'font-weight-bold'
                  : ''
              }
            >
              {size?.Name || size?.name}
            </span>
          )).reduce(
            (prev, curr, index) => prev.concat(index ? [', ', curr] : curr),
            [],
          )}
        </div>
      )}

      {CardExtraInfo && (
        <>
          <ProductInfoBlocksV2 extraInfos={CardExtraInfo} variant="list" />
        </>
      )}

      {OnlyInStore && (
        <div className="mb-1">{t('brendon.productInfo.onlyInStore')}</div>
      )}
    </div>
  );
}

export default ProductInfo;
