import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

class FormGroup extends Component {
    onChange = (value) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }
    };

    render() {
        const {
            children,
            validatingMessage,
            containerClass,
            labelClass,
            inputContainerClass,
            descriptionClass,
            labelItemClass,
            errorContainerClass,
            feedbackIcons = false,
            errorType = 'label-after', // label-after, input-after, input-after-always
        } = this.props;

        const {
            fieldProps: { field, form, meta },
            label,
            required,
            description,
        } = this.props;

        const calculatedLabel =
            label ||
            (form?.status && form?.status?.labels?.[field?.name]) ||
            null;
        const backendError =
            (form.status && form.status.backendErrors?.[field?.name]) || null;
        const error =
            form.isValidating && validatingMessage
                ? validatingMessage
                : meta.touched
                ? meta.error
                : backendError;
        const validated = meta.touched && !error;

        return (
            <div
                className={classNames(
                    containerClass
                        ? containerClass
                        : 'row form-group multiline',
                )}
            >
                <div
                    className={
                        labelClass
                            ? labelClass
                            : 'col-12 text-gray d-flex justify-content-between'
                    }
                >
                    <div>
                        <label
                            htmlFor={field.name}
                            className={
                                labelItemClass ? labelItemClass : 'text-left'
                            }
                        >
                            {calculatedLabel}{' '}
                            {required && <span className="required">*</span>}
                        </label>
                    </div>
                    {errorType === 'label-after' && (
                        <span
                            className={`field-validation-error ${
                                errorContainerClass
                                    ? errorContainerClass
                                    : 'text-right'
                            }`}
                        >
                            <span
                                id={`${field.name}-error`}
                                className="field-error"
                            >
                                {error}
                            </span>
                        </span>
                    )}
                    {errorType === 'input-after' && (
                        <div className="text-right text-danger d-lg-none pl-4">
                            {error}
                        </div>
                    )}
                </div>
                {errorType === 'input-after' && (
                    <div
                        className={`${
                            errorContainerClass
                                ? errorContainerClass
                                : 'd-none d-lg-block col-lg-3 order-0 text-right text-lg-left order-lg-last text-danger font-size-0-75 line-height-3 py-1'
                        }`}
                    >
                        {error}
                    </div>
                )}
                {errorType === 'input-after-always' && (
                    <div
                        className={`${
                            errorContainerClass
                                ? errorContainerClass
                                : 'col-12 order-last text-danger font-size-0-75 line-height-3 py-1'
                        }`}
                    >
                        {error}
                    </div>
                )}
                <div
                    className={
                        inputContainerClass ? inputContainerClass : 'col-12'
                    }
                >
                    {feedbackIcons && (
                        <>
                            <div className="inner-addon right-addon">
                                {error && (
                                    <div className="icon text-danger">
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                        />
                                    </div>
                                )}
                                {validated && (
                                    <div className="icon text-success">
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {children}
                </div>
                {description && (
                    <div
                        className={
                            descriptionClass
                                ? descriptionClass
                                : 'font-size-0-75 text-gray mt-2'
                        }
                    >
                        {description}
                    </div>
                )}
            </div>
        );
    }
}

export default FormGroup;
