import React from 'react';
import MetaHeader from '../../components/MetaHeader/MetaHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';
import { useResource } from '../../contexts/ResourceContext';

const RegisterSuccessScreen = () => {
    const { t } = useResource();
    return (
        <>
            <MetaHeader title={t('PageTitle.Register')} showBrendonAddon />

            <div className="row justify-content-center mb-3">
                <div className="col-7 col-sm-8 col-md-6 col-lg-4 mt-5">
                    <h1 className="font-size-1-125 mb-4 text-center">
                        {t('Account.Register')}
                    </h1>
                    <div className="text-center mb-4">
                        <FontAwesomeIcon
                            icon={faEnvelopeOpenText}
                            className="font-size-5 text-blue-medium"
                        />
                    </div>
                    <div className="text-center font-size-0-75" dangerouslySetInnerHTML={{__html: t('Account.Register.Content.ThankYou')}}></div>
                </div>
            </div>
            <div className="row justify-content-center mb-5">
                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                    <div className="text-center text-uppercase font-size-0-87 mb-5">
                        <strong>{t('Account.Register.Content.Confirm')}</strong>
                    </div>
                    <div className="formatted-content font-size-0-75">
                        <ul className="gray-bullet">
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'Account.Register.Content.InfoList1',
                                    ),
                                }}
                            ></li>
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'Account.Register.Content.InfoList2',
                                    ),
                                }}
                            ></li>
                            <li
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'Account.Register.Content.InfoList3',
                                    ),
                                }}
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterSuccessScreen;
