import React from 'react';
import { getURLPath } from '../utils/staticPaths';
/* Pages / Screens */
import CustomerInfoScreen from '../views/customer/CustomerInfoScreen';
import CustomerRewardPointsScreen from '../views/customer/CustomerRewardPointsScreen';
import CustomerOrderHistoryScreen from '../views/customer/CustomerOrderHistoryScreen';
import CustomerOrderDetailsScreen from '../views/customer/CustomerOrderDetailsScreen';
import CustomerChangePasswordScreen from '../views/customer/CustomerChangePasswordScreen';
import CustomerLogout from '../views/customer/CustomerLogout';
import CustomerDashboardScreen from '../views/customer/CustomerDashboardScreen';
import CustomerChangeEmailScreen from '../views/customer/CustomerChangeEmail';
import { withAuthGuard } from '../contexts/AuthProvider';

export default [
    {
        path: getURLPath('customerDashboard'),
        Component: withAuthGuard(CustomerDashboardScreen),
    },
    {
        path: getURLPath('customerProfile'),
        Component: withAuthGuard(CustomerInfoScreen),
    },
    {
        path: getURLPath('customerRewards'),
        Component: withAuthGuard(CustomerRewardPointsScreen),
    },
    {
        path: getURLPath('customerOrders'),
        Component: withAuthGuard(CustomerOrderHistoryScreen),
    },
    {
        path: getURLPath('customerOrderDetails'),
        Component: withAuthGuard(CustomerOrderDetailsScreen),
    },
    {
        path: getURLPath('customerChangeEmail'),
        Component: withAuthGuard(CustomerChangeEmailScreen),
    },
    {
        path: getURLPath('customerChangePassword'),
        Component: withAuthGuard(CustomerChangePasswordScreen),
    },
    {
        path: getURLPath('logout'),
        Component: withAuthGuard(CustomerLogout),
    },
];
