import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import ShopFinderService from '../../services/shopfinder';
import Loader from '../Loader';
import ShopMap from './ShopMap';
import { getDomainStoreId, getStoreDateFormatVisual } from '../../utils/localization';
import BannerZone from '../../banners/BannerZone';
import { useResource } from '../../contexts/ResourceContext';

const ShopDetails = ({ shopId }) => {
    const { t } = useResource();
    const [shopData, setShopData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(() => {
        const getShop = async () => {
            const responseData = await ShopFinderService.select(shopId);
            setShopData(responseData);
            setIsLoading(false);
        };
        setIsLoading(true);
        getShop();
    }, [shopId, setIsLoading]);

    // TODO: move to utils (calculator has the same function, but needs to update)
    const formatDate = useCallback((date) => {
        if (!date) return;
        const format = getStoreDateFormatVisual();
        const dateParts = date.split('-');
        return format
            .replace('yyyy', dateParts[0])
            .replace('MMMM', t(`brendon.months.${Number(dateParts[1])}`))
            .replace('dd', Number(dateParts[2]));
    }, []);

    const fullAddress = useMemo(
        () =>
            getDomainStoreId() == 1
                ? `${shopData?.City} ${shopData?.Postcode} ${shopData?.Address}`
                : `${shopData?.Address} ${shopData?.Postcode} ${shopData?.City}`,
        [shopData],
    );

    return (
        <>
            <Helmet title={shopData?.StoreName} />
            {isLoading ? (
                <Loader isLoading />
            ) : (
                <div className="shopfinder-details mt-4">
                    <h1 className="font-size-1-5 text-uppercase text-center">
                        {shopData?.StoreName}
                    </h1>
                    <div className="row">
                        <div className="col-12 col-sm-8 offset-sm-2 text-center text-uppercase">
                            <h2 className="font-size-1-125 mb-2-5">
                                {shopData?.ShopType}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="d-block-inline text-center"
                                dangerouslySetInnerHTML={{
                                    __html: shopData?.DescriptionHTML,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="mt-4 row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-4">
                                <h3 className="mb-2-5 font-size-1-125">
                                    {t(
                                        'brendon.shopfinder.shopdetails.address',
                                    )}
                                    :
                                </h3>
                                <div>
                                    {fullAddress}
                                </div>
                                <div>{shopData?.AddressExtension}</div>
                            </div>
                            <div className="mb-4">
                                <h3 className="mb-2-5 font-size-1-125">
                                    {t(
                                        'brendon.shopfinder.shopdetails.bytransport',
                                    )}
                                    :
                                </h3>
                                <div className="mb-2-5">
                                    {shopData?.ByCarDescription}
                                </div>
                                <div>{shopData?.ByPublicTransport}</div>
                            </div>

                            <div className="mb-4">
                                <h3 className="mb-2-5 font-size-1-125">
                                    {t('brendon.shops.openinfo.now')}{' '}
                                    <span
                                        className={`${
                                            shopData.IsOpen
                                                ? 'text-green'
                                                : 'text-red'
                                        }`}
                                    >
                                        {shopData?.OpenLabel}
                                    </span>
                                </h3>
                                <div>{shopData?.OpenInfo}</div>
                            </div>
                            {shopData?.SpecialOpeningInfos?.length > 0 && (
                                <div className="mb-4">
                                    <h3 className="mb-2-5 font-size-1-125">
                                        {t(
                                            'brendon.shopfinder.shopdetails.specialopening',
                                        )}
                                        :
                                    </h3>
                                    {shopData?.SpecialOpeningInfos.map(
                                        (openInfo, index) => (
                                            <div
                                                key={index}
                                                className={`d-flex mb-1 py-2 px-2-5 font-weight-bold rounded text-white ${
                                                    openInfo?.IsOpen
                                                        ? 'bg-green'
                                                        : 'bg-red'
                                                }`}
                                            >
                                                <div className="flex-1">
                                                    {formatDate(
                                                        openInfo.SpecDate,
                                                    )}{' '}
                                                    {openInfo.SpecDateName}
                                                </div>
                                                <div className="text-uppercase">
                                                    {openInfo?.OpenString}
                                                </div>
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}

                            {shopData?.OpenInfos?.length > 0 && (
                                <div className="mb-4">
                                    <h3 className="mb-2-5 font-size-1-125">
                                        {t(
                                            'brendon.shopfinder.shopdetails.opening',
                                        )}
                                        :
                                    </h3>
                                    {shopData?.OpenInfos.map(
                                        (openInfo, index) => (
                                            <div
                                                key={index}
                                                className="d-flex bg-gray-light mb-1 py-2 px-2-5 font-weight-bold rounded"
                                            >
                                                <div className="flex-1">
                                                    {openInfo.NameOfDay}
                                                </div>
                                                <div>
                                                    {openInfo?.OpenString}
                                                </div>
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}
                            <div className="mb-4">
                                <div
                                    className="services"
                                    dangerouslySetInnerHTML={{
                                        __html: shopData?.ShopBottomDescription,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-3">
                                <h3 className="mb-2-5 font-size-1-125">
                                    {t('brendon.shopfinder.shopdetails.map')}:
                                </h3>
                                <ShopMap
                                    mapHtmlObject={shopData?.MapHtmlObject}
                                />
                            </div>
                            {shopData?.PictureUrl && (
                                <img
                                    src={shopData?.PictureUrl}
                                    className="img-fluid mb-3"
                                    alt={shopData?.StoreName}
                                    title={shopData?.StoreName}
                                />
                            )}
                            {/*    <img
                                src="https://nopimages.azureedge.net/nopstage2/33537301_brendon-maternity-jade-112-red-59-kismama-haloing-brendon-33537301_600.jpg"
                                className="img-fluid mb-3"
                                alt=""
                            />
                            <img
                                src="https://nopimages.azureedge.net/nopstage2/33537301_brendon-maternity-jade-112-red-59-kismama-haloing-brendon-33537301_600.jpg"
                                className="img-fluid mb-3"
                                alt=""
                            /> */}
                        </div>
                    </div>
                    <BannerZone bannerBlockId={20} />
                </div>
            )}
        </>
    );
};

export default ShopDetails;
