import React, { useCallback, useMemo, useState } from 'react';
import DatePicker from '../../../Elements/Datepicker';
import { getImagePath } from '../../../../utils';
import {
  getStoreDateFormat,
  getStoreDateFormatCalendar,
} from '../../../../utils/localization';
import { useResource } from '../../../../contexts/ResourceContext';

const CalculatorForm = ({
  menstrualPeriod,
  setMenstrualPeriod,
  cycleLength,
  setCycleLength,
  blurCycleLength,
  onSubmit,
  className = 'my-7',
  classNameForm = 'bg-calculator-red-light',
  classNameSubmit = 'btn-calculator-red',
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { t } = useResource();

  const handleDateChange = useCallback(
    (value) => {
      setMenstrualPeriod(value);
      setOpenDatePicker(false);
    },
    [setMenstrualPeriod],
  );

  const dateFormat = useMemo(() => getStoreDateFormat(), []);
  const dateFormatCalendar = useMemo(() => getStoreDateFormatCalendar(), []);

  return (
    <div className={`calculator__form ${className || ''}`}>
      <div className={`rounded p-4 ${classNameForm || ''}`}>
        <div className="row align-items-center mb-3 mb-sm-2">
          <div className="col-12 col-md-8 mb-2 mb-md-0">
            <label
              htmlFor="menstrualPeriod"
              className="line-height-rem-1 font-weight-bold"
            >
              {t('brendon.calculator.ovulation.form.lastmenstrualperiod')}
            </label>
          </div>
          <div className="col-12 col-md-4">
            <DatePicker
              value={menstrualPeriod}
              onChange={handleDateChange}
              open={openDatePicker}
              disableMinDate={true}
              onCalendarClose={() => setOpenDatePicker(false)}
              onInputClick={() => setOpenDatePicker(true)}
              onClickOutside={() => setOpenDatePicker(false)}
              dateFormat={dateFormat}
              dateFormatCalendar={dateFormatCalendar}
            >
              <div
                className="oc-calendar position-absolute top"
                onClick={() => setOpenDatePicker(true)}
              >
                <img src={getImagePath('calendar-days-light.svg')} alt="" />
              </div>
            </DatePicker>
          </div>
        </div>
        <div className="row align-items-center mb-4 mb-sm-3">
          <div className="col-12 col-md-8 mb-2 mb-md-0">
            <label
              htmlFor="cycleLength"
              className="line-height-rem-1 font-weight-bold"
            >
              {t('brendon.calculator.ovulation.form.cycledays')}
            </label>
          </div>
          <div className="col-12 col-md-4">
            <input
              type="number"
              className="form-control text-center"
              id="cycleLength"
              value={cycleLength}
              onChange={(e) => setCycleLength(e.target.value)}
              onBlur={(e) => {
                blurCycleLength(e.target.value);
              }}
              max="45"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              onClick={onSubmit}
              className={`btn btn-block text-uppercase text-white ${
                classNameSubmit || ''
              }`}
            >
              {t('brendon.calculator.ovulation.form.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorForm;
