import React from 'react';

function Header({children}) {
    return <>
        <h5 className="mb-3 text-uppercase font-weight-medium font-size-1 line-height-3">{children}</h5>
        </>

}

export default Header;
