import React from 'react';
import BlogTitle from '../../components/Blog/BlogTitle';
import BlogList from '../../components/Blog/BlogList';
import BlogHtmlMeta from '../../components/Blog/BlogHtmlMeta';
import BlogSubTitle from '../../components/Blog/BlogSubTitle';
import { useSelector } from 'react-redux';
import { getShopStoreId } from '../../store/shopstore/selectors';
import { useResource } from '../../contexts/ResourceContext';

const BlogSearhScreen = () => {
    const { t } = useResource();
    const searchParam = new URLSearchParams(window.location.search);
    const storeId = useSelector(getShopStoreId);
    
    return (
        <>
            <BlogHtmlMeta />
            <div className="blog">
                <BlogTitle storeId={storeId} />
                <BlogSubTitle
                    type="query"
                    className="text-uppercase"
                    title={`${t('brendon.blog.search.query')} "${searchParam.get(
                        'q',
                    )}"`}
                />
                <BlogList
                    storeId={storeId}
                    searchString={searchParam.get('q')}
                />
            </div>
        </>
    );
};

export default BlogSearhScreen;
