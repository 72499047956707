import React, { useCallback } from 'react';
import { executeCallback } from '../../utils/function';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons';

const CheckoutStepBack = ({ title, onClick }) => {
    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            if (onClick) {
                executeCallback(onClick);
            } else {
                window.history.back();
            }
        },
        [onClick],
    );

    return (
        <div className="row justify-content-center pb-2-5">
            <div className="col-12 col-sm-8 col-md-8 col-lg-6">
                <a href="#" className="text-dark" onClick={handleClick}>
                    <FontAwesomeIcon
                        icon={faLongArrowLeft}
                        className="mr-2 line-height-1-rem"
                    />{' '}
                    {title}
                </a>
            </div>
        </div>
    );
};

export default CheckoutStepBack;
