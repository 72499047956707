import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const Input = React.forwardRef(
    (
        {
            className,
            onChange,
            inputClassName,
            invalid,
            children,
            white,
            ...props
        },
        ref,
    ) => {
        return (
            <div className={`position-relative ${className || ''}`}>
                <input
                    {...props}
                    onChange={(event) =>
                        onChange && onChange(event.target.value)
                    }
                    ref={ref}
                    className={`bg-white form-control pr-4 ${
                        invalid
                            ? 'border-danger'
                            : white
                            ? 'border-white'
                            : 'border-gray-light'
                    } ${inputClassName}`}
                />
                {invalid && (
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="font-size-1 position-absolute top right text-danger h-100 d-flex align-items-center mr-2"
                    />
                )}
            </div>
        );
    },
);

export default Input;
