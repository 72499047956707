import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';

const CustomerLogout = () => {
  const navigateTo = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    navigateTo('/');
  }, []);

  return null;
};

export default CustomerLogout;
