import React from 'react';
import { getURLPath } from '../../utils/staticPaths';
import { useResource } from '../../contexts/ResourceContext';
import {Link} from "react-router-dom";

const GuestLogin = () => {
    const { t } = useResource();
    return (
        <div className="col-sm-8 p-2 p-sm-3 m-2 border border-light rounded">
            <h1 className="font-size-1-125 mb-3 text-center">
                {t('Account.Login.CheckoutGuest.Title')}
            </h1>
            <div className="font-size-0-75 mb-3">
                <div className="formatted-content">
                    <ul className="gray-bullet">
                        <li>{t('Account.Login.CheckoutGuest.InfoList1')}</li>
                        <li>{t('Account.Login.CheckoutGuest.InfoList2')}</li>
                    </ul>
                </div>
            </div>
            <Link href={getURLPath('checkout')} className="btn btn-primary w-100">
                {t('Account.Login.CheckoutGuest.Button')}
            </Link>
        </div>
    );
};

export default GuestLogin;
