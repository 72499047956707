import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

const MessageSucces = ({ className, children }) => {
    return (
        <div
            className={classnames(
                'd-flex justify-content-between align-items-center alert text-green-darker bg-green-light rounded-lg py-3 mb-0',
                className,
            )}
        >
            {children}
            <FontAwesomeIcon
                icon={faCircleCheck}
                className="font-size-2 mr-2 text-green"
            />
        </div>
    );
};

export default MessageSucces;
