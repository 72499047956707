import React, {Component} from 'react';
import Select from "../Select";
import FormGroup from "./FormGroup";
import {Field} from "formik";
import variationTypes from './variationTypes';

class SelectGroup extends Component {
    render() {
        const {
            name,
            required,
            options,
            description,
            formGroupClasses,
            optionName,
            optionId,
            label,
            variation = 'default',
            errorType = 'label-after',
        } = this.props;

        const selectedVariation = variationTypes?.[variation] || variationTypes['default'];

        return <Field name={name}>
            {(fieldProps) => (
                <FormGroup
                    required={required}
                    fieldProps={fieldProps}
                    description={description}
                    errorType={errorType}
                    label={label}
                    {...formGroupClasses}
                    {...selectedVariation}>
                    <Select
                        {...fieldProps.field}
                        onChange={fieldProps.field.onChange(name)}
                        options={options?.map(option => ({
                            name: option?.[optionName] || option?.name,
                            value: option?.[optionId] || option?.id,
                        }))}
                    />
                </FormGroup>)}
        </Field>
    }
}

export default SelectGroup;
