import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/pro-light-svg-icons';
import Button from "../../elements/Button";
import { useResource } from '../../contexts/ResourceContext';

const CheckoutButton = ({
    checkoutDisabled,
    checkoutClickHandler,
    isLoading,
}) => {
    const { t } = useResource();
    return (<>
        {checkoutDisabled && <>
            <div className="btn btn-lg bg-gray-light text-gray text-hover-gray cursor-default w-100">
                <span>{ t("Checkout.Button") }</span>
                <FontAwesomeIcon icon={faShoppingCart} className="ml-1 font-size-0-875"/>
            </div>
        </>}
        {!checkoutDisabled && <>
            <Button
                text={ t("Checkout.Button") }
                variant="primary"
                size="lg"
                className="w-100 rounded"
                onClick={ checkoutClickHandler }
                isLoading={ isLoading }
                beforeChildren
            >
                <FontAwesomeIcon icon={faShoppingCart} className="mr-1 font-size-0-875"/>
            </Button>
        </>}
    </>)
}

export default CheckoutButton;