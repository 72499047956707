import React from 'react';
import SubPage from "./SubPage";
import Page from "./Page";
import Content from "./Content";
import { useResource } from '../../contexts/ResourceContext';

function GiftPage({onClose}) {
    const { t } = useResource();
    
    return <SubPage imageNumber="3" position="center" verticalPosition={25}>
        <Page title={t('brendon.intro.gift.title')}>
            <Content primaryButton={t('brendon.intro.gift.next')}
                     link={t('brendon.intro.gift.link')}
                     onSkip={onClose}>
                {t('brendon.intro.gift.description')}
            </Content>
        </Page>
    </SubPage>
}

export default GiftPage;
