const addBodyOverflowHidden = () => {
  document.body.classList.add('overflow-hidden');
  /*   document.body.classList.add('position-fixed'); need top actual position */
};

const removeBodyOverflowHidden = () => {
  document.body.classList.remove('overflow-hidden');
  /*   document.body.classList.remove('position-fixed'); */
};

const addResponsiveTableContainer = (tableNode) => {
  const divElem = document.createElement('div');
  divElem.classList.add('table-responsive');
  tableNode.parentNode.insertBefore(divElem, tableNode);
  divElem.appendChild(tableNode);
};

const makeRecommendedProductTable = (tableNode) => {
  const pElem = document.createElement('p');
  const emElem = document.createElement('em');
  emElem.classList.add('recommended-product');
  pElem.appendChild(emElem);
  tableNode.parentNode.insertBefore(pElem, tableNode);
  emElem.appendChild(tableNode); 
}

export default {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
  addResponsiveTableContainer,
  makeRecommendedProductTable
};
