import React, { Component } from 'react';
import { connect } from 'react-redux';
import withThemeProvider from '../hoc/withThemeProvider';
import withStore from '../hoc/withStore';
import Cart from '../services/cart';
import Modal from '../elements/Modal';
import SizeSelectorProduct from '../elements/SizeSelectorProduct';
import { updateCartCount } from '../store/cart/actions';
import { showError, showSuccess } from '../store/notification/actions';
import gaService from '../services/gaEnhancedEcommerce';
import { getStoreCurrency } from '../utils/localization';
import GA4Service from '../services/gaGA4';
import { toKebabCase } from '../utils/string';
import LoadingIcon from '../elements/Icons/LoadingIcon';
import withResource from '../hoc/withResource';
import { redirectToUrl } from '../utils/controller';
import CustomIcons from '../components/Elements/CustomIcons/CustomIcons';
import classnames from 'classnames';

class AddToCartIcon extends Component {
    state = {
        sizeChooserOpened: false,
        sizes: [],
        selectedSize: null,
        isAdded: false,
        isCartLoading: false,
    };
    afterOrder = () => {
        const { product, productId, categoryName, gaEEList = 'none', enableUpsell = false } = this.props;
 
        gaService.addProductToCart(
            gaService.transformProductInfoToIpmpressionFieldObject(
                categoryName,
                gaEEList,
                product,
            ),
            getStoreCurrency(),
        );

        GA4Service.gtagEvent('add_to_cart', {
            currency: getStoreCurrency(),
            value:
                product.DiscountedPrice > 0
                    ? product.DiscountedPrice
                    : product.Price,
            items: [
                GA4Service.transformProductInfoToItemListItem(
                    toKebabCase(gaEEList),
                    gaEEList,
                    categoryName,
                    product,
                ),
            ],
        });

        // TODO: timeout because of GA4 event
        setTimeout(() => {
            if (enableUpsell && product?.IsUpsell) {
                redirectToUrl(`/upsell/${productId}`);
            }
        }, 10);

    }
    onClick = async () => {
        await this.addToCart();
    };

    addToCart = async (size) => {
        const { isCartLoading } = this.state;
        if (isCartLoading) {
            return;
        }
            const {
                productId,
                product,
                updateCartCount,
                showError,
                showSuccess,
            } = this.props;
            
            if (product?.Sizes?.length > 0 && !size) {
                this.setState({
                    sizeChooserOpened: true,
                    sizes: product.Sizes.map((size) => ({
                        IsOrderable: size.Available,
                        SizeName: size.Name || size.name,
                    })),
                });
            } else {
                this.setState({
                    isCartLoading: true,
                });
                const result = await Cart.addToCartCustom(productId, size);
                if (result.Success === 1) {
                    updateCartCount(result.CartCount);
                    this.setState({
                        isAdded: true,
                        isCartLoading: false,
                    });
                    showSuccess(result.Message);
                    this.afterOrder();
                } else {
                    showError(result.Message);
                }
            }
        
    };

    selectSize = async (size) => {
        if (size.IsOrderable) {
            await this.addToCart(size.SizeName);
            this.close();
        } else {
            this.setState({
                selectedSize: size,
            });
        }
    };

    close = () => {
        this.setState({
            sizeChooserOpened: false,
            selectedSize: null,
        });
    };

    render() {
        const {
            sizeChooserOpened,
            sizes,
            selectedSize,
            isAdded,
            isCartLoading,
        } = this.state;
        const { t } = this.props;

        return (
            <>
                <div
                    className="position-relative text-primary line-height-3 cursor-pointer"
                    onClick={this.onClick}
                >
                    {isCartLoading && (
                        <div className="position-relative">
                            <CustomIcons iconName="AddToCartCircle" size="cartsize" />
                            <div className="position-center"><LoadingIcon size="xs" strokeColor="#FFFFF" noMargin /></div>
                        </div>
                    )}
                    {!isCartLoading && <CustomIcons iconName={isAdded ? "AddToCartInCart" : "AddToCartList"} size="cartsize" className={classnames(isAdded ? 'cartscaleup' : '')} /> }
                </div>
                <Modal
                    opened={sizeChooserOpened}
                    onClosed={this.close}
                    containerClasses="col-12 col-md-6 col-lg-4"
                    closeText={t('brendon.sizechooser.close')}
                >
                    <div className="px-3">
                        <div className="text-center font-weight-bold font-size-0-875 py-2">
                            {t('brendon.sizechooser.title')}
                        </div>
                        <div>
                            <SizeSelectorProduct
                                sizes={sizes}
                                selectedSize={selectedSize}
                                onSelect={this.selectSize}
                            />
                            <div
                                className={classnames("font-weight-medium font-size-0-75 text-gray",
                                selectedSize && !selectedSize.IsOrderable ? 'anim-opacity-1' : 'anim-opacity-0') }
                            >
                                {t('brendon.sizechooser.notOrderableSize')}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateCartCount: (cartCount) => dispatch(updateCartCount(cartCount)),
    showError: (message) => dispatch(showError(message)),
    showSuccess: (message) => dispatch(showSuccess(message)),
});

export default withThemeProvider(
    withResource(withStore(connect(null, mapDispatchToProps)(AddToCartIcon))),
);
