import hasPendingRequest from "./hasPendingRequest";
import {detect} from 'detect-browser';

const browser = detect();

const excludedBrowsers = ['safari', 'ios', 'crios'];
const excludedOS = ['iOS'];

function scrollRestorer() {
    const { os = '', name = ''} = browser;
    if (excludedBrowsers.indexOf(name) !== -1 || excludedOS.indexOf(os) !== -1) {
        history.scrollRestoration = 'auto';
        return;
    }
    
    if (!history.replaceState) {
        return;
    }

    let scrolled = false;

    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }

    window.addEventListener("beforeunload", function (event) {
        history.replaceState({
            scrollY: window.scrollY,
        }, '');
    });

    window.addEventListener('scroll', function (e) {
        scrolled = true;
    });

    function restoreScroll() {
        const hash = window.location.hash.substring(1);
        if (window.history.state?.scrollY) {
            window.scrollTo(0, window.history.state?.scrollY);
        } else if (hash.length) {
            document.getElementById(hash)?.scrollIntoView({
                behavior: 'smooth',
            })
        }
    }

    function tryToRestoreScroll() {
        if (scrolled) {
            return;
        }

        if (document.documentElement.scrollHeight < window.history.state?.scrollY || hasPendingRequest()) {
            setTimeout(tryToRestoreScroll, 50);
        } else {
            restoreScroll();
        }
    }

    window.addEventListener("load", function (event) {
        tryToRestoreScroll();
    });
}

export default scrollRestorer;