import {call, put, select, take, takeLatest,} from 'redux-saga/effects'
import {
    checkAttributeFilter,
    checkSpecificationFilter, clearAllFilters,
    goToPage, initializeFilters,
    setFiltersPagination, setLoading, setOrderBy, setProductContent,
    updateFilters,
} from "./actions";
import {getFilterQuery} from "./selectors";
import axios from "axios";
import scrollToElement from "../../scrollToElement";
import { isMobile } from "../../utils/mobile";

function setUrlHashQuery(query) {
    window.location.hash = `/${query}`;
}

async function getProducts(filters) {
    const {data} = await axios.post('/getFilteredProducts', filters);
      
    const $result = $(data);
    const productGridContent = $result.filter('.product-grid').html();

    const specificationFilters = JSON.parse($result.filter('#specificationFilterModel7SpikesJson').val());
    const attributeFilters = JSON.parse($result.filter('#attributeFilterModel7SpikesJson').val());
    const pagination = JSON.parse($result.filter('#pagination').val());
    const orderBy = JSON.parse($result.filter('#currentOrderByJson').val());
    const urlHashQuery = $result.filter('#urlHashQuery').val();
    return {
        productGridContent,
        specificationFilters,
        attributeFilters,
        pagination,
        orderBy,
        urlHashQuery
    }
}

async function scrollToFilters() {
    scrollToElement('#category-top');
}

function* queryFilteringResult(isTriggeredByUser, isPagination, isFiltering, query)  {
    try {
        yield put(setLoading(true));
        const filters = yield select(getFilterQuery, isPagination, query);
        const {attributeFilters, specificationFilters, productGridContent, pagination, orderBy, urlHashQuery} = yield call(getProducts, filters);
        yield put(updateFilters(attributeFilters, specificationFilters));
        yield put(setFiltersPagination(pagination));
        yield put(setProductContent(productGridContent));
        if (!isTriggeredByUser) {
            yield put(setOrderBy(orderBy)); 
        }
        setUrlHashQuery(urlHashQuery);
    } catch (e) {
        console.error(e);
    } finally {
        yield put(setLoading(false))
    }
    
    const disableScrolling = !isMobile() && isFiltering;
    
    if(isTriggeredByUser && !disableScrolling) {
        yield call(scrollToFilters);    
    }
}

function* handleFilterChange(action) {
    const isPagination = action.type === goToPage.toString();
    const isFiltering = action.type === clearAllFilters.toString() || 
        action.type === checkSpecificationFilter.toString() || 
        action.type === checkAttributeFilter.toString();

    yield queryFilteringResult(true, isPagination, isFiltering);
}

function* filterSaga() {
    /* This part is not need anymore
    if(window.location.hash) {
        yield take(initializeFilters);
        yield queryFilteringResult(false, false, false, window.location.hash);
    }
    */

    yield takeLatest([
        clearAllFilters,
        checkSpecificationFilter,
        checkAttributeFilter,
        goToPage,
        setOrderBy
    ], handleFilterChange);
}

export default filterSaga;