import React from 'react';
import LogoPath from '../../../assets//brendon-logo.svg';
import {Link} from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <img title="Brendon" alt="Brendon" height="40" className="h-p30 h-md-p40" src={LogoPath} />
    </Link>
  )
};

export default Logo;
