import React, { useMemo } from 'react';
import CheckboxComboListItem from './CheckboxComboListItem';
import LinkComboListItem from './LinkComboListItem';

/**
 * @typedef {Object} Item
 * @property {string} name - Item name.
 * @property {string} description - Item description.
 * @property {boolean} isEnabled - Item is enabled.
 * @property {object} icon - Item icon.
 **/

/**
 * Variant combo list it's a kind of list that can be used with checkbox or link and data separated by items.
 * 
 * @param {string} variant - Variant type. (checkbox - CheckboxComboListItem, link - LinkComboListItem).
 * @param {string} containerClassName - Main container class name.
 * @param {string} ulClassName - Unordered list class name (item's container).
 * @param {ReactNode} beforeChildren - Before children (in ul list before all li elems).
 * @param {ReactNode} afterChildren - After children (in ul list after all li elems).
 * @param {Array.<Item>} items - Items.
 * @param {boolean} disabled - All item are disabled.
 * @param {number} selectedIndex - Selected index.
 * @param {function} onSelect - On select callback function.
 * @param {string} containerHoverClassName - Container hover class name.\
 * @param {string} textHoverClassName - Text and icon hover class name.
 * @param {string} itemTextClassName - Item text class name.
 * @param {string} itemNameClassName - Item name class name.
 * @param {string} iconContainerClassName - Icon container class name.
 * @param {string} detailsLinkText - Details link text.
 * @param {string} iconType - Icon type.
 * 
 **/

const VariantComboList = ({
    variant = 'checkbox', // checkbox, link
    containerClassName,
    ulClassName = 'mb-4',
    beforeChildren,
    afterChildren,
    items,
    disabled,
    selectedIndex,
    onSelect,
    containerHoverClassName = 'border-hover-dark',
    textHoverClassName = '',
    itemTextClassName = '',
    itemNameClassName = '',
    iconContainerClassName = '',
    detailsLinkText,
    iconType,
}) => {
    const ListItem = useMemo(
        () =>
            variant === 'checkbox' ? CheckboxComboListItem : LinkComboListItem,
        [variant],
    );

    return (
        <div className={`checkbox-combo-list ${containerClassName}`}>
            {items?.length > 0 && (
                <>
                    <ul className={ulClassName}>
                        {beforeChildren}
                        {items?.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem
                                    key={index}
                                    disabled={disabled || !item.isEnabled}
                                    item={item}
                                    index={index}
                                    selectedIndex={selectedIndex}
                                    onClick={onSelect(index)}
                                    containerHoverClassName={
                                        containerHoverClassName
                                    }
                                    textHoverClassName={textHoverClassName}
                                    itemTextClassName={itemTextClassName}
                                    itemNameClassName={itemNameClassName}
                                    iconContainerClassName={iconContainerClassName}
                                    detailsLinkText={detailsLinkText}
                                    enableDetails={item.enableDetails || false}
                                    iconType={iconType}
                                />
                            </React.Fragment>
                        ))}
                        {afterChildren}
                    </ul>
                </>
            )}
        </div>
    );
};

export default VariantComboList;
