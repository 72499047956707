import React from 'react';
import FullscreenMobilModal from '../Elements/FullscreenModal';
import Button from '../../elements/Button';
import FullscreenModalItem from '../Elements/FullscreenModalItem';
import FilterRadio from './FilterRadio';
import FilterToggler from './FilterToggler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import ExtraInfoItem from './ExtraInfoItem';
import { useResource } from '../../contexts/ResourceContext';

const MobileFilterModal = ({
    isOpen,
    rightTitleChildren,
    handleCancel,
    handleApply,
    onSelect,
    onOpen,
    filters,
    openedFilters = [],
    clickedFilter,
    totalProducts,
    fullHeight,
}) => {
    const { t } = useResource();
    return (
        <FullscreenMobilModal
            fullHeight={fullHeight}
            isOpen={isOpen}
            title={t('brendon.products.filters.title')}
            rightTitleChildren={rightTitleChildren}
            navChildren={
                <>
                    <Button
                        variant="primary-bordered"
                        className="font-size-0-875 mx-1 flex-1"
                        size="md"
                        onClick={handleCancel}
                    >
                        {t('brendon.products.filters.cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        size="md"
                        className="font-size-0-875 mx-1 flex-1 rounded text-none"
                        onClick={handleApply}
                    >
                        {t('brendon.products.filter.show', totalProducts || 0)}
                    </Button>
                </>
            }
        >
            {filters?.map(filter => (
                <React.Fragment key={`f_${filter.Id}`}>
                    {filter.IsCheckbox ? (
                        <FullscreenModalItem
                            key={`filter_${filter.Id}`}
                            scrollHookId={`filter${filter.Id}`}
                            scrollTo={clickedFilter === filter.Id}
                            leftChildren={
                                <div /* onClick={() => onSelect(filter.Id)} */ className="font-weight-bold">
                                    {filter.Name}
                                    {filter?.Description && filter.Description.length > 0 && (
                                        <FontAwesomeIcon icon={faInfoCircle} className="font-size-1 ml-1" />
                                    )}
                                </div>
                            }
                            rightChildren={
                                <FilterRadio
                                    isChecked={filter.IsFilterChecked}
                                    isDisabled={filter?.Disabled || false}
                                    /* onChanged={onSelect} */
                                    value={filter.Id}
                                />
                            }
                            onSelect={() => {
                                if (!filter?.Disabled) {
                                  onSelect(filter.Id);
                                }
                            }}
                            isSelected={filter.IsFilterChecked}
                            isDisabled={filter?.Disabled || false}
                            selectedClassName="text-primary"
                            className="border-bottom p-3"
                        >
                              {filter?.Description && filter.Description.length > 0 && (
                                    <div>
                                        <ExtraInfoItem
                                            message={filter.Description} 
                                            showText={t('brendon.products.filters.show')}
                                            className="text-body mt-3"
                                        />
                                    </div>
                                )}
                        </FullscreenModalItem>
                    ) : (
                        <FullscreenModalItem
                            key={`filter_${filter.Id}`}
                            scrollHookId={`filter${filter.Id}`}
                            scrollTo={clickedFilter === filter.Id}
                            leftChildren={
                                <div className="font-weight-bold cursor-pointer" onClick={()=> {
                                    onOpen(filter.Id, !openedFilters?.includes(filter.Id));
                                }}>{filter.Name}
                                    {filter?.Description && filter.Description.length > 0 && (
                                        <FontAwesomeIcon icon={faInfoCircle} className="font-size-1 ml-1" />
                                    )}
                                </div>
                            }
                            rightChildren={
                                <FilterToggler
                                    isOpen={openedFilters?.includes(filter.Id)}
                                    onChanged={onOpen}
                                    value={filter.Id}
                                /> 
                            }
                            onSelect={()=> {
                                onOpen(filter.Id, !openedFilters?.includes(filter.Id));
                            }}
                            className="border-bottom p-3"
                            isSelected={filter?.FilterItems?.some(
                                filterItem => filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2
                            )}
                            leftSelectedClassName="text-primary"
                        >
                            {openedFilters?.includes(filter.Id) && <>
                                {filter?.Description && filter.Description.length > 0 && (
                                    <div>
                                        <ExtraInfoItem
                                            message={filter.Description} 
                                            showText={t('brendon.products.filters.show')}
                                            className="text-body mt-3"
                                        />
                                    </div>
                                )}
                                {filter?.FilterItems.map((filterItem) => (
                                    <FullscreenModalItem
                                        key={`filteropt_${filterItem.Id}`}
                                        scrollHookId={`filter${filterItem.Id}`}
                                        scrollTo={clickedFilter === filterItem.Id}
                                        leftChildren={
                                            <div
                                                /* onClick={() => onSelect(filterItem.Id)} */
                                            >
                                                {filterItem.Name}
                                            </div>
                                        }
                                        rightChildren={
                                            <FilterRadio
                                                isChecked={filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2}
                                                isDisabled={filterItem?.Disabled || false}
                                                /* onChanged={onSelect} */
                                                value={filterItem.Id}
                                            />
                                        }
                                        onSelect={() => {
                                            if (!filterItem?.Disabled) {
                                                onSelect(filterItem.Id)
                                            }
                                        }}
                                        isSelected={filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2}
                                        isDisabled={filterItem?.Disabled || false}
                                        selectedClassName="text-primary"
                                        className="pt-3"
                                    />
                                ))}
                            </>}
                        </FullscreenModalItem>
                    )}
                </React.Fragment>
            ))}
        </FullscreenMobilModal>
    );
};
MobileFilterModal.displayName = 'MobileFilterModal';
export default MobileFilterModal;
