import React, { useState, useEffect, useCallback } from 'react';
import BlogListItem from '../BlogListItem';
import BlogTagsV2 from '../BlogTagsV2';
import MoreButton from '../MoreButton';
import BlogService from '../../../services/blog';
import { MAX_POST_ON_LIST } from '../../../models/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import SectionContainer from '../MainSection/SectionContainer';
import BlogCategoryMenu from '../BlogCategoryMenu';
import { useResource } from '../../../contexts/ResourceContext';

const BlogListByTag = ({ storeId, tagId, setTag }) => {
    const [posts, setPosts] = useState([]);
    const [tags, setTags] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [blogCategories, setBlogCategories] = useState([]);
    const { t } = useResource();
    
    // Only tag list
    useEffect(() => {
        const getBlogList = async () => {
            const result = await BlogService.getPostList(storeId, { tagId });
            if (
                Array.isArray(result?.postList) &&
                result?.postList?.length > 0
            ) {
                result?.postList.sort(
                    (a, b) => Number(a?.DisplayOrder) - Number(b?.DisplayOrder),
                );
                setTag(result?.tagName); // Tag name
            }
            setPosts(result?.postList);
            setTags(result?.tagList);
        };
        if (tagId) {
            getBlogList();
        }
    }, [storeId, tagId]);

    useEffect(() => {
        const fetchData = async () => {
            const { BlogCategories = [] } = await BlogService.getMain();
            setBlogCategories(BlogCategories);
        };
        fetchData();
    }, [setBlogCategories]);

    const showMore = useCallback(() => {
        setPageNum((p) => ++p);
    }, [setPageNum]);

    const visiblePostNum = pageNum * MAX_POST_ON_LIST;

    return (
        <div className="row">
            <div className="col-12 col-lg-8">
                {posts?.slice(0, visiblePostNum)?.map((item, index) => {
                    return <BlogListItem item={item} key={index} />;
                })}

                {visiblePostNum < posts?.length && (
                    <MoreButton
                        label={t('brendon.blog.morepost')}
                        onClick={showMore}
                        icon={
                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                        }
                        btnClassName="btn-lg px-4"
                    />
                )}
            </div>
            <div className="col-12 col-lg-4">
                <SectionContainer className="mb-5">
                    <BlogTagsV2
                        title={t('Blog.TagCloud')}
                        className="px-3 pt-3 bg-gray-light rounded"
                        tags={tags}
                    />
                </SectionContainer>
                <SectionContainer className="pb-2">
                    <BlogCategoryMenu categories={blogCategories} />
                </SectionContainer>
            </div>
        </div>
    );
};

export default BlogListByTag;
