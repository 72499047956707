import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { getImagePath } from '../utils';
import LoadingIcon from '../elements/Icons/LoadingIcon';
import classNames from 'classnames';

const durationMs = 250;

const Wrapper = styled.div`
    opacity: 0;
    pointer-events: none;

    &.enter,
    &.appear {
        opacity: 0;
        pointer-events: auto;
        transition: opacity ${durationMs}ms ease-in-out;
    }

    &.enter-active,
    &.appear-active {
        opacity: 1;
        transition: opacity ${durationMs}ms ease-in-out;
    }

    &.enter-done,
    &.appear-done {
        opacity: 1;
        pointer-events: auto;
    }

    &.exit {
        opacity: 1;
        transition: opacity ${durationMs}ms ease-in-out;
    }

    &.exit-active {
        opacity: 0;
        transition: opacity ${durationMs}ms ease-in-out;
    }

    &.exit-done {
        opacity: 0;
    }
`;

function Loader(props) {
    const {
        isLoading,
        onShow,
        onHide,
        disableBGWhite = false,
        loaderType = 'default',
        absoluteContainer = true,
        ...rest
    } = props;
    return (
        <CSSTransition
            in={isLoading}
            appear={true}
            timeout={durationMs}
            mountOnEnter={false}
            onEntered={onShow}
            onExiting={onHide}
            {...rest}
        >
            <Wrapper
                className={classNames(
                    absoluteContainer ? 'position-absolute top left' : null,
                    'w-100 vh-100',
                    disableBGWhite ? null : 'bg-white',
                )}
            >
                <div className="position-relative position-sticky top vh-100 mh-100 overflow-hidden d-flex justify-content-center align-items-center">
                    {loaderType === 'default' && (
                        <img src={getImagePath('loading.gif')} alt="" />
                    )}
                    {loaderType === 'new' && (
                        <LoadingIcon strokeColor={'#ccc'} />
                    )}
                </div>
            </Wrapper>
        </CSSTransition>
    );
}

export default Loader;
