import React, {Component} from 'react';
import Content from "./Content";
import Input from "../../elements/Input";
import DatePicker from "../../components/Elements/Datepicker";
import Checkbox from "../../elements/SquareCheckbox";
import Newsletter from "../../services/newsletter";
import styled from 'styled-components';
import sendEvent from "../../tracking";
import { isValidEmail } from '../../utils';
import {CSSTransition, SwitchTransition} from "react-transition-group";
import withResource from '../../hoc/withResource';

const LinkingText = styled.span`
    a {
        font-weight: 500;
    }
`;

const Animating = styled.div`
    opacity: 0;
    transition: all 0.5s ease-in-out;
    
    &.appear,
    &.enter {
        opacity: 0;
    }
    
    &.appear-done,
    &.appear-active,
    &.enter-active, 
    &.enter-done {
        opacity: 1;
    }
   
    &.exit {
        opacity: 1;
    }

    &.exit-active,
    &.exit-done {
        opacity: 0;
    }
`;

class Subscriber extends Component {
    state = {
        newsletterSubscribed: false,
        newsletterSubscribedFailed: false,
        accepted: false,
        email: '',
        date: '',
    };

    componentDidMount() {
        sendEvent('intro', 'newsletter');
    }

    subscribe = async () => {
        const {accepted, date, email} = this.state;
        const {pregnancyNewsletter} = this.props;
        const emailInvalid = !(await isValidEmail(email));
        const dateInvalid = (pregnancyNewsletter && (!date || date.length == 0))?true:false;
        this.setState({
            acceptedInvalid: !accepted,
            dateInvalid,
            emailInvalid: !email?true:emailInvalid,
        });
        if (!email || !accepted || dateInvalid || emailInvalid) {
            return;
        }
        try {
            const data = {
                Email: email,
                PregnancyNewsletter: !!pregnancyNewsletter,
                Accepted: accepted
            };

            if (pregnancyNewsletter) {
                data.EstimatedBirthDate = (date && date?.toISOString?.().substring?.(0, 10)) || date;
            }

            const result = await Newsletter.subscribe(data);
            sendEvent('intro', 'subscribe', 'success');
            this.setState({
                newsletterSubscribed: result.Hide,
                failReason: result.Result,
                newsletterSubscribedFailed: true,
            });
        } catch (e) {
            sendEvent('intro', 'subscribe', 'failed');
            this.setState({
                newsletterSubscribed: false,
                newsletterSubscribedFailed: true,
            });
        }
    };

    render() {
        const {
            onSkip,
            success,
            description,
            emailPlaceholder,
            datePlaceholder,
            failText,
            retryText,
            subscribeText,
            pregnancyNewsletter,
            t,
        } = this.props;
        const {
            newsletterSubscribed,
            newsletterSubscribedFailed,
            accepted,
            email,
            date,
            emailInvalid,
            dateInvalid,
            acceptedInvalid,
            failReason,
        } = this.state;

        return <SwitchTransition>
            <CSSTransition timeout={500}
                           key={newsletterSubscribed ? 'subscribed' : newsletterSubscribedFailed ? 'failed' : 'start'}
                           appear={true}>
                <Animating className="d-flex flex-column justify-content-between flex-fill">
                    {newsletterSubscribed ?
                        <Content
                            primaryButton={t('brendon.intro.next')}
                            onClick={onSkip}
                        >
                            {success}
                        </Content>
                        :
                        (newsletterSubscribedFailed ?
                                <Content
                                    primaryButton={retryText}
                                    onClick={() => this.setState({
                                        newsletterSubscribedFailed: false,
                                    })}
                                    onSkip={onSkip}
                                >
                                    <div className="mb-3">{failText}</div>
                                    <div>{failReason}</div>
                                </Content> :
                                <Content
                                    primaryButton={subscribeText}
                                    onClick={this.subscribe}
                                    onSkip={onSkip}
                                >
                                    <div className="d-flex flex-column justify-content-between h-100">
                                        <div className="mb-3">
                                            {description}
                                        </div>
                                        <form className="row">
                                            <div className="col-12 col-sm-8">
                                                <Input className="w-100 mb-2" 
                                                       value={email} 
                                                       invalid={emailInvalid}
                                                       type="email"
                                                       name="email"
                                                       placeholder={emailPlaceholder}
                                                       onChange={async (value) => this.setState({
                                                           email: value,
                                                           emailInvalid: !(await isValidEmail(value)),
                                                       })}>
                                                </Input>
                                                {pregnancyNewsletter &&
                                                <DatePicker wrapperClassName="w-100 mb-2"
                                                            value={date}
                                                            invalid={dateInvalid}
                                                            onChange={(value) => this.setState({
                                                                date: value,
                                                                dateInvalid: !value
                                                            })}
                                                            placeholder={datePlaceholder}
                                                />
                                                }
                                            </div>
                                            <div className="col-12 mb-3">
                                                <Checkbox value={accepted} invalid={acceptedInvalid}
                                                          light
                                                          onChange={(accepted) => this.setState({
                                                              accepted: accepted,
                                                              acceptedInvalid: !accepted
                                                          })}>
                                                    <LinkingText
                                                        dangerouslySetInnerHTML={{__html: t('brendon.intro.privacyPolicyText')}}/>
                                                </Checkbox>
                                            </div>
                                        </form>
                                    </div>
                                </Content>
                        )}
                </Animating>
            </CSSTransition>
        </SwitchTransition>
    }
}

export default withResource(Subscriber);
