import React, {Component} from 'react';
import Select from "../elements/Select";

class FilterOrderBy extends Component {

    render() {
        const {selectedSortOption, sortOptions, title, onOrderBySelected} = this.props;

        if (!sortOptions || !sortOptions.length) {
            return null;
        }

        return <div className="product-sorting d-flex align-items-center">
            {/* <span className="mr-2 font-size-0-75">{title}</span> */}
            <Select
                sm
                value={selectedSortOption}
                onChange={onOrderBySelected}
                options={sortOptions.map(option => ({
                    name: option.Text,
                    value: option.Value,
                }))}/>
        </div>

    }
}

export default FilterOrderBy;
