import React from 'react';

const BlogSubTitle = ({ title, className, children }) => (
    <div className="d-flex mb-2">
        <h1 className={`font-size-1-125 align-self-center mr-2 ${className}`}>{title}</h1>
        {children}
    </div>
);

export default BlogSubTitle;
