import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shippingAddress as ShippingAddressService } from '../../../services/checkout';
import CartService from '../../../services/cart';
import { initalOptionsCommand } from '../../../utils/controller';
import { useDispatch } from 'react-redux';
import { showError } from '../../../store/notification/actions';
import withStore from '../../../hoc/withStore';
import AddressBox from '../../../components/Checkout/AddressBox';
import AddAddressBox from '../../../components/Checkout/AddAddressBox';
import ShippingAddressForm from '../../../components/Checkout/ShippingAddressForm';
import AddressBoxShippingData from '../../../components/Checkout/AddressBoxShippingData';
import CheckoutStepTitle from '../../../components/Checkout/CheckoutStepTitle';
import Loader from '../../../components/Loader';
import gaGA4 from '../../../services/gaGA4';
import { getStoreCurrency } from '../../../utils/localization';
import { useResource } from '../../../contexts/ResourceContext';

const CheckoutPartialShippingAddress = ({ nextStep }) => {
    const { t } = useResource();
    const [addresses, setAddresses] = useState([]);
    const [defaultAddressData, setDefaultAddressData] = useState(null);
    const [countries, setCountries] = useState([]);
    const [editingAddress, setEditingAddress] = useState(null);
    const [addressLoading, setAddressLoading] = useState(-1);
    const [editingNewAddress, setEditingNewAddress] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const formWrapper = useRef(null);
    const dispatch = useDispatch();

    const getShippingAddresses = useCallback(async () => {
        const {
            ShippingAddresses = [],
            Countries = [],
            InitOption = 'OK',
            ...data
        } = await ShippingAddressService.get();
        if (!initalOptionsCommand(InitOption, null, nextStep)) {
            return;
        }
        setCountries(Countries);
        setDefaultAddressData({
            ReceiverName: data?.DefaultReceiverName || '',
            CountryId: data?.DefaultCountryId || Countries?.[0]?.Id,
            City: data?.DefaultCity || '',
            Address: data?.DefaultAddress || '',
            ZipPostalCode: data?.DefaultZipPostalCode || '',
        });
        setPageLoading(false);
        if (ShippingAddresses?.length > 0) {
            setAddresses(ShippingAddresses);
            setEditingAddress(null);
        } else {
            setAddresses([]);
            setEditingNewAddress(true);
            setEditingAddress(null);
        }
    }, [
        setAddresses,
        setCountries,
        setDefaultAddressData,
        setEditingAddress,
        setEditingNewAddress,
    ]);

    useEffect(() => {
        getShippingAddresses();
    }, []);

    const handleDelete = useCallback(
        async (addressId) => {
            const { IsSuccess, Message } = await ShippingAddressService.delete(
                addressId,
            );
            if (IsSuccess) {
                getShippingAddresses();
            } else {
                dispatch(showError(Message));
            }
        },
        [dispatch, getShippingAddresses],
    );

    const handleEdit = useCallback(
        (addressId) => {
            setEditingAddress(
                addresses?.find((address) => address.Id === addressId),
            );
        },
        [setEditingAddress, addresses],
    );

    const ga4ShippingInfoEvent = useCallback(async () => {
        const cartResponse = await CartService.evaluation();
        gaGA4.gtagEvent('add_shipping_info', {
                ...gaGA4.transformShoppingCartEvaluetionToItem(
                    cartResponse,
                    getStoreCurrency(),
                ),
                shipping_tier: 'delivery',
        });
    }, []);

    const handleSelect = useCallback(
        async (addressId) => {
            setAddressLoading(addressId);
            const {
                IsSuccess,
                Message,
                NextSite,
            } = await ShippingAddressService.select(addressId);
            if (IsSuccess) {
                try {
                    await ga4ShippingInfoEvent();
                }  catch (error) {
                    console.log('ga4:shipping_info:', error);
                }
                nextStep(NextSite);
            } else {
                dispatch(showError(Message));
            }
        },
        [dispatch, setAddressLoading],
    );

    const handleSave = useCallback(
        async (address) => {
            const apiAddressService =
                Number(address?.Id) > 0
                    ? ShippingAddressService.update
                    : ShippingAddressService.post;
            const { IsSuccess, NextSite, Message } = await apiAddressService(
                address,
            );
            if (IsSuccess) {
                try {
                    await ga4ShippingInfoEvent();
                } catch (error) {
                    console.log('ga4:shipping_info:', error);
                }
                setEditingAddress(null);
                setEditingNewAddress(false);
                nextStep(NextSite);
            } else {
                dispatch(showError(Message));
            }
        },
        [setEditingAddress, setEditingNewAddress, dispatch, nextStep],
    );

    const clickNewAddress = useCallback(() => {
        setEditingNewAddress((prev) => !prev);
        setEditingAddress(null);
    }, []);

    const scrollToForm = useCallback(() => {
        window.requestAnimationFrame(() => {
            const element = formWrapper?.current;
            const pos = element?.getBoundingClientRect().top + window.scrollY;
            window?.scrollTo({
                top: pos > 100 ? pos - 100 : pos,
                behavior: 'smooth',
            });
        });
    }, [formWrapper]);

    useEffect(() => {
        if (editingNewAddress || editingAddress) {
            scrollToForm();
        }
    }, [editingNewAddress, editingAddress]);

    return (
        <>
            {isPageLoading ? (
                <Loader isLoading />
            ) : (
                <>
                    {addresses.length > 0 && (
                        <CheckoutStepTitle
                            description={t("Checkout.ShippingAddress.Title")}
                        />
                    )}
                    {addresses.length > 0 && (
                        <div className="row mb-4">
                            {addresses.map((address) => (
                                <div
                                    className="col-12 col-md-6 col-lg-4 d-flex mb-3"
                                    key={address.Id}
                                >
                                    <AddressBox
                                        address={{
                                            ...address,
                                            Country: countries.find(
                                                (country) =>
                                                    country.Id ===
                                                    address.CountryId,
                                            )?.CountryName,
                                        }}
                                        selectAddressText={t(
                                            'Checkout.ShippingAddress.Select',
                                        )}
                                        countries={countries}
                                        onDelete={handleDelete}
                                        onEdit={handleEdit}
                                        onSelect={handleSelect}
                                        isLoading={
                                            addressLoading === address.Id
                                        }
                                    >
                                        <AddressBoxShippingData
                                            address={{
                                                ...address,
                                                Country: countries.find(
                                                    (country) =>
                                                        country.Id ===
                                                        address.CountryId,
                                                )?.CountryName,
                                            }}
                                        />
                                    </AddressBox>
                                </div>
                            ))}
                            <div className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                                <AddAddressBox
                                    selected={editingNewAddress}
                                    title={t(
                                        'brendon.checkout.shippingaddress.addNewAddressTitle',
                                    )}
                                    onClick={clickNewAddress}
                                />
                            </div>
                        </div>
                    )}
                    <div ref={formWrapper}>
                        {(editingNewAddress || editingAddress) && (
                            <div className="row justify-content-center pt-2">
                                <div className="col-12 col-md-8 col-lg-10 text-center">
                                    <h2 className="font-size-1 font-weight-medium text-uppercase line-height-3 mb-7">
                                        {t(
                                            'Checkout.EnterShippingAddress',
                                        )}
                                    </h2>
                                </div>
                            </div>
                        )}
                        {(editingNewAddress || editingAddress) && (
                            <>
                                <ShippingAddressForm
                                    countries={countries}
                                    initialValues={{
                                        ReceiverName: '',
                                        ZipPostalCode: '',
                                        CountryId: countries?.[0]?.Id,
                                        City: '',
                                        Address: '',
                                        ShippingDesc: '',
                                        ...defaultAddressData,
                                        ...editingAddress,
                                    }}
                                    nextButtonTitle={t(
                                        'brendon.checkout.ShippingAddress.nextbutton',
                                    )}
                                    saveHandler={handleSave}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default withStore(CheckoutPartialShippingAddress);
