const scrollToElement = (selector, speed) => {
    const element = document.querySelector(selector);
    const rootElement = document.scrollingElement || document.documentElement;
    animate(rootElement, rootElement?.scrollTop, getOffsetTop(element), speed || 250);
}

const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

const animate = (elem, startPos, endPos, time) => {
    if (!elem) {
        return;
    }
    let step = 0; 
    const timer = setInterval(function() {
        step += 0.02
        window.scrollTo(window.pageXOffset, startPos + step * (endPos-startPos))
        if (step >= 1) {
            clearInterval(timer);
        }
    }, 1 / time)
}
 

export default scrollToElement
