import React from 'react';
import { redirectToUrl } from '../../utils/controller';
import { Link } from 'react-router-dom';

/*
        height: '460px',
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(52,52, 52, 0.7)), url('${image}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          */
const BlogLead = ({ title, text, image, url }) => {
    return (
        <div
            className={`blog-lead rounded ${image ? 'bg-image' : ''}`}
            style={
                image
                    ? {
                          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(52,52, 52, 0.7)), url('${image}')`,
                      }
                    : {}
            }
        >
            <div
                className="d-flex flex-column justify-content-end h-100 px-3 cursor-pointer"
                onClick={() => redirectToUrl(url)}
            >
                {/* <div className="px-2 bg-blog-color-1 rounded-pill align-self-start text-uppercase font-size-0-75 line-height-rem-2 font-weight-medium">Babát szeretnék</div> */}
                <div className="mb-3 text-white">
                    <h2 className="font-size-1-25 my-2">
                        <Link to={url} className="text-white">
                            {title}
                        </Link>
                    </h2>
                    <Link to={url} className="d-none d-sm-block text-white">
                        {text}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BlogLead;
