const axios = require('axios');

const subscribe = async (data) => { 
    const result = await axios.post('/newslettersubscription/register', {
        Email: data?.Email,
        EstimatedBirthDate: !data?.EstimatedBirthDate || data?.EstimatedBirthDate?.length === 0 ? '' : data.EstimatedBirthDate,
    });

    return {
        Hide: result.data?.IsSuccess,
        Result: result.data?.Message,
    };
}

export const Newsletter = {
    subscribe: subscribe,
};

export default Newsletter;
