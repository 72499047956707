import React, { Component } from 'react';
import withThemeProvider from '../../hoc/withThemeProvider';
import Category from './Category';
import CategoryGroupName from './CategoryGroupName';
import CategoryGroup from './CategoryGroup';
import Popup from './Popup';
import ChecklistService from '../../services/checklist';
import { getURLPath } from '../../utils/staticPaths';
import withResource from '../../hoc/withResource';

class NewbornEquipment extends Component {
    state = {
        selectedGroupIndex: null,
        checklist: {},
        showSignIn: false,
    };

    constructor(props) {
        super(props);
        this.state.checklist = props.checklist;
    }
    async componentDidMount() {
        const checklist = await ChecklistService.list();
        this.setState({
            checklist,
        });
    }
    check = async (newChecked, category) => {
        const { registered } = this.props;
 

        if (!registered) {
            this.setState({
                showSignIn: true,
            });
            return;
        }

        try {
            const checklist = await ChecklistService.set({
                ChecklistId: category.ChecklistId,
                IsChecked: newChecked,
            });
            this.setState({
                checklist,
            });
        } catch (e) {}

    };

    select = (index) => {
        this.setState({
            selectedGroupIndex: index,
        });
    };

    onPopupClose = () => {
        this.setState({
            showSignIn: false,
        });
    };

    render() {
        const { selectedGroupIndex, checklist, showSignIn } = this.state;
        const {
            registered,
            t,
        } = this.props; // NEED to know user is logged in or not

        const selectedGroup =
            selectedGroupIndex !== null
                ? checklist?.CategoryGroups[selectedGroupIndex]
                : null;

        return (
            <>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="border-top">
                            <div className="row">
                                {checklist?.CategoryGroups?.map(
                                    (categoryGroup, index) => (
                                        <div className="col-12" key={index}>
                                            <CategoryGroup
                                                categoryGroup={categoryGroup}
                                                selected={
                                                    selectedGroupIndex === index
                                                }
                                                onSelect={() =>
                                                    this.select(index)
                                                }
                                                check={this.check}
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                    {selectedGroup && (
                        <div className="d-none d-md-block col-8 col-lg-6">
                            <CategoryGroupName>
                                {selectedGroup.CategoryGroupName}
                            </CategoryGroupName>
                            <div className="row">
                                <div className="col-12">
                                    {selectedGroup.Categories.map(
                                        (category) => (
                                            <Category
                                                key={category.ChecklistId}
                                                category={category}
                                                check={this.check}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!registered && (
                    <Popup
                        show={showSignIn}
                        onClose={this.onPopupClose}
                        loginUrl={getURLPath('login')}
                        description={t('kit.signInDescription')}
                        loginButtonText={t('Account.Login.LoginButton')}
                    />
                )}
            </>
        );
    }
}

export default withResource(withThemeProvider(NewbornEquipment));
