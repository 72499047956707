import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck,faCircle,faExclamation} from '@fortawesome/pro-solid-svg-icons';

export default function Checkbox({className, id, value, onChange, children, label, labelHtml, invalid}) {
    return <div className={`position-relative pl-4 ${className ? className : ''}`}>
        <input type="checkbox" checked={value}
               onChange={onChange} className="d-none"
               id={id}/>
        <label className="d-block mb-2 font-size-0-875 font-weight-medium"
               htmlFor={id}>
            <div className="position-absolute top left font-size-1-25 d-flex align-items-center justify-content-center">
                {value && <FontAwesomeIcon icon={faCheck} className="text-primary position-absolute font-size-0-875" />}
                <FontAwesomeIcon icon={faCircle} className="border-red" />
                {invalid && <div className="position-absolute border-red border rounded-circle h-100 w-100"/>}
            </div>
            <div className="pr-4 line-height-3">
            <span dangerouslySetInnerHTML={{__html: labelHtml}}/>
            {label}
            </div>
            {invalid && <div className="position-absolute top right mt-1 mr-2 font-size-1  d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faExclamation} className="position-absolute font-size-0-625" />
                <FontAwesomeIcon icon={faCircle} className="text-red" />
            </div>}
        </label>
        {children}
    </div>
};
