import getStore from "../store";
import {Provider} from "react-redux";
import React from "react";

function withStore(Component) {
    const store = getStore();
    return (props) => {
        return <Provider store={store}>
            <Component {...props}/>
        </Provider>
    }
}

export default withStore;
