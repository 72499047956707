import React, {Component} from "react";
import CategoryGroupName from "./CategoryGroupName";
import Category from "./Category";
import styled from "styled-components";
import {Collapse} from 'react-collapse';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';

const Arrow = styled(FontAwesomeIcon)`
    transition: transform 0.2s ease-in-out;
    transform: rotate(${props => props.open ? 180 : 0}deg);
`;

class CategoryGroup extends Component {
    state = {
        open: false,
    };

    constructor(props) {
        super(props);
    }

    toggle = () => {
        this.setState(state => ({
            open: !state.open,
        }));
        const {onSelect} = this.props;
        if(onSelect) {
            onSelect();
        }
    };

    render() {
        const {open} = this.state;
        const {categoryGroup, check, selected} = this.props;
        return <div className="border-bottom">
            <div
                className="cursor-pointer"
                onClick={this.toggle}>
                <div className={`d-none d-md-block ${selected ? 'text-primary' : ''}`}>
                    <CategoryGroupName>
                        {categoryGroup.CategoryGroupName}
                        <FontAwesomeIcon icon={faChevronRight} className="font-size-0-625 w-p30 text-center flex-shrink-0" />
                    </CategoryGroupName>
                </div>
                <div className={`d-md-none ${open ? 'text-primary' : ''}`}>
                    <CategoryGroupName>
                        {categoryGroup.CategoryGroupName}
                        <Arrow icon={faChevronDown} className="w-p30 text-center flex-shrink-0"
                               open={open}/>
                    </CategoryGroupName>
                </div>
            </div>
            <div className={ `d-md-none` }>
                <Collapse isOpened={ open }>
                    <div className="pb-2 pb-md-0">
                        {categoryGroup.Categories.map((category) => <Category key={category.ChecklistId}
                                                                            category={category}
                                                                            check={check}/>)}
                    </div>
                </Collapse>
            </div>
        </div>
    }
}

export default CategoryGroup;
