import React, { useCallback, useEffect, useState } from 'react';
import VariantComboList from '../Elements/ComboList/VariantComboList';
import { CustomerOrdersService } from '../../services/customer';
import { formatDateTime } from '../../utils/date';
import { getStoreDataFormatDateTime } from '../../utils/localization';
import { formatPrice } from '../../utils/price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getURLPathWithParams } from '../../utils/staticPaths';
import PageLoader from '../PageLoader';
import { useResource } from '../../contexts/ResourceContext';

const CustomerOrders = ({}) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigateTo = useNavigate();
    const { t } = useResource();

    useEffect(() => {
        const getOrders = async () => {
            const { CustomerOrders } = await CustomerOrdersService.list();
            if (Array.isArray(CustomerOrders)) {
                const orderItems = CustomerOrders?.map((order) => ({
                    id: order.OrderID,
                    name: `#${order.OrderID}`,
                    rows: [
                        {
                            content: formatDateTime(
                                getStoreDataFormatDateTime(),
                                order.CreateTime,
                            ),
                            className: '',
                        },
                        {
                            content: formatPrice(order.OrderValue),
                            className: '',
                        },
                        {
                            content: order.OrderStatus,
                            className: '',
                        },
                    ],
                    isEnabled: true,
                    customIconChildren: (
                        <div className="text-center align-self-center">
                            <FontAwesomeIcon
                                icon={faCartShopping}
                                className="font-size-1-5"
                            />
                        </div>
                    ),
                }));
                setOrders(orderItems);
            }
            setIsLoading(false);
        };
        getOrders();
    }, []);

    const onSelectHandler = useCallback(
        (index) => () => {
            const { id:orderId } = orders[index];
            navigateTo(getURLPathWithParams('customerOrderDetails', { orderId }))
        },
        [orders],
    );

    return (
        <PageLoader isLoading={isLoading}>
            <div className="row justify-content-center mb-n4">
                {!orders ||
                    (orders?.length === 0 && (
                        <>{t('brendon.customer.orders.noOrders')}</>
                    ))}
                {orders?.length > 0 && (
                    <VariantComboList
                        variant="link"
                        items={orders}
                        onSelect={onSelectHandler}
                        containerClassName="col-12 col-sm-10 col-md-8 col-lg-6"
                        containerHoverClassName="border-hover-dark"
                        itemNameClassName="font-size-1 mb-1"
                        textHoverClassName="text-dark"
                    />
                )}
            </div>
        </PageLoader>
    );
};

export default CustomerOrders;
