import { DEDICATED_URLS } from "../models/constants";
import { executeCallback } from "./function";

export function initalOptionsCommand(initialOptions, okCallback, defaultCallback) {
    switch (initialOptions) {
        case 'NoProductInCart' :
            window.location.href = DEDICATED_URLS['cart'];
            break;
        case 'NoOrderableProductInCart' :
            window.location.href = DEDICATED_URLS['cart'];
            break;
        case 'Ok' :
        case 'OK' :
            executeCallback(okCallback);
            return true;
        default :
            executeCallback(defaultCallback, initialOptions);
            return false;
    }
}

export function redirectToUrl(url) {
    window.location.href = url;
};
