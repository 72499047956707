import React, { useState } from 'react';
import CartService from '../../services/cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { useResource } from '../../contexts/ResourceContext';

const DiscountBox = ({ coupons, refreshCart }) => {
    const { t } = useResource();
    const [coupon, setCoupon] = useState('');
    const [couponResponse, setCouponResponse] = useState({});

    const couponChange = (e) => setCoupon(e.target.value);
    const removeCoupon = (couponCode) => async () => {
        const response = await CartService.couponChange({
            CouponCode: couponCode,
            IsDeleted: true,
        });
        setCouponResponse(response);
        if (response?.IsSuccess === true && refreshCart) {
            refreshCart();
        }
    };

    const submitCoupon = async () => {
        const response = await CartService.couponChange({
            CouponCode: coupon,
            IsDeleted: false,
        });
        setCouponResponse(response);
        if (response?.IsSuccess === true && refreshCart) {
            refreshCart();
        }
    };

    const renderApprovedCoupons = (coupon, idx) => (
        <div
            key={`coupon_${idx}`}
            className="mt-1 d-flex line-height-3 align-items-center"
        >
            <span className="text-gray font-size-0-75">
                {t('ShoppingCart.DiscountCouponCode.CurrentCode').replace(
                    '{0}',
                    coupon?.CouponCode,
                )}
            </span>
            <button
                onClick={removeCoupon(coupon?.CouponCode)}
                type="button"
                name={`removediscount-${coupon?.CouponCode}`}
                value=" "
                title={`${t('Common.Remove')}`}
                className="p-0 ml-1 border-0 text-primary bg-transparent"
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        </div>
    );

    return (
        <div className="mb-7">
            <div className="text-uppercase font-weight-light line-height-3 font-size-1 mb-1 pl-1">
                {t('ShoppingCart.DiscountCouponCode')}
            </div>
            <div className="border rounded-lg p-2-5">
                <div className="mb-2 text-gray font-size-0-875">
                    {t('ShoppingCart.DiscountCouponCode.Tooltip')}
                </div>
                <div className="row no-gutters mb-2">
                    <div className="col">
                        <input
                            name="discountcouponcode"
                            type="text"
                            className="form-control form-control-sm w-100 rounded"
                            onChange={couponChange}
                        />
                    </div>
                    <div className="col-auto pl-2">
                        <input
                            type="button"
                            name="applydiscountcouponcode"
                            value={`${t(
                                'ShoppingCart.DiscountCouponCode.Button',
                            )}`}
                            className="btn btn-sm btn-primary rounded"
                            onClick={submitCoupon}
                        />
                    </div>
                </div>

                {!couponResponse?.IsSuccess &&
                    Object.keys(couponResponse).length !== 0 && (
                        <div
                            className={`my-1 font-size-0-75 ${
                                couponResponse?.IsSuccess
                                    ? 'text-green'
                                    : 'text-red'
                            }`}
                        >
                            {t(
                                `brendon.cart.coupon.error.${couponResponse?.ErrorType}`,
                            )}
                        </div>
                    )}

                {coupons?.map(renderApprovedCoupons)}
            </div>
        </div>
    );
};

export default DiscountBox;
