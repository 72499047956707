import React from 'react';
import Notification from '../../components/Notification';
import MainHeader from '../../components/Header/MainHeader';
import Footer from '../../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import Intro from '../../components/NewsletterIntro/Intro';
import { getURLPath } from '../../utils/staticPaths';

const RootLayoutContainer = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <Notification />
      <MainHeader />
      {children}
      <Footer />
      {location?.pathname != getURLPath('registerResult2') && (
        <Intro />
      )}
    </>
  );
};

export default RootLayoutContainer;
