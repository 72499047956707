import React from "react";


const AddressBoxBillingData = ({ address }) => {
    return (
        <>
            <div className="font-weight-medium text-dark">
                {address?.NameOnBill}
            </div>
            <div>
                {address?.Email}
            </div>
            <div>
                {address.PhoneNumber}
            </div>
            <div>
                {address?.TaxNumber}
            </div>
            <div>
                {`${address.ZipPostalCode} ${address.City}, ${address.Country}`}
            </div>
            <div>
                {address.Address}
            </div>
            <div>
                {address?.InvoiceDesc}
            </div>
        </>
    );
}

export default AddressBoxBillingData;
