const axios = require('axios');

const SHOPPING_CART_TYPE = {
    CART: 1,
    WISHLIST: 2,
};

async function addToCartWithSizeName(productId, sizeName, quantity) {
    const result = await axios.get(`/ShoppingCart/AddToCart/${productId}${sizeName!='' && Number(quantity)>0 ?`/${sizeName}/${quantity}`:``}`);
    return result.data;
}

async function addToCartCustom(productId, size) {
    const result = await axios.get(`/ShoppingCart/AddToCart/${productId}${size ? `/${size}/1`: ''}`);
    return result.data;
}

async function addToCart(productId, data) {
    const result = await axios.post(`/addproducttocart/details/${productId}/${SHOPPING_CART_TYPE.CART}`, data);
    return result.data;
}

async function addToWishlist(productId, data) {
    const result = await axios.post(`/addproducttocart/details/${productId}/${SHOPPING_CART_TYPE.WISHLIST}`, data);
    return result.data;
}

async function evaluation() {
    const result = await axios.get(`/ShoppingCart/Evaluation`);
    return result.data;
}

async function modifyItem(data) {
    const result = await axios.post(`/ShoppingCart/ShoppingCartItemModify`, data);
    return result.data;
}

async function couponChange(data) {
    const result = await axios.post(`/ShoppingCart/CouponChange`, data);
    return result.data;
}

async function getCartProductUrlList() {
    const result = await axios.get(`/ShoppingCart/ProductUrlList`);
    return result.data?.ProductUrls;
}

async function AddGiftCardToCart(formData) {
    const result = await axios.post(`/ShoppingCart/AddGiftCard`, formData);
    return result.data;
}

async function checkRewardPointRedeem() {
    const result = await axios.get(`/ShoppingCart/RewardPointRedeem`);
    return result.data;
}

/* Checkout/GetPaymentModeList
•	Checkout/SetGiftCard
•	Checkout/DeleteGiftCard/{GiftCardID}
•	Checkout/PickPaymentMode */

async function getPaymentModeList() {
    const result = await axios.get(`/Checkout/GetPaymentModeList`);
    return result.data;
}

async function setGiftCard(giftCardCode) {
    const result = await axios.post(`/Checkout/SetGiftCard`, {
        GiftCardCode: giftCardCode,
    });
    return result.data;
}

async function deleteGiftCard(giftCardId) {
    const result = await axios.post(`/Checkout/DeleteGiftCard/${giftCardId}`);
    return result.data;
}

async function pickPaymentMode(paymentModeId) {
    const result = await axios.post(`/Checkout/PickPaymentMode`, {
        PaymentModeId: paymentModeId,
    });
    return result.data;
}

export const Cart = {
    addToCart,
    addToWishlist,
    addToCartCustom,
    addToCartWithSizeName,
    evaluation,
    modifyItem,
    couponChange,
    getCartProductUrlList,
    AddGiftCardToCart,
    checkRewardPointRedeem,
    getPaymentModeList,
    setGiftCard,
    deleteGiftCard,
    pickPaymentMode,
};

export default Cart;