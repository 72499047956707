import React, { Component } from 'react';
import FormGroup from "./FormGroup";
import ButtonGroupButton from "./ButtonGroupButton";
import { Field } from "formik";
import variationTypes from './variationTypes';

class ButtonGroup extends Component {

    render() {
        const {
            name,
            label,
            required,
            description,
            options,
            children,
            variation = 'default',
            errorType = 'label-after',
        } = this.props;

        const selectedVariation = variationTypes?.[variation] || variationTypes['default'];

        return <Field name={name}>
            {(fieldProps) => (
                <FormGroup
                    required={required}
                    fieldProps={fieldProps}
                    description={description}
                    label={label}
                    errorType={errorType}
                    {...selectedVariation}
                    >
                    <div className="row">
                        {children}
                        {options.map((option, idx) => (<ButtonGroupButton
                            key={`btng${option.Id}`}
                            onChange={fieldProps.field.onChange(name)}
                            value={option.Id}
                            label={option.Name}
                            selected={fieldProps.field.value && option.Id.toString() === fieldProps.field.value.toString()}
                            className={'col-12 col-sm-6 mb-2 mb-sm-0'}
                        />
                        ))}
                    </div>
                </FormGroup>)}
        </Field>
    }
}

export default ButtonGroup;
