import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import { executeCallback } from '../../utils/function';

function FilterRadio({ isChecked, isDisabled = false, value, onChanged }) {
  
  const toggle = (prevChecked) => () => {
    if (!isDisabled) {
      executeCallback(onChanged, value, !prevChecked);
    }
  };

  return (
      <div className={classnames(`flex-shrink-0`, isDisabled ? 'opacity-50' : '')} onClick={toggle(isChecked)}>
          {isChecked ? (
              <FontAwesomeIcon icon={faCheckCircle} className="font-size-1-25" />
          ) : (
              <FontAwesomeIcon icon={faCircle} className="font-size-1-25" />
          )}
      </div>
  );
}

export default FilterRadio;
