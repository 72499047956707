import React, { useMemo, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';

const ExtraInfoItem = ({ message, limit = 80, type = 'info', showText, className }) => {
    const [showExtraInfo, setShowExtraInfo] = useState(false);

    const iconType = useMemo(() => {
        const icons = {
            info: faInfoCircle,
        };
        return icons[type?.toLowerCase?.()] || faInfoCircle;
    }, [type]);

    const openExtraInfo = useCallback((e) => {
        e.preventDefault();
        setShowExtraInfo(true);
    }, [setShowExtraInfo]);

    const visibleMessage = useMemo(() => {
        if (message?.length > limit) {
            return message?.substring(0, limit) + '...';
        }
        return message;
    }, [message]);

    const extraInfo = useMemo(() => {
        if (message?.length > limit) {
            return message?.substring(limit, message?.length);
        }
        return '';
    }, [message]);

    return (
        <div
            className={classnames(
                `d-flex bg-gray-light rounded px-2 py-2 align-items-center line-height-3 mb-1`,
                type?.toLowerCase?.() === 'warning' ? 'text-danger' : null,
                className
            )}
        >
            <FontAwesomeIcon
                icon={iconType}
                className="font-size-1" 
            />
            <div className="ml-1 line-height-rem-0-8125">
                <span className="line-height-rem-1-125 font-size-0-75">
                    {showExtraInfo ? message : visibleMessage}
                </span>
                {!showExtraInfo && extraInfo != '' && (
                    <span
                        className="font-size-0-75 cursor-pointer text-underline line-height-rem-1-125"
                        onClick={openExtraInfo}
                    >
                        {showText}
                    </span>
                )}
            </div>
        </div>
    );
};

export default ExtraInfoItem;
