import React from "react";
import styled from 'styled-components';
import {CSSTransition} from "react-transition-group";
import loadingGifPath from "../../assets/loading.gif";

const durationMs = 250;

const Wrapper = styled.div`
    opacity: 0;
    pointer-events: none;
    
    &.enter,
    &.appear {
        opacity: 0;
        pointer-events: auto;
        transition: opacity ${durationMs}ms ease-in-out;
    }
    
    &.enter-active,
    &.appear-active {
        opacity: 1;
        transition: opacity ${durationMs}ms ease-in-out;
    }
    
    &.enter-done,
    &.appear-done {
        opacity: 1;
        pointer-events: auto;
    }
    
    &.exit {
        opacity: 1;
        transition: opacity ${durationMs}ms ease-in-out;
    }
    
    &.exit-active {
        opacity: 0;
        transition: opacity ${durationMs}ms ease-in-out;
    }
    
    &.exit-done {
        opacity: 0;
    }
`;

function Loader(props) {
    const {isLoading, onShow, onHide, wrapperClassName = 'h-100',  ...rest} = props;
    return <CSSTransition in={isLoading}
                          appear={true}
                          timeout={durationMs}
                          mountOnEnter={false}
                          onEntered={onShow}
                          onExiting={onHide}
                          {...rest}>
        <Wrapper className={`position-absolute w-100 top left bg-white ${wrapperClassName || ''}`}>
            <div
                className="position-relative position-sticky top vh-100 mh-100 overflow-hidden d-flex justify-content-center align-items-center">
                <img src={loadingGifPath} alt=""/>
            </div>
        </Wrapper>
    </CSSTransition>
}

export default Loader;
