import React, { useState, useEffect } from "react";
import ProductBox from "./ProductBox";
import ProductsNotFound from "./ProductsNotFound";
import MoreCategories from './MoreCategories';

const ProductListByModel = ({
  products,
  categoryName,
  gaEEList,
  legacy,
  enableLeftSide,
  selectedOrderBy,
  enableUpsell,
  upsellList,
}) => {
  const [productsByModels, setProductsByModels] = useState([]);

  useEffect(() => {
    if (products) {
      const productsByModels = products.reduce((acc, product) => {
        const model = product.Model;
        if (!acc[model]) {
          acc[model] = [];
        }
        acc[model].push(product);
        return acc;
      }, {});
      setProductsByModels(productsByModels);
    }
  }, [products]);

  const renderProducts = (productOfModels) =>
    productOfModels.map((product) => (
      <div
        className={`d-flex flex-column col-12 col-md-6 ${
          enableLeftSide ? "col-lg-4" : "col-lg-3"
        } py-md-2 border-left border-bottom`}
        key={product.ProductID}
      >
        <ProductBox
          categoryName={categoryName}
          product={product}
          legacy={legacy}
          selectedOrderBy={selectedOrderBy}
          gaEEList={gaEEList}
          enableUpsell={enableUpsell}
        />
            {upsellList && <>
              {product.CategoryText && product.CategorySlug ? 
                <div className="upsell_more d-sm-none d-md-block" >
                  <MoreCategories text={product.CategoryText} link={`/${product.CategorySlug}`} />
                </div> 
                : 
                <div className="upsell_more d-sm-none d-md-block px-2-5 py-2" >{' '}</div>
              }
            </>}
      </div>
    ));

  return (
    <>
      {products?.length > 0 && Object.keys(productsByModels)?.length > 0 ? (
        Object.entries(productsByModels)?.map(
          ([modelName, productOfModels], index) => (
            <React.Fragment key={`model-cont-${index}`}>
              <div className="col-12" key={`model-${index}`}>
                <h3 className="bg-gray rounded py-2 mt-4 mx-2 mx-sm-0 text-center text-white text-uppercase">
                  {modelName}
                </h3>
              </div>
              {renderProducts(productOfModels)}
            </React.Fragment>
          )
        )
      ) : (
         <ProductsNotFound />
      )}
    </>
  );
};

export default ProductListByModel;
