import React, { useCallback, useState, useEffect, useMemo } from 'react';
import VariantComboList from '../../../../components/Elements/ComboList/VariantComboList';
import { shopList as ShopListService } from '../../../../services/checkout';
import CheckoutStepTitle from '../../../../components/Checkout/CheckoutStepTitle';
import { showError } from '../../../../store/notification/actions';
import { useDispatch } from 'react-redux';
import { initalOptionsCommand } from '../../../../utils/controller';
import Loader from '../../../../components/Loader';
import gaGA4 from '../../../../services/gaGA4';
import CheckoutStepBack from '../../../../components/Checkout/CheckoutStepBack';
import { useResource } from '../../../../contexts/ResourceContext';

const ShopList = ({ step, changeStep, nextStep }) => {
    const { t } = useResource();
    const [shopList, setShopList] = useState([]);
    const [isPageLoading, setPageLoading] = useState(true);
    const dispatch = useDispatch();

    const getShopList = useCallback(async () => {
        const { InitOption, ShopList } = await ShopListService.get();
        if (!initalOptionsCommand(InitOption, null, nextStep)) {
            return;
        }
        setShopList(ShopList);
        setPageLoading(false);
    }, [getShopList]);

    useEffect(() => {
        getShopList();
    }, []);

    const selectHandler = useCallback(
        (index) => () => {
            const { ShopId: shopId = null } = shopList[index] || {};

            if (shopId !== null) {
                ShopListService.select(shopId)
                    .then(({ IsSuccess, Message, NextSite }) => {
                        if (IsSuccess) {
                            gaGA4.gtagEvent('checkout_store_pickup_selected');
                            nextStep(NextSite);
                            return;
                        } else {
                            dispatch(showError(Message));
                        }
                    })
                    .catch((error) => {
                        dispatch(showError(error));
                    });
            }
        },
        [shopList, dispatch, nextStep, showError],
    );

    return (
        <>
            {isPageLoading ? (
                <Loader isLoading />
            ) : (
                <>
                    <CheckoutStepTitle
                       /*  title_resource_name={'brendon.checkout.shoplist.title'} */
                        description={t("brendon.checkout.shoplist.description")}
                    />
                    <CheckoutStepBack
                        title={t('checkout.progress.back.deliverymodes')}
                        />
{/*                     <div className="row justify-content-center pb-2-5">
                        <div className="col-12 col-sm-8 col-md-8 col-lg-6">
                            <a
                                href="#"
                                className="text-dark"
                                onClick={clickBack}
                            >
                                <FontAwesomeIcon
                                    icon={faLongArrowLeft}
                                    className="mr-2 line-height-1-rem"
                                />{' '}
                                {t('checkout.progress.back.deliverymodes')}
                            </a>
                        </div>
                    </div> */}
                    <div className="row justify-content-center">
                        <VariantComboList
                            variant="link"
                            items={shopList.map(
                                ({
                                    StoreName,
                                    ShopType,
                                    City,
                                    Address,
                                    Postcode,
                                    AddressExtension,
                                }) => ({
                                    name: StoreName,
                                    subName: ShopType,
                                    beforeDescription: `${Postcode} ${City}, ${Address}`,
                                    description: AddressExtension,
                                    isEnabled: true,
                                    /*                             enableDetails: true,
                            detailsChildren: <ShopListItemDetails />, */
                                }),
                            )}
                            onSelect={selectHandler}
                            containerClassName="col-12 col-sm-8 col-md-8 col-lg-6"
                            containerHoverClassName="border-hover-dark"
                            textHoverClassName="text-dark"
                            itemTextClassName="ml-2-5"
                            itemNameClassName="text-uppercase"
                            noIconClassName="ml-2"
                            detailsLinkText={t(
                                'brendon.checkout.shoplist.shopdetails',
                            )}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default ShopList;
