import React from 'react';
import SectionContainer from './MainSection/SectionContainer';
import SectionTitle from './MainSection/SectionTitle';
import SectionCardContainer from './MainSection/SectionCardContainer';
import SectionCard from './MainSection/SectionCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { redirectToUrl } from '../../utils/controller';
import { Link } from 'react-router-dom';

const BlogCategorySection = ({
    title,
    moreUrl,
    moreText,
    backgroundColorClass,
    items,
    classNameSecond,
    secondChildren,
    lastChildren,
}) => {    
    return (
        <>
            <SectionContainer className="mb-7">
                <SectionTitle
                    className="mb-3"
                    titlePrefixContent={
                        <span
                            className={`circle ${backgroundColorClass} d-inline-block mr-2`}
                        ></span>
                    }
                    title={title}
                    moreUrl={moreUrl}
                    moreText={moreText}
                    moreClassName="d-none d-sm-block"
                />
                <SectionCardContainer mobileOutOfPage>
                    {items &&
                        items.map((item, index) => (
                            <SectionCard
                                key={index}
                                item={item}
                                containerClassName={`col p-0 ${
                                    item.className || ''
                                } `}
                                className={`${backgroundColorClass}`}
                            />
                        ))}

                    {secondChildren && (
                        <div
                            className={`${
                                lastChildren
                                    ? 'section-card-item second mr-2 mr-md-0'
                                    : 'section-card-item col-12 p-0'
                            }`}
                        >
                            <div
                                className={`bg-gray-light h-adaptiv rounded ${classNameSecond}`}
                            >
                                {secondChildren}
                            </div>
                        </div>
                    )}

                    {lastChildren && (
                        <div className="p-0 flex-1 d-none d-sm-none d-md-block rounded">
                            <div className="bg-gray-light h-adaptiv rounded">
                                {lastChildren}
                            </div>
                        </div>
                    )}
                </SectionCardContainer>
                {lastChildren && (
                    <div className="col-12 p-0 d-md-none mt-2 mt-md-0 rounded">
                        <div className="bg-gray-light rounded">
                            {lastChildren}
                        </div>
                    </div>
                )}
                {(moreText || moreUrl) && (
                    <div className="d-flex d-sm-none mt-2 p-3 bg-gray-light font-size-1 font-weight-medium line-height-rem-1-25 align-items-center rounded">
                        <Link href={moreUrl} className="flex-1 text-body">
                            {moreText}
                        </Link>
                        <div className="ml-auto circle" onClick={ () => redirectToUrl(moreUrl) }>
                            <FontAwesomeIcon
                                icon={faLongArrowRight}
                                className=""
                            />
                        </div>
                    </div>
                )}
            </SectionContainer>
        </>
    );
};

export default BlogCategorySection;
