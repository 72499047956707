import React from 'react';
import { getURLPath } from '../utils/staticPaths';
/* Pages / Screens */
import MainScreen from '../views/screens/MainScreen';
import Cart from '../views/shopping-cart/Cart';
import CheckoutScreen from '../views/checkout/CheckoutScreen';
import WishlistScreen from '../views/wishlist/WishlistScreen';
import RegisterScreen from '../views/common/RegisterScreen';
import RegisterSuccessScreen from '../views/common/RegisterSuccess';
import LoginScreen from '../views/common/LoginScreen';
import ForgotPasswordScreen from '../views/common/ForgotPasswordScreen';
import ForgotPasswordConfirmScreen from '../views/common/ForgotPasswordConfirmScreen';
import SearchPage from '../views/search/SearchPage';
import BlogMainScreen from '../views/blog/BlogMainScreen';
import BlogSearchScreen from '../views/blog/BlogSearchScreen';
import NewbornChecklistScreen from '../views/common/NewbornChecklistScreen';
import withStore from '../hoc/withStore';
import PurchaseCompletedScreen from '../views/checkout/PurchaseCompletedScreen';
import MainNoHeightLayout from '../views/layouts/MainNoHeightLayout';
import UpsellScreen from '../views/common/UpsellScreen';

const BlogScreenWithStore = withStore(BlogMainScreen);
const BlogSearchScreenWithStore = withStore(BlogSearchScreen);

export default {
  custom: [
    {
      path: getURLPath('checkoutCompleted'),
      element: <MainNoHeightLayout />,
      children: [
        {
          path: getURLPath('checkoutCompleted'),
          element: <PurchaseCompletedScreen />,
        },
      ]
    },
  ],
  main: [
    {
      path: '/',
      element: <MainScreen />,
    },
    {
      path: getURLPath('cart'),
      element: <Cart />,
    },
    {
      path: getURLPath('checkout'),
      element: <CheckoutScreen />,
    },
    {
      path: getURLPath('checkoutCompleted'),
      element: <PurchaseCompletedScreen />,
    },
    {
      path: getURLPath('wishlist'),
      element: <WishlistScreen />,
    },
    {
      path: getURLPath('register'),
      element: <RegisterScreen />,
    },
    {
      path: getURLPath('registerSuccess'),
      element: <RegisterSuccessScreen />,
    },
    {
      path: getURLPath('login'),
      element: <LoginScreen />,
    },
    {
      path: getURLPath('loginWithGuest'),
      element: <LoginScreen />,
    },
    {
      path: getURLPath('forgotPassword'),
      element: <ForgotPasswordScreen />,
    },
    {
      path: getURLPath('forgotPasswordConfirm'),
      element: <ForgotPasswordConfirmScreen />,
    },
    {
      path: getURLPath('search'),
      element: <SearchPage />,
    },
    {
      path: getURLPath('newbornChecklist'),
      element: <NewbornChecklistScreen />,
    },
    {
      path: getURLPath('blog'),
      element: <BlogScreenWithStore />,
    },
    {
      path: getURLPath('blogSearch'),
      element: <BlogSearchScreenWithStore />,
    },
    {
      path: getURLPath('upsell'),
      element: <UpsellScreen />,
    }
  ],
};
