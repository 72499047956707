import React, { useEffect, useState } from 'react';
import BlogTitle from '../../components/Blog/BlogTitle';
import BlogPost from '../../components/Blog/BlogPost';
import BlogService from '../../services/blog';
import BlogHtmlMeta from '../../components/Blog/BlogHtmlMeta';
import BannerZone from '../../banners/BannerZone';
import { useSelector } from 'react-redux';
import { getShopStoreId } from '../../store/shopstore/selectors';

const BlogPostScreen = ({ id: postId }) => {
  const [postData, setPostData] = useState({});
  const storeId = useSelector(getShopStoreId);

  useEffect(() => {
    const getPost = async () => {
      if (postId) {
        const response = await BlogService.getPost(postId);
        setPostData(response);
      }
    };
    getPost();
  }, [setPostData, postId]);

  return (
    <>
      <BlogHtmlMeta
        titleType="post"
        titleAddon={postData?.post?.MetaTitle}
        metaDescription={postData?.post?.MetaDescription}
      />
      <div className="blog">
        <BannerZone bannerBlockId={13} ContainerClass={'mb-3'} />
        <BlogTitle storeId={storeId} />
        <BlogPost storeId={storeId} postData={postData} />
        <BannerZone bannerBlockId={14} />
      </div>
    </>
  );
};

export default BlogPostScreen;
