import React, { Component } from 'react';
import styled from 'styled-components';
import withThemeProvider from '../hoc/withThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import SyndiGo from './3rdPartyScripts/SyndiGo';

const LoadMoreWrapper = styled.div`
    ${(props) =>
        props.closed
            ? `
    height: ${props.maxHeight}px;
    overflow: hidden;
    `
            : ''}

    @media all and (min-width: ${(props) => props.theme.gridBreakpoints.md}) {
        height: auto;
        overflow: auto;
    }
`;

const LoadMoreOverlay = styled.div`
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    height: 120px;
`;

class ProductDescription extends Component {
    state = {
        opened: false,
        needToShowLoadMore: false,
        loadSyndiGo: false,
    };

    content = null;

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize);
        this.interval = setInterval(this.handleResize, 1000);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
        if (this.interval) {
            clearInterval(this.interval);
        }
    };

    isDescriptionContainIframe = (description) => {
        return (
            description &&
            description != '' &&
            description.indexOf('<iframe') > -1
        );
    };
    isDescriptionContainSynthrone = (description) => {
        return (
            description &&
            description != '' &&
            description.indexOf('synthrone_content') > -1
        );
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps?.description != this.props?.description &&
            this.isDescriptionContainIframe(this.props?.description)
        ) {
            this.handleResize();
        }
    };

    handleResize = (e) => {
        if (!this.isDescriptionContainIframe(this.props?.description)) {
            return;
        }

        this.iframes &&
            this.iframes.forEach((iframe) => {
                if (iframe.contentDocument === null) return;

                iframe.height =
                    iframe.contentDocument.body.scrollHeight ||
                    iframe.contentDocument.body.offsetHeight;
            });

        const { closedHeight } = this.props;

        if (
            this.state.needToShowLoadMore !==
            this.content.clientHeight > closedHeight
        ) {
            this.setState({
                needToShowLoadMore: this.content.clientHeight > closedHeight,
            });
        }
    };

    open = () => {
        this.setState({
            opened: true,
        });
    };

    setContent = async (content) => {
        const { productSku } = this.props;

        this.content = content;
   /*      this.synthrone_iframe = content.querySelector('#synthrone_content'); */

        if (this.isDescriptionContainSynthrone(this.props?.description)) {
            this.setState({
                loadSyndiGo: true,
            });
        }
        //
        /* if (this.synthrone_iframe && productSku) {
                const iframeContent = await Synthrone.load(productSku);
            const contentDocument = this.synthrone_iframe.contentDocument;
            this.synthrone_iframe.setAttribute('scrolling','no')
            contentDocument.open();
            contentDocument.write(`${iframeContent}`);
            contentDocument.close(); 
 
        }*/

        this.iframes = content?.querySelectorAll('iframe');
        this.iframes &&
            this.iframes.forEach((iframe) => {
                if (iframe.getAttribute('id') === 'synthrone_content') return;
                if (iframe.contentDocument === null) return;
                const document = iframe.contentDocument;
                document.open();
                document.write(
                    `<html><head></head><body style="position: absolute !important;width: 100% !important;margin: 0 !important; padding: 0 !important;overflow: hidden !important;">${iframe.textContent}</body></html>`,
                );
                document.close();
            });
    };

    render() {
        const { opened, needToShowLoadMore, loadSyndiGo } = this.state;
        const {
            title,
            description,
            loadMore,
            closedHeight,
            productSku,
        } = this.props;

        const closed = needToShowLoadMore && !opened;

        return (
            <>
                <h3 className="font-size-1 text-uppercase font-weight-normal border-bottom line-height-3 pb-2">
                    {title}
                </h3>
                <div className="mb-5">
                    <LoadMoreWrapper
                        className="position-relative"
                        closed={closed}
                        maxHeight={closedHeight}
                    >
                        <div
                            ref={this.setContent}
                            className="formatted-content font-size-0-875"
                            dangerouslySetInnerHTML={{
                                __html: description.replace(
                                    '<iframe id="synthrone_content"></iframe>',
                                    '',
                                ),
                            }}
                        />
                        <div>
                            {loadSyndiGo && (
                                <syndigo-powerpage pageid={productSku}>
                                    {' '}
                                </syndigo-powerpage>
                            )}
                        </div>
                        {closed && (
                            <LoadMoreOverlay className="position-absolute bottom w-100 d-lg-none" />
                        )}
                    </LoadMoreWrapper>
                    {closed && (
                        <div
                            className="cursor-pointer border border-gray-medium text-uppercase font-weight-medium font-size-0-75 d-flex d-lg-none justify-content-center align-items-center h-p40"
                            onClick={this.open}
                        >
                            {loadMore}
                            <FontAwesomeIcon icon={faPlus} className="ml-2" />
                        </div>
                    )}
                    {loadSyndiGo && <SyndiGo />}
                </div>
            </>
        );
    }
}

export default withThemeProvider(ProductDescription);
