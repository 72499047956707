
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import {
    faShoppingCart,
    faShippingFast,
    faEnvelopeOpenText,
    faMapMarkedAlt,
    faCreditCardFront,
    faClipboardCheck,
    faCheckCircle,
    faShieldCheck as falShieldCheck,
    faChevronLeft,
    faMoneyBillAlt,
 } from '@fortawesome/pro-light-svg-icons'
import {
    faCheck,
    faInfoCircle as fasInfoCircle,
    faChevronRight as fasChevronRight,
    faChevronUp,
    faChevronDown,
} from '@fortawesome/pro-solid-svg-icons'
import { faFacebookF, faInstagram, faYoutube, faFacebookSquare, faPaypal } from '@fortawesome/free-brands-svg-icons'
import {
    faAt,
    faChevronLeft as farChevronLeft,
    faChevronRight as farChevronRight,
    faTags,
    faInfoCircle,
    faShippingFast as farShippingFast,
    faShoppingCart as farShoppingCart,
    faStars,
    faGiftCard,
    faMailBulk,
    faListAlt,
    faTrashAlt,
    faLockAlt,
    faHome,
    faUser,
    faArrowRight,
} from '@fortawesome/pro-regular-svg-icons'


FontAwesomeLibrary.add(
    faShoppingCart,
    faShippingFast,
    faUser,
    faEnvelopeOpenText,
    faCheck,
    faFacebookF,
    faInstagram,
    faYoutube,
    faAt,
    faChevronLeft,
    faInfoCircle,
    fasInfoCircle,
    faTrashAlt,
    fasChevronRight,
    fasChevronRight,
    farShoppingCart,
    farShippingFast,
    faStars,
    faGiftCard,
    faMailBulk,
    faFacebookSquare,
    faListAlt,
    faMapMarkedAlt,
    faCreditCardFront,
    faClipboardCheck,
    faCheckCircle,
    faLockAlt,
    faHome,
    falShieldCheck,
    faTags,
    farChevronRight,
    farChevronLeft,
    faPaypal,
    faMoneyBillAlt,
    faChevronUp,
    faChevronDown,
    faArrowRight,
);