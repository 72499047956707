import React, {Component} from 'react';
import styled from 'styled-components';
import FilterTag from "../filter/FilterTag";
import withResource from '../hoc/withResource';
import {isDiscounted} from "../models/constants";
import classnames from "classnames";

const ScrollWrapper = styled.div`
    overflow: -moz-scrollbars-none;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    
    ::-webkit-scrollbar { 
        display: none; 
    }
`;

const Fader = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
`;

class FilterTags extends Component {

    state = {
        containerWidth: 'auto',
    }
    onFilterRemoved = (filterId, filterItemId) => {
        const {onFilterRemoved} = this.props;
        if (onFilterRemoved) {
            onFilterRemoved(filterItemId);
        }
    };
    
    refresh = (e ) => {
        const container = document.querySelector('.container');
        this.setState(
            {
                containerWidth: container?.clientWidth || 'auto'
            }
        );
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.refresh);
        this.refresh();
    }
    componentWillMount = () => {
        window.removeEventListener('resize', this.refresh);
    }

    render() {
        const {filters, t, isNewFilterEnabled} = this.props;

        const activeFilters = filters?.filter(filter => filter.FilterItems.some(filterItem => filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2))
            .map(filter => ({
                ...filter,
                FilterItems: filter.FilterItems.filter(filterItem => filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2),
            })) || [];
        
        const filterCount = activeFilters.reduce((sum, filter) => sum + filter.FilterItems.length, 0);
        
        const Wrapper = isNewFilterEnabled ? 'div': ScrollWrapper;

        return <div>
            {!isNewFilterEnabled && filterCount > 0 && <div className="d-md-none font-size-0-75 mb-1">{t('brendon.filters.activeCount', filterCount)}</div>}
            {activeFilters?.length > 0 && <div className="px-sm-2 mx-n2 mb-4">
                <div className="overflow-hidden px-2 px-sm-0 position-relative">
                    <Wrapper className="mx-n2 d-flex">
                        <div className="px-2">
                            <div className={classnames("d-flex gap-10", isNewFilterEnabled ? 'flex-wrap filter-tags' : 'flex-md-wrap')} style={{width: this.state.containerWidth }} >
                                {activeFilters.map(filter => {
                                    return filter.FilterItems.map(filterItem =>
                                        <FilterTag
                                            className=""
                                            filterName={filter.Name}
                                            parentFilterCheckbox={filter.IsCheckbox || false}
                                            filterItemName={filterItem.Name}
                                            isDiscounted={isDiscounted(filter.Id)}
                                            filterId={filter.Id} filterItemId={filterItem.Id}
                                            key={`filter-${filter.Id}-${filterItem.Id}`}
                                            onRemove={this.onFilterRemoved}/>)
                                })}
                            </div>
                        </div>
                    </Wrapper>
                    <Fader className="d-md-none"/>
                </div>
            </div>}
           
        </div>
    }
}


export default withResource(FilterTags);
