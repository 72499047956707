import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import MainLayout from '../views/layouts/MainLayout'; 
import staticRoutes from './staticRoutes';
import staticCustomerRoutes from './staticCustomerRoutes';
import CustomerLayout from '../views/layouts/CustomerLayout';
import DynamicScreens, { dynamicSreenLoader } from '../views/dynamic/DynamicScreens';

/* TODO: MainNoHeightLayout kell ezekhez
cartFinished urls
 
        "CancelPayment",
        "PaymentBigFishBorgun/Callback",
        "PaymentBigFishBorgun/CancelPayment",
        "AcceptPayment",
        "CancelPayment",
        "PaymentPayPalStandardPro/CancelOrder"
*/

export default createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [...staticRoutes.main],
    },
    ...staticRoutes.custom,
    {
        path: '/customer',
        element: <CustomerLayout />,
        children: [...staticCustomerRoutes],
    },
    {
        path: ':path',
        element: <DynamicScreens />,
        loader:  dynamicSreenLoader,
    },
]);
