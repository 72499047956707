import React, { useState, useCallback } from 'react';
import { Formik, Form } from 'formik';
import createPasswordSchema from '../../models/validation/customerPasswordSchema';
import { CustomerPasswordChangeService } from '../../services/customer';
import { executeCallback } from '../../utils/function';
import withStore from '../../hoc/withStore';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../store/notification/actions';
import PasswordGroup from '../../elements/forms/PasswordGroup';
import PageLoader from '../PageLoader';
import { useResource } from '../../contexts/ResourceContext';
import {Link} from "react-router-dom";
import { getURLPath } from '../../utils/staticPaths';

const CustomerChangePassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { t } = useResource();
    
    const savePassword = useCallback(async (data) => {
        setIsLoading(true);
        const { Success, Errors } = await CustomerPasswordChangeService.post(
            data,
        );
        if (Success) {
            dispatch(showSuccess(t('brendon.customer.passwordchange.success')));
        } else {
            if (Errors?.length > 0) {
                dispatch(showError(Errors.join(' ')));
            } else {
                dispatch(showError(t('brendon.customer.passwordchange.error')));
            }
        }
        setIsLoading(false);
    }, []);

    return (
        <PageLoader isLoading={isLoading}>
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                    <Link href={getURLPath('forgotPassword')}>
                        {t('brendon.customer.passwordchange.recovery')}
                    </Link>
                </div>
            </div>
            <div className="row justify-content-center">
                <Formik
                    enableReinitialize
                    validationSchema={createPasswordSchema()}
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        newPasswordConfirm: '',
                    }}
                    onSubmit={async (values, setSubmitting) => {
                        executeCallback(savePassword, {
                            OldPassword: values.oldPassword,
                            NewPassword: values.newPassword,
                        });
                        setSubmitting(false);
                    }}
                >
                    {({ values, setFieldValue, validateForm }) => {
                        return (
                            <Form
                                autoComplete="off"
                                className="col-12 col-sm-6 col-md-5 col-lg-4"
                            >
                                <PasswordGroup
                                    label={t(
                                        'account.changepassword.fields.oldpassword',
                                    )}
                                    variation="wide"
                                    name="oldPassword"
                                    errorType="input-after-always"
                                    feedbackIcons
                                    required
                                />
                                <PasswordGroup
                                    label={t(
                                        'account.changepassword.fields.newpassword',
                                    )}
                                    variation="wide"
                                    name="newPassword"
                                    errorType="input-after-always"
                                    feedbackIcons
                                    required
                                />
                                <PasswordGroup
                                    label={t(
                                        'account.changepassword.fields.confirmnewpassword',
                                    )}
                                    variation="wide"
                                    name="newPasswordConfirm"
                                    errorType="input-after-always"
                                    feedbackIcons
                                    required
                                />

                                <div className="row form-group mt-4 mb-0">
                                    <div className="col-12">
                                        <input
                                            type="submit"
                                            value={t('Common.Save')}
                                            name="save-info-button"
                                            className="btn btn-primary btn-lg rounded-5 w-100 font-size-1"
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </PageLoader>
    );
};

export default withStore(CustomerChangePassword);
