import { call, debounce, put, select } from 'redux-saga/effects';
import { changeSearchQuery, setSearchResult } from './actions';
import { selectIsSearchQueryLoaded, getNewQueryMode } from './selectors';
import axios from 'axios';

const BRENDON_AUTOCOMPLETE_URL = '/BrendonAjax/GetAutoSuggestResult'; // ?q=baba
const BRENDON_AUTOSUGGEST_URL = '/GetAutoSuggest'; // ?q=baba

async function getSearchQueryNew(query) {
    const { data } = await axios.get(BRENDON_AUTOSUGGEST_URL, {
        params: {
            q: query,
        },
    });

    const itemGroups = [];
    let itemGroupIndex = 0;
    const items = {};
    const moreItems = {};

    data?.AutosuggestItems?.forEach((item) => {
        switch (item.GroupType) {
            case 'Title':
                item.Slug = '/' + item.Slug.split('/').join('/');
                item.Type = 'Title';
                itemGroups.push(item);
                itemGroupIndex = itemGroups.length - 1;
                break;
            case 'Item':
                item.Slug = '/' + item.Slug.split('/').join('/');
                if (!items[itemGroupIndex]) {
                    items[itemGroupIndex] = [];
                }
                item.Type = 'Item';
                items[itemGroupIndex].push(item);
                break;
            case 'MoreItems':
  /*               item.Slug = `${item.Slug}?q=${query}`;
                moreItems[itemGroupIndex] = item; */
                item.Slug = `${item.Slug}?q=${query}`
                item.Type = 'MoreItems';
                if (!items[itemGroupIndex]) {
                    items[itemGroupIndex] = [];
                }
                items[itemGroupIndex].push(item);
                break;
        }
    });

    return {
        itemGroups,
        items,
        moreItems,
    };
}

async function getSearchQuery(query) {
    const { data } = await axios.get(BRENDON_AUTOCOMPLETE_URL, {
        params: {
            q: query,
        },
    });

    data.Categories.forEach((category) => {
        category.SlugURL = '/' + category.SlugURL.split('/').join('/');
    });

    data.Products.forEach((product) => {
        product.Slug = '/' + product.Slug.split('/').join('/');
    });

    return data;
}

function* updateSearchQuery(action) {
    const query = action.payload;
    const newQueryMode = yield select((state) => getNewQueryMode(state));

    if (query.length < 3) {
        return;
    }

    const isSearchQueryLoaded = yield select(selectIsSearchQueryLoaded(query));
    if (isSearchQueryLoaded) {
        return;
    }

    try {
        const result = yield call(
            newQueryMode ? getSearchQueryNew : getSearchQuery,
            query,
        );
        yield put(setSearchResult(query, result));
    } catch (e) {
        console.error(e);
    }
}

function* searchSaga() {
    yield debounce(300, changeSearchQuery, updateSearchQuery);
}

export default searchSaga;
