import { object, string, ref } from 'yup';
import { legacy_t as t} from '../../contexts/ResourceContext';
import {
    isSpaceFilled,
    isValidEmail,
    isValidHungarianPhone,
    isValidSlovakianMobilePhone,
} from '../../utils/validation';
import { FORM_FIELD_LENGTHS } from '../constants';
import { getDomainStoreId } from '../../utils/localization';

const isMobilPhoneValidation = (value) =>
    getDomainStoreId() == 1
        ? isValidHungarianPhone(value)
        : isValidSlovakianMobilePhone(value);

export default function createCustomerPersonalDataSchema() {
    return object({
        name: string()
            .transform(isSpaceFilled)
            .required(t('brendon.dropoff.validation.nameRequired')),
        email: string()
            .transform(isSpaceFilled)
            .test(
                'email',
                t('brendon.dropoff.validation.emailInvalid'),
                isValidEmail,
            )
            .max(
                FORM_FIELD_LENGTHS.EMAIL,
                t('brendon.dropoff.validation.emailLength').replace(
                    '#0#',
                    FORM_FIELD_LENGTHS.EMAIL,
                ),
            )
            .required(t('brendon.dropoff.validation.emailRequired')),
        phone: string()
            .transform(isSpaceFilled)
            .required(t('brendon.dropoff.validation.phoneRequired'))
            .test(
                'phone',
                t('brendon.dropoff.validation.phoneInvalid'),
                isMobilPhoneValidation,
            ),
        /*     .max(
                18,
                t('brendon.dropoff.validation.phoneLength').replace(
                    '#0#',
                    18,
                ),
            ), */
        postalCode: string()
            .transform(isSpaceFilled)
            .required(t('brendon.dropoff.validation.postalCodeRequired'))
            .max(
                FORM_FIELD_LENGTHS.ZIP,
                t('brendon.dropoff.validation.postalLength').replace(
                    '#0#',
                    FORM_FIELD_LENGTHS.ZIP,
                ),
            ),
        city: string()
            .transform(isSpaceFilled)
            .required(t('brendon.dropoff.validation.cityRequired'))
            .max(
                50,
                t('brendon.dropoff.validation.cityLength').replace('#0#', 50),
            ),
        street: string()
            .transform(isSpaceFilled)
            .required(t('brendon.dropoff.validation.streetRequired'))
            .max(
                50,
                t('brendon.dropoff.validation.streetLength').replace('#0#', 50),
            ),
        /*         houseNumber: string()
            .transform(isSpaceFilled)
            .required(t('brendon.dropoff.validation.houseNumberRequired'))
            .max(
                50,
                t('brendon.dropoff.validation.houseNumberLength').replace(
                    '#0#',
                    50,
                ),
            ), */
    });
}
