import React from 'react';
import { executeCallback } from '../../utils/function';
import BrMinusIcon from '../../../assets/icons/br-filter-minus.svg';
import BrPlusIcon from '../../../assets/icons/br-filter-plus.svg';

function FilterToggler({ isOpen, value, onChanged }) {
  const toggle = (prevState) => () => {
    executeCallback(onChanged, value, !prevState);
  };
  return (
    <div
      className="flex-shrink-0 cursor-pointer"
      onClick={toggle(isOpen)}
    >
      {isOpen ? <img src={BrMinusIcon} alt="-" /> : <img src={BrPlusIcon} alt="+" />}
    </div>
  );
}
FilterToggler.displayName = 'FilterToggler';
export default FilterToggler;
