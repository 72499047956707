import React from 'react';
import ProductInfoStrip from '../components/ProductInfoStrip';
import classNames from 'classnames';

function ProductInfoBlocksV2({ extraInfos, variant = 'normal', className }) {
  if (!extraInfos || extraInfos.length === 0) return null;
  return (
    <div className={classNames('font-size-0-75', className)}>
      {extraInfos.map((info, index) => (
        <ProductInfoStrip
          key={index}
          url={info.Url}
          urlText={info.UrlText}
          infoText={info.InfoText}
          icon={info?.Icon}
          variant={variant}
          text={info?.Text}
        >
          <>{info?.Text ? <span>{info?.Text}</span> : ''}</>
        </ProductInfoStrip>
      ))}
    </div>
  );
}

export default ProductInfoBlocksV2;
