import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';

export default ({
  title,
  keywords,
  description,
  noIndex = false,
  showBrendonAddon = false,
}) => {
  useLayoutEffect(() => {
    if (keywords != null) {
      document
        .querySelector('meta[name="keywords"]:not([data-react-helmet])')
        ?.remove();
    }
    if (description != null) {
      document
        .querySelector('meta[name="description"]:not([data-react-helmet])')
        ?.remove();
    }
  }, [keywords, description]);

  const brendonTitle = ` | ${process.env.BRENDON_DEFAULT_TITLE}`;

  console.log('showBrendonAddon', showBrendonAddon)
  return (
    <Helmet>
      {title && (
        <title>
          {title}
          {showBrendonAddon ? brendonTitle : ""}
        </title>
      )}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" contenct={keywords} />}
      {noIndex && <meta name="robots" content="noindex,follow" />}
    </Helmet>
  );
};
