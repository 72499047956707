import { number, object, string } from 'yup';
import { legacy_t as t} from '../../contexts/ResourceContext';
import {
    ADDRESS_TYPE,
    HUNGARY_COUNTRY_ID,
    FORM_FIELD_LENGTHS,
} from '../constants';

import validateHungarianTaxNumberFormat from './hungarianTaxNumberValidator';
import TaxService from '../../services/tax';
import { isValidEmail } from '../../utils';
import { isSpaceFilled } from '../../utils/validation';

async function validateHungarianTaxNumber(value) {
    if (!validateHungarianTaxNumberFormat(value)) {
        return false;
    }
    const {
        QueryTaxpayerResult,
        TaxpayerValidity,
    } = await TaxService.getTaxInfo(value.slice(0, 8));
    if (QueryTaxpayerResult !== 0) {
        return true;
    }

    return TaxpayerValidity;
}

async function validateHungarianTaxNumberOrEmpty(value) {
    if (value === '' || value === undefined) {
        return true;
    }
    return validateHungarianTaxNumber(value);
}

function createBillingAddressSchema(customFields, disableTaxNumberValidation) {
    const { addressType, taxNumber, companyNumber } = customFields;

    return object({
        Email: string()
            .test(
                'Email',
                t('brendon.checkout.billingaddress.validation.emailInvalid'),
                isValidEmail,
            )
            .max(
                FORM_FIELD_LENGTHS.EMAIL,
                t(
                    'brendon.checkout.billingaddress.validation.emailLength',
                ).replace('#0#', FORM_FIELD_LENGTHS.EMAIL),
            )
            .required(
                t('brendon.checkout.billingaddress.validation.emailRequired'),
            ),
        PhoneNumber: string().transform(isSpaceFilled)
            .required(
                t('brendon.checkout.billingaddress.validation.phoneRequired'),
            )
            .max(
                18,
                t(
                    'brendon.checkout.billingaddress.validation.phoneLength',
                ).replace('#0#', 18),
            ),
        NameOnBill: string().transform(isSpaceFilled).required(
            t('brendon.checkout.billingaddress.validation.companyRequired'),
        ),
        ZipPostalCode: string().transform(isSpaceFilled).required(
                t(
                    'brendon.checkout.billingaddress.validation.postalCodeRequired',
                ),
            )
            .max(
                FORM_FIELD_LENGTHS.ZIP,
                t(
                    'brendon.checkout.billingaddress.validation.postalLength',
                ).replace('#0#', FORM_FIELD_LENGTHS.ZIP),
            ),
        City: string().transform(isSpaceFilled)
            .required(
                t('brendon.checkout.billingaddress.validation.cityRequired'),
            )
            .max(
                50,
                t(
                    'brendon.checkout.billingaddress.validation.cityLength',
                ).replace('#0#', 50),
            ),
        Address: string().transform(isSpaceFilled)
            .required(
                t('brendon.checkout.billingaddress.validation.addressRequired'),
            )
            .max(
                50,
                t(
                    'brendon.checkout.billingaddress.validation.addressLength',
                ).replace('#0#', 50),
            ),
        [addressType]: number(
            t('brendon.checkout.billingaddress.validation.addressTypeRequired'),
        )
            .min(
                1,
                t(
                    'brendon.checkout.billingaddress.validation.addressTypeRequired',
                ),
            )
            .required(
                t(
                    'brendon.checkout.billingaddress.validation.addressTypeRequired',
                ),
            ),
        [taxNumber]: string().when(addressType, {
            is: (value) =>
                value && Number(value) === Number(ADDRESS_TYPE.LEGAL_PERSON),
            then: disableTaxNumberValidation
                ? string().max(
                      20,
                      t(
                          'brendon.checkout.billingaddress.validation.taxNumberInvalid',
                      ).replace('#0#', 20),
                  )
                : string().when('CountryId', {
                      is: (value) => value?.toString() === HUNGARY_COUNTRY_ID,
                      then: string()
                          .required(
                              t(
                                  'brendon.checkout.billingaddress.validation.taxNumberRequired',
                              ),
                          )
                          .test(
                              'hungarianTaxNumber',
                              t(
                                  'brendon.checkout.billingaddress.validation.taxNumberInvalid',
                              ),
                              validateHungarianTaxNumber,
                          ),
                      otherwise: string().test(
                          'hungarianTaxNumber',
                          t(
                              'brendon.checkout.billingaddress.validation.taxNumberInvalid',
                          ),
                          validateHungarianTaxNumberOrEmpty,
                      ),
                  }),
            otherwise: string(),
        }),
        [companyNumber]: string().max(
            20,
            t(
                'brendon.checkout.billingaddress.validation.companyNumberLength',
            ).replace('#0#', 20),
        ),
    });
}

export default createBillingAddressSchema;
