import React, { useState, useEffect, useMemo, useCallback } from 'react';
import GreyDataBox from '../Elements/GreyDataBox';
import {
  CustomerOrdersService,
  CustomerReorderService,
} from '../../services/customer';
import { formatDateTime } from '../../utils/date';
import {
  getStoreDataFormatDateTime,
  formatAddress,
} from '../../utils/localization';
import OrderSummary from '../../views/shopping-cart/order-summary/OrderSummary';
import OrderTotals from '../ShoppingCart/OrderTotals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/pro-light-svg-icons';
import Button from '../../elements/Button';
import PageLoader from '../PageLoader';
import { useNavigate } from 'react-router-dom';
import { getURLPath } from '../../utils/staticPaths';
import { useResource } from '../../contexts/ResourceContext';

const CustomerOrderDetails = ({ orderId }) => {
  const { t } = useResource();
  const [order, setOrder] = useState({
    BillingAddress: {
      BillingAddress_ZipPostalCode: '',
      BillingAddress_City: '',
      BillingAddress_Country: '',
    },
    OrderShippingData: {
      ShippingAddress_ZipPostalCode: '',
      ShippingAddress_City: '',
      ShippingAddress_Country: '',
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isReorderLoading, setIsReorderLoading] = useState(false);
  const navigateTo = useNavigate();

  useEffect(() => {
    const getOrder = async () => {
      const customerOrder = await CustomerOrdersService.get({ orderId });
      setIsLoading(false);
      setOrder(customerOrder);
    };

    if (orderId) {
      getOrder();
    }
  }, [orderId]);

  const reorder = useCallback(() => {
    setIsReorderLoading(true);
    const makeReorder = async () => {
      const { IsSuccess } = await CustomerReorderService.get(orderId);
      if (IsSuccess) {
        // window.location.href = DEDICATED_URLS.cart;
        navigateTo(getURLPath('cart'));
      }
    };
    makeReorder();
  }, [orderId]);

  const billingAddress = useMemo(
    () =>
      formatAddress(
        order?.BillingAddress?.BillZipPostalCode,
        order?.BillingAddress?.BillCity,
        order?.BillingAddress?.BillCountryName,
      ),
    [order],
  );

  const shippingaddress = useMemo(
    () =>
      formatAddress(
        order?.ShippingAddress?.ShippingCountryZipPostalCode,
        order?.ShippingAddress?.ShippingCountryCity,
        order?.ShippingAddress?.ShippingCountryName,
      ),
    [order],
  );

  return (
    <PageLoader isLoading={isLoading}>
      <div className="row justify-content-center m-0 mb-2-5 d-lg-none">
        <Button
          variant="primary"
          size="lg"
          className="w-100 d-flex justify-content-center align-items-baseline py-1 rounded-5 font-size-1"
          onClick={reorder}
          isLoading={isReorderLoading}
        >
          <FontAwesomeIcon icon={faCartShopping} className="mr-1" />
          {t('Order.Reorder')}
        </Button>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6 pb-2-5 py-lg-2">
          <GreyDataBox
            title={t('brendon.account.customerorders.details')}
            rightChildren={
              <div className="d-none d-lg-block">
                <Button
                  variant="primary"
                  size="md"
                  className="w-100 d-flex justify-content-center align-items-baseline py-1 rounded-5 font-size-1"
                  onClick={reorder}
                  isLoading={isReorderLoading}
                >
                  <FontAwesomeIcon icon={faCartShopping} className="mr-1" />
                  {t('Order.Reorder')}
                </Button>
              </div>
            }
            className={'h-100 rounded'}
            classNameRight={'text-center flex-1'}
            classNameTitle={'pb-1'}
          >
            <ul className="font-weight-medium">
              <li className="pb-1">
                {formatDateTime(
                  getStoreDataFormatDateTime(),
                  order?.StatusData?.CreateTime,
                )}
              </li>
              {/*      <li className="pb-1">
                                {formatPrice(
                                    order?.OrderSummary?.ShoppingCartTotal,
                                )}
                            </li> */}
              <li>{order?.StatusData?.OrderStatus}</li>
            </ul>
          </GreyDataBox>
        </div>
        <div className="col-12 col-lg-6 pb-2-5 py-lg-2">
          <GreyDataBox
            title={t('brendon.account.customerorders.contact')}
            className={'h-100'}
            classNameTitle={'pb-1'}
          >
            <ul className="font-weight-medium">
              {order?.ContactInfo?.Email && (
                <li className="pb-1">{order?.ContactInfo?.Email}</li>
              )}
              {order?.ContactInfo?.PhoneNumber && (
                <li className="pb-1">{order?.ContactInfo?.PhoneNumber}</li>
              )}
              <li></li>
            </ul>
          </GreyDataBox>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-4 pb-2-5 py-lg-2">
          <GreyDataBox
            title={t('brendon.account.customerorders.billingaddress')}
            className={'h-100 rounded'}
            classNameTitle={'pb-1'}
          >
            <ul className="font-weight-medium">
              {order?.BillingAddress?.NameOnBill && (
                <li className="pb-1">{order?.BillingAddress?.NameOnBill}</li>
              )}
              <li className="pb-1">{billingAddress}</li>
              {order?.BillingAddress?.BillAddress && (
                <li>{order?.BillingAddress?.BillAddress}</li>
              )}
            </ul>
          </GreyDataBox>
        </div>

        <div className="col-12 col-lg-4 pb-2-5 py-lg-2">
          <GreyDataBox
            title={t('brendon.account.customerorders.shippingaddress')}
            className={'h-100 rounded'}
            classNameTitle={'pb-1'}
          >
            <ul className="font-weight-medium">
              {order?.StatusData?.DeliveryMethodName && (
                <li className="pb-1">
                  {order?.StatusData?.DeliveryMethodName}
                </li>
              )}
              {order?.ShippingAddress?.ReceiverName && (
                <li className="pb-1">{order?.ShippingAddress?.ReceiverName}</li>
              )}
              <li className="pb-1">{shippingaddress}</li>
              {order?.ShippingAddress?.ShippingCountryAddress && (
                <li>{order?.ShippingAddress?.ShippingCountryAddress}</li>
              )}
            </ul>
          </GreyDataBox>
        </div>

        <div className="col-12 col-lg-4 pb-2-5 py-lg-2">
          <GreyDataBox
            title={t('brendon.account.customerorders.paymentmethod')}
            className={'h-100 rounded'}
            classNameTitle={'pb-1'}
          >
            <ul className="font-weight-medium">
              {order?.StatusData?.PaymentMethodName && (
                <li className="pb-1">{order?.StatusData?.PaymentMethodName}</li>
              )}
              {order?.StatusData?.PaymentStatus && (
                <li className="pb-1">{order?.StatusData?.PaymentStatus}</li>
              )}
            </ul>
          </GreyDataBox>
        </div>
      </div>
      <div>
        {order?.OrderItems?.length > 0 && (
          <OrderSummary
            className=""
            items={order?.OrderItems?.map((item) => ({
              ...item,
              ShoppingCartItemId: item?.OrderItemId,
              Subtotal: item?.Price,
              Price: item?.UnitPrice,
              Url: `/${item?.Url}`,
            }))}
            legacy={false}
            showProductImages={true}
            isEditable={false}
            quantityLabel={t('checkout.shoppingcart.quantity')}
            unitPriceLabel={t('checkout.shoppingcart.unitprice')}
            itemTotalLabel={t('checkout.shoppingcart.itemtotal')}
          >
            <div className={`row justify-content-between`}>
              <div className="col-12 col-md-6 col-lg-4"> </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                <OrderTotals
                  sumItems={order?.OrderSummary?.SumItems}
                  sumAmountDiscounts={order?.OrderSummary?.SumAmountDiscounts}
                  discountSubItems={order?.Coupons?.map((item) => ({
                    item: item?.CouponCode,
                    price: item?.Price,
                  }))}
                  rewardPointRedeemedSum={
                    order?.OrderSummary?.RewardPointRedeemedSum
                  }
                  sumItemsReducedByDiscounts={
                    order?.OrderSummary?.SumItemsReducedByDiscounts
                  }
                  shippingFee={order?.OrderSummary?.ShippingFee}
                  shippingFeeInfoText={order?.ShippingFeeInfo?.map(
                    (item) => item.ShippingFeeInfoText,
                  )}
                  amountWithShippingFee={
                    order?.OrderSummary?.AmountWithShippingFee
                  }
                  amountToPay={order?.OrderSummary?.AmountToPay}
                  giftCardCodes={order?.OrderSummary?.GiftCards}
                />
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 d-flex justify-content-center align-items-baseline py-1 rounded-5 font-size-1"
                  onClick={reorder}
                  isLoading={isReorderLoading}
                >
                  <FontAwesomeIcon icon={faCartShopping} className="mr-1" />
                  {t('Order.Reorder')}
                </Button>
              </div>
            </div>
          </OrderSummary>
        )}
      </div>
    </PageLoader>
  );
};

export default CustomerOrderDetails;
