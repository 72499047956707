import React from 'react';
import withThemeProvider from '../../hoc/withThemeProvider';
import withStore from '../../hoc/withStore';
import { connect } from 'react-redux';
import SizeSelector from '../../components/SizeSelector';
import AddToCartButton from './AddToCartButton';
import DeleteButton from '../../elements/DeleteButton';
import { dismissMessage, showError } from '../../store/notification/actions';
import gaService from '../../services/gaEnhancedEcommerce';
import { getStoreCurrency } from '../../utils/localization';
import ItemPrice from '../ShoppingCart/ItemPrice';
import GA4Service from '../../services/gaGA4';
import withResource from '../../hoc/withResource';

const WishlistLine = ({
  item,
  addToCart,
  dismissMessage,
  showError,
  removeWishListItem,
  isLoading,
  t,
}) => {
  const [size, setSize] = React.useState(null);

  const addToCartHandler = () => {
    dismissMessage();
    addToCart(item.ProductId, size ? size.SizeName : null);

    gaService.addProductToCart(
      gaService.transformToProductFieldObject({
        ...item,
        Brand: item?.Brand,
      }),
      getStoreCurrency(),
    );
    GA4Service.gtagEvent('add_to_cart', {
      currency: getStoreCurrency(),
      value: item.DiscountedPrice > 0 ? item.DiscountedPrice : item.Price,
      items: [
        GA4Service.transformProductToItemListItem('wishlist', 'Wishlist', item),
      ],
    });
  };

  const removeProductHandler = () => {
    removeWishListItem();
  };

  const changeSizeHandler = (selectedSize) => {
    if (selectedSize !== null && !selectedSize.IsOrderable) {
      showError(t('brendon.wishlist.sizeisnotorderable'));
    }
    if (selectedSize !== null) {
      setSize(selectedSize);
    }
  };

  const canAddToCart =
    item?.Availability === 'yes' &&
    ((size !== null && size?.IsOrderable) ||
      item?.Sizes === null ||
      item?.Sizes?.length == 0);
  const isOnlyInStore = item?.Availability?.toLowerCase() === 'onlyinstore';

  return (
    <div className="py-3 px-2 mx-n2 px-sm-0 mx-sm-0 font-size-0-75 border-bottom">
      <div className="row align-items-center">
        <div className="col-5 col-md-2 col-lg-1 align-self-start">
          <a
            className="aspect-ratio-square d-block w-100 rounded-sm overflow-hidden"
            href={item?.Url}
          >
            <img alt={item?.Title} src={item.Image} title={item?.Title} />
          </a>
        </div>
        <div className="col col-lg-4 d-flex flex-column flex-lg-row align-self-stretch align-self-lg-center">
          <div className="flex-fill d-flex flex-column flex-lg-row justify-content-between pb-sm-2 pb-md-0">
            <div className="d-flex justify-content-between">
              <a href={item?.Url} className="text-dark line-height-rem-1">
                {!item?.Name ? (
                  <div className="font-size-0-875">
                    <div className="font-weight-medium">{item?.Brand}</div>
                    <div>
                      {item?.Model} {item?.Design}
                    </div>
                    <div>{item?.Function}</div>
                  </div>
                ) : (
                  <div className="font-size-0-875 font-weight-medium">
                    {item.Name}
                  </div>
                )}
              </a>
            </div>
            <div className="d-flex justify-content-between d-lg-none">
              <div className="font-size-0-875 text-nowrap font-weight-bold">
                <ItemPrice
                  price={item?.Price}
                  discountedPrice={item?.DiscountedPrice}
                  superPrice={item?.SuperPrice}
                />
              </div>
            </div>
          </div>
          <div className="d-none d-sm-block d-md-none">
            <div>
              {!isOnlyInStore && item?.Sizes?.length > 0 ? (
                <SizeSelector
                  onSizeChange={changeSizeHandler}
                  value={size}
                  className="mb-2"
                  orderableText={t('brendon.wishlist.orderable')}
                  notOrderableText={t('brendon.wishlist.notOrderable')}
                  chooseSizeText={t('brendon.wishlist.chooseSize')}
                  sizes={item.Sizes.map((size) => ({
                    SizeName: size?.Name,
                    IsOrderable: size?.Available,
                  }))}
                />
              ) : (
                <>
                  {!canAddToCart && (
                    <div className="line-height-5 font-weight-bold text-center text-lg-left">
                      {t('brendon.wishlist.notAvailable')}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="row">
              <div className="col">
                <AddToCartButton
                  canAddToCart={canAddToCart}
                  addToCart={addToCartHandler}
                  isLoading={isLoading}
                />
              </div>
              <div className="col-auto pl-0">
                <DeleteButton onClick={removeProductHandler} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 d-none d-lg-block">
          <div className="font-size-0-875 text-nowrap font-weight-bold">
            <ItemPrice
              price={item?.Price}
              discountedPrice={item?.DiscountedPrice}
              superPrice={item?.SuperPrice}
            />
          </div>
        </div>
        <div className="mt-2 mt-sm-0 col-12 col-md-4 col-lg-3 d-sm-none d-md-block">
          <div className="row align-items-end">
            <div className="col">
              <div className="row">
                <div className="col-12">
                  {!isOnlyInStore && item?.Sizes?.length > 0 ? (
                    <SizeSelector
                      onSizeChange={changeSizeHandler}
                      value={size}
                      className="mb-2 mb-lg-0"
                      orderableText={t('brendon.wishlist.orderable')}
                      notOrderableText={t('brendon.wishlist.notOrderable')}
                      chooseSizeText={t('brendon.wishlist.chooseSize')}
                      sizes={item.Sizes.map((size) => ({
                        SizeName: size?.Name,
                        IsOrderable: size?.Available,
                      }))}
                    />
                  ) : (
                    <>
                      {!canAddToCart && (
                        <div className="line-height-5 font-weight-bold text-center text-lg-left">
                          {t('brendon.wishlist.notAvailable')}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="col">
                  <div className="d-lg-none">
                    <AddToCartButton
                      canAddToCart={canAddToCart}
                      addToCart={addToCartHandler}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
                <div className="col-auto d-lg-none pl-0">
                  <DeleteButton onClick={removeProductHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-lg-block">
          <AddToCartButton
            canAddToCart={canAddToCart}
            addToCart={addToCartHandler}
            isLoading={isLoading}
          />
        </div>
        <div className="col-1 d-none d-lg-block">
          <DeleteButton onClick={removeProductHandler} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showError: (message) => dispatch(showError(message)),
  dismissMessage: () => dispatch(dismissMessage()),
});

export default withThemeProvider(
  withStore(connect(null, mapDispatchToProps)(withResource(WishlistLine))),
);
