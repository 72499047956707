import React from 'react'
import withStore from "../hoc/withStore";
import {connect} from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { selectProductSelectedSize } from "../store/product/selectors";
import { useResource } from '../contexts/ResourceContext';

function StockAvailabilityApi({ selectedSize, productData }) {
    const { t } = useResource();
    const { Availability, StockInfo } = productData;

    if (!Availability) return null;

    const stockResource = (key) => t(`brendon.stockavailability.${key}`)
    const getStockResult = () => {
        return (selectedSize && selectedSize?.Stockinfo) ? stockResource(selectedSize.Stockinfo) : stockResource(StockInfo)
    }
    return <div className="font-size-0-75 text-gray mb-2 px-3pixel font-weight-medium">
        <div className="stock">
            {(Availability.toLowerCase() === 'yes' || Availability.toLowerCase() === 'onlyinstore')
            ?
            Availability.toLowerCase() === 'onlyinstore' ?
                    <div className="only-in-store hide">
                        <span className="font-weight-medium">{t(`brendon.product.notorderable`)}</span>
                    </div>
                    :
                    <div className="d-flex justify-content-between text-dark">
                        <div>{getStockResult()}</div>
                    </div>
            :
            <div className="text-dark"><div>{getStockResult()}</div></div>}
        </div>
    </div>
}

const mapStateToProps = state => ({
    selectedSize: selectProductSelectedSize(state),
});

export default withStore(connect(mapStateToProps)(withImmutablePropsToJS(StockAvailabilityApi)));
