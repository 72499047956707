
import MenuService from "../../services/menu";
import {apiRequestAction, failure, request, success} from "../actions";
import {fromJS, Map, List } from "immutable";

export const GET_MEGA_MENU = 'GET_MEGA_MENU';

const initialState = fromJS({
    loading: false,
    CategoryMenu: {},
    TopMenu: {},
    FooterTopicList: {},
})

export const getMegaMenu = () => apiRequestAction(GET_MEGA_MENU, async () => MenuService.getMenu())();

export default function MegaMenuReducer(state = initialState, action) {
    switch (action.type) {
        case request(GET_MEGA_MENU):
            return state.set('loading', true);
        case success(GET_MEGA_MENU):
            return state
                .set('loading', false)
                .set('CategoryMenu', Map(action?.result?.CategoryMenu))
                .set('TopMenu', List(action?.result?.TopMenu))
                .set('FooterTopicList', List(action?.result?.FooterTopicList))
                .set('ShoppingInfos', List(action?.result?.ShoppingInfos));
        case failure(GET_MEGA_MENU):
            return state.set('loading', false);
        default:
            return state
    }
}