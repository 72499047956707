import React from 'react';
import styled from 'styled-components';
import withThemeProvider from "../../hoc/withThemeProvider";
import {
    CSSTransition, SwitchTransition,
} from 'react-transition-group';
import image1Url from '../../../assets/intro-1.jpg';
import image2Url from '../../../assets/intro-2.jpg';
import image3Url from '../../../assets/intro-3.jpg';

const Animating = styled.div`
    opacity: 0;
    transition: all 0.5s ease-in-out;
    
    &.appear,
    &.enter {
        opacity: 0;
    }
    
    &.appear-done,
    &.appear-active,
    &.enter-active, 
    &.enter-done {
        opacity: 1;
    }
   
    &.exit {
        opacity: 1;
    }

    &.exit-active,
    &.exit-done {
        opacity: 0;
    }
`;

const ImageWrapper = styled.div`
    max-height: 80px;

    @media all and (min-width: ${props => props.theme.gridBreakpoints.md}) {
        max-height: none;
    }
`;

const Image = styled.img`
    object-position: ${props => props.position === 'center' ? '50%' : '100%'} ${props => props.verticalPosition !== undefined ? `${props.verticalPosition}%` : '50%'};
    @media all and (min-width: ${props => props.theme.gridBreakpoints.md}) {
        height: 100%;
        border-top-left-radius: initial!important;
    }
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
`;

function SubPage({currentPage, maxPage, position, verticalPosition, children, imageNumber}) {

    const getImagePath = () => {
        const images = [
            image1Url,
            image2Url,
            image3Url,
        ]
        return images[Number(imageNumber) - 1];
    }

    return <div className="row no-gutters flex-column flex-md-row flex-fill">
        <ImageWrapper className="col-12 col-md-4 bg-gray d-flex order-md-last rounded-lg">
            <Image src={getImagePath()} position={position} verticalPosition={verticalPosition}
                   className="object-fit-cover w-100"/>
        </ImageWrapper>
        <div className="col-12 col-md-8 p-2 p-md-3 pt-md-5 d-flex flex-fill">
            <SwitchTransition>
                <CSSTransition timeout={500} key={currentPage} appear={true}>
                    <Animating className="d-flex flex-fill">
                        {children}
                    </Animating>
                </CSSTransition>
            </SwitchTransition>
        </div>
        <div className="font-size-0-625 text-gray position-absolute top left pt-2 pl-3 line-height-3">
            {currentPage ? `${currentPage}/${maxPage}` : <>&nbsp;</>}
        </div>
    </div>
}

export default withThemeProvider(SubPage);
