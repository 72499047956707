import { useEffect, useState } from 'react';

const useScript = (url, type, async) => {
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });

    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        if (async) {
            script.async = true;
        }
        if (type && type !== false) {
            script.type = type; // type="module"
        }

        script.onload = () => {
            setState({
                loaded: true,
                error: false,
            });
        };

        script.onerror = () => {
            setState({
                loaded: false,
                error: true,
            });
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url, setState]);

    return { ...state };
};

const useModuleScript = (url) => useScript(url, 'module');

export { useScript, useModuleScript };

export default useScript;
