import {createActions} from 'redux-actions';

export const {
    initializeNotifications,
    showError,
    showSuccess,
    dismissMessage,
    clearMessages,
} = createActions({
    INITIALIZE_NOTIFICATIONS: (error, warning, success) => ({
        error,
        warning,
        success,
    }),
    SHOW_ERROR: message => message,
    SHOW_SUCCESS: message => message,
    DISMISS_MESSAGE: () => null,
    CLEAR_MESSAGES: () => null,
});