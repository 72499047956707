import React, { useCallback } from 'react';
import SquareCheckbox from '../../elements/SquareCheckbox';
import CartService from '../../services/cart';
import { executeCallback } from '../../utils/function';
import { useResource } from '../../contexts/ResourceContext';

/**
 *
 * @param {boolean} isRewardPointRedeem
 * @param {string} rewardPointRedeemPointNoText
 * @param {string} rewardPointRedeemText
 * @param {string} rewardPointRedeemPercentText
 * @param {string} rewardPointRedeemGiftCardText
 * @param {function} refreshCart - If cart update is necessary
 *
 * @returns React.Node
 */
const RewardPointRedeem = ({
    rewardPointRedeemPointNoText,
    rewardPointRedeemText,
    rewardPointRedeemPercentText,
    rewardPointRedeemGiftCardText,
    isRewardPointRedeem,
    refreshCart,
}) => {
    const { t } = useResource();
    const onChange = useCallback(async () => {
        try {
            await CartService.checkRewardPointRedeem();
            executeCallback(refreshCart);
        } catch (error) {
            console.log(error);
        }
    }, [refreshCart]);

    return (
        <div className="mb-7">
            <div className="text-uppercase font-weight-light line-height-3 font-size-1 mb-1 pl-1">
                {t('brendon.shoppingcart.familypoint')}
            </div>
            <div className="border rounded-lg px-2-5 pt-2-5 pb-1">
                {rewardPointRedeemPointNoText && (
                    <div
                        className="mb-2 text-gray font-size-0-875"
                        dangerouslySetInnerHTML={{
                            __html: rewardPointRedeemPointNoText,
                        }}
                    ></div>
                )}
                {rewardPointRedeemText && (
                    <div className="row mb-2 ml-0 mr-0 p-2-5 bg-gray-light font-weight-bold font-size-0-875 rounded d-inline-block">
                        <div className="col ">
                            <SquareCheckbox
                                className="d-flex align-items-center"
                                value={isRewardPointRedeem}
                                onChange={onChange}
                            >
                                {rewardPointRedeemText}
                            </SquareCheckbox>
                        </div>
                    </div>
                )}
                {rewardPointRedeemPercentText && (
                    <div className="mb-2 text-gray font-size-0-875">
                        {rewardPointRedeemPercentText}
                    </div>
                )}
                {rewardPointRedeemGiftCardText && (
                    <div className="mb-2 text-gray font-size-0-875">
                        {rewardPointRedeemGiftCardText}
                    </div>
                )}
            </div>
        </div>
    );
};

export default RewardPointRedeem;
