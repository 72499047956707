import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from 'react-tooltip';

const InfoTooltip = ({ id = "infoTooltip", content, className, children }) => {
    return (
        <>
            {children}
            {!children && <FontAwesomeIcon
                id={id}
                icon={faInfoCircle}
                className={`font-size-0-75 text-center text-gray no-outline ${
                    className || ''
                }`}
            />}
            <Tooltip
                anchorId={id}
                content={content}
                place="top"
                className="tooltip-default shadow"
            />
        </>
    );
};

export default InfoTooltip;
