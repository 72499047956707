const checksumCoefficients = [9, 7, 3, 1, 9, 7, 3];

const areaCodes = [
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '51',
];

function validate(value) {
    if(!value) {
        return false;
    }
    
    if (!value.match(/^[0-9]{8}-?[0-9]-?[0-9]{2}$/g)) {
        return false;
    }
    
    const normalizedValue = value.replace(/-/g, '');
    
    const id = normalizedValue.slice(0, 8);
    const taxCategory = normalizedValue.slice(8, 9);
    const areaCode = normalizedValue.slice(9, 11);

    const idNumbers = id.split('').map(number => parseInt(number, 10));

    const checksum = (10 - idNumbers.slice(0, -1).reduce((accumulator, currentValue, index) => {
        return accumulator + currentValue * checksumCoefficients[index];
    }, 0) % 10) % 10;

    if (checksum !== idNumbers[7]) {
        return false;
    }

    const taxCategoryNumber = parseInt(taxCategory, 10);
    if (!(0 < taxCategoryNumber && taxCategoryNumber < 6) || taxCategoryNumber === 4) {
        return false;
    }

    if (!areaCodes.includes(areaCode)) {
        return false;
    }

    return true;
}

export default validate;