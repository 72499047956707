import React, { useMemo, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGift,
    faExclamationTriangle,
    faRulerTriangle,
    faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import { useResource } from '../../contexts/ResourceContext';

const ExtraInfoItem = ({ message, type, extraInfo }) => {
    const { t } = useResource();
    const [showExtraInfo, setShowExtraInfo] = useState(false);

    const iconType = useMemo(() => {
        const icons = {
            gift: faGift,
            warning: faExclamationTriangle,
            size: faRulerTriangle,
            info: faInfoCircle,
        };
        return icons[type?.toLowerCase?.()] || faInfoCircle;
    }, [type]);

    const openExtraInfo = useCallback(() => {
        setShowExtraInfo(true);
    }, [setShowExtraInfo]);

    return (
        <div
            className={classnames(
                `d-flex bg-gray-light rounded-15 py-6-px-10 align-items-start align-self-start line-height-3 mb-1`,
                type?.toLowerCase?.() === 'warning' ? 'text-danger' : null,
            )}
        >
            <FontAwesomeIcon
                icon={iconType}
                className="font-size-0-75"
                style={{ marginTop: '2px' }}
            />
            <div className="ml-1 line-height-rem-0-8125">
                <span className="line-height-rem-1-125 font-size-0-75">
                    {message}
                </span>
                {showExtraInfo && (
                    <div className="line-height-rem-1-125 font-size-0-75">
                        {extraInfo}
                    </div>
                )}
                {!showExtraInfo && extraInfo && extraInfo != '' && (
                    <div
                        className="font-size-0-75 cursor-pointer text-underline line-height-rem-1-125"
                        onClick={openExtraInfo}
                    >
                        {t('brendon.cart.extrainfo.details')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExtraInfoItem;
