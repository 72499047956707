
export function removeDuplicateObjectsFromArray(array, key) {
    var check = {};
    var result = [];

    array.forEach((element, index) => {
      element = array[index];

      if (!check[element[key]]) {
          check[element[key]] = true;
          result.push(element);
      }
    });

    return result;
}
 