import React, { useLayoutEffect, useRef } from 'react';
import DOMHelper from '../utils/DOMHelper';

function CategoryDescription({ description, enableHelp, position }) {
  const ref = useRef();

  if (!description) {
    return null;
  }

  useLayoutEffect(() => {
    if (ref?.current) {
      const tableNodes = ref?.current?.querySelectorAll('table') || [];
      tableNodes.forEach((tableNode) => {
        if (tableNode?.classList?.contains('custom-table')) {
          DOMHelper.addResponsiveTableContainer(tableNode);
        }
      });
    }
  }, [description, ref]);

  const containerClassName = enableHelp ? `mb-4` : '';

  return (
    <div id={`category-help-${position}`} className={containerClassName}>
      <article
        ref={ref}
        className="formatted-content"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </div>
  );
}

export default CategoryDescription;
