import React, { useState, useCallback, useEffect } from 'react';
import { MIN_CYCLE_LENGTH, MAX_CYCLE_LENGTH } from '../../../models/constants';
import CalculatorForm from './CalculatorShared/CalculatorForm';
import {
    embryoAge,
    expectedDateOfBirth as calculateBirthDate,
} from '../../../services/calculators/ovulation';
import PragnancyResultBox from './PregnancyCalculator/PragnancyResultBox';
import BlogService from '../../../services/blog';
import PregnancyWeekByWeek from './PregnancyCalculator/PregnancyWeekByWeek';
import { HOST_SETTINGS } from '../../../models/constants';

const PregnancyCalculator = ({ storeId }) => {
    const [menstrualPeriod, setMenstrualPeriod] = useState(
        new Date(Date.now()),
    );
    const [cycleLength, setCycleLength] = useState(28);
    const [result, setResult] = useState({
        calculated: false,
        expectedDateOfBirth: '',
        embryoAge: {
            weeks: null,
            days: null,
        },
    });
    const [posts, setPosts] = useState([]);

    const handleCycleLengthChange = useCallback(
        (value) => {
            setCycleLength(value);
        },
        [setCycleLength],
    );

    useEffect(() => {
        const getBlogList = async () => {
            const tagId = HOST_SETTINGS[window.location.hostname]?.blog?.pregnancy_calculator_tag_id || 153;
            const result = await BlogService.getPostList(storeId, {
                tagId
            });
            if (
                Array.isArray(result?.postList) &&
                result?.postList?.length > 0
            ) {
                result?.postList.sort(
                    (a, b) => Number(a?.DisplayOrder) - Number(b?.DisplayOrder),
                );
                setPosts(result?.postList);
            }
        };
        if (result?.calculated && posts.length === 0) {
            getBlogList();
        }
    }, [result, setPosts, storeId, posts.length]);

    const handleBlurCycleLength = useCallback(
        (value) => {
            if (cycleLength < MIN_CYCLE_LENGTH) {
                setCycleLength(MIN_CYCLE_LENGTH);
                return;
            }
            if (cycleLength > MAX_CYCLE_LENGTH) {
                setCycleLength(MAX_CYCLE_LENGTH);
                return;
            }
        },
        [cycleLength, setCycleLength],
    );

    const handleSubmit = useCallback(() => {
        const expectedDateOfBirth = calculateBirthDate(
            menstrualPeriod.toISOString(),
            cycleLength,
        );
        const today = new Date(Date.now());
    
        const calculatedAge = embryoAge(menstrualPeriod.toISOString());

        const someDay = menstrualPeriod.toISOString().split('T')[0] === today.toISOString().split('T')[0]
        
        setResult({
            calculated: true,
            expectedDateOfBirth,
            embryoAge: {
                weeks: someDay ? null : calculatedAge.calculated.weeks,
                days: someDay ? null : calculatedAge.calculated.days,
            },
        });
    }, [menstrualPeriod, cycleLength, setResult]);

    return (
        <div className="blog-calculators pregnancy-calculator">
            <CalculatorForm
                menstrualPeriod={menstrualPeriod}
                setMenstrualPeriod={setMenstrualPeriod}
                cycleLength={cycleLength}
                setCycleLength={handleCycleLengthChange}
                blurCycleLength={handleBlurCycleLength}
                onSubmit={handleSubmit}
                className="mt-7 mb-3"
                classNameForm="bg-calculator-orange"
                classNameSubmit="bg-calculator-orange-medium"
            />
            <PragnancyResultBox data={result} />
            {posts.length >0 && <PregnancyWeekByWeek
                posts={posts}
                startDate={menstrualPeriod}
                week={result?.embryoAge?.weeks || 0}
            />}
        </div>
    );
};

export default PregnancyCalculator;
