import React, { useState, useEffect, useCallback } from 'react';
import { orderConfirm as orderConfirmService } from '../../../services/checkout';
import { initalOptionsCommand } from '../../../utils/controller';
import ConfirmDataBox from '../../../components/Checkout/ConfirmDataBox';
import OrderSummary from '../../shopping-cart/order-summary/OrderSummary';
import OrderTotals from '../../../components/ShoppingCart/OrderTotals';
import CheckoutConfirm from '../../../components/Checkout/CheckoutConfirm';
import Loader from '../../../components/Loader';
import { useResource } from '../../../contexts/ResourceContext';

const CheckoutPartialConfirm = ({ nextStep }) => {
    const { t } = useResource();
    const [confirmData, setConfirmData] = useState({});

    const getOrderConfirm = useCallback(async () => {
        const orderConfirmData = await orderConfirmService.get();
        if (!initalOptionsCommand(orderConfirmData?.InitOption, null, nextStep)) {
            return;
        }
        setConfirmData(orderConfirmData);
    }, [setConfirmData]);

    useEffect(() => {
        getOrderConfirm();
    }, []);

    return (
        <>
            {Object.keys(confirmData).length === 0 ? (
                <Loader isLoading />
            ) : (
                <>
                    <div className="row mb-2">
                        <ConfirmDataBox
                            title={t('brendon.checkout.confirm.contactAddress')}
                        >
                            <ul className="font-weight-medium">
                                <li>{confirmData?.ContactInfo?.Email}</li>
                                <li>{confirmData?.ContactInfo?.PhoneNumber}</li>
                            </ul>
                        </ConfirmDataBox>
                        <ConfirmDataBox
                            title={t(
                                'brendon.checkout.orderconfirm.billingaddress',
                            )}
                        >
                            <ul className="font-weight-medium">
                                <li>
                                    {confirmData?.BillingAddress?.NameOnBill}
                                </li>
                                <li>
                                    {confirmData?.BillingAddress?.CompanyNumber}
                                </li>
                                <li>
                                    {confirmData?.BillingAddress?.TaxNumber}
                                </li>
                                <li>
                                    {confirmData?.BillingAddress
                                        ?.BillZipPostalCode
                                        ? `${
                                              confirmData?.BillingAddress
                                                  ?.BillZipPostalCode
                                          }${' '}`
                                        : ''}
                                    {confirmData?.BillingAddress?.BillCity}
                                    {confirmData?.BillingAddress
                                        ?.BillCountryName
                                        ? `, ${confirmData?.BillingAddress?.BillCountryName}`
                                        : ''}
                                </li>
                                <li>
                                    {confirmData?.BillingAddress?.BillAddress}
                                </li>
                                <li>
                                    {confirmData?.BillingAddress?.InvoiceDesc}
                                </li>
                            </ul>
                        </ConfirmDataBox>
                        <ConfirmDataBox
                            title={t(
                                'brendon.checkout.orderconfirm.shippingaddress',
                            )}
                        >
                            <ul className="font-weight-medium">
                                {confirmData?.DeliveryMethodName && (
                                    <li>{confirmData.DeliveryMethodName}</li>
                                )}
                                <li>
                                    {confirmData?.ShippingAddress?.ReceiverName}
                                </li>
                                <li>
                                    {confirmData?.ShippingAddress
                                        ?.ShippingCountryZipPostalCode
                                        ? `${
                                              confirmData?.ShippingAddress
                                                  ?.ShippingCountryZipPostalCode
                                          }${' '}`
                                        : ''}
                                    {
                                        confirmData?.ShippingAddress
                                            ?.ShippingCountryCity
                                    }
                                    {confirmData?.ShippingAddress
                                        ?.ShippingCountryName
                                        ? `, ${confirmData?.ShippingAddress?.ShippingCountryName}`
                                        : ''}
                                </li>
                                <li>
                                    {
                                        confirmData?.ShippingAddress
                                            ?.ShippingCountryAddress
                                    }
                                </li>
                                <li>
                                    {confirmData?.ShippingAddress?.Shippingdesc}
                                </li>
                            </ul>
                        </ConfirmDataBox>
                        <ConfirmDataBox
                            title={t(
                                'brendon.checkout.orderconfirm.paymentmethod',
                            )}
                        >
                            <ul className="font-weight-medium">
                                <li>
                                    {
                                        confirmData?.PaymentMode
                                            ?.PaymentMethodName
                                    }
                                </li>
                            </ul>
                        </ConfirmDataBox>
                    </div>
                    <div>
                        {confirmData?.ShoppingCartItems?.length > 0 && (
                            <OrderSummary
                                items={confirmData?.ShoppingCartItems}
                                legacy={false}
                                showProductImages={true}
                                isEditable={false}
                                quantityLabel={t(
                                    'ShoppingCart.Quantity',
                                )}
                                unitPriceLabel={t(
                                    'ShoppingCart.UnitPrice',
                                )}
                                itemTotalLabel={t(
                                    'ShoppingCart.ItemTotal',
                                )}
                            >
                                <div className={`row justify-content-between`}>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        {' '}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                                        <OrderTotals
                                            sumItems={confirmData?.SumItems}
                                            sumAmountDiscounts={
                                                confirmData?.SumAmountDiscounts
                                            }
                                            discountSubItems={confirmData?.Coupons?.map(
                                                (item) => ({
                                                    item: item?.CouponCode,
                                                    price: item?.Price,
                                                }),
                                            )}
                                            rewardPointRedeemedSum={
                                                confirmData?.RewardPointRedeemedSum
                                            }
                                            sumItemsReducedByDiscounts={
                                                confirmData?.SumItemsReducedByDiscounts
                                            }
                                            shippingFee={
                                                confirmData?.ShippingFee
                                            }
                                            shippingFeeInfoText={confirmData?.ShippingFeeInfo?.map(
                                                (item) =>
                                                    item.ShippingFeeInfoText,
                                            )}
                                            amountWithShippingFee={
                                                confirmData?.AmountWithShippingFee
                                            }
                                            amountToPay={
                                                confirmData?.AmountToPay
                                            }
                                            giftCardCodes={
                                                confirmData?.GiftCards
                                            }
                                        />

                                        <CheckoutConfirm
                                            confirmButtonTitle={t(
                                                'Checkout.ConfirmButton',
                                            )}
                                            hint={t(
                                                'brendon.checkout.termsandconditionshint',
                                            )}
                                            acceptText={t(
                                                'brendon.checkout.checkoutconfirm.termsandconditionstext',
                                            )}
                                        /*     antiforgery={antiforgery} */
                                        />
                                    </div>
                                </div>
                            </OrderSummary>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default CheckoutPartialConfirm;
