import React from 'react';

const SectionTitleHeader = ({ title, content }) => {
    return (
        <>
            {(title || content) && (
                <div>
                    <h2 className="font-size-1-25 line-height-1-625">
                        {title}
                    </h2>
                    {content && <p className="line-height-rem-1-325">{content}</p>}
                </div>
            )}
        </>
    );
};

export default SectionTitleHeader;
