import React from 'react';
import { useResource } from '../../contexts/ResourceContext';

const CheckoutStepTitle = ({ title_resource_name, description }) => {
    const { t } = useResource();

    return (
        <>
            {!description && title_resource_name && (
                <div className="col-12 text-center mb-4">
                    <h1 className="font-size-1-5 font-weight-medium text-uppercase line-height-3 mb-1">
                        {t(title_resource_name)}
                    </h1>
                </div>
            )}
            {description && (
                <div className="col-12 text-center mb-3">
                    {title_resource_name && (
                        <h1 className="font-size-1-5 font-weight-medium text-uppercase line-height-3 mb-4">
                            {t(title_resource_name)}
                        </h1>
                    )}
                    <p className="font-weight-medium">{description}</p>
                </div>
            )}
        </>
    );
};

export default CheckoutStepTitle;
