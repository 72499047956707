import React from "react";
import ProductBox from "./ProductBox";
import ProductsNotFound from './ProductsNotFound';
import MoreCategories from './MoreCategories';

const ProductList = ({
  products,
  categoryName,
  gaEEList,
  legacy,
  enableLeftSide,
  selectedOrderBy,
  enableUpsell,
  upsellList,
}) => (
  <>
    {products?.length > 0 ? (
      products.map((product, index) => (
        <React.Fragment key={`default-cont-${index}`}>
          <div
            className={`d-flex flex-column col-12 col-md-6 ${
              enableLeftSide ? "col-lg-4" : "col-lg-3"
            } py-md-2 border-left border-bottom  `}
            key={product.ProductID}
          >
 
            <ProductBox
              categoryName={categoryName}
              product={product}
              legacy={legacy}
              selectedOrderBy={selectedOrderBy}
              gaEEList={gaEEList}
              enableUpsell={enableUpsell}
            />
            {upsellList && <>
              {product.CategoryText && product.CategorySlug ? 
                <div className="upsell_more d-sm-none d-md-block" >
                  <MoreCategories text={product.CategoryText} link={`/${product.CategorySlug}`} />
                </div> 
                : 
                <div className="upsell_more d-sm-none d-md-block px-2-5 py-2" >{' '}</div>
              }
            </>}
          </div>
        </React.Fragment>
      ))
    ) : (
      <ProductsNotFound />
    )}
  </>
);

export default ProductList;
