import React from 'react';
import BoxImage from "./BoxImage";
import { useResource } from '../../contexts/ResourceContext';

function SelectorPage({onSelect}) {
    const { t } = useResource();
    
    return <div className="row text-center p-3 flex-fill flex-column">
        <div className="col-12 d-flex justify-content-center flex-column flex-fill">
            <div className="my-4">
                <h5 className="font-weight-bold font-size-1-25 mb-1">
                    {t('brendon.intro.welcome.title')}
                </h5>
                <div className="font-size-0-875 text-gray">
                    {t('brendon.intro.welcome.description')}
                </div>
            </div>
        </div>
        <div className="col-12 flex-fill">
            <div className="row">
                <BoxImage className="col-4" imageNumber="1"
                          onClick={() => onSelect('pregnant')}>{t('brendon.intro.welcome.pregnant')}</BoxImage>
                <BoxImage className="col-4" imageNumber="2"
                          onClick={() => onSelect('parent')}>{t('brendon.intro.welcome.parent')}</BoxImage>
                <BoxImage className="col-4" imageNumber="3"
                          onClick={() => onSelect('gift')}>{t('brendon.intro.welcome.gift')}</BoxImage>
            </div>
        </div>
    </div>
}

export default SelectorPage;
