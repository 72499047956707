import React, { useMemo } from 'react';
import { firstNLetter } from '../../../../utils/string';
import { getStoreYearMonthFormat } from '../../../../utils/localization';
import { useResource } from '../../../../contexts/ResourceContext';

const letterNums = {
  2: 3,
  5: 3,
};

const CalendarMonth = ({ month, year, data }) => {
  const { t } = useResource();

  const dayLabels = useMemo(() => {
    const dayLabels = [];
    for (let i = 0; i < 7; i++) {
      dayLabels.push(t(`brendon.calendar.day.${i}`));
    }
    return dayLabels;
  }, []);

  const monthHeader = useMemo(() => {
    const yearMonthFormat = getStoreYearMonthFormat();
    `${year}. ${t(`brendon.months.${Number(month)}`)}`;

    return yearMonthFormat
      .replace('yyyy', year)
      .replace('MM', t(`brendon.months.${Number(month)}`));
  }, [month, year]);

  return (
    <div className="calendar-month">
      <h2 className="calendar-month__header mb-2-5 font-size-1-25 text-uppercase">
        {monthHeader}
      </h2>
      <div className="calendar-month__days d-flex font-size-0-75 font-size-xl-1 ">
        {dayLabels.map((dayLabel, index) => (
          <div
            className="calendar-month__day-label col bg-gray-light rounded"
            key={dayLabel}
          >
            <span className="d-none d-sm-inline">{dayLabel}</span>
            <span className="d-inline d-sm-none">
              {firstNLetter(dayLabel, letterNums[index] || 1)}
            </span>
          </div>
        ))}
      </div>
      <div className="calendar-month__days d-flex flex-wrap">
        {data?.days?.length > 0 &&
          data?.days?.map(({ day, type }, idx) => (
            <div
              className={`calendar-month__day rounded ${`bg-${type}`}`}
              key={idx}
            >
              {day && (
                <div className="calendar-month__day-circle mb-1">{day}</div>
              )}
              {type !== 'regular' && (
                <div className="calendar-month__day-text font-size-0-625 font-size-xl-0-75 font-weight-medium">
                  {t(`brendon.calculator.ovulation.${type}`)}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CalendarMonth;
