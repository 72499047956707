import React, { useState, useCallback, useMemo } from 'react';

const GiftCardHelper = ({}) => {
    const [showHelper, setShowHelper] = useState(false);
    const { t } = useResource();
    
    const handleClick = useCallback(() => {
        setShowHelper((prev) => !prev);
    }, [setShowHelper]);

    const faq = useMemo(() => {
        return [
            {
                title: t(
                    'brendon.checkout.paymentmethods.giftcard.help.content.title1',
                ),
                text: t(
                    'brendon.checkout.paymentmethods.giftcard.help.content.text1',
                ),
            },
            {
                title: t(
                    'brendon.checkout.paymentmethods.giftcard.help.content.title2',
                ),
                text: t(
                    'brendon.checkout.paymentmethods.giftcard.help.content.text2',
                ),
            },
        ];
    }, []);

    return (
        <div className="w-100 pt-2-5">
            <a
                className="font-size-0-75 text-dark text-underline"
                onClick={handleClick}
            >
                {t('brendon.checkout.paymentmethods.giftcard.help.title')}
            </a>

            <div
                className={`font-size-0-75 closed ${
                    showHelper ? 'opened' : ''
                }`}
            >
                {faq.length > 0 &&
                    faq.map((item, index) => (
                        <React.Fragment key={`$gfhelp-${index}`}>
                            {(item.title != '' || item.text != '') && (
                                <div key={index} className="py-2">
                                    <div className="font-weight-medium">
                                        {item.title}
                                    </div>
                                    <div className="">{item.text}</div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
};

export default GiftCardHelper;
