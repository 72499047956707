import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

const ErrorMessages = ({ errorMessages }) => {
  return (
    <div className="mt-2">
      {errorMessages.length > 0 &&
        errorMessages?.map((errorMessage, index) => (
          <div className="col-12 text-danger mb-3" key={`error_${index}`}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-1 font-size-0-875 text-danger"
            />
            <div className="d-inline-flex col-11">{errorMessage}</div>
          </div>
        ))}
    </div>
  );
};

export default ErrorMessages;
