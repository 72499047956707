import React, { useLayoutEffect } from "react";
import {Helmet} from "react-helmet";

export default ({categoryInfo}) => {
    const {MetaTitle, MetaDescription, MetaKeywords, BredcrumbInfos, CategoryPictureUrl, NoIndex} = categoryInfo
    useLayoutEffect(()=>{
        if (MetaKeywords != null) {
            document.querySelector('meta[name="keywords"]:not([data-react-helmet])')?.remove()
        }
        if (MetaDescription != null) {
            document.querySelector('meta[name="description"]:not([data-react-helmet])')?.remove()
        }
    })

    const renderItemList = () => BredcrumbInfos?.map((item, idx) => (`{"@type":"ListItem",
            "position":"${(idx+1)}",
            "name":"${item.Title}",
            "item":"${window.location.protocol}//${window.location.host}/${item.Slug}"
            }`))

    return (<Helmet>
        <title>{ MetaTitle }</title>
        <script type="application/ld+json">{`
            {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":[${renderItemList()}]
            }
        `}
        </script>
        <meta name="description" content={MetaDescription} />
        <meta name="keywords" contenct={MetaKeywords} />
        <meta property="og:title" content={MetaTitle}/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Brendon"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content={CategoryPictureUrl} />
        <meta property="og:description" content={MetaDescription}/>
        { NoIndex && <meta name="robots" content="noindex,follow" />}
    </Helmet>)
}