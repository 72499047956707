import React, {Component} from 'react';
import styled from 'styled-components';
import SelectOption from "./SelectOption";
import OutsideClickDetector from "./OutsideClickDetector";
import ScrollableArea from "./ScrollableArea";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';

const HiddenSelect = styled.select`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const ScrollContainer = styled.div`
    height: ${props => props.opened ? 'auto' : '0'};
    overflow: hidden;
`;

const ScrollWrapper = styled.div`
    max-height: 160px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

class Select extends Component {
    state = {
        opened: false,
    };

    toggle = () => {
        this.setState(state => ({
            opened: !state.opened,
        }));
    };

    close = () => {
        this.setState({
            opened: false,
        });
    };

    onSelectChange = (value) => {
        const {onChange} = this.props;

        if (onChange) {
            onChange(value?.toString?.());
        }
        this.close();
    };

    onSelectChangeEvent = (e) => {
        this.onSelectChange(e.target.value);
    };

    componentDidUpdate() {
        const {value, options} = this.props;
        const selectOptionObject = options.find(option => option?.value?.toString?.() === value?.toString?.());
        if (!selectOptionObject) {
            this.onSelectChange(options[0]?.value?.toString?.())
        }
    }

    render() {
        const {opened} = this.state;

        const {name, value, options, onChange, sm} = this.props;
        const selectOptionObject = options.find(option => option?.value?.toString?.() === value?.toString?.());

        const selectOptionName = selectOptionObject ? selectOptionObject.name : null;

        return <div className={`font-weight-light font-size-0-75 text-nowrap ${sm ? 'h-p25' : 'h-p30'}`}>
            <OutsideClickDetector
                className={`border rounded-sm bg-white position-relative ${opened ? 'zindex-7000 shadow' : ''}`}
                onClickOutside={this.close}>
                <div
                    onClick={this.toggle}
                    className={`${sm ? 'h-p25' : 'h-p30'} d-flex justify-content-between align-items-center px-2 mt-npixel cursor-pointer`}>
                    {selectOptionName ? selectOptionName : <span/>}
                    <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                </div>
                <ScrollContainer opened={opened}>
                    <ScrollWrapper className="border-top">
                        <ScrollableArea>
                            <div className="p-1">
                                {options.map(option =>
                                    <SelectOption key={option.value} name={option.name} value={option.value}
                                                  onSelected={this.onSelectChange} sm={sm}/>
                                )}
                            </div>
                        </ScrollableArea>
                    </ScrollWrapper>
                </ScrollContainer>
            </OutsideClickDetector>
            <HiddenSelect name={name} value={value} className="zindex-8000 d-lg-none"
                          onChange={this.onSelectChangeEvent}
                          autoComplete="off">
                {options.map(option =>
                    <option key={option.value} value={option.value}>{option.name}</option>)
                }
            </HiddenSelect>
        </div>
    }
}

export default Select;
