import React from 'react';
import NewsletterForm from '../../newsletter/NewsletterForm';
import Icon from '../Icon';
import SafePayBox from './SafePayBox';
import FooterTopics from './FooterTopics';
import brendonPatternURL from '../../../assets/brendon-pattern.svg';
import withStore from '../../hoc/withStore';
import { useSelector } from 'react-redux';
import { selectShoppingInfos } from '../../store/megamenu/selectors';
import { useResource } from '../../contexts/ResourceContext';
 
const Footer = ({ children }) => {
  const storeInfos = useSelector(selectShoppingInfos);
  const { t } = useResource();

  return (
    <footer>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 col-md-4 mb-2">
            <div
              className="bg-blue-medium rounded p-3 p-md-4 h-100"
              id="newsletter"
            >
              <NewsletterForm
                title={t('Newsletter.Title')}
                emailPlaceholder={t('Newsletter.Email.Placeholder')}
                pregnancyLabel={t('Newsletter.Options.PregnancyNewsletter')}
                pregnancyDescription={t(
                  'Newsletter.Options.PregnancyNewsletterDescription',
                )}
                birthdayLabel={t('Brendon.Newsletter.BirthdayLabel')}
                birthdayPlaceholder={t(
                  'Newsletter.Options.PregnancyNewsletterPlaceholder',
                )}
                subscribeButtonTitle={t('Newsletter.Options.Subscribe')}
                errorText={t('brendon.newsletter.error')}
                consentText={t('Brendon.Newsletter.Options.ConsentText')
                  .replace(
                    '{0}',
                    `<a href="${t('brendon.privacyinfo.url')}" target="_blank" class="text-underline text-white text-hover-primary">`,
                  )
                  .replace('{1}', '</a>')}
              />
            </div>
          </div>

          <div className="col-12 col-md-4 mb-2">
            <div className="bg-light rounded p-3 p-md-4 h-100">
              <div className="d-flex align-items-center mb-3 mb-md-4">
                <Icon
                  iconType="fal"
                  iconName="truck-fast"
                  className="text-primary font-size-1-875 font-size-lg-2-5 mr-3"
                />
                <span className="font-weight-medium font-size-0-875 font-size-md-1 text-uppercase">
                  {t('brendon.footer.shopatbrendon')}
                </span>
              </div>
              <ul className="p-0">
                {Array.isArray(storeInfos) &&
                  storeInfos.map((text, idx) => (
                    <li
                      key={`storeinfo_${idx}`}
                      className="mb-3 d-flex font-size-0-8125 font-size-md-0-875 line-height-rem-1-125 line-height-rem-md-1-25"
                    >
                      <Icon
                        iconType="fas"
                        iconName="check"
                        className="text-white font-size-0-8125 mr-2 h-p20 w-p20 bg-green d-flex justify-content-center align-items-center rounded-circle flex-shrink-0 p-03"
                      />
                      {text}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-2">
            <SafePayBox title={t('Footer.Payments.Title')} className="h-100" />
          </div>
        </div>
        <FooterTopics />
        {children}
      </div>
      <div
        style={{
          backgroundImage: `url('${brendonPatternURL}')`,
        }}
        className="h-p30 h-md-p60"
      ></div>
    </footer>
  );
};

export default withStore(Footer);
