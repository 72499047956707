import React, {Component} from 'react';
import styled from 'styled-components';
import withThemeProvider from "../hoc/withThemeProvider";

const SizeBox = styled.div`
    min-width: 50px;
`;

class SizeSelectorProduct extends Component {
    selectSize = (size) => {
        const {onSelect} = this.props;
        if (onSelect) {
            onSelect(size);
        }
    };

    render() {
        const {sizes, selectedSize} = this.props;

        return <div className="d-flex mx-n1 flex-wrap font-size-0-75">
            {sizes.map((size) => <SizeBox key={size.SizeName}
                                          className={`${(selectedSize && selectedSize.SizeName === size.SizeName) ? 'text-primary border-primary' : ''} ${size.IsOrderable ? 'text-dark' : 'text-gray-medium'} font-weight-medium cursor-pointer text-center mx-1 mb-2 px-2 h-p40 text-center d-flex align-items-center border rounded justify-content-center`}
                                          onClick={() => this.selectSize(size)}>
                {size.SizeName}
            </SizeBox>)}
        </div>
    }
}

export default withThemeProvider(SizeSelectorProduct);
