import React from "react";

export default function Section({title, children}) {
    return <>
        {title &&
        <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-10">
                <div className="font-size-0-875 font-weight-medium mb-4">{title}</div>
            </div>
        </div>
        }
        <div className="mb-7">
            {children}
        </div>
    </>
}
