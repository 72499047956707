import React from 'react';
import { Outlet } from 'react-router-dom';
import RootLayoutContainer from './RootLayoutContainer';

const MainLayout = ({children}) => {
  return (
    <RootLayoutContainer>
      <div className="main-content-wrapper">
        <div className="mt-2 mb-4 mb-md-7">
          <div className="container">
            <Outlet />
            {children}
          </div>
        </div>
      </div>
    </RootLayoutContainer>
  );
};

export default MainLayout;
