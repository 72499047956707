import React, { Component } from 'react';
import SearchService from '../../services/search';
import queryString from 'query-string';
const FilterPanel = loadable(() => import('../../category/FilterPanel'));
import ProductList from '../../category/ProductList';
import FilterOrderBy from '../../filter/FilterOrderBy';
import { legacy_t as t} from '../../contexts/ResourceContext';
import Pager from '../../elements/Pager';
import loadable from '@loadable/component';
const SideItemList = loadable(() =>
    import('../../components/Elements/SideItemList'),
);
import scrollToElement from '../../scrollToElement';
import { isMobile } from '../../utils/mobile';
import FilterTags from '../../category/FilterTags';
import Loader from '../../elements/Loader';
import NoIndexMeta from '../../category/NoIndexMeta';
import gaService from '../../services/gaEnhancedEcommerce';
import { getStoreCurrency } from '../../utils/localization';
import { SEARCH_RESULT_SETTINGS, HOST_SETTINGS } from '../../models/constants';
import GA4Service from '../../services/gaGA4';
import { isNewFilterEnabled } from '../../services/featureFlagService';
import CustomIcons from '../../components/Elements/CustomIcons/CustomIcons';
import MobileFilterModal from '../../components/FilterNew/MobileFilterModal';
import MobileSortModal from '../../components/FilterNew/MobileSortModal';
import FilterButton from '../../components/FilterNew/FilterButton';
import DOMHelper from '../../utils/DOMHelper';
import FilterButtonContainerWithNav from '../../components/FilterNew/FilterButtonContainerWithNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

const SORT_OPTIONS = [
    {
        Text: t('brendon.products.orderby.priceAscending'),
        Value: 'price',
    },
    {
        Text: t('brendon.products.orderby.priceDescending'),
        Value: 'pricedesc',
    },
    {
        Text: t('brendon.products.orderby.stock'),
        Value: 'stock',
    },
    {
        Text: t('brendon.products.orderby.age'),
        Value: 'age',
    },
    {
        Text: t('brendon.products.orderby.name'),
        Value: 'name',
    },
];

class SearchPage extends Component {
    state = {
        currentPage: 1,
        totalPages: 1,
        totalProducts: null,
        selectedFilters: [],
        originalSelectedFilters: [],
        products: null,
        filters: null,
        selectedOrderBy: 'stock',
        preSelectedOrderBy: 'stock',
        query: '',
        categories: [],
        blogPosts: [],
        showBlogPosts: false,
        openedFilters: [],
        clickedFilter: null,
    };

    componentDidMount = async () => {
        window.addEventListener('popstate', this.refresh);
        window.addEventListener('hashchange', this.hashChange);
        return Promise.all([this.refresh(), [this.getCategoryResult()]]);
    };

    UNSAFE_componentWillUnmount() {
        window.removeEventListener('popstate', this.refresh);
        window.removeEventListener('hashchange', this.hashChange);
    }
    hashChange = (event) => {
        const { newURL = '', oldURL = '' } = event;
        const newHash = newURL.split('#')[1];
        const oldHash = oldURL.split('#')[1];
        if (newHash !== 'openedorder' && oldHash === 'openedorder') {
            this.closeOrderSelect();
        }
        if (newHash !== 'openedfilter' && oldHash === 'openedfilter') {
            this.cancelFilterFilterSelect();
        }
    }
    refresh = () => {
        const {
            filters,
            orderBy = null,
            pageNumber = null,
        } = queryString.parse(location.search);
        const selectedFilters = filters ? filters.split(';') : [];

        const selectedOrderBy = orderBy || 'stock';
        const currentPage = pageNumber || 1;

        this.setState(
            (state) => ({
                selectedFilters,
                selectedOrderBy,
                currentPage,
                preSelectedOrderBy: selectedOrderBy,
                filters: this.getFiltersState(state.filters, selectedFilters),
            }),
            async () => {
                return this.updateResults(true);
            },
        );
    };

    getSearchQuery = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('q');
    };

    getCategoryResult = async () => {
        const {
            categories,
            blogPosts,
        } = await SearchService.getSearchRecommendations(this.getSearchQuery());

        this.setState({
            categories,
            blogPosts,
        });
    };

    updateResults = async (doNotUpdateUrl) => {
        const {
            NoOfPages,
            NoOfProducts,
            FilterInfo,
            ProductInfo,
        } = await SearchService.getSearchProducts(
            this.getSearchQuery(),
            this.state.currentPage,
            this.state.selectedOrderBy,
            this.state.selectedFilters,
        );
        this.setState({
            totalPages: NoOfPages,
            totalProducts: NoOfProducts,
            products: ProductInfo,
            filters: FilterInfo?.map(filter => ({
                ...{...filter, Id: filter.FilterID, Name: filter.FilterName},
                FilterItems: filter.FilterOption.map(filterOption => ({
                    FilterItemState: !filterOption.Disabled ? (!filterOption.IsFilterOptionChecked ? 0 : 1) : (filterOption.IsFilterOptionChecked ? 2 : 3),
                    Id: filterOption.FilterOptionID,
                    Name: filterOption.FilterOptionName,
                    Disabled: filterOption?.Disabled || false,
                }))
            })),
        });

        if (!doNotUpdateUrl) {
            const selectedFilters = FilterInfo.reduce((acc, filter) => {
                const filters = filter.FilterOption.filter(
                    (filterOption) => filterOption.IsFilterOptionChecked,
                ).map((filterOption) => filterOption.FilterOptionID);
                return acc.concat(filters);
            }, []);

            this.setState({
                selectedFilters,
            });

            this.updateUrl(selectedFilters);
        }

        const impressionProducts = ProductInfo?.map((product) =>
            gaService.transformProductInfoToIpmpressionFieldObject(
                null,
                'Search result page',
                product,
            ),
        );
        gaService.productImpressions(impressionProducts, getStoreCurrency());
        const ga4ItemListItems = ProductInfo?.map(product => GA4Service.transformProductInfoToItemListItem('search_page_result', 'Search result page', null, product));
        GA4Service.gtagEvent('view_item_list', {
            item_list_id: 'search_page_result',
            item_list_name: 'Search result page',
            items: ga4ItemListItems
        });
    };

    updateUrl = (selectedFilters) => {
        const parsed = queryString.parse(location.search);

        if (selectedFilters?.length) {
            parsed.filters = selectedFilters.sort().join(';');
        } else {
            delete parsed.filters;
        }

        if (this.state.currentPage) {
            parsed.pageNumber = this.state.currentPage;
            parsed.pageSize = 30;
        }
        if (this.state.selectedOrderBy) {
            parsed.orderBy = this.state.selectedOrderBy;
        }

        const query = queryString.stringify(parsed);

        const queryParameter = query ? `?${query}` : '';

        if (queryParameter !== window.location.search) {
            const url =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                queryParameter;
            window.history.pushState({ path: url }, '', url);
        }
    };

    clearFilters = () => {
        this.setState(
            {
                selectedFilters: [],
                currentPage: 1,
            },
            async () => {
                await this.updateResults();
                if (isMobile()) {
                    this.scrollToFilters();
                }
            },
        );
    };

    onFilterRemoved = (filterItemId) => {
        this.setState(
            (state) => {
                const newSelectedFilters = state.selectedFilters.filter(
                    (filter) => filter !== filterItemId,
                );

                return {
                    selectedFilters: newSelectedFilters,
                    filters: this.getFiltersState(
                        state.filters,
                        newSelectedFilters,
                    ),
                    currentPage: 1,
                };
            },
            async () => {
                this.updateUrl(this.state.selectedFilters);
                await this.updateResults();

                if (isMobile()) {
                    this.scrollToFilters();
                }
            },
        );
    };

    cancelFilterFilterSelect = () => {
        this.setState(state => ({
            selectedFilters: state.originalSelectedFilters,
            openedFilters: [], /* state.originalOpenedFilters, */
            showFilterSelect: false,
        }), async () => {
            await this.updateResults();
            if (isMobile()) {
                this.scrollToFilters();
                DOMHelper.removeBodyOverflowHidden();
            }
        });
    };

    onFilterChanged = (_, filterOptionId) => {
        this.setState(
            (state) => {
                let newSelectedFilters = null;
                if (state.selectedFilters.indexOf(filterOptionId) === -1) {
                    newSelectedFilters = [
                        ...state.selectedFilters,
                        filterOptionId,
                    ];
                } else {
                    newSelectedFilters = state.selectedFilters.filter(
                        (filter) => filter !== filterOptionId,
                    );
                }

                return {
                    selectedFilters: newSelectedFilters,
                    filters: this.getFiltersState(
                        state.filters,
                        newSelectedFilters,
                    ),
                    currentPage: 1,
                };
            },
            async () => {
                this.updateUrl(this.state.selectedFilters);
                await this.updateResults();

                if (isMobile()) {
                    this.scrollToFilters();
                }
            },
        );
    };

    getFiltersState = (filters, selectedFilters) => {
        return filters?.map((filter) => ({
            ...filter,
            FilterItems: filter.FilterItems.map((filterItem) => ({
                ...filterItem,
                FilterItemState:
                    selectedFilters.indexOf(filterItem.Id) !== -1 ? 1 : 0,
            })),
        }));
    };

    onPageSelected = (page) => {
        this.setState(
            {
                currentPage: page,
            },
            async () => {
                await this.updateResults();
                this.scrollToFilters();
            },
        );
    };

    onOrderBySelected = (orderBy) => {
        this.setState(
            {
                preSelectedOrderBy: orderBy,
                selectedOrderBy: orderBy,
                currentPage: 1,
            },
            async () => {
                await this.updateResults();
                this.scrollToFilters();
            },
        );
    };

    preSelectOrderBy = (orderBy) => {
        this.setState({
            preSelectedOrderBy: orderBy,
        });
    };
    applyOrderBy = () => {
        this.onOrderBySelected(this.state.preSelectedOrderBy);
    };


    scrollToFilters = () => {
        scrollToElement('#category-top');
    };

    checkIfFilterIsSelected = (filterId) => {
        return this.state.selectedFilters.indexOf(filterId) !== -1;
    };

    isInstantCheckableParent = (filterId) => {
        const filter = this.state.filters.find(filter => filter.Id === filterId);
        return /* !filter?.IsFilterChecked &&  */(filter?.Description === null || filter?.Description === "")  && filter?.IsCheckbox === true; 
    };

    onFilterButtonClicked = (id) => {
        switch (id) {
            case 'order':
                window.location.hash = '#openedorder';
                this.showOrderSelect();
                break;
            case 'all':
                window.location.hash = '#openedfilter';
                this.showFilterSelect();
                break;
            default: {
                if (this.isInstantCheckableParent(id)) {
                    this.onFilterChanged(null, id);
                } else {
                    window.location.hash = '#openedfilter';
                    this.showFilterSelect(id);
                }
            }
        }
    }
    showOrderSelect = () => {
        this.setState({
            showOrderSelect: true,
        });
    }
    closeOrderSelect = () => {
        this.setState({
            showOrderSelect: false,
            preSelectedOrderBy: this.state.selectedOrderBy
        }, async () => {
            DOMHelper.removeBodyOverflowHidden();
        })
    }
    showFilterSelect = (id) => {
        this.setState(state => ({
            showFilterSelect: true,
            clickedFilter: id,
            originalSelectedFilters: state.selectedFilters,
            openedFilters: [...state.openedFilters.filter(filterId => filterId !== id), id],
        }));
    }

    render() {
        const {
            currentPage,
            totalPages,
            totalProducts = 0,
            products,
            filters,
            selectedFilters,
            selectedOrderBy,
            preSelectedOrderBy,
            categories,
            blogPosts,
            showBlogPosts,
        } = this.state;

        const isProductList = this.getSearchQuery()?.startsWith('ProductList');
        const enableLeftSide =
            filters?.length > 0 ||
            categories?.length > 0 ||
            blogPosts?.length > 0;
        //   totalProducts && (filters?.length > 0 || categories?.length > 0);

        const safeFilters = typeof filters === 'undefined' ? [] : filters 
        const highlightedFilters = safeFilters?.filter(filter => filter?.IsHighlighted)
        const enableAllFilters = highlightedFilters?.length > 1 || (highlightedFilters?.length <= 1 && safeFilters?.length > highlightedFilters?.length)

        return products === null ? (
            <Loader isLoading />
        ) : (
            <>
            <div>
                <NoIndexMeta />
                {!isProductList && (
                    <div className="row">
                        <div className="col-12">
                            <div className="font-size-0-75 font-weight-light line-height-4 mb-2">
                                {t('brendon.search.title')}:
                            </div>
                            <h1 className="font-size-1-5 font-weight-bold">
                                “{this.getSearchQuery()}”
                                {/*  {totalProducts > 0 && (
                                    <span className="font-size-0-75 font-weight-light ml-1">
                                        ({totalProducts}&nbsp;
                                        {t('brendon.products.piecesText')})
                                    </span>
                                )} */}
                            </h1>
                        </div>
                    </div>
                )}
                {isProductList && <div className="py-3"></div>}

                <div className="d-md-none">
                    <div className="mt-3 mb-5">
                        {totalProducts > 0 && (
                            <a
                                className="btn btn-gray-lighter-2 btn-block font-size-0-875 line-height-rem-1-875 text-capitalize mb-2-5"
                                href="#products"
                            >
                                {t('brendon.search.result.product').replace(
                                    '{0}',
                                    totalProducts || 0,
                                )}
                            </a>
                        )}
                        {categories?.length > 0 && (
                            <a
                                className="btn btn-gray-lighter-2 btn-block font-size-0-875 line-height-rem-1-875 text-capitalize mb-2-5"
                                href="#category"
                            >
                                {t('brendon.search.result.category').replace(
                                    '{0}',
                                    categories?.length || 0,
                                )}
                            </a>
                        )}
                        {blogPosts?.length > 0 && (
                            <a
                                className="btn btn-gray-lighter-2 btn-block font-size-0-875 line-height-rem-1-875 text-capitalize mb-2-5"
                                href="#blogpost"
                            >
                                {t('brendon.search.result.blogpost').replace(
                                    '{0}',
                                    blogPosts?.length || 0,
                                )}
                            </a>
                        )}
                    </div>
                    <a name="category"></a>
                    {Array.isArray(categories) && categories?.length > 0 && <SideItemList
                        items={categories}
                        title={t("brendon.search.subCategories.title")}
                        titleClassName=""
                        enableMore
                        moreTitle="brendon.search.moreCategories"
                        maxItems={SEARCH_RESULT_SETTINGS.mobile.max_categories}
                    />}
                    {/*  <li>
                        <a
                            href='#'
                            className="d-flex justify-content-between align-items-center line-height-2-5 py-2 text-dark border-bottom mb-npixel"
                            onClick={(e) => { e.preventDefault();  this.setState({ showBlogPosts: !showBlogPosts})  }}
                        >
                            <h2 className="m-0 font-size-0-75 font-weight-medium line-height-2-5">
                                {t('brendon.search.blogpost.show').replace('{0}',  blogPosts.length)}
                            </h2>
                        </a>
                    </li>    
                    </SideItemList> */}
                    <a name="blogpost"></a>
                    {Array.isArray(blogPosts) && blogPosts?.length > 0 && <SideItemList
                        className="d-md-none"
                        items={blogPosts}
                        title={t('brendon.search.blogpost.title')}
                        titleClassName=""
                        moreLink={`${
                            HOST_SETTINGS[window.location.hostname]?.blog
                                ?.search_url
                        }?q=${this.getSearchQuery()}`}
                        moreTitle="brendon.search.moreBlogPosts"
                        maxItems={SEARCH_RESULT_SETTINGS.mobile.max_blogposts}
                    />}
                </div>
                <div id="category-top">
                    <div className="row justify-content-between mb-4 product-selectors d-none d-md-flex mx-0 align-items-center">
                        <div class="font-size-0-875 font-weight-medium">
                            {/* {t('brendon.filters.products.count', totalProducts)} */}
                            {this.state.selectedFilters?.length > 1 && <span className="text-uppercase text-underline cursor-pointer" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</span>}
                        </div>
                        <div class="d-flex">
                            <div className="col-auto">
                                {totalProducts > 0 && (
                                    <FilterOrderBy
                                        sortOptions={SORT_OPTIONS}
                                        selectedSortOption={selectedOrderBy}
                                        onOrderBySelected={this.onOrderBySelected}
                                        /* title={t('brendon.products.orderby.title')} */
                                    />
                                )}
                            </div>
                            <div className="col-auto">
                                <Pager
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageSelected={this.onPageSelected}
                                />
                            </div>
                        </div>
                    </div>
                    {isNewFilterEnabled() && safeFilters?.length > 0 && <div className="d-md-none mb-4">
                            <div className="d-flex justify-content-between align-items-center mb-2-5">
                                <h3 className="font-size-1-25 mb-0">{t('brendon.products.filters.title')}:</h3>
                                {this.state.selectedFilters?.length > 1 && <span className="font-size-0-875 font-weight-medium text-uppercase text-underline cursor-pointer" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</span>}
                            </div>
                            <FilterButtonContainerWithNav onClick={this.onFilterButtonClicked} items={[
                                ...(highlightedFilters?.map(filterItem => (
                                {
                                    id: filterItem.Id,
                                    label: filterItem.Name,
                                    selected: filterItem.FilterItems.some(filterItem => filterItem.FilterItemState === 1 || filterItem.FilterItemState === 2),
                                    icon: null,
                                    afterIcon: (filterItem?.Description && filterItem?.Description !== '') && <FontAwesomeIcon icon={faInfoCircle} />,
                                }))),
                                ...(enableAllFilters ? [{
                                    id: 'all',
                                    label: t('brendon.products.filter.all'),
                                    selected: false,
                                    icon: <CustomIcons iconName="SliderSimple" size="small" className="mr-2" />,
                                }]:[])
                                ]} />
                        </div>}
                    {totalProducts > 0 && (
                        <div>
                            <FilterTags
                                filters={filters}
                                onFilterRemoved={this.onFilterRemoved}
                                isNewFilterEnabled={isNewFilterEnabled()}
                            />
                        </div>
                    )}
                    {isNewFilterEnabled() && 
                    <div className="d-md-none d-flex justify-content-between font-size-0-875 font-weight-medium mb-4">
                         <FilterButton
                            onClick={this.onFilterButtonClicked} 
                            variant="default"
                            {...{
                                id: 'order',
                                label: SORT_OPTIONS.find(opt => opt.Value === this.state.selectedOrderBy)?.Text || t('brendon.products.orderby.title'),
                                selected: false,
                                icon: <CustomIcons iconName="ArrowDownWide" size="small" className="mr-2" /> ,
                            }} />

                        {/* {this.state.selectedFilters?.length > 1 && <div className="text-uppercase text-underline" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</div>} */}
                        <div className="align-self-center">{t('brendon.filters.products.count', totalProducts)}</div>
                    </div>}
                </div>
                <div className="row">
                    {enableLeftSide && (
                        <div className="col-3">
                            {Array.isArray(categories) && categories?.length > 0 && <SideItemList
                                className="d-none d-md-block"
                                items={categories}
                                title={t("brendon.search.subCategories.title")}
                                enableMore
                                moreTitle="brendon.search.moreCategories"
                                maxItems={
                                    SEARCH_RESULT_SETTINGS.desktop
                                        .max_categories
                                }
                            />}
                            {Array.isArray(blogPosts) && blogPosts?.length > 0 && <SideItemList
                                className="d-none d-md-block"
                                items={blogPosts}
                                title={t('brendon.search.blogpost.title')}
                                moreLink={`${
                                    HOST_SETTINGS[window.location.hostname]
                                        ?.blog?.search_url
                                }?q=${this.getSearchQuery()}`}
                                moreTitle="brendon.search.moreBlogPosts"
                                maxItems={
                                    SEARCH_RESULT_SETTINGS.desktop.max_blogposts
                                }
                            />}
                            {safeFilters?.length > 0 && (
                                <FilterPanel
                                    filters={filters}
                                    totalProducts={totalProducts}
                                    selectedFilters={selectedFilters}
                                    sortOptions={SORT_OPTIONS}
                                    selectedSortOption={selectedOrderBy}
                                    onClearFilters={this.clearFilters}
                                    onFilterChanged={this.onFilterChanged}
                                    onOrderChanged={this.onOrderBySelected}
                                />
                            )}
                        </div>
                    )}
                    <div
                        className={`col-12 ${enableLeftSide ? 'col-md-9' : ''}`}
                    >
                        {/* <div className="mx-n2 border-bottom d-md-none"></div> */}
                        <div className="mb-4">
                            {Array.isArray(products) && products.length > 0 && (
                                <ProductList
                                    products={products}
                                    legacy={false}
                                    enableLeftSide={enableLeftSide}
                                    gaEEList={'Search result page'}
                                    title={t('brendon.search.product.title')}
                                    titleClassName="d-md-none pb-2 text-uppercase font-weight-light line-height-4 font-size-1 ml-2"
                                />
                            )}
                            {!Array.isArray(products) ||
                                (products.length === 0 && (
                                    <div className="p-2 text-center text-dark text-uppercase font-size-1 flex-fill">
                                        {t("Brendon.Search.NoProductsFound")}
                                    </div>
                                ))}
                        </div>
                        <Pager
                            className="mb-4 justify-content-center justify-content-md-end"
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageSelected={this.onPageSelected}
                        />
                    </div>
                </div>
            </div>
            <MobileFilterModal
                    isOpen={this.state.showFilterSelect && isNewFilterEnabled()}
                    clickedFilter={this.state.clickedFilter}
                    rightTitleChildren={
                        <div className="text-uppercase text-underline font-size-0-875 cursor-pointer" onClick={this.clearFilters}>{t('brendon.filters.tags.removeall')}</div>
                    }
                    handleCancel={this.cancelFilterFilterSelect}
                    handleApply={() => {
                        this.setState({
                            showFilterSelect: false,
                            openedFilters: []
                        }, async () => {
                            DOMHelper.removeBodyOverflowHidden();
                        })
                    }}
                    onSelect={(id) => {
                        this.onFilterChanged(null, id);
                    }}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    totalProducts={totalProducts}
                    openedFilters={this.state.openedFilters}
                    onOpen={(id, opened) => {
                        this.setState(state => ({
                            openedFilters: [...state.openedFilters.filter(filterId => filterId !== id), ...(opened ? [id] : [])]
                        }))
                    }}
                    fullHeight={true}
                />
                <MobileSortModal
                    isOpen={this.state.showOrderSelect && isNewFilterEnabled()}
                    handleCancel={() => {
                        this.closeOrderSelect();
                    }}
                    onSelect={(selectedOrderBy) => {
                        this.onOrderBySelected(selectedOrderBy);
                        this.setState({showOrderSelect: false}, async () => {
                            DOMHelper.removeBodyOverflowHidden();
                        });
                    }}
                    sortOptions={SORT_OPTIONS}
                    preSelectedOrderBy={preSelectedOrderBy}
                />
            </>
        );
    }
}

export default SearchPage;
