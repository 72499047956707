import React from 'react';
import BannerZone from '../../banners/BannerZone';

const MainScreen = () => {
    return (
        <>
            <BannerZone bannerBlockId={1} />
            <BannerZone bannerBlockId={8} />
        </>
    );
};

export default MainScreen;
