import {createSelector} from "reselect";

const selectSearch = state => state.get('search');

export const selectSearchQuery = createSelector(selectSearch, search => search.get('query') || '');

export const selectSearchResult = createSelector(selectSearch, selectSearchQuery, (search, query) => {
    return search.getIn(['results', query], search.get('lastLoadedResult'));
});

export const getNewQueryMode = createSelector(selectSearch, search => search.get('newQueryMode'));

export const selectIsSearchQueryLoaded = query => createSelector(selectSearch, search => search.hasIn(['results', query]));
