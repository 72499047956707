import React from 'react';
import ProductListDefault from './ProductListDefault';
import ProductListByModel from './ProductListByModel';

function ProductList({
    products,
    categoryName,
    legacy,
    enableLeftSide = true,
    selectedOrderBy,
    gaEEList,
    title,
    titleClassName,
    enableUpsell = true,
    upsellList = false,
}) {
    if (!products) {
        return null;
    }

    return (
        <div className="mx-n2 mx-md-0">
            <a name="products"></a>
            {title && (
                <div className={titleClassName}>
                    {title}
                </div>
            )}
            <div className="overflow-hidden">
                <div className="row mb-3 mb-md-0">
                    {selectedOrderBy === 'model' ? (
                        <ProductListByModel
                            products={products}
                            categoryName={categoryName}
                            gaEEList={gaEEList}
                            legacy={legacy}
                            enableLeftSide={enableLeftSide}
                            selectedOrderBy={selectedOrderBy}
                            enableUpsell={enableUpsell}
                            upsellList={upsellList}
                        />
                    ) : (
                        <ProductListDefault
                            products={products}
                            categoryName={categoryName}
                            gaEEList={gaEEList}
                            legacy={legacy}
                            enableLeftSide={enableLeftSide}
                            selectedOrderBy={selectedOrderBy}
                            enableUpsell={enableUpsell}
                            upsellList={upsellList}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProductList;
