import React from 'react';
import OvulationCalculator from './OvulationCalculator';
import PregnancyCalculator from './PregnancyCalculator';

const ComponentSelector = ({storeId, componentName}) => {

    switch (componentName) {
        case 'pregnancy_calculator':
            return <PregnancyCalculator storeId={storeId} />;
        case 'ovulation_calculator':
            return <OvulationCalculator />;
        default: 
            return null;
    }
};

export default ComponentSelector;
