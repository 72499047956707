import React, {Component} from 'react';
import styled from 'styled-components';
import withThemeProvider from "../../hoc/withThemeProvider";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import { connect } from 'react-redux';
import withStore from "../../hoc/withStore";
import {createStructuredSelector} from "reselect";
import { selectCategoryMenu, selectTopMenu } from "../../store/megamenu/selectors";
import { Link } from 'react-router-dom';

const MainMenuWrapper = styled.div`
    border-top-left-radius: ${props => props.theme.borderRadiusSm};
    border-top-right-radius: ${props => props.theme.borderRadiusSm};
`;

/* const MainMenuItem = styled.a`
    border-top-left-radius: ${props => props.theme.borderRadiusSm};
    border-bottom-left-radius: ${props => props.theme.borderRadiusSm};
`;
 */
const CategoryWrapper = styled.div`
    columns: 3;
    column-gap: ${props => parseInt(props.theme.gridGutterWidth, 10) * 2}px;

    & > div {
        -webkit-column-break-inside: avoid;
        column-break-inside: avoid;
        page-break-inside: avoid;
    }
`;

const CategoryColumnBorderWrapper = styled.div`
    pointer-events: none;
`;

class MegaMenu extends Component {
    state = {
        categories: null,
        topMenu: [],
        currentSubCategory: null,
        showMenu: false,
    };

    setSubcategory = (subcategory) => {
        this.setState({
            currentSubCategory: subcategory,
        });
    };

    showMenu = () => {
        this.setState({
            showMenu: true,
        });
    };

    hideMenu = () => {
        this.setState({
            showMenu: false,
        });
    };

    render() {
        const {currentSubCategory, showMenu} = this.state;
        const {defaultLink, defaultTitle, categories, topMenu} = this.props;
    
        return <div className="row justify-content-between">
            <div className="px-2">
                <MainMenuWrapper className={`overflow-hidden ${(showMenu && categories?.Name) ? 'shadow' : ''}`}
                                 onMouseLeave={this.hideMenu}
                                 onMouseEnter={this.showMenu}>
                    <div className={`${(showMenu && categories?.Name) ? 'bg-gray-light' : ''} position-relative zindex-9000`}>
                        <Link
                            to={categories ? `/${categories.SeName}` : defaultLink}
                            className="text-uppercase font-size-0-75 font-weight-medium d-inline-block px-3">
                                {categories ? categories.Name : defaultTitle}
                                {categories && <FontAwesomeIcon icon={faChevronDown} className="font-size-0-5625 ml-1" />}
                        </Link>
                    </div>
                    {categories &&
                    <div
                        className={`col-12 position-absolute zindex-8000 top left mt-4 ${showMenu ? 'd-block' : 'd-none'}`}>
                        <div className="shadow bg-white border border-light rounded-sm">
                            <div className="row font-size-0-875 ">
                                <div className="col-3">
                                    <div className="bg-gray-light d-flex flex-column pl-2 py-2 h-100">
                                        {categories?.SubCategories?.map(subcategory =>
                                            <Link to={`/${subcategory.SeName}`}
                                                          className={`${subcategory === currentSubCategory ? 'bg-white bg-hover-white text-primary text-hover-primary' : 'text-dark'} line-height-4 py-1 pl-2`}
                                                          key={subcategory.SeName}
                                                          onMouseOver={() => this.setSubcategory(subcategory)}>
                                                {subcategory.Name}
                                            </Link>)}
                                    </div>
                                </div>
                                <div className="col-9 pt-3 pb-2 line-height-3 px-3 mx-n2 position-relative">
                                    {currentSubCategory && <CategoryWrapper>
                                        <CategoryColumnBorderWrapper
                                            className="position-absolute top left h-100 w-100">
                                            <div className="row h-100 no-gutters">
                                                <div className="col-4 offset-4 border-left"/>
                                                <div className="col-4 border-left"/>
                                            </div>
                                        </CategoryColumnBorderWrapper>
                                        {currentSubCategory.SubCategories.map(subcategory => <div
                                            className="pb-2"
                                            key={subcategory.SeName}>
                                            <Link
                                                to={`/${subcategory.SeName}`}
                                                className="font-weight-bold text-dark mb-1 d-block">
                                                    {subcategory.Name}
                                            </Link>
                                            {
                                                subcategory.SubCategories.map(subSubCategory =>
                                                    <Link
                                                        to={`/${subSubCategory.SeName}`}
                                                        className="text-dark mb-1 d-block"
                                                        key={subSubCategory.SeName}>
                                                            {subSubCategory.Name}
                                                    </Link>)
                                            }
                                        </div>)}
                                    </CategoryWrapper>}
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </MainMenuWrapper>
            </div>
            <div className="col-auto">
                <div className="row">
                    {
                        topMenu?.map(menu => (<div key={`menu${menu?.OrderNo}`} className="col-auto">
                            <Link to={`/${menu?.Slug}`} className="text-uppercase font-size-0-75 font-weight-medium">{menu?.MenuName}</Link>
                        </div>))
                    }
                </div>
            </div>
        </div>
    }
}

// export default withThemeProvider(MegaMenu);
const mapStateToProps = () => createStructuredSelector({
    categories: selectCategoryMenu,
    topMenu: selectTopMenu,
});

export default withStore(connect(mapStateToProps, null)(withThemeProvider(MegaMenu)));
