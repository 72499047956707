import React, { Component } from 'react';
import { connect } from 'react-redux';
import OrderItem from './OrderItem';
import withThemeProvider from '../../../hoc/withThemeProvider';
import CartService from '../../../services/cart';
import withStore from '../../../hoc/withStore';
import { clearMessages, showError } from '../../../store/notification/actions';
import classNames from 'classnames';

class OrderSummary extends Component {
    state = {
        items: [],
        itemResponse: {},
    };

    constructor(props) {
        super(props);
    }

    changeItem = (ShoppingCartItemId) => {
        return async (productId, attributesXml, quantity) => {
            const { refreshCart, clearAllMessages } = this.props;
            clearAllMessages();
            const result = await CartService.modifyItem({
                ProductId: productId,
                AttributesXml: attributesXml,
                Quantity: quantity,
            });
            if (result?.IsSuccess === true && refreshCart) {
                const { itemResponse } = this.state;
                itemResponse[ShoppingCartItemId] = {};
                this.setState({ itemResponse });
                refreshCart();
            }
            if (result?.IsSuccess === false && result?.ErrorType) {
                const { itemResponse } = this.state;
                itemResponse[ShoppingCartItemId] = {
                    errorType: result?.ErrorType,
                    infoQuantity: result?.InfoQuantity,
                };
                this.setState({ itemResponse });
            }

            if (result?.IsSuccess === false && result?.ErrorText) {
                const { ErrorText } = result;
                const { showError } = this.props;
                showError(ErrorText);
            }
        };
    };

    removeItem = (ShoppingCartItemId) => {
        return async (productId, attributesXml) => {
            const { refreshCart } = this.props;
            const result = await CartService.modifyItem({
                ProductId: productId,
                AttributesXml: attributesXml,
                Quantity: 0,
            });
            if (result?.IsSuccess === true && refreshCart) {
                const { itemResponse } = this.state;
                itemResponse[ShoppingCartItemId] = {};
                this.setState({ itemResponse });
                refreshCart();
            }
            if (result?.IsSuccess === false && result?.ErrorType) {
                const { itemResponse } = this.state;
                itemResponse[ShoppingCartItemId] = {
                    ErrorText: result?.ErrorText,
                };
                this.setState({ itemResponse });
            }
        };
    };

    render() {
        const { items } = this.props;

        const cartItems = items.map((item) => ({
            ...item,
         /*    OriginalQuantity: item.Quantity, */
        }));

        const { itemResponse } = this.state;

        const {
            action,
            children,
            showProductImages,
            isEditable,
            unitPriceLabel,
            itemTotalLabel,
            quantityLabel,
            className = 'mx-sm-n2',
        } = this.props;

        const WithForm = ({ children }) =>
            this.props.isEditable ? (
                <form
                    action={action}
                    method="post"
                    encType="multipart/form-data"
                >
                    {children}
                </form>
            ) : (
                <>{children}</>
            );

        return (
            <WithForm>
                <>
                    <div className={classNames("mb-7", className)}>
                        {cartItems.map((item) => (
                            <OrderItem
                                key={`oi_${item.ShoppingCartItemId}`}
                                item={item}
                                changeItem={this.changeItem(
                                    item.ShoppingCartItemId,
                                )}
                                removeItem={this.removeItem(
                                    item.ShoppingCartItemId,
                                )}
                                showProductImages={showProductImages}
                                isEditable={isEditable}
                                quantityLabel={quantityLabel}
                                unitPriceLabel={unitPriceLabel}
                                itemTotalLabel={itemTotalLabel}
                                errorText={
                                    itemResponse[item.ShoppingCartItemId]
                                        ?.ErrorText ||
                                    item.ErrorText ||
                                    null
                                }
                            />
                        ))}
                    </div>
                    {children}
                </>
            </WithForm>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showError(message)),
    clearAllMessages: () => dispatch(clearMessages()),
});

export default withThemeProvider(
    withStore(connect(null, mapDispatchToProps)(OrderSummary)),
);
