import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { getURLPath } from '../../utils/staticPaths';
import { Link } from 'react-router-dom';
import { useResource } from '../../contexts/ResourceContext';

const CustomerBackButton = ({ to = getURLPath('customerDashboard') }) => {
  const { t } = useResource();
  return (
    <Link
      to={to}
      className="btn btn-gray-light font-size-0-875 line-height-rem-1-25 py-1 font-weight-normal rounded-15 text-capitalize mt-4 mb-4 border-0"
    >
      <FontAwesomeIcon icon={faLongArrowLeft} className="mr-2" />
      {t('brendon.customer.back')}
    </Link>
  );
};

export default CustomerBackButton;
