import React from "react";
import {ThemeProvider} from "styled-components";
import theme from "../theme";

function withThemeProvider(Component) {
    return (props) => {
        return <ThemeProvider theme={theme}>
            <Component {...props}/>
        </ThemeProvider>
    }
}

export default withThemeProvider;
