import React from 'react';
import { useResource } from '../contexts/ResourceContext';

const ProductsNotFound = () => {
  const { t } = useResource();

  return (
    <div className="p-2 text-center text-primary font-size-1-125 flex-fill">
      {t('Brendon.Catalog.NoProductsFound')}
    </div>
  );
};

export default ProductsNotFound;
