import React, {
    useRef,
    useCallback,
    useState,
    useLayoutEffect,
} from 'react';
import FilterButton from './FilterButton';
import CircleLink from '../Elements/CircleLink';
import {
    faLongArrowLeft,
    faLongArrowRight,
    faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @typedef {Object} FilterItems
 *
 * @property {string} id - Id of the filter item
 * @property {string} label - Label of the filter item
 * @property {boolean} selected - Whether the filter item is selected
 * @property {React.ReactNode} icon - Icon of the filter item
 *
 */

/**
 * FilterButtonContainer
 *
 * @param {FilterItems[]} items - List of filter items
 * @param {function} onClick - Function to be called when a filter item is clicked
 *
 * @returns {React.ReactNode}
 */
const FilterButtonContainerWithNav = ({ items, onClick }) => {
    const scrollBox = useRef(null);
    const [isStartOfScroll, setIsStartOfScroll] = useState(true);
    const [isEndOfScroll, setIsEndOfScroll] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const [inMove, setInMove] = useState(false);
    const SPEED = 30;
    const DISTANCE = 150;
    const STEP = 5;
    const DURATION = 250;

    const smoothScrollTo = useCallback(
        (element, to, duration) => {
            if (duration <= 0) {
                setInMove(false);
                return;
            }
            setInMove(true);
            const difference = to - element.scrollLeft;
            const perTick = (difference / duration) * SPEED;

            setTimeout(() => {
                element.scrollLeft = element.scrollLeft + perTick;
                if (element.scrollLeft === to) {
                    setInMove(false);
                    return;
                }
                smoothScrollTo(element, to, duration - STEP);
            }, 10);
        },
        [setInMove],
    );

    const scrollRight = useCallback(() => {
        if (inMove) return;
        smoothScrollTo(
            scrollBox.current,
            scrollBox.current.scrollLeft + DISTANCE,
            DURATION,
        );
    }, [inMove, scrollBox, scrollBox.current, smoothScrollTo]);

    const scrollLeft = useCallback(() => {
        if (inMove) return;
        smoothScrollTo(
            scrollBox.current,
            scrollBox.current.scrollLeft - DISTANCE,
            DURATION,
        );
    }, [inMove, scrollBox, scrollBox.current, smoothScrollTo]);

    useLayoutEffect(() => {
        setIsScrollable(
            scrollBox.current?.scrollWidth > scrollBox.current?.clientWidth,
        );
    }, [
        scrollBox.current,
        scrollBox.current?.scrollWidth,
        scrollBox.current?.clientWidth,
    ]);

    const scrollObserver = useCallback(() => {
        const { scrollLeft, clientWidth, scrollWidth } = scrollBox.current;
        if (scrollLeft === 0) {
            setIsStartOfScroll(true);
        } else {
            setIsStartOfScroll(false);
        }

        if (scrollLeft + clientWidth >= scrollWidth - 4) {
            setIsEndOfScroll(true);
        } else {
            setIsEndOfScroll(false);
        }

        setIsScrollable(
            scrollBox.current?.scrollWidth > scrollBox.current?.clientWidth,
        );

    }, [setIsStartOfScroll, setIsEndOfScroll, scrollBox.current]);

    useLayoutEffect(() => {
        if (scrollBox.current) {
            scrollBox.current?.addEventListener('scroll', scrollObserver);
        }
        return () => {
            scrollBox.current?.removeEventListener('scroll', scrollObserver);
        };
    }, [scrollBox.current]);

    useLayoutEffect(() => {
        window.addEventListener('resize', scrollObserver);
        return () => {
            window.removeEventListener('resize', scrollObserver);
        };
    }, [scrollBox.current]);

    return (
        <div className="position-relative">
            <div className="d-flex overflow-hidden">
                {!isStartOfScroll && (
                    <CircleLink
                        containerClassName="fbc-nav-left"
                        className="bg-gray-light box-shadow-filter-r m-2"
                        iconType={faLongArrowLeft}
                        onClick={scrollLeft}
                    />
                )}
                <div className="scrollbox d-flex text-nowrap" ref={scrollBox}>
                    {items.map((item) => (
                        <FilterButton
                            variant="default"
                            selectedIcon={
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="mr-2"
                                />
                            }
                            key={`filter_${item.id}`}
                            onClick={onClick}
                            {...item}
                        />
                    ))}
                </div>
                {isScrollable && !isEndOfScroll && (
                    <CircleLink
                        containerClassName="fbc-nav-right"
                        className="bg-gray-light box-shadow-filter-r m-2"
                        iconType={faLongArrowRight}
                        onClick={scrollRight}
                    />
                )}
            </div>
        </div>
    );
};

export default FilterButtonContainerWithNav;
