import axios from 'axios';

const taxInfoCache = {};

const TAX_VALIDATION_TIMEOUT_MS = 10000;

async function getTaxInfo(taxpayerId) {
    if(taxInfoCache[taxpayerId]) {
        return taxInfoCache[taxpayerId];
    }
    
    try {
        const {data} = await axios.get(`/BrendonAjax/GetTaxInfo/${taxpayerId}`, {
            timeout: TAX_VALIDATION_TIMEOUT_MS,
        });

        const {QueryTaxpayerResult} = data;

        if(QueryTaxpayerResult === 0) {
            taxInfoCache[taxpayerId] = data;
        }

        return data;   
    } catch (err) {
        taxInfoCache[taxpayerId] = {};
        return {};
    }
}

export const TaxService = {
    getTaxInfo,
};

export default TaxService;