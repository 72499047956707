import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { isLastNameFirst } from '../../utils/localization';
import InputGroup from '../../elements/forms/InputGroup';
import createCustomerPersonalDataSchema from '../../models/validation/customerPersonalDataSchema';
import { CustomerBaseDataService } from '../../services/customer';
import { executeCallback } from '../../utils/function';
import withStore from '../../hoc/withStore';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../store/notification/actions';
import PageLoader from '../PageLoader'; 
import { useResource } from '../../contexts/ResourceContext';

const CustomerInfo = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const dispatch = useDispatch();
    const { t } = useResource();

    const saveCustomerInfo = useCallback(async (data) => {
        const { Success } = await CustomerBaseDataService.post(data);
        if (Success) {
            dispatch(showSuccess(t('brendon.customer.info.save.success')));
        } else {
            dispatch(showError(t('brendon.customer.info.save.error')));
        }
    }, []);

    const loadCustomerInfo = useCallback(async () => {
        const {
            FirstName,
            LastName,
            Phone,
        } = await CustomerBaseDataService.get();
        setInitialValues({
            firstName: FirstName,
            lastName: LastName,
            phone: Phone,
        });
        setIsLoading(false);
    }, []);

    useEffect(() => {
        loadCustomerInfo();
    }, []);

    return (
        <PageLoader isLoading={isLoading}>
            <div className="row justify-content-center">
                <Formik
                    enableReinitialize
                    validationSchema={createCustomerPersonalDataSchema()}
                    initialValues={initialValues}
                    onSubmit={async (values, setSubmitting) => {
                        executeCallback(saveCustomerInfo, {
                            FirstName: values.firstName,
                            LastName: values.lastName,
                            Phone: values.phone,
                        });
                        setSubmitting(false);
                    }}
                >
                    {({ values, setFieldValue, validateForm }) => {
                        return (
                            <Form
                                autoComplete="off"
                                className="col-12 col-sm-6 col-md-5 col-lg-4"
                            >
                                {isLastNameFirst() ? (
                                    <>
                                        <InputGroup
                                            label={t('Account.Fields.LastName')}
                                            variation="wide"
                                            name="lastName"
                                            errorType="input-after-always"
                                            feedbackIcons
                                            required
                                        />
                                        <InputGroup
                                            label={t(
                                                'Account.Fields.FirstName',
                                            )}
                                            variation="wide"
                                            name="firstName"
                                            errorType="input-after-always"
                                            feedbackIcons
                                            required
                                        />
                                    </>
                                ) : (
                                    <>
                                        <InputGroup
                                            label={t(
                                                'Account.Fields.FirstName',
                                            )}
                                            variation="wide"
                                            name="firstName"
                                            errorType="input-after-always"
                                            feedbackIcons
                                            required
                                        />
                                        <InputGroup
                                            label={t('Account.Fields.LastName')}
                                            variation="wide"
                                            name="lastName"
                                            errorType="input-after-always"
                                            feedbackIcons
                                            required
                                        />
                                    </>
                                )}
                                <InputGroup
                                    label={t('Account.Fields.phone')}
                                    variation="wide"
                                    name="phone"
                                    errorType="input-after-always"
                                    feedbackIcons
                                    required
                                />
                                <div className="row form-group mt-4 mb-0">
                                    <div className="col-12">
                                        <input
                                            type="submit"
                                            value={t('Common.Save')}
                                            name="save-info-button"
                                            className="btn btn-primary btn-lg rounded-5 w-100 font-size-1"
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </PageLoader>
    );
};

export default withStore(CustomerInfo);
