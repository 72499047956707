import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faStars,
  faUserGear,
  faCartCircleCheck,
  faAt,
  faLockKeyhole,
} from '@fortawesome/pro-light-svg-icons';
import VariantComboList from '../Elements/ComboList/VariantComboList';
import { CustomerBaseDataService } from '../../services/customer';
import { isLastNameFirst } from '../../utils/localization';
import { getURLPath } from '../../utils/staticPaths';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../PageLoader';
import { useResource } from '../../contexts/ResourceContext';

const CustomerDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const navigateTo = useNavigate();
  const { t } = useResource();

  const dashboardItems = useMemo(
    () => [
      {
        pathName: 'customerDashboard',
        name: t('brendon.account.menu.points'),
        icon: faStars,
        isEnabled: true,
        url: getURLPath('customerRewards'),
      },
      {
        pathName: 'customerOrders',
        name: t('brendon.account.menu.orders'),
        icon: faCartCircleCheck,
        isEnabled: true,
        url: getURLPath('customerOrders'),
      },
      {
        pathName: 'customerProfile',
        name: t('brendon.account.menu.personal'),
        icon: faUserGear,
        isEnabled: true,
        url: getURLPath('customerProfile'),
      },
      {
        pathName: 'customerChangeEmail',
        name: t('brendon.account.menu.email'),
        icon: faAt,
        isEnabled: true,
        url: getURLPath('customerChangeEmail'),
      },
      {
        pathName: 'customerChangePassword',
        name: t('brendon.account.menu.password'),
        icon: faLockKeyhole,
        isEnabled: true,
        url: getURLPath('customerChangePassword'),
      },
    ],
    [t],
  );

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchData = async () => {
      const baseData = await CustomerBaseDataService.get({ signal });
      setUserData(baseData);
      setIsLoading(false);
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  const userName = useMemo(
    () =>
      isLastNameFirst()
        ? `${userData?.LastName || ''} ${userData?.FirstName || ''}`
        : `${userData?.FirstName || ''} ${userData?.LastName || ''}`,
    [userData?.LastName, userData?.FirstName],
  );
  return (
    <PageLoader isLoading={isLoading}>
      <div className="row justify-content-center">
        <div className="circle-link profile-icon">
          <FontAwesomeIcon icon={faUser} className="font-size-1-875" />
        </div>
      </div>
      <div className="text-center mt-2-5 mb-4">
        <div className="font-weight-bold">{userName}</div>
        <div>{userData?.Email?.Email}</div>
      </div>

      <div className="row justify-content-center mb-n4">
        <VariantComboList
          variant="link"
          items={dashboardItems}
          onSelect={(index) => () => {
            navigateTo(dashboardItems[index]?.url);
          }}
          containerClassName="col-12 col-sm-8 col-md-7 col-lg-4"
          containerHoverClassName="border-hover-dark"
          itemNameClassName="font-size-1"
          textHoverClassName="text-dark"
          disabled={false}
        />
      </div>
    </PageLoader>
  );
};

export default CustomerDashboard;
