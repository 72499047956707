export default {
    default : {
        input: {
            className: 'form-control h-p30 font-size-0-75',
        },
    },
    checkout: {
        containerClass: 'row form-group justify-content-center',
        labelClass: 'col col-md-8 col-lg-3 font-size-0-75 text-gray line-height-3 py-1 d-flex',
        inputContainerClass: 'col-12 col-md-8 col-lg-4',
        input: {
            className: 'form-control h-p30 font-size-0-75',
        },
    },
    customer: {
        containerClass: 'row form-group',
        labelClass: 'col-12 col-md-4 col-lg-4 font-size-0-75 text-gray line-height-4',
        inputContainerClass: 'col-12 col-md-8 col-lg-5',
        input: {
            className: 'form-control h-p30 font-size-0-75',
        },
    },
    wide: {
        labelClass: 'col-12',
        labelItemClass: 'font-size-0-875 line-height-0',
        input: {
            className: 'form-control height-50px p-2-5 text-gray-dark font-size-1 font-weight-medium rounded-5',
        },
    }
};
