import React from 'react';

const SectionCardContainer = ({className, mobileOutOfPage, children}) => {
  return (
    <div className={`section-card-container d-flex ${mobileOutOfPage?'outOfPage':''} ${className || ''}`} >
      {children}
    </div>
  )
};

export default SectionCardContainer;
