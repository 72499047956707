import React from 'react';

const SectionContainer = ({mobileOutOfPage, className, children}) => {
  return (
    <div className={`blog-section-container ${mobileOutOfPage?'outOfPage':''} ${className || ''}`}>
      {children}
    </div>
  )
};

export default SectionContainer;
