import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
    DEDICATED_URLS,
    CHECKOUT_STEPS,
    CHECKOUT_STEP_NAMES,
    CHECKOUT_STEP_TITLES,
} from '../../models/constants';
import CartCheckoutProgress from '../../components/Checkout/CartCheckoutProgress';
import BillingAddress from './partials/BillingAddress';
import ShippingAddress from './partials/ShippingAddress';
import PaymentMethod from './partials/PaymentMethod';
import CheckoutConfirm from './partials/CheckoutConfirm';
import DeliveryMode from './partials/DeliveryMode';
import ShopList from './partials/DeliveryModes/ShopList';
import withStore from '../../hoc/withStore';
import { setShopStoreId } from '../../store/shopstore';
import { useDispatch, useSelector } from 'react-redux';
import CheckoutStepTitle from '../../components/Checkout/CheckoutStepTitle';
import { isDeliveryModeEnabled as deliveryModeEnabled } from '../../store/checkout/selectors';
import { getDomainStoreId } from '../../utils/localization';
import GLSDropoff from './partials/DeliveryModes/GLSDropoff';

const CheckoutScreen = () => {
    const [checkoutProgressStep, setCheckoutProgressStep] = useState(null);
    const dispatch = useDispatch();
    const isDeliveryModeEnabled = useSelector(deliveryModeEnabled);

    useEffect(() => {
        const storeId = getDomainStoreId()
        if (storeId) {
            dispatch(setShopStoreId(storeId));
        }
    }, []);

    useEffect(() => {
        if (checkoutProgressStep === null) {
            if (window.location.hash !== '') {
                window.location = DEDICATED_URLS.checkout;
            }
            setCheckoutProgressStep(CHECKOUT_STEPS.ADDRESS);
        }
    }, [checkoutProgressStep]);

    useEffect(() => {
        const historyStateChanged = (e) => {
            const hash = window.location.hash;
            const step_name = hash.substring(1, hash.length).toUpperCase();
            if (CHECKOUT_STEPS[step_name]) {
                setCheckoutProgressStep(CHECKOUT_STEPS[step_name]);
            } else if (hash === '') {
                setCheckoutProgressStep(CHECKOUT_STEPS.ADDRESS);
            }
        };
        window.addEventListener('popstate', historyStateChanged);

        return () => {
            window.removeEventListener('popstate', historyStateChanged);
        };
    }, []);

    const addHistoryUrl = (step) => {
        const url = new URL(window.location);
        url.hash = `#${step}`;
        window.history.pushState({}, '', url);
    };

    const nextStep = (next) => {
        switch (next) {
            case 'ShoppingCart':
                window.location = DEDICATED_URLS.cart;
                break;
            case 'PaymentMode':
                addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.PAYMENT]);
                setCheckoutProgressStep(CHECKOUT_STEPS.PAYMENT);
                break;
            case 'ShippingData':
                addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.SHIPPING]);
                setCheckoutProgressStep(CHECKOUT_STEPS.SHIPPING);
                break;
            case 'GLSDropoff':
                addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.GLSDROPOFF]);
                setCheckoutProgressStep(CHECKOUT_STEPS.GLSDROPOFF);
                break;
            case 'DeliveryMode':
                addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.DELIVERYMODE]);
                setCheckoutProgressStep(CHECKOUT_STEPS.DELIVERYMODE);
                break;
            case 'ShopList':
                addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.SHOPLIST]);
                setCheckoutProgressStep(CHECKOUT_STEPS.SHOPLIST);
                break;
            case 'OrderConfirm':
                addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.CONFIRM]);
                setCheckoutProgressStep(CHECKOUT_STEPS.CONFIRM);
                break;
        }
    };

    const CheckoutStep = useCallback(({ step, changeStep, nextStep }) => {
        switch (step) {
            case CHECKOUT_STEPS.ADDRESS:
                return (
                    <BillingAddress
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
            case CHECKOUT_STEPS.SHIPPING:
                return (
                    <ShippingAddress
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
            case CHECKOUT_STEPS.DELIVERYMODE:
                return (
                    <DeliveryMode
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
            case CHECKOUT_STEPS.SHOPLIST:
                return (
                    <ShopList
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
            case CHECKOUT_STEPS.GLSDROPOFF:
                return (
                    <GLSDropoff
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
            case CHECKOUT_STEPS.PAYMENT:
                return (
                    <PaymentMethod
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
            case CHECKOUT_STEPS.CONFIRM:
                return (
                    <CheckoutConfirm
                        step={step}
                        changeStep={changeStep}
                        nextStep={nextStep}
                    />
                );
        }
        return null;
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [checkoutProgressStep]);

    return (
        <>
            <CartCheckoutProgress
                checkoutProgressStep={checkoutProgressStep}
                stepHandlers={{
                    [CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.CART]]: () => {
                        window.location = DEDICATED_URLS['cart'];
                    },
                    [CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.ADDRESS]]: () => {
                        addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.ADDRESS]);
                        setCheckoutProgressStep(CHECKOUT_STEPS.ADDRESS);
                    },
                    [CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.SHIPPING]]: () => {
                        if (isDeliveryModeEnabled) {
                            addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.DELIVERYMODE]);
                            setCheckoutProgressStep(
                                CHECKOUT_STEPS.DELIVERYMODE,
                            );
                            return;
                        }
                        addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.SHIPPING]);
                        setCheckoutProgressStep(CHECKOUT_STEPS.SHIPPING);
                    },
                    [CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.PAYMENT]]: () => {
                        addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.PAYMENT]);
                        setCheckoutProgressStep(CHECKOUT_STEPS.PAYMENT);
                    },
                    [CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.CONFIRM]]: () => {
                        addHistoryUrl(CHECKOUT_STEP_NAMES[CHECKOUT_STEPS.CONFIRM]);
                        setCheckoutProgressStep(CHECKOUT_STEPS.CONFIRM);
                    },
                }}
            >
            </CartCheckoutProgress>
            <CheckoutStepTitle
                title_resource_name={CHECKOUT_STEP_TITLES[checkoutProgressStep]}
            />
            <CheckoutStep
                step={checkoutProgressStep}
                changeStep={(step) => setCheckoutProgressStep(step)}
                nextStep={nextStep}
            />
        </>
    );
};

export default withStore(CheckoutScreen);
