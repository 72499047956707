import {object, string, ref} from 'yup';
import { legacy_t as t} from '../contexts/ResourceContext';
import { isValidEmail } from '../utils';

function registrationValidationSchema() {
    return object({
        FirstName: string()
            .required(t('account.fields.firstName.required'))
            .max(45,t('account.fields.firstname.lengthvalidation').replace('#0#',45)),
        LastName: string()
            .required(t('account.fields.lastName.required'))
            .max(45,t('account.fields.lastname.lengthvalidation').replace('#0#',45)),
        Email: string()
            .required(t('account.fields.email.required'))
            .test('Email', t('common.wrongemail'), isValidEmail)
            .max(254,t('account.fields.email.lengthvalidation').replace('#0#',254)),
        ConfirmEmail: string()
            .required(t('account.fields.email.required'))
            .test('Email', t('common.wrongemail'), isValidEmail)
            .oneOf([ref('Email'), null], t('Account.Fields.Email.EnteredEmailsDoNotMatch')),
        Password: string()
            .test('len', t('account.fields.password.lengthvalidation').replace('#0#', 6), val => val && val.toString().length >= 6)
            .required(t('account.fields.password.required')),
        ConfirmPassword: string()
             .required(t('account.fields.confirmpassword.require'))
            .oneOf([ref('Password'), null], t('account.fields.password.enteredpasswordsdonotmatch')),
    });
}

export default registrationValidationSchema; 