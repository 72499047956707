import React from 'react';
import Square from "../../elements/Square";
import image1Url from '../../../assets/intro-1.jpg';
import image2Url from '../../../assets/intro-2.jpg';
import image3Url from '../../../assets/intro-3.jpg';

function BoxImage({children, imageNumber, className, ...props}) {

    const getImagePath = () => {
        const images = [
            image1Url,
            image2Url,
            image3Url,
        ]
        return images[Number(imageNumber) - 1];
    }

    return <div {...props} className={`${className} cursor-pointer`}>
        <Square className="mb-1">
            <img src={getImagePath()} className="rounded-lg" alt=""/>
        </Square>
        <div className="text-uppercase text-primary font-weight-medium font-size-0-75 line-height-3">
            {children}
        </div>
    </div>
}

export default BoxImage;