import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withStore from '../../hoc/withStore';
import WishlistLine from '../../components/Wishlist/WishlistLine';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import WishlistService from '../../services/wishlist';
import Loader from '../../elements/Loader';
import { showError, showSuccess } from '../../store/notification/actions';
import { updateWishlistCount, updateCartCount } from '../../store/cart/actions';
import { useResource } from '../../contexts/ResourceContext';

const WishlistScreen = ({
    showError,
    showSuccess,
    updateWishlistCount,
    updateCartCount,
}) => {
    const { t } = useResource();
    const [wishlistItems, setWishlistItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addToCartLoading, setAddToCartLoading] = useState({});

    const fetchData = useCallback(async () => {
        const {
            WishlistItems,
            WishlistCount,
        } = await WishlistService.getWishlist();
        setWishlistItems(WishlistItems);
        setLoading(false);
        if (WishlistCount) {
            updateWishlistCount(WishlistCount);
        }
    }, [setWishlistItems, setLoading, updateWishlistCount]);

    useEffect(() => {
        fetchData();
    }, []);

    const addToCart = useCallback(
        async (productId, size) => {
            try {
                setAddToCartLoading((prevValue) => ({
                    ...prevValue,
                    [productId]: true,
                }));
                const {
                    Success,
                    CartCount,
                    WishListCount,
                    Message,
                } = await WishlistService.addWishlistItemToCart(
                    productId,
                    size,
                );

                if (Number(Success) === 1) {
                    fetchData();
                    showSuccess(t('brendon.wishlist.addToCartSuccess'));
                    updateWishlistCount(WishListCount);
                    updateCartCount(CartCount);
                } else {
                    if (Message !== '') {
                        showError(t(Message));
                    }
                }
                setAddToCartLoading((prevValue) => ({
                    ...prevValue,
                    [productId]: false,
                }));
            } catch (err) {
                showError(t('brendon.wishlist.addToCartError'));
                setAddToCartLoading((prevValue) => ({
                    ...prevValue,
                    [productId]: false,
                }));
                throw err;
            }
        },
        [
            showError,
            showSuccess,
            updateWishlistCount,
            updateCartCount,
            setAddToCartLoading,
        ],
    );

    const removeWishListItem = useCallback(
        (productId) => async () => {
            const {
                IsSuccess,
                Message,
                WishlistCount,
            } = await WishlistService.removeWishlistItem(productId);
            if (IsSuccess) {
                showSuccess(t('brendon.wishlist.removed'));
                fetchData();
            } else {
                showError(Message);
            }
            if (WishlistCount) {
                updateWishlistCount(WishlistCount);
            }
        },
        [fetchData, showError, showSuccess, updateWishlistCount],
    );

    return (
        <>
           {!loading && (
                <h1 className="font-size-1-125 font-weight-bold mb-2 mt-5">
                    {t('brendon.wishlist.title')}
                </h1>
            )}
            <div className="mt-3 p-0">
               <Loader isLoading={loading} />
                {!loading && (
                    <>
                        {wishlistItems?.length ? (
                            wishlistItems?.map((item) => (
                                <WishlistLine
                                    key={item.WishlistItemId}
                                    item={item}
                                    addToCart={addToCart}
                                    removeWishListItem={removeWishListItem(
                                        item.ProductId,
                                    )}
                                    isLoading={addToCartLoading[item.ProductId]}
                                />
                            ))
                        ) : (
                            <div className="font-weight-light font-size-0-75">
                                {t('brendon.wishlist.empty')}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showError(message)),
    showSuccess: (message) => dispatch(showSuccess(message)),
    updateWishlistCount: (wishlistCount) =>
        dispatch(updateWishlistCount(wishlistCount)),
    updateCartCount: (cartCount) => dispatch(updateCartCount(cartCount)),
});

export default withStore(
    connect(null, mapDispatchToProps)(withImmutablePropsToJS(WishlistScreen)),
);
