import React, { useState } from 'react';
import MoreButton from './MoreButtonV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import SectionTags from './MainSection/SectionTags';
import SectionTitleHeader from './MainSection/SectionTitleHeader';
import { useResource } from '../../contexts/ResourceContext';

const BlogTagsV2 = ({ title, content, tags, className, sticky = false }) => {
  const [moreTag, setMoreTag] = useState(true);
  const { t } = useResource();

  if (!Array.isArray(tags) || tags.length === 0) {
    return null;
  }

  return (
    <div className={`blog-tag ${sticky ? 'position-sticky' : ''} ${className}`}>
      <SectionTitleHeader title={title} content={content} />
      <div className={`${moreTag ? 'tag-container-2' : ''} pb-2`}>
        <SectionTags tags={tags} tagContainerClassName="mb-2 bg-white" />
      </div>
      {moreTag && (
        <MoreButton
          label={t('brendon.blog.more.moretag')}
          onClick={() => {
            setMoreTag(false);
          }}
          icon={<FontAwesomeIcon icon={faPlus} className="text-white mr-1" />}
          className="more-tag-2"
        />
      )}
    </div>
  );
};

export default BlogTagsV2;
