import React from 'react';
import { Outlet } from 'react-router-dom';
import RootLayoutContainer from './RootLayoutContainer';

const CustomerLayout = () => {
  return (
    <>
      <RootLayoutContainer>
        <div className="main-content-half-height mt-2 mb-4 mb-md-7">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </RootLayoutContainer>
    </>
  );
};

export default CustomerLayout;
