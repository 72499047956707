import React, { Component } from 'react';

class FormText extends Component {
 

    render() {
        const {
            side,
            children = 'm',
            className,
            containerClassName = ''
        } = this.props;

        
        return <div className={`row form-group justify-content-center ${containerClassName}`}>
            <div className={`col col-md-8 col-lg-3 font-size-0-75 text-gray line-height-3 py-1 d-flex ${side == 'l'?className:''}`}>
                {side == 'l' ? children : ''}
            </div>
            <div className={`d-none d-lg-block col-lg-3 order-0 text-right text-lg-left order-lg-last text-danger font-size-0-75 line-height-3 py-1 ${side == 'm' ? className : ''}`}>
                {side == 'm' ? children : ''}
            </div>
            <div className={`col-12 col-md-8 col-lg-4 ${side == 'r' ? className : ''}`}>
                {side == 'r' ? children : ''}
            </div>
        </div>
    }
}

export default FormText;
