import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogTagList } from '../../store/blog';
import { selectBlogTagList } from '../../store/blog/selectors';
import BlogLatest from './BlogLatest';
import SectionContainer from './MainSection/SectionContainer';
import BlogTagsV2 from './BlogTagsV2';
import SectionTags from './MainSection/SectionTags';
import ComponentSelector from './LeadComponents/ComponentSelector';
import { useResource } from '../../contexts/ResourceContext';
import DOMHelper from '../../utils/DOMHelper';

const BlogPost = ({ storeId, postData }) => {
  const { post, tagList: connectedTags } = postData;
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch();
  const selectedTagList = useSelector(selectBlogTagList);
  const ref = useRef();
  const { t } = useResource();

  useEffect(() => {
    dispatch(getBlogTagList());
  }, [dispatch]);

  useEffect(() => {
    setTags(selectedTagList.toArray());
  }, [selectedTagList]);

    useLayoutEffect(() => {
      if (ref?.current) {
        const tableNodes = ref?.current?.querySelectorAll('table') || [];
        tableNodes.forEach((tableNode) => {
          if (tableNode?.classList?.contains('custom-table')) {
            DOMHelper.addResponsiveTableContainer(tableNode);
          } else {
            DOMHelper.makeRecommendedProductTable(tableNode);
          }
        });
      }
    }, [postData, ref]);

  return (
    <div className="row blog-post">
      <div className="col-12 col-lg-8">
        <h1 className="mb-3">{post?.Title}</h1>
        <div className="mb-4">
          <SectionTags
            tags={connectedTags}
            tagContainerClassName="bg-gray-light"
          />
        </div>
        {post?.PictureUrl && !post?.NoLeadPicture && (
          <img
            src={post?.PictureUrl}
            alt={post?.Title}
            className="img-fluid mb-5 rounded"
            title={post?.Title}
          />
        )}
        {post?.BodyOverview && (
          <div
            className="lead mb-3"
            dangerouslySetInnerHTML={{ __html: post?.BodyOverview }}
          />
        )}
        {
          <ComponentSelector
            storeId={storeId}
            componentName={postData?.post?.LeadComponent}
          />
        }
        <div
          className="post"
          dangerouslySetInnerHTML={{ __html: post?.Body }}
          ref={ref}
        />
        <div className="my-4">
          <SectionTags
            tags={connectedTags}
            tagContainerClassName="bg-gray-light"
          />
        </div>
      </div>
      <div className="col-12 col-lg-4">
        {postData?.postListByTag && postData?.postListByTag?.length > 0 && (
          <BlogLatest posts={postData?.postListByTag} className="mb-5" />
        )}
        <SectionContainer>
          <BlogTagsV2
            title={t('Blog.TagCloud')}
            className="px-3 pt-3 bg-gray-light rounded"
            tags={tags}
          />
        </SectionContainer>
      </div>
    </div>
  );
};

export default BlogPost;
