
function numberToDoubleDigit(number) {
    return number < 10 ? `0${number}` : number;
}
function splitStringifyNumber(number) {
    return numberToDoubleDigit(number)?.toString()?.split('') || [];
}

function formatNumber(numberStr, thousands = ' ', decimal = '.')
{
    if (numberStr === undefined || numberStr === null || isNaN(numberStr)) {
        return '';
    }
	const parsed = String(numberStr).split('.');
	let [num, decimalNum] = parsed;
    decimalNum = parsed.length > 1 ? decimal + decimalNum : '';
	const rgx = /(\d+)(\d{3})/;
	while (rgx.test(num)) {
		num = num.replace(rgx, '$1' + thousands + '$2');
	}
	return num + decimalNum;
}

const firstNLetter = (str, n) => str.slice(0, n);
const toKebabCase = (str) => str?.replace(/([a-z])([A-Z])/g, '$1-$2').replaceAll(' ', '-').toLowerCase() || '';

export {
    numberToDoubleDigit,
    splitStringifyNumber,
    formatNumber,
    firstNLetter,
    toKebabCase
}