 
function getImagePath(image) {
    return `/images/${image}`;
}

function serialize (data) {
	let obj = {};
	for (let [key, value] of data) {
		if (obj[key] !== undefined) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
			obj[key] = value;
		}
	}
	return obj;
}


function serializeFormData(selector) {
    return serialize(new FormData(document.querySelector(selector)));
}

function createQueryString(obj) {
	return Object.entries(obj).map(e => e.join("=")).join('&')
}

function selectedRadioValue(formId) {
	const radios = document.querySelectorAll(`${formId} input[type=radio]`)
	let value;
	radios.forEach(radio => {
		if (radio.checked) {
			value = radio.value;
			return;
		}
	})
	return value;
}

const hasDecimals = (num) => !(num % 1 === 0)

// TODO: move to utils/validation so it needs to remove from here
const isValidEmail = async (email) => email && email?.match(/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@(([a-zA-Z0-9-]+\.)+(([a-zA-Z0-9-]+){2,}))))*$/)?true:false

// ha enableDecimal, akkor a szamnak lehet tizedes jegye egyébként nem
const isPriceValid = async (price, enableDecimal) => {
    // if not number or has not deciamals -> we left validation
    if (isNaN(price) || !hasDecimals(price)) {
        return true;
    }
    
   return enableDecimal ? true : false;

}

export {
    getImagePath,
    serialize,
    serializeFormData,
	createQueryString,
	selectedRadioValue,
    isValidEmail,
	isPriceValid,
};