import React, {Component} from 'react';
import styled from 'styled-components';
import withStore from "../hoc/withStore";
import {connect} from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import {selectNotification} from "../store/notification/selectors";
import {dismissMessage, initializeNotifications} from "../store/notification/actions";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';

const Fader = styled.div`
    opacity: 0;
    pointer-events: none;
    
    &.enter {
      opacity: 0;
      pointer-events: auto;
    }
    
    &.enter-active {
      opacity: 1;
      transition: opacity 200ms;
    }
    
    &.enter-done {
      opacity: 1;
      pointer-events: auto;
    }
    
    &.exit {
      opacity: 1;
    }
    
    &.exit-active {
      opacity: 0;
      transition: opacity 200ms;
    }
`;

class Notification extends Component {
    componentDidMount() {
        const {initializeMessages = [], initialErrorMessages = [], initialWarningMessages = [], initialSuccessMessages = []} = this.props;
        initializeMessages(initialErrorMessages, initialWarningMessages, initialSuccessMessages);
    }

    render() {
        const {message, dismissMessage} = this.props;

        const {type, text} = message || {};
        return <SwitchTransition>
            <CSSTransition  appear={true} timeout={200} key={message?.text} mountOnEnter={true}>
                {message ? <Fader
                    className={`container-fluid position-fixed bottom left w-100 zindex-9000 text-white ${type === 'success' ? 'bg-green' : 'bg-red'}`}>
                    <div className="row">
                        <div className="col line-height-3 py-2 font-size-0-875">
                            <div dangerouslySetInnerHTML={{__html: text}}/>
                        </div>
                        <div className="col-auto my-auto">
                            <FontAwesomeIcon icon={faTimes} className="d-flex justify-content-center align-items-center w-p40 px-2 cursor-pointer"
                               onClick={dismissMessage} />
                        </div>
                    </div>
                </Fader> : <div/>}
            </CSSTransition>
        </SwitchTransition>
    }
}

const mapStateToProps = state => ({
    message: selectNotification(state),
});

const mapDispatchToProps = dispatch => ({
    initializeMessages: (error, warning, success) => dispatch(initializeNotifications(error, warning, success)),
    dismissMessage: () => dispatch(dismissMessage()),
});

export default withStore(connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Notification)));
