import React, { useState, useEffect } from 'react';
import ProductService from '../services/products';
import ProductGallery from './ProductGallery';
import ProductPrice from './ProductPrice';
import ProductInfoBlocks from './ProductInfoBlocks';
import StockAvailabilityApi from './StockAvailabilityApi';
import ProductSizeSelector from '../components/ProductSizeSelector';
import AddToCartButton from './AddToCartButton';
import AddToWishlistButton from './AddToWishlistButton';
import BannerZone from '../banners/BannerZone';
import RelatedColors from './RelatedColors';
import ProductDescription from '../components/ProductDescription';
import RelatedCategories from '../product/RelatedCategories';
import RelatedProducts from '../product/RelatedProducts';
import ProductBox from '../category/ProductBox';
import { getImagePath } from '../utils';
import GiftCardInfo from './GiftCardInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import ProductMeta from './ProductMeta';
import NotPublished from './NotPublished';
import Loader from '../elements/Loader';
import LazyLoad from 'vanilla-lazyload';
import ProductNotAvailable from './ProductNotAvailable';
import gaService from '../services/gaEnhancedEcommerce';
import { getStoreCurrency, getStoreEnableDecimal } from '../utils/localization';
import GA4Service from '../services/gaGA4';
import LinkWithCircleArrow from '../components/Elements/LinkWithCircleArrow';
import ProductInfoBlocksV2 from './ProductInfoBlocksV2';
import { useResource } from '../contexts/ResourceContext';

const ProductDetails = ({ productId }) => {
  const { t } = useResource();
  const [productData, setProductData] = useState({});
  const [isProductDataLoaded, setProductDataLoaded] = useState(false);
  const [pictures, setPictures] = useState([]);
  const loader = getImagePath('loading.gif');

  const isGiftCard = () => {
    return productData?.Type && productData.Type.toLowerCase() === 'giftcard';
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      const responseData = await ProductService.getProductPage(productId);
      setProductData(responseData);

      if (
        !Array.isArray(responseData.ProductImages) ||
        responseData.ProductImages.length == 0
      ) {
        setPictures([
          {
            ImageUrl: responseData.Image,
            AlternateText: responseData.Alt,
          },
        ]);
      } else {
        setPictures(
          responseData.ProductImages.map((image) => ({
            AlternateText: image?.Alt,
            ImageUrl: image?.Url,
            ThumbImageUrl: image?.ThumbnailUrl,
          })),
        );
      }
      new LazyLoad({
        elements_selector: '.lazy',
        cancel_on_exit: false,
        unobserve_entered: true,
      });
      setProductDataLoaded(true);
      gaService.productDetailImpressions(
        gaService.transformToProductFieldObject(responseData),
      );

      GA4Service.gtagEvent('view_item', {
        currency: getStoreCurrency(),
        value:
          responseData.DiscountedPrice > 0
            ? responseData.DiscountedPrice
            : responseData.Price,
        items: [
          GA4Service.transformProductToItemListItem(
            'product-details',
            'Product details',
            responseData,
          ),
        ],
      });
    };
    if (productId) {
      fetchProductDetails();
    }
  }, [productId]);

  const isAvailableShortData = () =>
    productData?.Brand ||
    productData?.Design ||
    productData?.Model ||
    productData?.Function
      ? true
      : false;
  const isBreadcrumbAvilable =
    productData?.ParentCategory &&
    productData.ParentCategory?.Url &&
    productData?.ParentCategory.Url != '';
  const isOutOfStock =
    productData?.Availability &&
    productData.Availability.toLowerCase() === 'no';

  if (isProductDataLoaded && Number(productData?.ProductId) === 0) {
    return <ProductNotAvailable />;
  }

  return isProductDataLoaded === false ? (
    <Loader isLoading />
  ) : (
    <>
      {productData?.ProductMeta && (
        <ProductMeta
          productMeta={productData.ProductMeta}
          productData={productData}
        />
      )}
      {isBreadcrumbAvilable && (
        <div>
          <a
            href={productData?.ParentCategory?.Url}
            className="font-size-0-75 line-height-4 text-dark mb-2 d-inline-flex align-items-center text-nowrap"
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mr-2 font-size-0-5 h-p10"
            />
            <span>{productData?.ParentCategory?.Name}</span>
          </a>
        </div>
      )}
      {!isBreadcrumbAvilable && (
        <div className="line-height-4 text-dark mb-2">&nbsp;</div>
      )}
      <div className="row align-items-start">
        <div className="col-12 col-md-6 col-lg-8">
          <ProductGallery
            loader={loader}
            pictures={pictures}
            videos={productData?.Videos?.map((video) => ({
              ...video,
              ImageUrl: pictures?.[0]?.ImageUrl,
              AlternateText: pictures?.[0]?.AlternateText,
              ThumbImageUrl: pictures?.[0].ThumbImageUrl,
            })) || []}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4 mb-5 position-lg-sticky top pt-3 mt-n3">
          <div className="text-center text-md-left">
            {!isAvailableShortData() && (
              <h1 className="font-size-0-875 font-size-lg-1 font-weight-medium">
                {productData?.Name}
              </h1>
            )}
            {isAvailableShortData() && (
              <h1 className="font-weight-normal font-size-0-875 font-size-lg-1">
                <div className="font-weight-medium text-uppercase">
                  {productData?.Brand}
                </div>
                <div>
                  {productData?.Model} {productData?.Design}
                </div>
                <div>
                  {productData['Function'] ? productData['Function'] : ''}
                </div>
              </h1>
            )}

            {isGiftCard() && (
              <GiftCardInfo
                addButtonTitle={t('ShoppingCart.AddToCart')}
                productId={productId}
                defaultValue={productData?.DefaultValue}
                minPriceText={productData?.MinimumCustomerEnteredPrice}
                maxPriceText={productData?.MaximumCustomerEnteredPrice}
                minPrice={productData?.CustomerEnteredPriceMinValue}
                maxPrice={productData?.CustomerEnteredPriceMaxValue}
                enableDecimal={getStoreEnableDecimal()}
              />
            )}

            {!isGiftCard() && <ProductPrice productData={productData} />}
          </div>
          {productData?.Published && !isGiftCard() && (
            <>
              <form method="post" id="product-details-form">
                <ProductInfoBlocksV2
                  extraInfos={productData?.PageExtraInfo}
                  className="mb-4"
                />

                <StockAvailabilityApi productData={productData} />
                {isOutOfStock && (
                  <LinkWithCircleArrow
                    href={productData?.ParentCategory?.Url}
                    text={productData?.ParentCategory?.Name}
                    className="py-2"
                  />
                )}

                <ProductSizeSelector
                  sizeAttributeName={`product_attribute_${productId}`}
                  sizes={productData?.Sizes || []}
                />

                {productData?.Availability &&
                  productData.Availability.toLowerCase() === 'yes' && (
                    <div className="mb-2">
                      <AddToCartButton
                        legacy={false}
                        title={t('ShoppingCart.AddToCart')}
                        productId={productId}
                        productData={productData}
                        isSizesProduct={productData?.Sizes.length > 0}
                        enableUpsell
                      />
                    </div>
                  )}

                {productData?.IsWishlistButton &&
                  productData?.Availability &&
                  productData?.Availability.toLowerCase() !== 'no' && (
                    <div className="mb-2">
                      <AddToWishlistButton
                        title={t('Products.Wishlist.AddToWishlist')}
                        productId={productId}
                      />
                    </div>
                  )}
              </form>
            </>
          )}
          {isProductDataLoaded && Number(productData?.ProductId) !== 0 && (
            <NotPublished published={productData?.Published} />
          )}
          {!productData?.Published && (
            <LinkWithCircleArrow
              href={productData?.ParentCategory?.Url}
              text={productData?.ParentCategory?.Name}
              className="py-2 mb-2"
            />
          )}
          <div className="font-size-0-75 line-height-3 text-gray">
            <div>
              {t('Products.Sku')}: {productData?.Sku}
            </div>
          </div>
          <BannerZone bannerBlockId={6} productId={productId} />
        </div>

        <div className="col-12 col-lg-8">
          <div className="mb-5">
            {Array.isArray(productData?.ColorVariations) &&
              productData.ColorVariations.length > 0 && (
                <RelatedColors
                  show-price="false"
                  products={productData.ColorVariations}
                  currentProductId={productId}
                  title={t('Plugins.Widgets.ProductRelatedColors.Caption')}
                />
              )}
          </div>
        </div>

        {productData?.Description && (
          <div className="col-12 col-lg-8">
            <ProductDescription
              title={t('Products.Description')}
              description={productData?.Description}
              loadMore={t('Products.LoadMore')}
              closedHeight="300"
              productSku={productData?.Sku}
            />
          </div>
        )}
      </div>

      {Array.isArray(productData?.RelatedProducts) &&
        productData.RelatedProducts.length > 0 && (
          <div className="mb-5">
            <RelatedProducts title={t('Products.RelatedProducts')}>
              {productData.RelatedProducts.map((product) => (
                <div
                  className="col-9 col-md-4 col-lg-3"
                  key={`relatedProduct_${product.ProductId}`}
                >
                  <div className="border rounded-sm h-100">
                    <ProductBox
                      useBoxOnMobile={true}
                      product={product}
                      gaEEList={'Related Products'}
                    />
                  </div>
                </div>
              ))}
            </RelatedProducts>
          </div>
        )}

      {Array.isArray(productData?.RelatedCategories) &&
        productData.RelatedCategories.length > 0 && (
          <div className="mb-5">
            <RelatedCategories
              title={t('Products.RelatedCategories')}
              categories={productData.RelatedCategories}
            />
          </div>
        )}
    </>
  );
};

export default ProductDetails;
