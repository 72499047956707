import React from 'react';

const ShopMap = ({ mapHtmlObject }) => {
    return (
        <div className="map">
            <iframe
                src={mapHtmlObject}
                width="100%"
                height="450"
                style={{ border: 0 }}
                aria-hidden="false"
                tabIndex="0"
            ></iframe>
        </div>
    );
};

export default ShopMap;
