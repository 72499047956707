import { put, takeLatest } from 'redux-saga/effects';
import { success } from '../actions';
import { GET_MEGA_MENU } from './index';
import { updateWishlistProducts } from '../wishlist/actions';
import { updateWishlistCount } from '../cart/actions';
import { updateCartCount } from '../cart/actions';

function* handleGetMegaMenu({ result }) {
  if (result?.Extrainfos?.WishListProductIds) {
    yield put(
      updateWishlistProducts(
        result?.Extrainfos?.WishListProductIds?.map((id) => ({
          ProductId: id,
        })) || [],
      ),
    );
    yield put(updateWishlistCount(result?.Extrainfos?.WishListCount || 0));
    yield put(updateCartCount(result?.Extrainfos?.ShoppingCartCount || 0));
  }
}

function* saga() {
  yield takeLatest(success(GET_MEGA_MENU), handleGetMegaMenu);
}

export default saga;
