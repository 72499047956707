import { DOMAIN_STORES, STORE_SETTINGS } from '../models/constants';
import getStore from '../store';

export function getDomain() {
    const hostname = window.location.hostname;
    return hostname.split('.').slice(-2).join('.')?.toLowerCase?.();
}
export function getDomainStoreId() {
/*     const hostname = window.location.hostname;
    const domain = hostname.split('.').slice(-2).join('.')?.toLowerCase?.(); */
    const domain = getDomain();
    return DOMAIN_STORES[domain] || DOMAIN_STORES['brendon.hu']; // brendon.hu is the default store (eg localhost)
}

export function getStoreSettings() {
    const storeId = getDomainStoreId();
    return STORE_SETTINGS[storeId];
}

export function getStoreDefaultLanguage() {
    return getStoreSettings()?.localization?.defaultLanguage;
}

export function getStoreCurrencySymbol() {
    return getStoreSettings()?.localization?.defaultCurrency;
}

export function getStoreCurrency() {
    return getStoreSettings()?.localization?.currency;
}

export function getStoreEnableDecimal() {
    return getStoreSettings()?.localization?.enableDecimal;
}

export function getStoreDateFormat() {
    return getStoreSettings()?.localization?.dateFormat;
}

export function getStoreDateFormatCalendar() {
    return getStoreSettings()?.localization?.dateFormatCalendar;
}

export function getStoreYearMonthFormat() {
    return getStoreSettings()?.localization?.yearMonthFormat;
}

export function getStoreDateFormatVisual() {
    return getStoreSettings()?.localization?.dateFormatVisual;
}

export function getStoreLanguage() {
  return getStoreSettings()?.localization?.defaultLanguage;
}

export function getStoreDataFormatDateTime() {
    return getStoreSettings()?.localization?.dateFormatDateTime;
}

export function isLastNameFirst() {
    return getStoreSettings()?.localization?.lastNameFirst;
}

export function formatAddress(
    zip,
    city,
    country,
    postfixes = { zip: ' ', city: ', ', country: '' },
) {
    const formatedOrder = [];
    if (getDomainStoreId() == 1) {
        if (zip) {
            formatedOrder.push(`${zip}${postfixes?.zip}`);
        }
        if (city) {
            formatedOrder.push(`${city}${postfixes?.city}`);
        }
        if (country) {
            formatedOrder.push(`${country}${postfixes?.country}`);
        }
        return formatedOrder.join('');
    } else {
        if (zip) {
            formatedOrder.push(`${zip}${postfixes?.zip}`);
        }
        if (city) {
            formatedOrder.push(`${city}${postfixes?.city}`);
        }
        if (country) {
            formatedOrder.push(`${country}${postfixes?.country}`);
        }
        return formatedOrder.join('');
    }
}
