import React from 'react';
import { RouterProvider } from 'react-router-dom';
import baseRouter from './routes/baseRouter';
import { ResourceProvider } from './contexts/ResourceContext';
import { AuthProvider } from './contexts/AuthProvider';
import DevAuthGuard from './DevAuthGuard';

const RootEntry = () => {
  return (
    <ResourceProvider>
      <AuthProvider>
        <DevAuthGuard>
          <RouterProvider router={baseRouter} />
        </DevAuthGuard>
      </AuthProvider>
    </ResourceProvider>
  );
};

export default RootEntry;
