import React, { useState, useEffect, useLayoutEffect } from "react";
import BannerZone from '../../banners/BannerZone';
import TopicService from "../../services/topic";
import { Helmet } from "react-helmet";
import CountDownTimer from "../../components/CountDownTimer";

const TopicDetails = ({id: topicId}) => {
    const [topicData, setTopicData] = useState({});
    const [htmlTitle, setHtmlTitle] = useState();

    useEffect(() => {
      const getTopic = async () => {
        if (topicId) {
          setTopicData(await TopicService.getTopic(topicId));
        }
      }
      getTopic();
  
    }, [setTopicData, topicId]);

    useLayoutEffect(() => { 
        if (topicData?.MetaDescription != null) {
            document.querySelector('meta[name="description"]:not([data-react-helmet])')?.remove()
        }
    }, [topicData?.MetaDescription]);

    useLayoutEffect(() => {
        const originalTitle = document.querySelector('title').innerText;
        const titleAddon = topicData?.Title;
        setHtmlTitle((titleAddon && titleAddon!='' ?  titleAddon + ' | ' : '') + originalTitle);
    }, [setHtmlTitle, topicData?.Title]);


    return (
        <>
            <Helmet>
                <title>{ htmlTitle }</title>
                {topicData?.MetaDescription && <meta name="description" content={topicData?.MetaDescription}></meta>}
                {topicData?.NoIndex && <meta name="robots" content="noindex,follow" />}
            </Helmet>
            <div className="mt-5">
                <h1 className="font-size-1-125 mb-4">{ topicData?.Title }</h1>
                <CountDownTimer timerDate={ topicData?.TimerTime || ''} className="mb-3" />
                <BannerZone bannerBlockId={ 5 } topicId={ topicId } ContainerClass={ "mb-3" } />
                <div className="formatted-content" dangerouslySetInnerHTML={ { __html: topicData?.Body } }></div>
                <BannerZone bannerBlockId={ 10 } topicId={ topicId } ContainerClass={ "mb-3" } />
            </div>
        </>
    )
};

export default TopicDetails;
