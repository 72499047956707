import { object, string } from 'yup';
import { legacy_t as t} from '../../contexts/ResourceContext';
import { FORM_FIELD_LENGTHS } from '../constants';
import { isSpaceFilled } from '../../utils/validation';

const shippingAddressSchema = object({
    ReceiverName: string().transform(isSpaceFilled).required(
        t('brendon.checkout.shippingaddress.validation.companyRequired'),
    ),
    ZipPostalCode: string().transform(isSpaceFilled)
        .required(
            t('brendon.checkout.shippingaddress.validation.postalCodeRequired'),
        )
        .max(
            FORM_FIELD_LENGTHS.ZIP,
            t(
                'brendon.checkout.shippingaddress.validation.postalLength',
            ).replace('#0#', FORM_FIELD_LENGTHS.ZIP),
        ),
    City: string().transform(isSpaceFilled)
        .required(t('brendon.checkout.shippingaddress.validation.cityRequired'))
        .max(
            50,
            t('brendon.checkout.shippingaddress.validation.cityLength').replace(
                '#0#',
                50,
            ),
        ),
    Address: string().transform(isSpaceFilled)
        .required(
            t('brendon.checkout.shippingaddress.validation.addressRequired'),
        )
        .max(
            50,
            t(
                'brendon.checkout.shippingaddress.validation.addressLength',
            ).replace('#0#', 50),
        ),
});

export default shippingAddressSchema;
