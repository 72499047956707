import React, { useState, useEffect, createRef, useRef, useMemo } from 'react';
import Loader from '../../../../components/Loader';
import CheckoutStepTitle from '../../../../components/Checkout/CheckoutStepTitle';
import GLSDropoffMap from '../../../../components/3rdPartyScripts/GLSDropoffMap';
import CheckoutStepBack from '../../../../components/Checkout/CheckoutStepBack';
import VariantComboList from '../../../../components/Elements/ComboList/VariantComboList';
import { Form, Formik } from 'formik';
import createGLSDropoffSchema from '../../../../models/validation/checkoutGLSDropoffSchema';
import InputGroup from '../../../../elements/forms/InputGroup';
import {
    faShoppingCart,
    faPenToSquare,
} from '@fortawesome/pro-light-svg-icons';
import Button from '../../../../elements/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { glsDropoff } from '../../../../services/checkout';
import { initalOptionsCommand } from '../../../../utils/controller';
import withStore from '../../../../hoc/withStore';
import { useDispatch } from 'react-redux';
import { showError } from '../../../../store/notification/actions';
import { getStoreDefaultLanguage } from '../../../../utils/localization';
import { useResource } from '../../../../contexts/ResourceContext';

const GLSDropoff = ({ step, changeStep, nextStep }) => {
    const { t } = useResource();
    const formRef = useRef(null);
    const [isPageLoading, setPageLoading] = useState(true);
    const [GLSScriptLoaded, setGLSScriptLoaded] = useState(false);
    const [dropoffDetails, setDropoffDetails] = useState({
        loadedData: null,
        dropoffData: null,
    });
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();

    const mapRef = createRef(null);

    useEffect(() => {
        const getGLSDropoff = async () => {
            const { InitOption, ...data } = await glsDropoff.get();
            if (!initalOptionsCommand(InitOption, null, nextStep)) {
                return;
            }
            setDropoffDetails((prevDetails) => ({
                ...prevDetails,
                loadedData: data,
            }));

            setPageLoading(false);
        };
        getGLSDropoff();
    }, []);

    const { loadedData, dropoffData } = dropoffDetails || null;
    const GLSCountry = useMemo(() => getStoreDefaultLanguage(), []);

    return (
        <>
            {isPageLoading ? (
                <Loader isLoading />
            ) : (
                <>
                    <CheckoutStepTitle
                        description={t('checkout.dropoff.title')}
                    />
                    <CheckoutStepBack
                        title={t('checkout.progress.back.deliverymodes')}
                    />
                    <div className="row justify-content-center">
                        {GLSScriptLoaded && <VariantComboList
                            variant="link"
                            items={[
                                dropoffData
                                    ? {
                                          name: dropoffData?.name,
                                          beforeDescription: `${dropoffData?.contact?.postalCode} ${dropoffData?.contact?.city}, ${dropoffData?.contact?.address}`,
                                          isEnabled: true,
                                      }
                                    : {
                                          name: t(
                                              'brendon.checkout.dropoff.notselected',
                                          ),
                                          subName: t(
                                              'brendon.checkout.dropoff.notselected.content',
                                          ),
                                          isEnabled: true,
                                      },
                            ]}
                            onSelect={() => () => {
                                mapRef?.current?.showModal();
                            }}
                            containerClassName="col-12 col-sm-8 col-md-8 col-lg-6"
                            containerHoverClassName="border-hover-dark"
                            textHoverClassName="text-dark"
                            itemTextClassName="ml-2-5"
                            itemNameClassName="text-uppercase"
                            noIconClassName="ml-2"
                            /*                             detailsLinkText={t(
                                'brendon.checkout.shoplist.shopdetails',
                            )} */
                            iconType={
                                dropoffData ? faPenToSquare : faLongArrowRight
                            }
                        />}
                    </div>
                    {dropoffData && (
                        <div className="row justify-content-center">
                            <Formik
                                innerRef={formRef}
                                enableReinitialize
                                validateOnChange
                                validateOnMount
                                validationSchema={createGLSDropoffSchema()}
                                initialTouched={{
                                    name: loadedData?.CustomerName
                                        ? true
                                        : false,
                                    email: loadedData?.Email ? true : false,
                                    phone: loadedData?.PhoneNumber
                                        ? true
                                        : false,
                                    city: loadedData?.City ? true : false,
                                    postalCode: loadedData?.ZippPostalCode
                                        ? true
                                        : false,
                                    street: loadedData?.Address ? true : false,
                                }}
                                initialValues={{
                                    name: loadedData?.CustomerName || '',
                                    email: loadedData?.Email || '',
                                    phone: loadedData?.PhoneNumber || '',
                                    city: loadedData?.City || '',
                                    postalCode:
                                        loadedData?.ZippPostalCode || '',
                                    street: loadedData?.Address || '',
                                    /*                                     houseNumber: '', */
                                    country: loadedData?.Country || '',
                                }}
                                onSubmit={async (values, setSubmitting) => {
                                    setSaveLoading(true);
                                    try {
                                        const {
                                            IsSuccess,
                                            NextSite,
                                            Message,
                                        } = await glsDropoff.post({
                                            Id: dropoffData?.id,
                                            DropoffPointName: dropoffData?.name,
                                            Contact: {
                                                CountryCode:
                                                    dropoffData?.contact
                                                        ?.countryCode,
                                                City:
                                                    dropoffData?.contact?.city,
                                                PostalCode:
                                                    dropoffData?.contact
                                                        ?.postalCode,
                                                Address:
                                                    dropoffData?.contact
                                                        ?.address,
                                                Name:
                                                    dropoffData?.contact
                                                        ?.name || '',
                                                Email:
                                                    dropoffData?.contact
                                                        ?.email || '',
                                            },
                                            SecondaryAddress: {
                                                Phone: values.phone,
                                                Email: values.email,
                                                Name: values.name,
                                                City: values.city,
                                                Address: values.street,
                                                PostalCode: values.postalCode,
                                            },
                                        });
                                        if (IsSuccess) {
                                            nextStep(NextSite);
                                        } else {
                                            dispatch(showError(Message));
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        setSaveLoading(false);
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({ values, isValid/* , dirty */ }) => {
                                    const submitDisabled =
                                        !isValid /* || !dirty */ || !dropoffData?.id;
                                    return (
                                        <Form
                                            autoComplete="off"
                                            className="col-12 col-sm-8 col-md-8 col-lg-6"
                                        >
                                            <InputGroup
                                                label={t(
                                                    'brendon.checkout.dropoff.name',
                                                )}
                                                variation="wide"
                                                name="name"
                                                errorType="input-after-always"
                                                feedbackIcons
                                                required
                                            />

                                            <InputGroup
                                                label={t(
                                                    'brendon.checkout.dropoff.email',
                                                )}
                                                variation="wide"
                                                name="email"
                                                errorType="input-after-always"
                                                feedbackIcons
                                                required
                                                readonly
                                            />

                                            <InputGroup
                                                label={t(
                                                    'brendon.checkout.dropoff.phone',
                                                )}
                                                variation="wide"
                                                name="phone"
                                                errorType="input-after-always"
                                                feedbackIcons
                                                required
                                            />

                                            <div>
                                                <h2 className="font-size-1-25 mb-2-25 font-weight-normal">
                                                    {t(
                                                        'brendon.checkout.dropoff.reserveAddressTitle',
                                                    )}
                                                </h2>
                                                <div className="mb-3">
                                                    {t(
                                                        'brendon.checkout.dropoff.reserveAddressDesc',
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <InputGroup
                                                        label={t(
                                                            'brendon.checkout.dropoff.postalcode',
                                                        )}
                                                        variation="wide"
                                                        name="postalCode"
                                                        errorType="input-after-always"
                                                        feedbackIcons
                                                        required
                                                    />
                                                </div>
                                                <div className="col-8">
                                                    <InputGroup
                                                        label={t(
                                                            'brendon.checkout.dropoff.city',
                                                        )}
                                                        variation="wide"
                                                        name="city"
                                                        errorType="input-after-always"
                                                        feedbackIcons
                                                        required
                                                    />
                                                    {/*     <InputGroup
                                                        label={t(
                                                            'brendon.checkout.dropoff.housenumber',
                                                        )}
                                                        variation="wide"
                                                        name="houseNumber"
                                                        errorType="input-after-always"
                                                        feedbackIcons
                                                        required
                                                    /> */}
                                                </div>
                                            </div>
                                            <InputGroup
                                                label={t(
                                                    'brendon.checkout.dropoff.street',
                                                )}
                                                variation="wide"
                                                name="street"
                                                errorType="input-after-always"
                                                feedbackIcons
                                                required
                                            />

                                            <InputGroup
                                                label={t(
                                                    'brendon.checkout.dropoff.country',
                                                )}
                                                name="country"
                                                variation="wide"
                                                readonly
                                            />

                                            <div className="row form-group mt-4 mb-0">
                                                <div className="col-12">
                                                    <Button
                                                        type="submit"
                                                        size="lg"
                                                        variant="primary-disabled-gray"
                                                        className="w-100 font-size-1"
                                                        text={t(
                                                            'brendon.checkout.dropoff.save',
                                                        )}
                                                        isLoading={
                                                            isSaveLoading
                                                        }
                                                        disabled={
                                                            submitDisabled
                                                        }
                                                        beforeChildren
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faShoppingCart
                                                            }
                                                            className="mr-1 font-size-0-875"
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    )}
                    <GLSDropoffMap
                        ref={mapRef}
                        type="dialog"
                        country={GLSCountry}
                        onLoaded = {() => {
                            setGLSScriptLoaded(true);
                        }}
                        onSelect={(details) => {
                            setDropoffDetails((prevDetails) => ({
                                ...prevDetails,
                                dropoffData: details,
                            }));
                        }}
                    />
                </>
            )}
        </>
    );
};

export default withStore(GLSDropoff);
