import {createSelector} from "reselect";
import {isDiscounted} from "../../models/constants";

const FILTER_ITEM_STATES = {
    0: 'Unchecked',
    1: 'Checked',
    2: 'CheckedDisabled',
    3: 'Disabled'
};

function isChecked(filterItemState) {
    return filterItemState === 1 || filterItemState === 2;
}

export const selectFilter = state => state.get('filters');

export const getCategoryContextFilter = createSelector(selectFilter, filters => filters.get('categoryContextFilter'));

export const selectSpecificationFilters = createSelector(selectFilter, getCategoryContextFilter, (filters, categoryContextFilter) => {
    return !categoryContextFilter ? filters.get('specificationFilters') : filters.get('specificationFilters')
        .filter(filter => {
            const name = filter.get('Name');
            return categoryContextFilter.some(contextFilter => contextFilter.get('Name') === name);
        })
        .map(filter => {
            const name = filter.get('Name');
            const contextFilter = categoryContextFilter.find(contextFilter => contextFilter.get('Name') === name);
            return filter
                .set('OrderBy', contextFilter.get('OrderBy'))
                .set('Description', contextFilter.get('Description'));
        })
        .sort((filterA, filterB) => {
            return filterA.get('OrderBy') - filterB.get('OrderBy');
        });
});

export const selectAttributeFilters = createSelector(selectFilter, getCategoryContextFilter, (filters, categoryContextFilter) => {
    return !categoryContextFilter ? filters.get('attributeFilters') : filters.get('attributeFilters')
        .filter(filter => {
            const name = filter.get('Name');
            return categoryContextFilter.some(contextFilter => contextFilter.get('Name') === name);
        })
        .map(filter => {
            const name = filter.get('Name');
            const contextFilter = categoryContextFilter.find(contextFilter => contextFilter.get('Name') === name);
            return filter
                .set('OrderBy', contextFilter.get('OrderBy'))
                .set('Description', contextFilter.get('Description'));
        })
        .sort((filterA, filterB) => {
            return filterA.get('OrderBy') - filterB.get('OrderBy');
        });
});

export const selectSpecificationFiltersJS = createSelector(selectSpecificationFilters, filters => filters ? filters.toJS() : null);

export const selectAttributeFiltersJS = createSelector(selectAttributeFilters, filters => filters ? filters.toJS() : null);

const selectCategoryId = createSelector(selectFilter, filters => filters.get('categoryId'));
const selectPageNumber = createSelector(selectFilter, filters => filters.getIn(['pagination', 'PageNumber']));
const selectSortOptions = createSelector(selectFilter, filters => filters.get('sortOptions'));

export const selectSortOptionsJS = createSelector(selectSortOptions, sortOptions => sortOptions ? sortOptions.toJS() : null);

export const selectIsLoading = createSelector(selectFilter, filters => filters.get('isLoading'));

export const selectProductContent = createSelector(selectFilter, filters => filters.get('productContent'));

export const selectSelectedSortOption = createSelector(selectSortOptions, sortOptions => {
    if (!sortOptions) {
        return null;
    }
    const selected = sortOptions.find(option => option.get('Selected'));
    return selected ? selected.get('Value') : null;
});

export const selectActiveSpecificationFilters = createSelector(selectSpecificationFilters, filters =>
    filters
        .map(filter =>
            filter.update('FilterItems', filterItems =>
                filterItems.filter(filterItem => isChecked(filterItem.get('FilterItemState')))
            )
        )
        .filter(filter => filter.get('FilterItems').size > 0)
);

export const selectActiveAttributeFilters = createSelector(selectAttributeFilters, filters =>
    filters
        .map(filter =>
            filter.update('FilterItems', filterItems =>
                filterItems.filter(filterItem => isChecked(filterItem.get('FilterItemState')))
            )
        )
        .filter(filter => filter.get('FilterItems').size > 0));

export const selectActiveSpecificationFiltersJS = createSelector(selectActiveSpecificationFilters, filters => filters ? filters.toJS() : null);

export const selectActiveAttributeFiltersJS = createSelector(selectActiveAttributeFilters, filters => filters ? filters.toJS() : null);

export const selectActiveFiltersCount = createSelector(selectActiveAttributeFilters, selectActiveSpecificationFilters, (attributeFilters, specificationFilters) => {
    const attributeFiltersCount = attributeFilters
        .reduce((count, filter) => count +
            filter
                .get('FilterItems')
                .filter(filterItem => isChecked(filterItem.get('FilterItemState')))
                .size,
            0);

    const specificationFiltersCount = specificationFilters
        .reduce((count, filter) => count +
            filter
                .get('FilterItems')
                .filter(filterItem => isChecked(filterItem.get('FilterItemState')))
                .size,
            0);
    return attributeFiltersCount + specificationFiltersCount;
});

export const selectHasActiveFilters = createSelector(selectActiveFiltersCount, activeFiltersCount => activeFiltersCount > 0);

export const selectIsDiscountFilterChecked = createSelector(selectActiveAttributeFilters, selectActiveSpecificationFilters, (attributeFilters, specificationFilters) => {
    const attributeFiltersCount = attributeFilters
        .reduce((count, filter) => count +
            filter
                .get('FilterItems')
                .filter(filterItem => isDiscounted(filterItem.get('Id')))
                .size,
            0);

    const specificationFiltersCount = specificationFilters
        .reduce((count, filter) => count +
            filter
                .get('FilterItems')
                .filter(filterItem => isDiscounted(filterItem.get('Id')))
                .size,
            0);
    return attributeFiltersCount + specificationFiltersCount > 0;
});

export const selectHasDiscountFilter = createSelector(selectAttributeFilters, selectSpecificationFilters, (attributeFilters, specificationFilters) => {
    const attributeFiltersCount = attributeFilters
        .reduce((count, filter) => count +
            filter
                .get('FilterItems')
                .filter(filterItem => isDiscounted(filterItem.get('Id')))
                .size,
            0);

    const specificationFiltersCount = specificationFilters
        .reduce((count, filter) => count +
            filter
                .get('FilterItems')
                .filter(filterItem => isDiscounted(filterItem.get('Id')))
                .size,
            0);
    return attributeFiltersCount + specificationFiltersCount > 0;
});

export const selectFiltersPagination = createSelector(selectFilter, filters => filters.get('pagination'));

export const selectFiltersPaginationJS = createSelector(selectFiltersPagination, pagination => pagination ? pagination.toJS() : null);

export const selectProductCount = createSelector(selectFiltersPagination, pagination => pagination.get('TotalItems'));

const getFilterItemState = filterItem => {
    return FILTER_ITEM_STATES[filterItem.FilterItemState || 0];
};

const getSpecificationFilterGroups = state => {
    const specificationFilters = selectSpecificationFiltersJS(state);

    return specificationFilters.map(filter => ({
        Id: filter.Id,
        IsMain: filter.IsMain ? true : undefined,
        FilterItems: filter.FilterItems.map(filterItem => ({
            Id: filterItem.Id,
            FilterItemState: getFilterItemState(filterItem)
        }))
    }));
};

const getAttributeFilterGroups = state => {
    const attributeFilters = selectAttributeFiltersJS(state);

    return attributeFilters.map(filter => ({
        Id: filter.Id,
        IsMain: filter.IsMain ? true : undefined,
        FilterItems: filter.FilterItems.map(filterItem => ({
            ValueId: filterItem.ValueId.toString(),
            ProductVariantAttributeIds: filterItem.ProductVariantAttributeIds.map(id => id.toString()),
            FilterItemState: getFilterItemState(filterItem)
        }))
    }));
};

export function getFilterQuery(state, isPagination, query = '') {
    const categoryId = selectCategoryId(state);

    return {
        categoryId,
        manufacturerId: '0',
        vendorId: '0',
        priceRangeFilterModel7Spikes: null,
        specificationFiltersModel7Spikes: {
            CategoryId: categoryId,
            ManufacturerId: '0',
            VendorId: '0',
            SpecificationFilterGroups: getSpecificationFilterGroups(state)
        },
        attributeFiltersModel7Spikes: {
            CategoryId: categoryId,
            ManufacturerId: '0',
            VendorId: '0',
            AttributeFilterGroups: getAttributeFilterGroups(state),
            Priority: 1
        },
        pageNumber: isPagination ? selectPageNumber(state) : '1',
        orderby: selectSelectedSortOption(state) || '0',
        viewmode: null,
        pagesize: 0,
        queryString: query,
        shouldNotStartFromFirstPage: true,
        keyword: '',
        searchCategoryId: '0',
        searchManufacturerId: '0',
        searchVendorId: '0',
        priceFrom: '',
        priceTo: '',
        includeSubcategories: 'False',
        searchInProductDescriptions: 'False',
        advancedSearch: 'False',
        isOnSearchPage: 'False',
        inStockFilterModel: null
    }
}
